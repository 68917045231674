import { Injectable } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { Subject } from 'rxjs';

@Injectable()
export class WorkService {
  private apiPath = '/profile/work';
  private works;

  // データ受け渡し用
  private sharedWorks = new Subject<any>();
  public sharedWorks$ = this.sharedWorks.asObservable();

  constructor(private common: CommonService) {}

  /* API */
  // 職歴取得
  getWork(reset: boolean) {
    if (!reset && this.works) {
      this.sharedWorks.next(this.works);
      return;
    }

    this.common
      .apiGet(this.apiPath)
      .then(res => {
        this.works = res.data[Constant.apWorkCareer];
        this.setJob(res.data[Constant.apWcDesirejob]);
        this.sharedWorks.next(this.works);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.sharedWorks.next(Constant.apError);
      });
  }

  // 追加、更新
  postWork(data, id?): Promise<any> {
    let apiPath = this.apiPath;
    if (id) {
      apiPath += '/' + id;
      return this.common.apiPut(apiPath, data);
    } else {
      return this.common.apiPost(apiPath, data);
    }
  }

  // 削除
  delWork(id): Promise<any> {
    const apiPath = this.apiPath + '/' + id;
    return this.common.apiDel(apiPath);
  }

  /* プライベート */
  // 職種を職歴リストに含める
  private setJob(jobArray) {
    const jobs = {};
    jobArray.forEach(element => {
      if (jobs[element[Constant.apMainId]]) {
        jobs[element[Constant.apMainId]].push(element);
      } else {
        jobs[element[Constant.apMainId]] = [element];
      }
    });
    this.works.forEach(element => {
      if (jobs[element.id]) {
        element.jobName = this.common.setShowJobNameAry(jobs[element.id]);
        element.category = jobs[element.id];
      } else {
        element.jobName = [];
        element.category = [];
      }
    });
  }
}
