<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<div class="form">
  <form [formGroup]="form" *ngIf="form">
    <div class="mail-attention" *ngIf="mode === Constant.lgModeSignup">
      プライベートのメールアドレスでご登録ください。
      <span class="orange-text">学校や企業のメールアドレスでのご登録はオススメしていません。</span>
    </div>
    <ng-container *ngIf="mode === Constant.lgModeLogin && companyName">
      <div>
        このページからログインすると、<a (click)="onCompanyTop()">{{ companyName }}</a>とつながります。
      </div>
      <div class="what-tsunagaru-area">
        <img src="assets/common/question.svg" (click)="onWhatIsTalentPool()">
        <a (click)="onWhatIsTalentPool()">つながるとは</a>
      </div>
    </ng-container>

    <mat-form-field floatLabel="always" class="input-area">
      <mat-label>メールアドレス</mat-label>
      <input matInput type="email" formControlName="email" name="email" required>
      <mat-error *ngIf="email.hasError('required') || email.hasError('email')">メールアドレスは正しい形式で入力してください。
      </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>パスワード</mat-label>
      <input matInput type="password" formControlName="password" name="password" required />
      <mat-hint align="end" *ngIf="mode == Constant.lgModeSignup">※英字と数字の組み合わせ、8桁以上</mat-hint>
      <mat-error *ngIf="mode == Constant.lgModeSignup && password.invalid && password.dirty">{{ Constant.msgErrorPasswordFormat }}
      </mat-error>
      <mat-error *ngIf="mode == Constant.lgModeLogin && password.invalid && password.dirty">パスワードを入力してください。
      </mat-error>
    </mat-form-field>
    <mat-error *ngIf="errorMessage" class="error-message">{{ errorMessage }}</mat-error>

    <ng-container *ngIf="mode === Constant.lgModeSignup">
      <div class="consent">
        CaLinの<a (click)="onAgreement()">利用規約</a>および
        <a (click)="onPrivacy()">プライバシーポリシー</a>に同意のうえ、「同意して確認メールを送信」を押してください。
      </div>
    </ng-container>

    <div class="button-container">
      <button *ngIf="mode == Constant.lgModeLogin" (click)="onLogin()" mat-raised-button color="accent"
        [disabled]="showSpinner">
        ログイン
      </button>
      <button *ngIf="mode == Constant.lgModeSignup" (click)="onConfirm()" mat-raised-button color="accent"
        [disabled]="!form.valid || showSpinner">
        同意して確認メールを送信
      </button>
    </div>

  </form>

</div>

<ng-container *ngIf="mode === Constant.lgModeLogin">
  <div class="new-regist">
    初めてご利用の方
    <br />
    <button mat-raised-button (click)="onSignUp()" class="new-btn">新規アカウント登録</button>
  </div>
  <div class="center-text">
    パスワードをお忘れの方は
    <a (click)="onForgetPass()">こちら</a>
  </div>
  <div class="center-text" *ngIf="companyName">
    <a (click)="onCompanyTop()">{{ companyName}} トップ</a>
  </div>
  <div class="loginform-footer-area">
    <mat-divider class="divider"></mat-divider>
    <ng-container *ngTemplateOutlet="footerConsent"></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="mode === Constant.lgModeSignup">
  <div class="center-text tologin">
    すでにアカウントをお持ちの方<br />
    <button mat-raised-button (click)="onAlreadyAccount()" class="new-btn">ログインページへ</button>
  </div>
  <div class="center-text" *ngIf="companyName">
    <a (click)="onCompanyTop()">{{ companyName}} トップ</a>
  </div>
  <div class="loginform-footer-area">
    <mat-divider class="divider"></mat-divider>
    <ng-container *ngTemplateOutlet="footerConsent"></ng-container>
  </div>
</ng-container>
<ng-template #footerConsent>
  <div class="consent-div">
    <ng-container *ngIf="companyName">{{ companyName }}</ng-container><ng-container *ngIf="!companyName">企業各社</ng-container>では、みなさまとのつながりを採用サービス
    <a [href]="Constant.footerUrlTalentLp" target="_blank">CaLin <img src="../../assets/lp/launch_green.png" width="13" height="13"></a>
    で管理しています。
    <ng-container *ngIf="companyName">{{ companyName }}</ng-container><ng-container *ngIf="!companyName">企業</ng-container>とつながるにはCaLinのアカウントを作成する必要があります。
    CaLinのご利用は完全無料です。
  </div>
</ng-template>
