import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';

@Component({
  selector: 'app-slider-category',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './slider-category.component.html',
  styleUrl: './slider-category.component.css'
})
export class SliderCategoryComponent {
  @Input() data;
  public readonly Constant = Constant;
  public sliderNo = 0
  public isLikePosting = false
  private sliderPostion = 0

  @ViewChild('calElm') calElm;

  constructor(
    private common: CommonService,
  ) { }

  prevSlide() {
    const col = this.calElm.nativeElement.querySelector('.item')
    let moveCnt = 3
    if (this.sliderNo - moveCnt < 0) {
      moveCnt = this.sliderNo
    }
    this.sliderPostion += (col.clientWidth + 30) * moveCnt
    this.sliderNo -= moveCnt
    this.calElm.nativeElement.style.transform = 'translateX(' + this.sliderPostion.toString() + 'px)'
  }

  nextSlide() {
    const col = this.calElm.nativeElement.querySelector('.item')
    let moveCnt = 3
    if (this.sliderNo + moveCnt * 2 > this.data.list.length) {
      moveCnt -= this.sliderNo + moveCnt * 2 - this.data.list.length
    }
    this.sliderPostion += (col.clientWidth + 30) * -1 * moveCnt
    this.sliderNo += moveCnt
    this.calElm.nativeElement.style.transform = 'translateX(' + this.sliderPostion.toString() + 'px)'

  }

  onTop() {
    window.scroll(0, 0)
    this.common.preCheck()
  }
}
