import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Type, ValueProvider } from '@angular/core';
import { AppOverlayRef } from './overlay-ref';

/**
 * Overlayで表示されるComponentに渡されるデータを受け取るInjectionToken
 */
 export const APP_OVERLAY_DATA = new InjectionToken<any>('APP_OVERLAY_DATA');

@Injectable({
  providedIn: 'root'
})
export class PdfOverlayService {

  constructor(
    private readonly _injector: Injector,
    private readonly _overlay: Overlay,
  ) {}

  /**
   * Overlayを表示します。
   */
  show<TComponent>(component: Type<TComponent>, data: any): void {
    let positionStrategy: GlobalPositionStrategy = this._overlay.position().global();
    positionStrategy = positionStrategy.centerVertically().centerHorizontally();

    const config: OverlayConfig = {
      positionStrategy,
      width: '100vw',
      height: 'auto',
      hasBackdrop: true,
      backdropClass: ['pdf-backdrop-class']
    };
    const overlayRef: OverlayRef = this._overlay.create(config);

    // 表示するComponentに渡すデータを含むInjectorの作成
    const appOverlayRef: AppOverlayRef<TComponent> = new AppOverlayRef<TComponent>(overlayRef);
    const injector: Injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: AppOverlayRef, useValue: appOverlayRef } as ValueProvider,
        { provide: APP_OVERLAY_DATA, useValue: data } as ValueProvider,
      ],
    });

    const componentPortal: ComponentPortal<TComponent> = new ComponentPortal(component, null, injector);
    overlayRef.attach(componentPortal);

    // 背景クリックで閉じる
    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
  }
}
