import { Component, OnInit, Input, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Constant } from '../../constant';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../shared/common.service';
import { CommonCompanyService } from './../../shared/common-company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-footer',
  templateUrl: './company-footer.component.html',
  styleUrls: ['./company-footer.component.css']
})
export class CompanyFooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() companyInfo;
  public readonly Constant = Constant;
  public entryUrl = '';
  public linkCnt = Array(3)
  public registering = false;  // プール登録中フラグ
  private contact: string;
  private contactQuestion;  // 接点の質問
  private subscriptionTsunagaruInfo: Subscription;
  private currentScrollPosition

  constructor(
    private common: CommonService,
    private commonCompany: CommonCompanyService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    // 採用トップへのリンク
    this.entryUrl = Constant.rpLogin + Constant.slash + this.companyInfo.code;

    // 変換が必要なテキストの変換を実施
    this.companyInfo = this.common.convertCompanyInfoText(this.companyInfo);

    // ロゴ画像取得
    this.getCompanyLogo()
  }

  ngAfterViewInit(): void {
      // つながる処理の終了
    this.subscriptionTsunagaruInfo = this.commonCompany.sharedTsunagaruExit$.subscribe(data => {
      this.registering = false;
    });
  }

  ngOnDestroy(): void {
    this.commonCompany.progressViewDetach();

    if (this.subscriptionTsunagaruInfo) {
      this.subscriptionTsunagaruInfo.unsubscribe();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // フッターボタン非表示、表示
    const footerBtn = document.getElementById(Constant.elementIdFooterBtn)
    if (footerBtn) {
      if (this.currentScrollPosition < window.scrollY) {
        footerBtn.classList.add(Constant.elementClassHidden);
      } else {
        footerBtn.classList.remove(Constant.elementClassHidden);
      }
    }
    this.currentScrollPosition = window.scrollY
  }

  // ページトップへ
  onTop() {
    window.scroll(0, 0);
    this.common.preCheck().then(res => {});
  }

  // オフラインチェック
  checkOnline(): Boolean {
    return this.common.preCheckWindowOpen();
  }

  // つながる
  onTsunagaru() {
    this.registering = true;
    this.commonCompany.progressViewAttach();

    this.common.preCheck().then(res => {
      this.contact = localStorage.getItem(Constant.lsContactCode  + this.companyInfo.code);
      if (this.contact) {
        // 質問取得
        this.commonCompany.getContactInfo(this.companyInfo.code, this.contact).then(res => {
          this.contactQuestion = res.data.question;
          this.commonCompany.onTsunagaru(
            this.companyInfo.code, this.companyInfo.name, this.auth.loggedIn.value, this.contact, this.contactQuestion
          );
        })
        .catch(err => {
          this.registering = false;
          this.common.showToastMessage(Constant.msgTsunagaruError);
          this.commonCompany.progressViewDetach();
        });

      } else {
        this.commonCompany.onTsunagaru(this.companyInfo.code, this.companyInfo.name, this.auth.loggedIn.value, null, null);
      }
    })
    .catch(err => {
      this.registering = false;
      this.commonCompany.progressViewDetach();
    });
  }

  // ロゴ画像取得
  private getCompanyLogo() {
    this.common.getCompanyLogo(this.companyInfo.code)
    .then(url => {
      this.companyInfo.logoUrl = url;
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
    });
  }
}
