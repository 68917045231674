import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';

@Component({
  selector: 'app-tsunagaru-dialog',
  templateUrl: './tsunagaru-dialog.component.html',
  styleUrls: ['./tsunagaru-dialog.component.css']
})
export class TsunagaruDialogComponent implements OnInit {
  public name = Constant.textKigyo;
  public blueFlg = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.companyName) {
      this.name = this.data.companyName;

      if (this.data.blueFlg === false) {
        this.blueFlg = false;
      } else {
        this.blueFlg = true;
      }
    }
  }
}
