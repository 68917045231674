<div class="text">認証コード（メールアドレス変更用）を送信します。</div>
<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<div class="error-message">
  <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
</div>
<div class="button-container">
  <button type="button" (click)="onSend()" mat-raised-button color="accent" [disabled]="showSpinner">
    認証コードを送信
  </button>
</div>
