<ng-container *ngIf="mode == Constant.pfModeSend">
  <div class="text">メールアドレスを入力してください。</div>
  <form [formGroup]="form" class="form">
    <div class="form-field">
      <mat-form-field floatLabel="always" class="input-area">
        <mat-label>メールアドレス</mat-label>
        <input matInput type="email" formControlName="email" name="email" required />
        <mat-error *ngIf="email.invalid && email.dirty">メールアドレスは正しい形式で入力してください。
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>

      <div class="button-container">
        <button (click)="onSend()" mat-raised-button color="accent" [disabled]="!form.valid || mailSended">
          認証コードを送信
        </button>
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="mode == Constant.pfModeChange">
  <div class="text">認証コード（パスワード再設定用）をメールで送信しました。 <br />
    認証コード（パスワード再設定用）と新しいパスワードを入力してください。
  </div>
  <form [formGroup]="formChange" class="form">
    <div class="form-field">
      <mat-form-field floatLabel="always" class="input-area">
        <mat-label>メールアドレス</mat-label>
        <input matInput type="email" formControlName="email2" name="email" required />
        <mat-error *ngIf="email2.invalid && email2.dirty">メールアドレスは正しい形式で入力してください。
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="input-area">
        <mat-label>認証コード</mat-label>
        <input matInput formControlName="code" name="code" required />
        <mat-error *ngIf="code.invalid && code.dirty">認証コードを入力してください。
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="input-area">
        <mat-label>パスワード</mat-label>
        <input
          matInput
          type="password"
          formControlName="password"
          name="password"
          required
        />
        <mat-hint>{{ Constant.msgErrorPasswordFormat }}</mat-hint>
        <mat-error *ngIf="password.invalid && password.dirty">{{ Constant.msgErrorPasswordFormat }}</mat-error>
      </mat-form-field>

      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
      <mat-error *ngIf="expired">入力されたメールアドレスまたは認証コード（パスワード再設定用）は無効です。
        <br />認証コードの有効期限（発行後1時間）が切れた場合は、再度手続きをやり直してください。
        <br />
        <br />
        パスワードをお忘れの方は
        <a (click)="onPassAgain()">こちら</a>
      </mat-error>

      <div class="button-container">
        <button
          (click)="onChange()"
          mat-raised-button
          color="accent"
          [disabled]="!formChange.valid || isPasswordChanging"
        >
          パスワードを変更
        </button>
      </div>
    </div>
  </form>
</ng-container>
