import { Component } from '@angular/core';
import { Constant } from '../constant';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-lp-footer',
  templateUrl: './lp-footer.component.html',
  styleUrls: ['./lp-footer.component.css']
})
export class LpFooterComponent {
  public readonly Constant = Constant;

  constructor(
    private common: CommonService,
  ) { }

  // LPへ
  onTop() {
    window.scrollTo(0, 0);
  }

  // リンククリック時のAnalyticsEvent
  onClickGAEvent(action) {
    this.common.sendGAEvent(Constant.gaCategoryButtons, action);
  }
}
