import { Component, OnInit, Inject, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Constant } from './../../constant';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit, AfterViewInit {
  public safeHtml: SafeHtml;
  public safeTitle: SafeHtml
  public readonly Constant = Constant;
  public title;
  public message;
  public subMessage;
  public type;
  public common: CommonService;
  public buttonText;
  public closeFlg = false;
  public blueFlg = false;
  public fontSize = Constant.elementGeneralFontSize;
  private htmlElement;

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    // メッセージテキストの一部色変更のためにHTMLコードを変換
    this.safeTitle = this.domSanitizer.bypassSecurityTrustHtml(this.title);
    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
    this.type = this.data.type;
    this.common = this.data.common;
    if (this.type === Constant.mdTypeYesNo) {
      this.buttonText = this.data.buttonText;
    }
    if (this.data.subMessage) {
      this.subMessage = this.data.subMessage;
    }
    if (this.data.fontSize) {
      this.fontSize = this.data.fontSize;
    }
    if (this.data.closeFlg) {
      this.closeFlg = this.data.closeFlg;
    }
    if (this.data.blueFlg) {
      this.blueFlg = this.data.blueFlg;
    }
    this.htmlElement = this.elementRef.nativeElement;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // 本文のフォントサイズ設定
    const messageElementId = Constant.elementIdMessage;
    const messageElement = this.htmlElement.querySelector(messageElementId);
    const messageFontSize = this.fontSize + Constant.pixel;
    this.renderer.setStyle(messageElement, Constant.elementFontSize, messageFontSize);

    // 補足説明のフォントサイズ設定
    const subElementId = Constant.elementIdSubMessage;
    const subElement = this.htmlElement.querySelector(subElementId);
    if (subElement) {
      const subFontSize = this.fontSize - 1 + Constant.pixel;
      this.renderer.setStyle(subElement, Constant.elementFontSize, subFontSize);
    }
  }

  /* テンプレート起動 */
  onLeft() {
    this.dialogRef.close(Constant.mdBtnLeft);
  }

  onRight() {
    this.common
      .preCheck()
      .then(res => {
        this.dialogRef.close(Constant.mdBtnRight);
      })
      .catch(err => {});
  }

  onOK() {
    this.dialogRef.close(true);
  }

  onTsunagaru() {
    this.common.preCheck().then(res => {
      this.dialogRef.close(true);
    })
    .catch(err => {});
  }
}
