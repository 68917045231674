import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { SelectDialogComponent } from '../dialog/select-dialog/select-dialog.component';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { ProfileService } from '../shared/profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-new',
  templateUrl: './profile-new.component.html',
  styleUrls: ['./profile-new.component.css']
})
export class ProfileNewComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public page: number;
  public form1: FormGroup;
  public form2: FormGroup;
  public form3: FormGroup;
  public errorMessage: string;
  public errorMessageTerm: string;
  public errorMessageDate: string;
  public master = {};
  public profile;
  private setForm3Flag = false;
  private jobMaster = [];
  private placeMaster = []; // 勤務地マスタ
  public selectedDesireJob = [];
  public selectedDesireJobName = [];
  private selectedDesirePlace = [];
  public selectedDesirePlaceName: string[] = [];
  private subscriptionMaster: Subscription;
  private subscriptionProfile: Subscription;
  public isPageChanged = true;
  public lastNameMaxLength = false; // 「姓」の入力制御
  public firstNameMaxLength = false; // 「名」の入力制御
  public lastNameKanaMaxLength = false; // 「姓 かな」の入力制御
  public firstNameKanaMaxLength = false; // 「名 かな」の入力制御
  public address2MaxLength = false; // 「市区町村」の入力制御

  constructor(
    private auth: AuthService,
    private common: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private profileService: ProfileService
  ) {
    this.page = this.activatedRoute.snapshot.data['page'];
  }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlRegist);

    // マスタ取得
    if (this.page >= 2) {
      this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
        data.forEach(element => {
          this.master[element.type] = element.data;
        });
        // 職種マスタ作成
        this.jobMaster = this.common.convertJobMaster(this.master[Constant.pmDesireJobType]);

        // 勤務地マスタ作成
        this.placeMaster = this.common.convertJobMaster(this.master[Constant.pmDesireJobPlaceType]);

        if (this.page === 3) {
          if (!this.form3) {
            this.initForm3();
          }
          if (!this.setForm3Flag) {
            this.setValueForm3();
          }
        }
      });
      this.profileService.getMaster(false);
    }

    // プロフィール取得
    this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
      this.profile = data;
      // フォーム作成
      if (this.page === 1) {
        this.initForm1();
      } else if (this.page === 2) {
        this.initForm2();
      } else if (this.page === 3 && this.master[Constant.pmDesireEmployeementType] && !this.form3) {
        this.initForm3();
      }
      if (this.page === 3 && this.master[Constant.pmDesireEmployeementType] && !this.setForm3Flag) {
        this.setValueForm3();
      }
    });
    this.profileService.getProfile(true);

    // メッセージ表示
    setTimeout(() => {
      this.showMessage();
    }, 100);
  }

  ngOnDestroy() {
    this.subscriptionProfile.unsubscribe();
    if (this.subscriptionMaster) {
      this.subscriptionMaster.unsubscribe();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート */
  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  onInput(target, formNo, maxLenFlg, maxLenCnt) {
    const formName = target.getAttribute('formControlName');
    const beforeText = target.value;
    const changeText = beforeText.replace(/\r?\n/g, '');
    // 変換前の入力中テキストでも最大文字数を超えた時点でエラー(赤線)にする
    this[formNo].patchValue({[formName]: beforeText});
    // 改行不可の入力欄に改行入りテキストがペーストされた場合は改行無しに変換したテキストに置き換える
    if (beforeText !== changeText) {
      this[formNo].patchValue({[formName]: changeText});
    }
    // 入力内容が最大文字数を超えた場合は各フラグをtrueにする
    if (target.value.length > maxLenCnt) {
      this[maxLenFlg] = true;
    } else {
      this[maxLenFlg] = false;
    }
  }

  // 文字数カウント
  countInputText(txt) {
    return txt.length;
  }

  // フォーカス移動
  onNextFocus(len, value, nextElm) {
    this.common.nextFocus(len, value, nextElm);
  }

  // 未定チェック
  onChangeUndecided() {
    this.common.disabledTerm(this.form3.get(Constant.apActiveUndecidedFlag).value, this.form3);
  }

  // 登録して次へ
  onNext() {
    this.isPageChanged = false;
    this.errorMessageDate = '';
    this.errorMessageTerm = '';
    this.errorMessage = '';
    this.common
      .preCheck()
      .then(res => {
        this.nextExec();
      })
      .catch(err => {
        // オフラインでエラーの場合は登録ボタンのdisabledを無効に戻す
        this.isPageChanged = true;
      });
  }

  // 職種選択
  onDesireJob() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      data: {
        type: Constant.sdTypeJob,
        title: Constant.sdTypeJobTitle,
        items: this.jobMaster,
        selected: this.selectedDesireJob
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.selectedDesireJobName = [];
      this.selectedDesireJob = [];
      if (result.length > 0) {
        this.selectedDesireJob = result;
        this.selectedDesireJobName = this.common.setShowJobNameAry(result);
      }
    });
  }

  // 勤務地選択
  // TODO:職種と戻りの形式がずれてしまったので、あとで直したい。
  onDesirePlace() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      data: {
        type: Constant.sdTypePlace,
        title: Constant.sdTypePlaceTitle,
        items: this.placeMaster,
        selected: this.selectedDesirePlace
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.selectedDesirePlaceName = [];
      this.selectedDesirePlace = [];
      if (result && result.length > 0) {
        result.forEach(element => {
          this.selectedDesirePlace.push(element.id);
          this.selectedDesirePlaceName.push(element[Constant.apItemValue]);
        });
      }
    });
  }

  // 卒業（予定）年度選択
  onGraduateYear() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        type: Constant.sdTypeYear,
        title: Constant.sdTypeYearTitle,
        items: this.master[Constant.pmGraduateYearType],
        selected: this.profile[Constant.apGraduateYearType]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.profile[Constant.apGraduateYearType] = result[0];
      this.profile[Constant.apGraduateYearTypeName] = this.common.convertMasterIdToItemValue(
        this.profile[Constant.apGraduateYearType], this.master, Constant.pmGraduateYearType
      );
    });
  }


  /* プライベート関数 */
  // メッセージ表示
  private showMessage() {
    if (this.auth.companyName) {
      let title: string;
      let message: string;
      const name = this.auth.companyName;

      if (!this.auth.alreadyRegistFlg) {
        title = name + Constant.msgPoolNewTitle;
        message = Constant.msgPoolNewMessage;
      } else {
        title = name + Constant.msgPoolAlreadyTitle;
        message = Constant.msgPoolAlreadyMessage;
      }
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: { title: title, message: message, type: Constant.mdTypeOK }
      });
      this.auth.companyName = '';
      this.auth.alreadyRegistFlg = false;
    }
  }

  // フォーム作成
  private initForm1() {
    this.form1 = new FormGroup({
      last_name: new FormControl(this.profile.last_name, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName)
      ]),
      first_name: new FormControl(this.profile.first_name, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName)
      ]),
      last_name_kana: new FormControl(this.profile.last_name_kana, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName),
        Validators.pattern(/^[\u3040-\u309f|\u002d|\u002f|\u003d|\u30fb|\u30fc|\uff0f|\uff1d|\uff65]+$/)
      ]),
      first_name_kana: new FormControl(this.profile.first_name_kana, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName),
        Validators.pattern(/^[\u3040-\u309f|\u002d|\u002f|\u003d|\u30fb|\u30fc|\uff0f|\uff1d|\uff65]+$/)
      ]),
      telno: new FormControl(this.profile.telno, [
        Validators.pattern(/^[0-9]*$/),
        Validators.required,
        Validators.minLength(Constant.fvMinLengthTelNo)
      ])
    });
  }

  private initForm2() {
    this.form2 = new FormGroup({
      sex_id: new FormControl(this.profile.sex_id, [Validators.required]),
      birthday_year: new FormControl(this.profile.birthday_year, [
        Validators.required,
        Validators.min(Constant.fvMinYear),
        Validators.max(Constant.fvMaxYear),
        Validators.pattern(/^[0-9]*$/)
      ]),
      birthday_month: new FormControl(this.profile.birthday_month, [
        Validators.required,
        Validators.min(Constant.fvMinMonth),
        Validators.max(Constant.fvMaxMonth),
        Validators.pattern(/^[0-9]*$/)
      ]),
      birthday_day: new FormControl(this.profile.birthday_day, [
        Validators.required,
        Validators.min(Constant.fvMinDay),
        Validators.max(Constant.fvMaxDay),
        Validators.pattern(/^[0-9]*$/)
      ]),
      address1: new FormControl(this.profile.address1, [Validators.required, Validators.maxLength(5)]),
      address2: new FormControl(this.profile.address2, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthCityName)
      ])
    });
  }

  private initForm3() {
    const formControl = {};

    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      const keyString: string = 'desire_employeement_' + element.id;
      formControl[keyString] = new FormControl();
    });

    formControl[Constant.tfStartYear] = new FormControl('', [
      Validators.min(Constant.fvActiveStartYear),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.tfStartMonth] = new FormControl('', [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.tfEndYear] = new FormControl('', [
      Validators.min(Constant.fvActiveStartYear),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.tfEndMonth] = new FormControl('', [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.apActiveUndecidedFlag] = new FormControl();
    formControl[Constant.apDesirejobUndecidedFlag] = new FormControl();
    formControl[Constant.apGraduateYearSkipFlag] = new FormControl();
    formControl[Constant.apDesireAnnualIncome] = new FormControl('', [Validators.min(0), Validators.pattern(/^[0-9]*$/)]),
    this.form3 = new FormGroup(formControl);
  }

  // フォームに値をセット
  private setValueForm3() {
    if (!this.profile || !this.form3) {
      return;
    }
    this.setForm3Flag = true;

    // 活動時期
    const startAry = this.profile[Constant.apActivePlannedStart].split('/');
    const endAry = this.profile[Constant.apActivePlannedEnd].split('/');

    if (this.profile[Constant.apActiveUndecidedFlag] === Constant.prfFlagOnStr) {
      // 未定
      this.common.disabledTerm(true, this.form3);
    }

    if (startAry.length <= 1 || endAry.length <= 1) {
      startAry[0] = '';
      startAry[1] = '';
      endAry[0] = '';
      endAry[1] = '';
    }

    const formValue = {
      start_year: startAry[0],
      start_month: startAry[1],
      end_year: endAry[0],
      end_month: endAry[1],
      active_undecided_flag: Number(this.profile[Constant.apActiveUndecidedFlag]),
      desirejob_undecided_flag: this.profile[Constant.apDesirejobUndecidedFlag],
      graduate_year_skip_flag: this.profile[Constant.apGraduateYearSkipFlag],
      desire_annual_income: this.profile[Constant.apDesireAnnualIncome] === -1 ? '' :  this.profile[Constant.apDesireAnnualIncome],
    };

    // 雇用形態
    const employeementAry = this.profile[Constant.apDesireEmployeementId].split(',');
    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      const keyString: string = 'desire_employeement_' + element.id;
      const value: boolean = employeementAry.indexOf(element.id.toString()) > -1 ? true : false;
      formValue[keyString] = value;
    });

    this.form3.setValue(formValue);

    // 職種、勤務地
    this.selectedDesireJobName = this.common.setShowJobNameAry(this.profile[Constant.apDesirejob]);
    if (this.profile.desirejob_place) {
      this.selectedDesirePlaceName = this.profile.desirejob_place.split(',');
    } else {
      this.selectedDesirePlaceName = [];
    }
    this.selectedDesireJob = this.profile[Constant.apDesirejob];
    if (this.profile.desirejob_place_id) {
      this.selectedDesirePlace = this.profile.desirejob_place_id.split(',').map(Number);
    } else {
      this.selectedDesirePlace = [];
    }
  }

  private nextExec() {
    const data = {};
    if (this.page === 1) {
      // 1ページ目
      data[Constant.apBase] = [];
      for (const formName in this.form1.value) {
        if (this.form1.value.hasOwnProperty(formName)) {
          data[Constant.apBase].push({
            key: formName,
            value: this.common.replaceSpace(this.form1.value[formName])
          });
        }
      }
    } else if (this.page === 2) {
      // 2ページ目
      // 生年月日チェック
      const birthday = this.common.dateFromYearMonthDay(
        this.form2.value[Constant.apBirthdayYear],
        this.form2.value[Constant.apBirthdayMonth],
        this.form2.value[Constant.apBirthdayDay]
      );
      if (!birthday) {
        this.errorMessageDate = Constant.msgErrorInvalidDate;
        this.isPageChanged = true;
        return;
      }

      data[Constant.apBase] = [];
      for (const formName in this.form2.value) {
        if (
          formName === Constant.apBirthdayYear ||
          formName === Constant.apBirthdayMonth ||
          formName === Constant.apBirthdayDay
        ) {
          continue;
        }
        if (this.form2.value.hasOwnProperty(formName)) {
          data[Constant.apBase].push({
            key: formName,
            value: this.common.replaceSpace(this.form2.value[formName])
          });
        }
      }
      // 生年月日
      data[Constant.apBase].push({ key: Constant.apBirthday, value: birthday });
    } else if (this.page === 3) {
      // 3ページ目
      // 活動予定期間チェック
      const startYear = this.form3.get(Constant.tfStartYear).value;
      const startMonth = this.form3.get(Constant.tfStartMonth).value;
      const endYear = this.form3.get(Constant.tfEndYear).value;
      const endMonth = this.form3.get(Constant.tfEndMonth).value;

      let active_start = this.common.dateFromYearMonth(startYear, startMonth);
      let active_end = this.common.dateFromYearMonth(endYear, endMonth);
      const active_undecided = this.form3.get(Constant.apActiveUndecidedFlag).value;
      if (startYear || startMonth || endYear || endMonth) {
        if (active_undecided) {
          if (!active_start || !active_end || active_start > active_end ||
            isNaN(Date.parse(active_start)) || isNaN(Date.parse(active_end)) ) {
            // 未定でエラーの場合、期間をリセットして保存
            active_start = '';
            active_end = '';
          }
        } else {
          if (!active_start || !active_end) {
            this.errorMessageTerm = Constant.msgErrorInputAllTerm;
            this.isPageChanged = true;
            return;
          } else if (active_start > active_end) {
            this.errorMessageTerm = Constant.msgErrorInvalidTerm;
            this.isPageChanged = true;
            return;
          }
        }
      }

      // 雇用形態
      const desire_employeement = [];
      this.master[Constant.pmDesireEmployeementType].forEach(element => {
        if (this.form3.get('desire_employeement_' + element.id).value) {
          desire_employeement.push({ key: 'id', value: element.id });
        }
      });
      data[Constant.apDesireEmployeement] = desire_employeement;

      // 職種
      const desire_job = [];
      this.selectedDesireJob.forEach(element => {
        desire_job.push({
          large_key: 'id',
          large_value: element[Constant.apLargeId],
          key: 'id',
          value: element.id
        });
      });
      data[Constant.apDesirejob] = desire_job;

      // 勤務地
      const desire_place = [];
      this.selectedDesirePlace.forEach(element => {
        desire_place.push({ key: 'id', value: element });
      });
      data[Constant.apDesirejobPlace] = desire_place;

      data[Constant.apBase] = [
        { key: Constant.apActivePlannedStart, value: active_start },
        { key: Constant.apActivePlannedEnd, value: active_end },
        { key: Constant.apActiveUndecidedFlag, value: Number(active_undecided)},
        { key: Constant.apDesirejobUndecidedFlag, value: Number(this.form3.get(Constant.apDesirejobUndecidedFlag).value) }, // 職種未定
        { key: Constant.apGraduateYearType, value: this.profile[Constant.apGraduateYearType] },  // 卒業年度
        { key: Constant.apGraduateYearSkipFlag, value: Number(this.form3.get(Constant.apGraduateYearSkipFlag).value) },  // 卒業年度スキップ
        { key: Constant.apDesireAnnualIncome, value: this.form3.get(Constant.apDesireAnnualIncome).value },
      ];
    }

    // 登録
    this.profileService
      .postProfile(data)
      .then(result => {
        this.common.debug().log(result);
        if (result.status === Constant.OK) {
          this.page++;
          this.router.navigate([Constant.rpProfilenew + this.page]);
          this.isPageChanged = true;
        } else {
          this.errorMessage = result.message;
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        // 通信エラーの場合はエラートーストを表示し、登録ボタンのdisabledを無効に戻す
        this.common.showMessageGeneral(Constant.msgErrorNetwork);
        this.isPageChanged = true;
      });
  }

  // 活動予定時期用のバリデーション
  // 雇用形態
  desireEmploymentTypeValidation() {
    let isInvalid = true;
    this.master[Constant.pmDesireEmployeementType].forEach(element => {
      if (this.form3.get('desire_employeement_' + element.id).value) {
        isInvalid = false;
      }
    });
    return isInvalid;
  }

  isActivePlanValid() {
    let isInvalid = true;
    const active_undecided = this.form3.get(Constant.apActiveUndecidedFlag).value;
    if (active_undecided) {
      // 未定のチェックが入っていた場合はOK
      isInvalid = false;
    }

    const startYear = this.form3.get(Constant.tfStartYear).value;
    const startMonth = this.form3.get(Constant.tfStartMonth).value;
    const endYear = this.form3.get(Constant.tfEndYear).value;
    const endMonth = this.form3.get(Constant.tfEndMonth).value;

    if (startYear && startMonth && endYear && endMonth) {
      isInvalid = false;
    }

    return isInvalid;
  }

  isSexIdValid() {
    let isInvalid = true;
    const sexId = this.form2.get(Constant.apSexId).value;
    if (sexId !== Constant.prfSexNotSelected) {
      isInvalid = false;
    }
    return isInvalid;
  }

  // 職種選択のバリデーション
  isDesireJobValid() {
    if (this.form3.value[Constant.apDesirejobUndecidedFlag] || this.selectedDesireJob.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  isGraduateValid() {
    if (this.form3.value[Constant.apGraduateYearSkipFlag] || this.profile[Constant.apGraduateYearTypeName]) {
      return false;
    } else {
      return true;
    }
  }
}
