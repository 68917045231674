<div class="index">
  <app-lp-header page=""></app-lp-header>
  <div class="contents">
    <div class="title">公開中の求人一覧</div>

    <div *ngIf="showSpinner" class="spinner">
      <mat-spinner diameter="60"></mat-spinner>
    </div>

    <!-- ページング -->
    <ng-container *ngTemplateOutlet="paging"></ng-container>
    <ng-template #paging>
      <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagingNum($event)">
        <div class="custom-pagination">
          <div class="pagination-container">
            <ng-container *ngIf="allJobList && allJobList.length > 0">
              <div *ngIf="p.isFirstPage()" class="paging-link-container paging-disable">
                <span>＜</span>
              </div>
              <div mat-button *ngIf="!p.isFirstPage()" class="paging-link-container" (click)="p.previous()">
                <span class="paging-text">＜</span>
              </div>
              <div *ngFor="let page of p.pages">
                <div mat-button class="paging-link-container" (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value">
                  <span class="paging-text">{{ page.label }}</span>
                </div>
                <div class="paging-link-container current-page" *ngIf="p.getCurrent() === page.value">
                  <span class="paging-text current">{{ page.label }}</span>
                </div>
              </div>
              <div *ngIf="p.isLastPage()" class="paging-link-container paging-disable">
                <span>＞</span>
              </div>
              <div mat-button *ngIf="!p.isLastPage()" class="paging-link-container" (click)="p.next()">
                <span class="paging-text">＞</span>
              </div>
            </ng-container>
          </div>
        </div>
      </pagination-template>
    </ng-template>

    <div *ngFor="let joboffer of allJobList |
    paginate : {
      id: config.id,
      itemsPerPage: config.itemsPerPage,
      currentPage: config.currentPage,
      totalItems: config.totalItems
    }; index as idx" class="job">
      <mat-divider class="separator" *ngIf="idx > 0"></mat-divider>
      <div class="title-container">
        <a
          routerLink="/{{Constant.rpJobOfferDetail}}/{{joboffer.code}}/{{joboffer.joboffer_code}}"
          target="_blank"
        >
          {{ joboffer.title }}
        </a>
        <div class="generalfont companyname-area">{{ joboffer.name }}</div>
      </div>
      <div class="image-condition-container">
        <div *ngIf="joboffer.image_path != Constant.empty" class="jobimage-container">
          <a
            routerLink="/{{Constant.rpJobOfferDetail}}/{{joboffer.code}}/{{joboffer.joboffer_code}}"
            target="_blank"
          >
            <img class="image" src="{{ joboffer.image_url }}" *ngIf="joboffer.image_url" appImageLoadingError />
          </a>
        </div>
        <div class="jobcondition-area">
          <div class="display-flex">
            <div class="image-container"><img class="icon-small" src="assets/joboffer/map.png" /></div>
            <div class="generalfont">
              <span class="jobplace">{{ joboffer.job_place }} {{ joboffer.job_place_city }}</span>
              <span *ngIf="joboffer.sub_job_place">
                {{ Constant.comma }}{{ joboffer.sub_job_place }}
              </span>
            </div>
          </div>
          <div class="display-flex">
            <div class="image-container"><img class="icon-small" src="assets/joboffer/label.png" /></div>
            <div class="generalfont">{{ joboffer.job_category }}<span *ngIf="joboffer.job_category_middle"> > {{ joboffer.job_category_middle }}</span></div>
          </div>
          <div class="display-flex">
            <div class="image-container"><img class="icon-small" src="assets/joboffer/business.png" /></div>
            <div class="generalfont">{{ joboffer.employment_type }}</div>
          </div>
        </div>
      </div>
      <div class="display-flex generalfont">
        <div class="update_date">最終更新日：{{ joboffer.updated_at }}</div><div>求人公開日：{{ joboffer.release_date }}</div>
      </div>
    </div>

    <!-- ページング -->
    <ng-container *ngTemplateOutlet="paging"></ng-container>
  </div>
  <app-lp-footer></app-lp-footer>
</div>
