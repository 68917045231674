import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constant } from './../constant';
import { ContactTagDialogComponent } from '../dialog/contact-tag-dialog/contact-tag-dialog.component';
import { NeedLoginDialogComponent } from '../dialog/need-login-dialog/need-login-dialog.component';
import { CommonService } from './common.service';
import { AuthService } from '../auth/auth.service';
import { Observable ,  of ,  Subject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { catchError, map } from 'rxjs/operators';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressDialogComponent } from '../dialog/progress-dialog/progress-dialog.component';
import { sprintf } from 'sprintf-js';

@Injectable({
  providedIn: 'root'
})
export class CommonCompanyService {
  // タイトル連携
  private sharedHeaderData = new Subject<any>();
  public sharedHeaderData$ = this.sharedHeaderData.asObservable();

  // 表示中のページ通知
  private sharedHeaderPage = new Subject<any>();
  public sharedHeaderPage$ = this.sharedHeaderPage.asObservable();

  // 処理終了を通知する
  private sharedTsunagaruExit = new Subject<any>();
  public sharedTsunagaruExit$ = this.sharedTsunagaruExit.asObservable();

  // いいね処理終了を通知する
  private sharedLikeExit = new Subject<any>();
  public sharedLikeExit$ = this.sharedLikeExit.asObservable();

  private progressView;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private common: CommonService,
    private auth: AuthService,
    private title: Title,
    private meta: Meta,
    private overlay: Overlay,
  ) { }

  /**
   * 企業情報を取得する
   * @param {String} companyCode - 企業コード
   */
   public getCompanyInfo(companyCode) {
    const apiPath = '/company/info/' + companyCode;
    return this.common.apiPostBeforeLogin(apiPath)
      .then(res => {
        if (!res.data.name) {
          // 企業名が取得できない場合、エラーページへ遷移
          this.router.navigate([Constant.rpError]);
        }
        return res.data;
      })
      .catch(err => {
        if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
          this.common.debug().log(err);
        }
        return null;
      });
  }

  //
  setCompanyHeaderData(code, data, page) {
    const headerData = {
      code: code,
      name: data.name,
      displayName: data.display_name,
      homepage: data.homepage,
      cts_cnt: data.cts_cnt,
      jo_cnt: data.jo_cnt,
      page: page
    };

    this.sharedHeaderData.next(headerData);
  }

  // ヘッダーメニューactive切り替え
  setCompanyHeaderPage(page) {
    this.sharedHeaderPage.next(page);
  }

  // メインカラー切り替え
  setCompanyMainColor(color) {
    if (!color) {
      color = Constant.companyMainColor
    }
    document.body.style.setProperty('--company-main-color', '#' + color);
    document.body.style.setProperty('--company-main-color-alpha', '#' + color + '0c');
  }

  onFocusCheckLogin(loginFlg): Observable<boolean> {
    // ログインチェック
    return this.auth.isAuthenticated().pipe(
      map(res => {
        if (res) {
          return true;
        } else if (loginFlg) {
          this.common.showReloadDialog();
          return false;
        }
      })
    );
  }

  createProgressView() {
    // プログレスビューの設定
    const positionStrategy = this.overlay
      .position()
      .global()
      .width('100%')
      .height('100%');
    const overlayConfig = new OverlayConfig({ positionStrategy });
    this.progressView = this.overlay.create(overlayConfig);
  }

  progressViewAttach() {
    if (!this.progressView) {
      this.createProgressView()
    }
    this.progressView.attach(new ComponentPortal(ProgressDialogComponent));
  }

  progressViewDetach() {
    if (this.progressView) {
      this.progressView.detach();
    }
  }

  onTsunagaru(companyCode, companyName, isLogin, contactTag, contactQuestion) {
    if (isLogin) {
      // ログイン済み
      if (contactTag) {
        // 接点タグありの場合、接点履歴を取得
        this.getContactHistory(companyCode, contactTag).then(res => {
          if (res) {
            // 接点履歴あり
            contactTag = null
            this.tsunagaruExec(companyCode, companyName, contactTag);
          } else {
            // 接点履歴なし
            // アンケートダイアログを表示
            this.showContactTagDialog(companyCode, companyName, contactTag, contactQuestion);
          }
        })

      } else {
        this.tsunagaruExec(companyCode, companyName, contactTag);
      }
    } else {
      // 未ログインの場合、ログイン誘導ダイアログを表示
      this.showLoginDialog(companyCode, companyName);
      this.sharedTsunagaruExit.next(true);
    }
  }

  // タレントプール登録
  private registPool(companyCode, contactTag) {
    const apiPath = '/pool/' + companyCode;
    this.common.apiPost(apiPath).then(res => {
      // 接点確認、追加
      this.setContact(companyCode, contactTag);

    })
    .catch(err => {
      if (err.status === Constant.NG) {
        // NGは登録済みの場合もあるので、 成功と同様の処理とする
        this.auth.alreadyRegistFlg = true;
        // 接点確認、追加
        this.setContact(companyCode, contactTag);
      } else {
        this.sharedTsunagaruExit.next(false);
      }
    });
  }

  // 接点のアンケートダイアログ表示
  private showContactTagDialog(companyCode, companyName, contactTag, contactQuestion) {
    const dialogRef = this.dialog.open(ContactTagDialogComponent, {
      width: Constant.dlWidthNeedLogin,
      autoFocus: false,
      disableClose: true,
      data: {
        message: contactQuestion,
        companyName: companyName,
        type: Constant.enqTypePool,
        common: this.common
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === Constant.lsTrue) {
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionContactTagQuestionYes);
        this.tsunagaruExec(companyCode, companyName, contactTag);

      } else if (result === Constant.lsFalse) {
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionContactTagQuestionNo);
        localStorage.removeItem(Constant.lsContactCode + companyCode);
        this.tsunagaruExec(companyCode, companyName, null);
      }
    });
  }

  private tsunagaruExec(companyCode, companyName, contactTag) {
    this.common.preCheck().then(res => {
      this.auth.companyName = companyName;
      // プール登録
      this.registPool(companyCode, contactTag);
    })
    .catch(err => {
      this.sharedTsunagaruExit.next(false);
    });
  }

  // 要ログインダイアログ表示
  private showLoginDialog(companyCode, companyName) {
    const dialogRef = this.dialog.open(NeedLoginDialogComponent, {
      width: Constant.dlWidthNeedLogin,
      autoFocus: false,
      data: {
        companyName: companyName,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let url;
        if (result === Constant.rpLogin) {
          url = result;
        } else {
          url = result + '/' + companyCode;
        }

        this.router.navigate([url]);
      }
    });
  }

  private toMypage(companyCode) {
    const apiPath = '/profile';
    this.common.preCheck().then(check => {
      this.common.apiGet(apiPath).then(res => {
        // 追加質問チェックのため、企業コードを保存
        localStorage.setItem(Constant.lsRefererCompanyCode, companyCode);

        if (res.data.last_name === '') {
          // プロフィール新規登録
          this.router.navigate([Constant.rpProfilenew + 1]);
        } else {
          // マイページへ遷移
          this.router.navigate([Constant.rpMypage]);
        }
      })
      .catch(err => {
        if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
          this.common.debug().log(err);
          this.sharedTsunagaruExit.next(false);
        }
      });
    })
    .catch(err => {
      this.sharedTsunagaruExit.next(false);
    });
  }

  private setContact(companyCode, contactTag) {
    if (contactTag) {
      // 接点確認、追加
      this.getContactHistory(companyCode, contactTag).then(res => {
        if (res) {
          // 登録済み
          // マイページへ遷移
          this.toMypage(companyCode);
          // 接点タグを削除
          localStorage.removeItem(Constant.lsContactCode + companyCode);
        } else {
          // 未登録
          // 接点追加後マイページへ
          this.addContact(companyCode, contactTag).then(res2 => {
            this.toMypage(companyCode);
            // 接点タグを削除
            localStorage.removeItem(Constant.lsContactCode + companyCode);
          })
          .catch(err2 => {
            this.sharedTsunagaruExit.next(false);
          });
        }
      })
      .catch(err => {
        this.sharedTsunagaruExit.next(false);
      });

    } else {
      // マイページへ遷移
      this.toMypage(companyCode);
    }
  }

  // 接点情報取得
  getContactInfo(company, tag) {
    const apiPath = '/contact/' + company + '/' + tag + '/info';
    return this.common
      .apiGetBeforeLogin(apiPath)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // 接点履歴取得
  getContactHistory(company, tag): Promise<any> {
    const apiPath = '/contact/' + company + '/' + tag;
    return this.common
      .apiGet(apiPath)
      .then(res => {
        return Promise.resolve(true);
      })
      .catch(err => {
        return Promise.resolve(false);
      });
  }

  // 追加
  addContact(company, tag): Promise<any> {
    const apiPath = '/contact/' + company;
    const data = {
      tag: tag
    };
    return this.common.apiPost(apiPath, data);
  }

  // SEO設定
  setSeoMeta(title, description) {
    this.title.setTitle(title);
    this.meta.updateTag({name: 'description', content: description});
  }

  // メッセージダイアログ表示
  showMsgDialog(title: string, message: string, blueFlg: boolean = false): Observable<boolean> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        title: title,
        message: message,
        type: Constant.mdTypeOK,
        common: this.common,
        blueFlg: blueFlg
      }
    });

    return dialogRef.afterClosed().pipe(
      map(result => {
        return result;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  // YesNoダイアログ表示
  showYesNoDialog(title: string, message: string, blueFlg: boolean = false): Observable<boolean> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        title: title,
        message: message,
        type: Constant.mdTypeYesNo,
        common: this.common,
        buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes },
        blueFlg: blueFlg
      }
    });

    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result === Constant.mdBtnRight) {
          return true;
        } else {
          return false;
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  // 求人、コンテンツのPV登録
  postEntrypageview(companyCode, isLogin, page, detailId) {
    const apiPath = '/company/entrypageview';
    const body = {
      companycode: companyCode,
      page: page,
      detail_id: detailId
    };
    if (isLogin) {
      this.common.apiPost(apiPath, body);
    } else {
      const temp_id = localStorage.getItem(Constant.lsTempTalentId)
      if (temp_id) {
        body[Constant.apTempTalentId] = temp_id
      }
      this.common.apiPostBeforeLogin(apiPath, body);
    }
  }

  // PV記録のため、仮のID設定
  createTempTalentId() {
    let temp_id = localStorage.getItem(Constant.lsTempTalentId);
    if (!temp_id) {
      temp_id = window.navigator.userAgent.toLowerCase() + '-' + Date.now()
      if (temp_id.length > 300) {
        temp_id = temp_id.substring(0, 300)
      }
      localStorage.setItem(Constant.lsTempTalentId, temp_id)
    }
  }


  // 仮タレントIDのPV履歴等を更新
  updateTempActionPV() {
    const tempId = localStorage.getItem(Constant.lsTempTalentId)
    if (tempId) {
      const apiPath = '/company/entrypageview';
      const body = {}
      body[Constant.apTempTalentId] = tempId
      this.common.apiPut(apiPath, body)
      .then(res => {
        // 仮ID削除
        localStorage.removeItem(Constant.lsTempTalentId)
      })
      .catch(err => { });
    }
  }

  // 求人、コンテンツのシェア履歴登録
  postShareHistory(companyCode, isLogin, page, detailId, shareTypeId) {
    const apiPath = '/company/sharehistory';
    const body = {
      companycode: companyCode,
      page: page,
      detail_id: detailId,
      share_type_id: shareTypeId
    };
    if (isLogin) {
      this.common.apiPost(apiPath, body);
    } else {
      const temp_id = localStorage.getItem(Constant.lsTempTalentId)
      if (temp_id) {
        body[Constant.apTempTalentId] = temp_id
      }
      this.common.apiPostBeforeLogin(apiPath, body);
    }
  }


  //　コンテンツいいね開始
  startLikeAction(contents, companyName) {
    // 確認ダイアログ表示
    const title = sprintf(Constant.contentsLikeConfirmTitle, contents.title);
    const message = sprintf(Constant.contentsLikeDescription, companyName)
    + '<p style="font-size: 12px">' + companyName + Constant.joActionSubDescription + '</p>';

    const comfirmActionDialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        title: title,
        message: message,
        type: Constant.mdTypeYesNo,
        common: this.common,
        buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes },
        blueFlg: true
      }
    });

    comfirmActionDialogRef.afterClosed().subscribe(result => {
      if (result === Constant.mdBtnRight) {
        // 接点タグの有無チェック
        let contact = localStorage.getItem(Constant.lsContactCode  + contents.company_code);
        if (contact) {
          // アンケート取得
          this.getContactInfo(contents.company_code, contact).then(res => {
            const contactQuestion = res.data.question;

            // 接点履歴を取得
            this.getContactHistory(contents.company_code, contact).then(res => {
              if (res) {
                // 接点履歴ありなので接点情報削除
                contact = null
                localStorage.removeItem(Constant.lsContactCode  + contents.company_code);
                this.registPoolWithLike(contents, companyName)
              } else {
                // 接点履歴なし
                // アンケートダイアログ表示
                this.showContactTagDialogWithLike(contents, companyName, contact, contactQuestion);
              }
            })
          })
          .catch(err => {
            this.showLikeFailedDialog();
          });

        } else {
          // プール登録
          this.registPoolWithLike(contents, companyName)
        }
      } else {
        // 終了
        this.sharedLikeExit.next(null);
      }
    });
  }

  // 接点のアンケート表示
  private showContactTagDialogWithLike(contents, companyName, contact, contactQuestion) {
    const dialogRef = this.dialog.open(ContactTagDialogComponent, {
      width: Constant.dlWidthNeedLogin,
      autoFocus: false,
      disableClose: true,
      data: {
        message: contactQuestion,
        companyName: companyName,
        type: Constant.enqTypeLike,
        common: this.common
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === Constant.lsTrue) {
        this.registPoolWithLike(contents, companyName, contact);

      } else if (result === Constant.lsFalse) {
        localStorage.removeItem(Constant.lsContactCode + contents.company_code);
        this.registPoolWithLike(contents, companyName);
      }
    });
  }

  // いいねのタレントプール登録
  private registPoolWithLike(contents, companyName, contact?) {
    const apiPath = '/pool/' + contents.company_code;
    this.common.apiPost(apiPath)
    .then(res => {
      // 接点タグ登録
      this.setContactWithLike(contents, companyName, contact);
    })
    .catch(err => {
      if (err.data?.cnt === 0) {
        // 登録済みの場合も成功とする
        // 接点タグ登録
        this.setContactWithLike(contents, companyName, contact);
      } else {
        this.common.debug().log(err);
        this.showLikeFailedDialog();
      }
    });
  }

  // 接点タグ登録
  private setContactWithLike(contents, companyName, contact) {
    if (contact) {
      const apiPath = '/contact/' + contents.company_code;
      const data = {
        tag: contact
      };
      this.common.apiPost(apiPath, data).then(res => {
         // ローカルストレージの接点タグ削除
         localStorage.removeItem(Constant.lsContactCode + contents.company_code);
        // いいね登録
        this.execLike(contents, companyName);
      })
      .catch(err => {
        this.showLikeFailedDialog();
      });

    } else {
      // いいね登録
      this.execLike(contents, companyName);
    }
  }

  // いいね登録
  private execLike(contents, companyName) {
    // いいねを登録する
    const apiPath = '/content/' + contents.code + '/like'
    this.common.apiPost(apiPath)
    .then(res => {
      this.likeComplete(contents, companyName);
    })
    .catch(err => {
      this.showLikeFailedDialog();
    });
  }

  // ログイン前いいね登録
  execLikeBeforeLogin(contents, companyName) {
    // いいねを登録する
    const apiPath = '/content/' + contents.code + '/like'
    const tempId = localStorage.getItem(Constant.lsTempTalentId)
    if (!tempId) {
      this.showLikeFailedDialog()
      return
    }

    const body = {}
    body[Constant.apTempTalentId] = tempId

    this.common.apiPostBeforeLogin(apiPath, body)
    .then(res => {
      this.likeCompleteToTsunagaru(contents, companyName);
    })
    .catch(err => {
      this.showLikeFailedDialog();
    });
  }

  // いいね失敗のダイアログ
  private showLikeFailedDialog() {
    this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      disableClose: true,
      data: {
        title: Constant.msgContentGoodFailed,
        message: Constant.msgErrorNetworkReload,
        type: Constant.mdTypeOK,
        blueFlg: true
      }
    });
    this.sharedLikeExit.next(null);
  }

  // いいね完了
  private likeComplete(contents, companyName) {
    const title = sprintf(Constant.msgContentGoodComp, contents.title);
    const msg = sprintf(Constant.joActionComplete, companyName);

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        title: title,
        message: msg,
        type: Constant.mdTypeOK,
        blueFlg: true,
        fontSize: 12
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // 質問のチェック
      this.getCompanyQuestion(contents);
    });
  }

  // いいね完了→つながる誘導（ログイン前）
  private likeCompleteToTsunagaru(contents, companyName) {
    const msg = companyName + Constant.msgContentGoodToTsunagaru;

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        title: Constant.msgContentGoodBeforeLogin,
        message: msg,
        type: Constant.mdTypeTsunagaru,
        common: this.common,
        blueFlg: true,
        closeFlg: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      contents.good_flag = 1;
      contents.good_cnt += 1
      this.sharedLikeExit.next(contents);
      if (result) {
        this.showLoginDialog(contents.company_code, companyName);
      }
    });
  }

  // 追加質問の有無取得
  private getCompanyQuestion(contents) {
    const apiPath = '/question/check/' + contents.company_code;

    this.common
      .apiGet(apiPath)
      .then(res => {
        if (res.data?.name) {
          this.showAddQuestionlMessage(contents.company_code, res.data.name, contents);
        } else {
          contents.good_flag = 1;
          contents.good_cnt += 1
          this.sharedLikeExit.next(contents);
        }
      })
      .catch(err => {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.sharedLikeExit.next(null);
      });
  }

  // 追加質問ありのダイアログ表示
  private showAddQuestionlMessage(companyCode, companyName, contents) {
    const btnText = '質問を見る';
    const message = sprintf(Constant.msgPoolAddQuestionMessageWithCompany, companyName);

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      disableClose: true,
      data: {
        message: message,
        type: Constant.mdTypeYesNo,
        blueFlg: true,
        common: this.common,
        buttonText: { left: Constant.mdBtnTextClose, right: btnText }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === Constant.mdBtnRight) {
        // 質問ページへ
        this.router.navigate([Constant.rpSpq, companyCode]);
      }
      contents.good_flag = 1;
      contents.good_cnt += 1
      this.sharedLikeExit.next(contents);
    });
  }

  // いいね削除
  delGoodWithConfirm(likeContents, isLogin) {
    const apiPath = '/content/' + likeContents.code + '/like'
    this.common.showDelConfirm(Constant.msgDeleteContentGood, true).subscribe(res => {
      if (res) {
        if (isLogin) {
          // ログイン中
          this.common.apiDel(apiPath).then(response => {
            if (response.status === Constant.OK) {
              this.common.showMessageGeneral(Constant.msgDeleteContentGoodComp);
              likeContents.good_flag = 0;
              likeContents.good_cnt -= 1
            } else {
              this.common.showMessageGeneral(Constant.msgDeleteContentGoodFailed);
            }
            this.sharedLikeExit.next(likeContents);
          })
          .catch(err => {
            this.common.showMessageGeneral(Constant.msgDeleteContentGoodFailed);
            this.sharedLikeExit.next(null);
          });
        } else {
          // 未ログイン
          const tempId = localStorage.getItem(Constant.lsTempTalentId)
          const body = {}
          if (tempId) {
            body[Constant.apTempTalentId] = tempId
          }
          this.common.apiDelBeforeLogin(apiPath, body).then(response => {
            if (response.status === Constant.OK) {
              this.common.showMessageGeneral(Constant.msgDeleteContentGoodComp);
              likeContents.good_flag = 0;
              likeContents.good_cnt -= 1
            } else {
              this.common.showMessageGeneral(Constant.msgDeleteContentGoodFailed);
            }
            this.sharedLikeExit.next(likeContents);
          })
          .catch(err => {
            this.common.showMessageGeneral(Constant.msgDeleteContentGoodFailed);
            this.sharedLikeExit.next(null);
          });
        }

      } else {
        this.sharedLikeExit.next(null);
      }
    });
  }

  // コンテンツ系　右メニュー固定
  // top:ブラウザ上端からの固定位置 bottom:ブラウザ下端からの固定位置
  contentsRightMenuFixed(currentScrollPosition, top, bottom) {
    const rightMenu = document.getElementById(Constant.elementIdContentsRightMenu)
    const rightMenuArea = document.getElementById(Constant.elementIdContentsRightMenuArea)

    if (rightMenu && rightMenuArea) {
      const y = window.scrollY; //スクロール量
      const w_h = window.innerHeight; //ブラウザの高さ
      const c_p = rightMenuArea.getBoundingClientRect().top + y; //サイドカラム上端のy座標
      const c_h = rightMenuArea.clientHeight; //サイドカラム領域の高さ
      const i_p = rightMenu.getBoundingClientRect().top + y; //中身上端のy座標
      const i_h = rightMenu.clientHeight; //中身の高さ

      if ( i_h < c_h ) { //中身がサイドカラムより小さかったら
        if ( i_h <= w_h - top - bottom ) { //中身がブラウザより小さかったらStickyと同じ挙動
          rightMenu.setAttribute('style', 'position:sticky; top:' + top + 'px; bottom:auto;')

        } else { //中身がブラウザより大きかったら
          if ( y > currentScrollPosition ) { //下降中の処理
            if( y + w_h - i_p - i_h < bottom - 1 ) { //中身がブラウザより下に突き抜けていたら位置を相対固定していっしょにスクロール *小数点上下の調整あり
              rightMenu.setAttribute('style', 'position:absolute; top:' + (i_p - c_p) + 'px; bottom:auto;')

            } else { //サイトバーがブラウザ下端まで来たらFixed
              rightMenu.setAttribute('style', 'position:fixed; top:auto; bottom: ' + bottom + 'px; width:' + rightMenu.parentElement.getBoundingClientRect().width + 'px')
            }
            if( c_p + c_h <= i_p + i_h + 1 ) { //中身がサイドカラム下端まで来たらストップ *小数点上下の調整あり
              rightMenu.setAttribute('style', 'position:absolute; top:auto; bottom: 0;')
            }
          }
          if ( y < currentScrollPosition ) { //上昇中の処理
            if ( i_p - y < top ) { //中身がブラウザより上に突き抜けていたら位置を相対固定していっしょにスクロール
              rightMenu.setAttribute('style', 'position:absolute; top:' + (i_p - c_p) + 'px; bottom:auto;')
            } else { //中身がブラウザ上端まで来たらFixed
              rightMenu.setAttribute('style', 'position:fixed; top:' + top + 'px; bottom: auto; width:' + rightMenu.parentElement.getBoundingClientRect().width + 'px')
            }
            if (i_p - c_p <= 1) { //中身がサイドカラム上端まで来たらストップ
              rightMenu.setAttribute('style', 'position:absolute; top:0; bottom: auto;;')
            }
          }
        }
      }
    }
  }

  // 右メニューとコンテンツエリアの高さを合わせる
  contentsEqualRightMenuHeight() {
    const rightMenu = document.getElementById(Constant.elementIdContentsRightMenu)
    const contentsArea = document.getElementById(Constant.elementIdContentsRightMenuArea)
    contentsArea.style.height = 'auto'

    if (rightMenu && contentsArea.clientHeight < rightMenu.clientHeight) {
      contentsArea.style.height = rightMenu.clientHeight + 'px'
    }

    // 右メニューの横幅設定
    if (rightMenu) {
      rightMenu.style.width = rightMenu.parentElement.getBoundingClientRect().width + 'px'
    }
  }

  // コンテンツ系 タグ一覧配列をカテゴリ別配列に変換
  convTagListAry(data) {
    // 0件を除く
    let tagList = data.filter((val) => val.cnt > 0)

    if (tagList.length > 0) {
      // カテゴリ別に並べる
      const temp = tagList.reduce(function(rv, x) {
        (rv[String(x['tagcategory_id'])] = rv[String(x['tagcategory_id'])] || []).push(x);
          return rv;
      }, {});

      // id順に並ぶようにする
      tagList = Object.keys(temp)
          .map(function(k) { return { key: k, value: temp[k] }; })
          .sort(function(a, b) { return a.value - b.value; });
    }
    return tagList
  }
}
