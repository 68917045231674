<div mat-icon-button class="close-btn-area">  
  <img src="assets/common/clear.svg" class="close-button" (click)="onClose()">  
</div>  
<mat-dialog-content class="dialog-content">
  <div class="mat-mdc-dialog-title">表示条件の変更</div>
  <form [formGroup]="form" class="form">
      <!-- 業種選択 -->
      <div *ngIf="data.type == Constant.sdTypeJob" class="checkbox-area">
        <div class="label">業種</div>
        <ng-container *ngFor="let item of masterIndustry">
          <div class="item large">
            <mat-checkbox [formControlName]="item.id" class="margin">
              {{ item[Constant.apItemValue] }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>

      <!-- 勤務地選択 -->
      <div *ngIf="data.type == Constant.sdTypePlace" class="checkbox-area">
        <div class="label">勤務地</div>
        <div class="item location"><mat-checkbox [formControlName]="place_all" class="margin" (change)="onPlaceAll($event.checked)">全国</mat-checkbox></div>
        <ng-container *ngFor="let item of masterPlace">
          <div class="item large">
            <mat-checkbox [formControlName]="item[Constant.apLargeId]" class="margin" (change)="onLargeId($event.checked, item[Constant.apLargeId])">
              {{ item[Constant.apLargeItemValue] }}
            </mat-checkbox>
          </div>
          <div class="item middle half location" *ngFor="let middle of item.middle">
            <mat-checkbox [formControlName]="item[Constant.apLargeId]+'-'+ middle.id" class="margin" (change)="onMiddelId(item[Constant.apLargeId])">{{
              middle[Constant.apItemValue]
            }}</mat-checkbox>
          </div>
        </ng-container>
      </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="button-container">
    <button class="half type" mat-raised-button (click)="onChangeType(Constant.sdTypeJob)" *ngIf="data.type == Constant.sdTypePlace">設定して<br>業種へ</button>
    <button class="half type" mat-raised-button (click)="onChangeType(Constant.sdTypePlace)" *ngIf="data.type == Constant.sdTypeJob">設定して<br>勤務地へ</button>
    <button class="half search" mat-raised-button color="accent" (click)="onSelect()">検索</button>
  </div>
</mat-dialog-actions>

