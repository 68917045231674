<div *ngIf="showSpinner" class="spinner blue">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div *ngIf="detailInfo">
  <div class="rect pdf" *ngIf="detailInfo.document_url">
    <div class="overlay-area">
    <div *ngIf="pdfTotalPage==0 && materialShowSpinner" class="spinner blue">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <pdf-viewer [src]="detailInfo.document_url" class="pdf-viewer" [show-all]="false" [page]="pdfRealPage" [render-text]="false"
    [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true"
    (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"
    (page-rendered)="pdfRendered()"></pdf-viewer>
    <div class="last-page" [class.show]="pdfTotalPage > 0 && pdfShowPage > 1 && pdfShowPage > pdfTotalPage">
      <div class="more-contents-btn" (click)="onLink(Constant.rpJobOfferList)">
        求人情報を見る
      </div>
    </div>
    <div class="pdf-viewer-navi" *ngIf="pdfTotalPage>0">
      <div class="leftbtn">
        <button mat-flat-button (click)="prevBtn()" [disabled]="pdfShowPage==1 || pdfBtnDisabled">＜</button>
        <button mat-flat-button (click)="nextBtn()" [disabled]="pdfShowPage==pdfTotalPage+1 || pdfBtnDisabled">＞</button>
      </div>
      <span>{{ pdfShowPage }} / {{ pdfTotalPage+1 }}</span>
      <div class="rightbtn">
        <button mat-icon-button (click)="onPdfDialog()" class="pc"><mat-icon>fullscreen</mat-icon></button>
      </div>
    </div>
    </div>
    <h3 class="pdf-title-area">{{ detailInfo.title }}</h3>
    <div class="action-area">
      <div class="like-area">
        <div class="like" (click)="onLikeButton(detailInfo)" [class.liked]="detailInfo.good_flag == 1">
          <img src="assets/company/icon_heart_R.png" *ngIf="detailInfo.good_flag != 1">
          <img src="assets/company/icon_heart_W.png" *ngIf="detailInfo.good_flag == 1">
          <div class="cnt">{{ detailInfo.good_cnt }}</div>
        </div>
      </div>
      <div class="sns-area">
        <ng-container *ngTemplateOutlet="sns; context:{ limited: detailInfo.limited_flag }"></ng-container>
      </div>
    </div>
  </div>

</div>
<div *ngIf="detailInfo === ''" class="not-contents">
  コンテンツが存在しないか、<br>公開されていません。
</div>
<div class="unit" *ngIf="otherList?.length > 0">
  <div class="header">他の資料</div>
  <div class="other-area">
    <ng-container *ngFor="let item of otherList; index as i" #otherMaterial>
      <ng-container *ngIf="item.code == null">
        <ng-container *ngIf="item.endFlg == false">
          <div class="more-contents">
            <div *ngIf="isDataGetting" class="spinner blue">
              <mat-spinner diameter="30"></mat-spinner>
            </div>
            <div class="more-contents-btn" (click)="onNextData()">
              もっと見る
              <!-- <img src="assets/company/arrow_under.svg"> -->
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5575 0.221192L5 3.65619L8.4425 0.221191L9.5 1.27869L5 5.77869L0.5 1.27869L1.5575 0.221192Z" fill="#434343" class="s"/>
              </svg>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="box" *ngIf="item.code != null">
        <div class="rect materect">
          <div class="image-container">
            <a *ngIf="item.image_url" routerLink="/{{Constant.rpMaterial}}/{{companyCode}}/{{item.code}}">
              <img class="image" [src]="item.image_url" appImageLoadingError />
            </a>
            <a *ngIf="item.image_url===''" routerLink="/{{Constant.rpMaterial}}/{{companyCode}}/{{item.code}}">
              <img class="image noimage" lazyLoad="assets/common/noimage.png" appImageLoadingError />
              <div class="image-back-noimage"></div>
            </a>
          </div>
          <div class="title-container">
            <a class="title" routerLink="/{{Constant.rpMaterial}}/{{companyCode}}/{{item.code}}">{{ item.title }}</a>
          </div>
          <div class="like-area">
            <div class="like" (click)="onLikeButton(item)" [class.liked]="item.good_flag == 1" [class.disabled]="isLikePosting">
              <img src="assets/company/icon_heart_R.png" *ngIf="item.good_flag != 1">
              <img src="assets/company/icon_heart_W.png" *ngIf="item.good_flag == 1">
              <div class="cnt">{{ item.good_cnt }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="unit" *ngIf="contentsListData" [class.bottom-thin]="jobofferListData?.list.length==0">
  <div class="header">新着記事</div>
    <app-slider [data]="contentsListData"></app-slider>
</div>
<div class="unit bottom-thin" *ngIf="jobofferListData">
  <div class="header" [class.margintop-thin]="contentsListData?.list.length>0">新着求人</div>
    <app-slider [data]="jobofferListData"></app-slider>
</div>

<app-tsunagaru [data]="tsunagaruData" *ngIf="tsunagaruData"></app-tsunagaru>

<app-company-footer [companyInfo]="companyInfo" *ngIf="companyInfo"></app-company-footer>

<!-- SNSアイコン群テンプレート -->
<ng-template #sns let-limited="limited">
  @if (!limited) {
    <a (click)="onCopy()"><span class="tooltip on-cp">{{ getSnsShareTooltip('') }}</span><img matTooltip="リンクをコピー" src="assets/company/link-line.png"></a>
    <a (click)="shareSNS(Constant.snsFb)"><span class="tooltip sns-fb">{{getSnsShareTooltip(Constant.snsFb)}}</span><img src="assets/company/sns_facebook.png"></a>
    <a (click)="shareSNS(Constant.snsX)"><span class="tooltip sns-x">{{getSnsShareTooltip(Constant.snsX)}}</span><img src="assets/company/sns_x.png"></a>
    <a (click)="shareSNS(Constant.snsLinkedin)"><span class="tooltip sns-linkedin">{{getSnsShareTooltip(Constant.snsLinkedin)}}</span><img src="assets/company/sns_linkedin.png"></a>
    <a (click)="shareSNS(Constant.snsLine)"><span class="tooltip sns-line">{{getSnsShareTooltip(Constant.snsLine)}}</span><img src="assets/company/sns_line.png" class="line"></a>
  } @else {
    <a (click)="disabledShare()" class="share-disabled"><img src="assets/company/link-line.png"></a>
    <a (click)="disabledShare()" class="share-disabled"><img src="assets/company/sns_facebook.png"></a>
    <a (click)="disabledShare()" class="share-disabled"><img src="assets/company/sns_x.png"></a>
    <a (click)="disabledShare()" class="share-disabled"><img src="assets/company/sns_linkedin.png"></a>
    <a (click)="disabledShare()" class="share-disabled"><img src="assets/company/sns_line.png" class="line"></a>
  }
</ng-template>
