import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { EducationService } from './../shared/education.service';
import { ProfileService } from '../shared/profile.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SelectDialogComponent } from '../dialog/select-dialog/select-dialog.component';

@Component({
  selector: 'app-profile-education-edit',
  templateUrl: './profile-education-edit.component.html',
  styleUrls: ['./profile-education-edit.component.css']
})
export class ProfileEducationEditComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public educations; // 学歴
  public form: FormGroup;
  public errorMessageTerm: string;
  public selectedEducationId: number;
  public selectedSchoolTypeId: number;
  public selectedSchoolType: string;
  public master = {};  // マスタ
  public dataSending = false; // データ送信中・削除中のフラグ
  public loading = true;
  private subscriptionEducations: Subscription;
  private subscriptionMaster: Subscription;
  public schoolNameMaxLength = false; // 「学校名」の入力制御
  public departmentMaxLength = false; // 「学部・専攻など」の入力制御
  public mainActivitiesMaxLength = false; // 「主な活動」の入力制御

  constructor(
    private common: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private educationService: EducationService,
    private profileService: ProfileService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);

    // IDセット
    const id = this.activatedRoute.snapshot.params['id'];
    if (id) {
      this.selectedEducationId = parseInt(id, 10);
    }

    // マスタ取得
    this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
      data.forEach(element => {
        this.master[element.type] = element.data;
      });
    });
    this.profileService.getMaster(false);

    // フォーム作成
    this.initForm();

    // 職歴取得
    if (this.selectedEducationId) {
      this.subscriptionEducations = this.educationService.sharedEducations$.subscribe(data => {
        let isValidWorkId = false;
        data.forEach(element => {
          if (element.id === this.selectedEducationId) {
            isValidWorkId = true;
            setTimeout(() => {
              this.setValue(element);
              this.loading = false;
            });
          }
        });
        if (!isValidWorkId) {
          this.router.navigate([Constant.rpError]);
        }
      });
      this.educationService.getEducation(false);
    } else {
      setTimeout(() => {
        this.loading = false;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptionMaster.unsubscribe();
    if (this.subscriptionEducations) {
      this.subscriptionEducations.unsubscribe();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // キャンセル
  onCancel() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpEducation]);
      })
      .catch(err => {});
  }

  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  onInput(target, maxLenFlg, maxLenCnt) {
    const formName = target.getAttribute('formControlName');
    const beforeText = target.value;
    const changeText = beforeText.replace(/\r?\n/g, '');
    this.form.patchValue({[formName]: beforeText});
    // 改行不可の入力欄に改行入りテキストがペーストされた場合は改行無しに変換したテキストに置き換える
    if (formName === 'school_name' || formName === 'department') {
      if (beforeText !== changeText) {
        this.form.patchValue({[formName]: changeText});
      }
    }
    // 入力内容が最大文字数制限を超えた場合は各フラグをtrueにする
    if (target.value.length > maxLenCnt) {
      this[maxLenFlg] = true;
    } else {
      this[maxLenFlg] = false;
    }
  }

  // 文字数カウント
  countInputText(txt) {
    return txt.length;
  }

  // フォーカス移動
  onNextFocus(len, value, nextElm) {
    this.common.nextFocus(len, value, nextElm);
  }

  // 削除
  onDel() {
    this.common
      .preCheck()
      .then(res => {
        this.delExec();
      })
      .catch(err => {});
  }

  // 登録
  onRegist() {
    this.errorMessageTerm = '';

    this.common
      .preCheck()
      .then(res => {
        this.registExec();
      })
      .catch(err => {});
  }

  // 学校区分選択
  onSelectSchoolType() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      data: {
        type: Constant.sdTypeSchool,
        title: Constant.sdTypeSchoolTitle,
        items: this.master[Constant.pmSchoolType],
        selected: this.selectedSchoolTypeId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.selectedSchoolTypeId = result[0];
      this.selectedSchoolType = this.common.convertMasterIdToItemValue(result[0], this.master, Constant.pmSchoolType);
    });
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      start_year: new FormControl('', [
        Validators.required,
        Validators.min(Constant.fvMinYear),
        Validators.pattern(/^[0-9]*$/)
      ]),
      start_month: new FormControl('', [
        Validators.required,
        Validators.min(Constant.fvMinMonth),
        Validators.max(Constant.fvMaxMonth),
        Validators.pattern(/^[0-9]*$/)
      ]),
      end_year: new FormControl('', [
        Validators.required,
        Validators.min(Constant.fvMinYear),
        Validators.pattern(/^[0-9]*$/)
      ]),
      end_month: new FormControl('', [
        Validators.required,
        Validators.min(Constant.fvMinMonth),
        Validators.max(Constant.fvMaxMonth),
        Validators.pattern(/^[0-9]*$/)
      ]),
      school_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthFifty)
      ]),
      dropout_flag: new FormControl(''),
      bunri_type_id: new FormControl('', [Validators.required]),
      department: new FormControl('', [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthFifty)
      ]),
      main_activities: new FormControl('', [
        Validators.maxLength(Constant.fvMaxLengthTwoHundred)
      ])
    });
  }

  // フォームに値をセット
  private setValue(data) {
    const admissionAry = data.admission_date.split('/');
    const exitAry = data.exit_date.split('/');

    this.form.setValue({
      start_year: admissionAry[0],
      start_month: admissionAry[1],
      end_year: exitAry[0],
      end_month: exitAry[1],
      school_name: data.school_name,
      dropout_flag: data.dropout_flag,
      bunri_type_id: data.bunri_type_id,
      department: data.department,
      main_activities: data.main_activities
    });

    // 学校区分
    this.selectedSchoolType = data[Constant.apSchoolTypeName];
    this.selectedSchoolTypeId = data[Constant.apSchoolTypeId];
  }

  private registExec() {
    // 期間チェック
    const admission_date = this.common.dateFromYearMonth(
      this.form.get(Constant.tfStartYear).value,
      this.form.get(Constant.tfStartMonth).value
    );
    const exit_date = this.common.dateFromYearMonth(
      this.form.get(Constant.tfEndYear).value,
      this.form.get(Constant.tfEndMonth).value
    );

    if (admission_date > exit_date) {
      this.errorMessageTerm = Constant.msgErrorInvalidTerm;
      return;
    }

    // データ送信中スピナー表示
    this.dataSending = true;

    // GAイベント
    if (this.selectedEducationId) {
      // 学歴更新
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUpdateProfileEducationEdit);
    } else {
      // 学歴追加
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUpdateProfileEducationAdd);
    }

    const data = [];
    for (const formName in this.form.value) {
      if (
        formName === Constant.tfStartYear ||
        formName === Constant.tfStartMonth ||
        formName === Constant.tfEndYear ||
        formName === Constant.tfEndMonth
      ) {
        continue;
      }

      if (this.form.value.hasOwnProperty(formName)) {
        if (formName === Constant.apDropout) {
          data.push({ key: formName, value: Number(this.form.value[formName]) });
        } else {
          data.push({ key: formName, value: this.common.replaceSpace(this.form.value[formName]) });
        }
      }
    }

    // 期間
    data.push({ key: Constant.apAdmissionDate, value: admission_date });
    data.push({ key: Constant.apExitDate, value: exit_date });

    // 学校区分
    data.push({ key: Constant.apSchoolTypeId, value: this.selectedSchoolTypeId });

    const msgTitle = Constant.tlEducation;
    this.educationService
      .postEducation(data, this.selectedEducationId)
      .then(result => {
        this.common.debug().log(result);
        this.dataSending = false;
        if (result.status === Constant.OK) {
          this.common.showMessage(true, msgTitle);
          this.router.navigate([Constant.rpEducation]);
        } else {
          // APIエラーの場合は「更新に失敗しました」トーストを表示
          this.common.showMessage(false, msgTitle);
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.dataSending = false;
        // オフライン等で結果が取得出来なかった場合は「失敗した可能性があります」トーストを表示
        this.common.showToastMessage(Constant.msgUpdateError);
      });
  }

  private delExec() {
    // 確認ダイアログ表示
    const message = Constant.tlEducation + Constant.msgConfirmDelProfile;
    this.common.showDelConfirm(message).subscribe(res => {
      if (res) {
        this.dataSending = true;
        this.educationService
          .delEducation(this.selectedEducationId)
          .then(response => {
            this.common.debug().log(response);
            this.dataSending = false;
            if (response.status === Constant.OK) {
              this.common.showToastMessage(Constant.msgDelEducationComplete);
              this.router.navigate([Constant.rpEducation]);
            } else {
              // APIエラーの場合は「削除に失敗しました」トーストを表示
              this.common.showToastMessage(Constant.msgDelEducationError);
            }
          })
          .catch(error => {
            this.common.debug().log(error);
            this.dataSending = false;
            // オフライン等で結果が取得出来なかった場合は「失敗した可能性があります」トーストを表示
            this.common.showToastMessage(Constant.msgDeleteError);
          });
      }
    });
  }
}
