import { LpComponent } from './lp/lp.component';
import { MypageComponent } from './mypage/mypage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { Constant } from './constant';
import { LoginComponent } from './login/login.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { ProfileNewComponent } from './profile-new/profile-new.component';
import { ProfileNewCompComponent } from './profile-new-comp/profile-new-comp.component';
import { ProfileCareerComponent } from './profile-career/profile-career.component';
import { ProfileCareerEditComponent } from './profile-career-edit/profile-career-edit.component';
import { ProfileEducationComponent } from './profile-education/profile-education.component';
import { ProfileEducationEditComponent } from './profile-education-edit/profile-education-edit.component';
import { ProfileDesireComponent } from './profile-desire/profile-desire.component';
import { ProfileDesireEditComponent } from './profile-desire-edit/profile-desire-edit.component';
import { ProfileBasicComponent } from './profile-basic/profile-basic.component';
import { ProfileBasicEditComponent } from './profile-basic-edit/profile-basic-edit.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AccountComponent } from './account/account.component';
import { ConfirmMailComponent } from './confirm-mail/confirm-mail.component';
import { ResendMailcodeComponent } from './resend-mailcode/resend-mailcode.component';
import { PasswordForgetComponent } from './password-forget/password-forget.component';
import { ResendCodeComponent } from './resend-code/resend-code.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { CompanyInfoListComponent } from './company-info-list/company-info-list.component';
import { WalkthroughComponent } from './walkthrough/walkthrough.component';
import { LpJobofferListComponent } from './lp-joboffer-list/lp-joboffer-list.component';
import { LpColumnComponent } from './lp-column/lp-column.component';
import { LpStartComponent } from './lp-start/lp-start.component';
import { LpPageComponent } from './lp-page/lp-page.component';
import { ColumnComponent } from './column/column.component';
import { ProfileFileComponent } from './profile-file/profile-file.component';
import { ShowFileComponent } from './show-file/show-file.component';
import { UnsubscribeMailComponent } from './unsubscribe-mail/unsubscribe-mail.component';
import { SpecialQuestionComponent } from './special-question/special-question.component';
import { CompanyTopComponent } from './company-top/company-top.component';
import { CompanyAboutComponent } from './company-about/company-about.component';
import { ContentsTagComponent } from './contents-tag/contents-tag.component';

const routes: Routes = [
  /* デフォルト採用ページ */
  {
    path: Constant.rpLogin + '/:code',
    component: CompanyTopComponent,
  },
  {
    path: Constant.rpLogin + '/:code/:contact',
    component: CompanyTopComponent,
  },

  /* 求人一覧 */
  { path: Constant.rpJobOfferList,
    loadChildren: () => import('./company-joboffer/company-joboffer.module').then(m => m.CompanyJobofferModule) },

  /* コンテンツ一覧 */
  { path: Constant.rpContentsList,
    loadChildren: () => import('./company-contents/company-contents.module').then(m => m.CompanyContentsModule) },

  /* コンテンツカテゴリー */
  { path: Constant.rpContentsCategory,
    loadChildren: () => import('./company-contents/company-contents.module').then(m => m.CompanyContentsModule) },

  /* 求人詳細 */
  {
    path: Constant.rpJobOfferDetail,
    loadChildren: () => import('./joboffer-detail/joboffer-detail.module').then(m => m.JobofferDetailModule)
  },

  /* コンテンツ詳細 */
  {
    path: Constant.rpContentsDetail,
    loadChildren: () => import('./contents-detail/contents-detail.module').then(m => m.ContentsDetailModule)
  },

  // コンテンツタグ
  {
    path: Constant.rpContentsTag + '/:code',
    component: ContentsTagComponent,
  },
  { path: Constant.rpContentsTag, redirectTo: '' },

  { path: Constant.rpCompanyAbout + '/:code', component: CompanyAboutComponent },
  { path: Constant.rpCompanyAbout, redirectTo: '' },

  { path: Constant.rpLogin, component: LoginComponent },
  {
    path: Constant.rpLoginForm + '/:code/:contact',
    component: LoginComponent,
    data: { mode: Constant.lgModeLogin }
  },
  {
    path: Constant.rpLoginForm + '/:code',
    component: LoginComponent,
    data: { mode: Constant.lgModeLogin }
  },
  {
    path: Constant.rpLoginForm,
    redirectTo: Constant.rpLogin,
    pathMatch: 'full'
  },
  {
    path: Constant.rpSignup + '/:code',
    component: LoginComponent,
    data: { mode: Constant.lgModeSignup }
  },
  {
    path: Constant.rpSignup,
    component: LoginComponent,
    data: { mode: Constant.lgModeSignup }
  },
  { path: Constant.rpConfirm + '/:code', component: ConfirmComponent },
  { path: Constant.rpConfirm, component: ConfirmComponent },
  { path: Constant.rpResend, component: ResendCodeComponent },
  { path: Constant.rpPassForget, component: PasswordForgetComponent },
  {
    path: Constant.rpPassForget + '/1',
    data: { mode: Constant.pfModeChange },
    component: PasswordForgetComponent
  },
  {
    path: Constant.rpCompanyInfo,
    component: CompanyInfoListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpCareer,
    component: ProfileCareerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpCareerEdit,
    component: ProfileCareerEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpCareerEdit + '/:id',
    component: ProfileCareerEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpEducation,
    component: ProfileEducationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpEducationEdit,
    component: ProfileEducationEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpEducationEdit + '/:id',
    component: ProfileEducationEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpDesire,
    component: ProfileDesireComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpDesireEdit,
    component: ProfileDesireEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpBasic,
    component: ProfileBasicComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpBasicEdit,
    component: ProfileBasicEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpAttachment,
    component: ProfileFileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpProfilenew + '1',
    component: ProfileNewComponent,
    data: { page: 1 },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpProfilenew + '2',
    data: { page: 2 },
    component: ProfileNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpProfilenew + '3',
    data: { page: 3 },
    component: ProfileNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpProfilenew + '4',
    component: ProfileNewCompComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpMessagelist,
    component: MessageListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpMessagedetail + '/:code',
    component: MessageDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpAccount,
    component: AccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpAccountMail,
    component: AccountComponent,
    data: { mode: Constant.acModeChangeMail },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpAccountPass,
    component: AccountComponent,
    data: { mode: Constant.acModeChangePass },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpConfirmmail,
    component: ConfirmMailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpResendmail,
    component: ResendMailcodeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpUnsubscribe,
    component: UnsubscribeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpMypage,
    component: MypageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpWalkThrough + '1',
    component: WalkthroughComponent,
    data: { page: 1 },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpWalkThrough + '2',
    component: WalkthroughComponent,
    data: { page: 2 },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpWalkThrough + '3',
    component: WalkthroughComponent,
    data: { page: 3 },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpWalkThrough + '4',
    component: WalkthroughComponent,
    data: { page: 4 },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpTmpFile,
    component: ShowFileComponent,
    data: { type: Constant.fileTypeMessage },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpMsgFile + '/:id',
    component: ShowFileComponent,
    data: { type: Constant.fileTypeMessage },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpAttachment + '/:id',
    component: ShowFileComponent,
    data: { type: Constant.fileTypeProfile },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rpSpq + '/:code',
    component: SpecialQuestionComponent,
    canActivate: [AuthGuard]
  },
  { path: Constant.rpMyColumn, component: ColumnComponent, canActivate: [AuthGuard] },
  { path: Constant.rpMyColumn + '/:id', component: ColumnComponent, canActivate: [AuthGuard] },
  { path: Constant.rpLpJobofferList + '/:page', component: LpJobofferListComponent },
  { path: '', component: LpComponent },
  { path: Constant.rpLpStartguide, component: LpStartComponent },
  { path: Constant.rpLpColumn, component: LpColumnComponent },
  { path: Constant.rpLpColumn + '/:id', component: LpColumnComponent },
  { path: Constant.rpLpPage + '/:page', component: LpPageComponent },
  { path: Constant.rpError, component: PageNotFoundComponent },
  { path: Constant.rpMaintenance, component: MaintenanceComponent },
  { path: Constant.rpMailUnsubscribe + '/:code', component: UnsubscribeMailComponent },
  { path: '**', redirectTo: Constant.rpError }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules,
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
