// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'dev',
  version: '1.0.0',
  amplify: {
    Auth: {
      identityPoolId: 'ap-northeast-1:4ce4069b-7823-44fc-b025-3b245381a3e1',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_H4tjM5s8z',
      userPoolWebClientId: '67fnubb8f54o19dih6glbbk57s'
    },
    API: {
      endpoints: [
        {
          name: 'talent',
          endpoint: 'https://uw9ts04576.execute-api.ap-northeast-1.amazonaws.com/dev'
        },
        {
          name: 'admin',
          endpoint: 'https://kjxitb5e1i.execute-api.ap-northeast-1.amazonaws.com/dev'
        }
      ]
    },
    Storage: {
      profilePicture: {
        bucket: 'dev.talentpool.talent.profilepicture',
        region: 'ap-northeast-1',
        level: 'private'
      },
      profileFile: {
        bucket: 'dev.talentpool.talent.profilefile',
        region: 'ap-northeast-1',
        level: 'private'
      },
      messageFile: {
        bucket: 'dev.talentpool.common.messagefile',
        region: 'ap-northeast-1',
        level: 'private'
      },
    }
  },
  API_KEY: 'EUCSLyqsKr3Lx0JOmJunj377t1jCIA1m9RfJiAMD',
  API_KEY_ADMIN: 'eeB5Ms5EXy13fySNcchE03ApNLuIJTUE2HIArxEG',
  COLUMN_IMG_URL: 'https://dev.calin.co.jp/assets/column/',
  AnalyticsTrackingId: 'G-BB8WHS1GDN'
};
