import { Injectable } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Subject } from 'rxjs';
import { Constant } from './../constant';

@Injectable()
export class CompanyService {
  private apiPath = '/pool';
  private companys;

  // データ受け渡し用
  private sharedCompanys = new Subject<any>();
  public sharedCompanys$ = this.sharedCompanys.asObservable();
  private sharedMessages = new Subject<any>();
  public sharedMessages$ = this.sharedMessages.asObservable();

  constructor(private common: CommonService) {}

  // プール企業取得
  getList() {
    this.common
      .apiGet(this.apiPath)
      .then(res => {
        this.companys = res.data;
        this.sharedCompanys.next(this.companys);
      })
      .catch(err => {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.common.debug().log(err);
        this.sharedCompanys.next(Constant.apError);
      });
  }

  // 解除
  delPool(id): Promise<any> {
    const apiPath = this.apiPath + '/' + id;
    return this.common.apiDel(apiPath);
  }

  getMessageList() {
    const apiPath = '/message';
    this.common
      .apiGet(apiPath)
      .then(res => {
        this.sharedMessages.next(res.data);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.sharedMessages.next(Constant.apError);
      });
  }

  // メッセージの未読チェック
  checkUnreadMessage(data) {
    let unread = false;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        if (element.readed_flag === Constant.msgUnReaded &&
          (element.type_id === Constant.msgTypeIdCompany || element.type_id === Constant.msgTypeIdCompanyAll)) {
          unread = true;
          break;
        }
      }
    }
    return unread;
  }
}
