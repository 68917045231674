<app-lp-header page="column"></app-lp-header>
<div class="wrap" *ngIf="!loading">
  <ng-container *ngIf="columnId==0; else detail">
    <img src="/assets/lp/txt_column.png" class="title-img">
    <h2>就活準備コラム</h2>
    <div *ngIf="showSpinner" class="spinner">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <div class="flex">
      <div class="col" *ngFor="let column of columnList">
        <a routerLink="/column/{{ column.id }}"><img *ngIf="column.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ column.image_path }}"></a>
        <a class="title" routerLink="/column/{{ column.id }}">{{ column.title }}</a>
        <div class="contents">{{ ellipsisText(column.content, 99) }}</div>
      </div>
    </div>
  </ng-container>
  <ng-template #detail>
    <div class="back">
      <a routerLink="/{{ Constant.rpLpColumn }}">＜ 就活準備コラム 一覧へ</a>
    </div>
    <div class="detail">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner diameter="60"></mat-spinner>
      </div>
      <ng-container *ngIf="detailData">
        <h2 class="title">{{ detailData.title }}</h2>
        <img *ngIf="detailData.image_path" class="image" src="{{ columnImgUrl }}{{ detailData.image_path }}">
        <div [innerHTML]='detailData.contentHtml' class="contents"></div>
        <mat-divider></mat-divider>
        <div class="pagelink">
          <div class="col left" [class.no-data]="!detailData.pre">
            <ng-container *ngIf="detailData.pre">
              <a routerLink="/column/{{ detailData.pre.id }}" *ngIf="detailData.pre" class="textlink">＜＜　前の就活準備コラム</a>
              <a routerLink="/column/{{ detailData.pre.id }}"><img *ngIf="detailData.pre.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ detailData.pre.image_path }}"></a>
              <a class="title" [class.padding-top]="!detailData.pre.image_path" routerLink="/column/{{ detailData.pre.id }}">{{ detailData.pre.title }}</a>
              <div class="contents">{{ ellipsisText(detailData.pre.content, 49) }}</div>
            </ng-container>
          </div>
          <div class="col right" [class.no-data]="!detailData.next">
            <ng-container *ngIf="detailData.next">
              <a routerLink="/column/{{ detailData.next.id }}" *ngIf="detailData.next" class="textlink">次の就活準備コラム　＞＞</a>
              <a routerLink="/column/{{ detailData.next.id }}"><img *ngIf="detailData.next.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ detailData.next.image_path }}"></a>
              <a class="title" [class.padding-top]="!detailData.next.image_path" routerLink="/column/{{ detailData.next.id }}">{{ detailData.next.title }}</a>
              <div class="contents">{{ ellipsisText(detailData.next.content, 49) }}</div>
            </ng-container>
          </div>
        </div>
        <div class="back-bottom">
          <a routerLink="/column">就活準備コラム一覧に戻る</a>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
<app-lp-footer></app-lp-footer>
