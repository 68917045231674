<nav class="float-menu">
  <div class="logo-area">
    <a routerLink="/"><img src="/assets/common/logo.png" (click)="onTopPage()" width="70" /></a>
    <span class="color-blue"><b>はじめまして！<br>わたしの気になる会社</b></span>
    <div class="show-pc flex">
      <a class="menu" routerLink="/" (click)="onTopPage()" [class.active]="page=='top'">トップ</a>
      <a class="menu" routerLink="/{{ Constant.rpLpStartguide }}" [class.active]="page==Constant.rpLpStartguide">はじめての方へ</a>
      <a class="menu" routerLink="/{{ Constant.rpLpPage }}/1" [class.active]="page==Constant.rpLpPage">企業を探す</a>
      <a class="menu" routerLink="/{{ Constant.rpLpColumn}}" [class.active]="page=='column'">就活準備コラム</a>
    </div>
  </div>
  <div class="button-area show-pc">
    <a class="btn login-btn" routerLink="/{{ Constant.rpLogin }}" (click)="onClickGAEvent(Constant.gaActionMenuLogin)">
      ログイン
    </a>
    <a class="btn new-account-btn" routerLink="/{{ Constant.rpSignup }}" (click)="onClickGAEvent(Constant.gaActionMenuAccount)">
      会員登録（無料）
    </a>
  </div>
  <div class="show-sp mb-menu">
    <button mat-icon-button #trigger [matMenuTriggerFor]="menu" class="menu-btn" (click)="onMenuOpen()">
      <img src="../../assets/lp/btnMenu.png" width="20">
    </button>
    <mat-menu #menu="matMenu" backdropClass="menu-backdrop" [overlapTrigger]="true">
      <div (click)="onDisabledMenu($event)">
        <div class="mb-menu-close">
          <button mat-icon-button (click)="onMenuClose()"><img src="../../assets/lp/btnClose.png" width="20"></button>
        </div>
        <div class="logo" mat-menu-item [disabled]="true">
          <img src="/assets/common/logo.png" height="30" class="color-blue">はじめまして！<br>わたしの気になる会社
        </div>
        <a mat-menu-item class="mb-menu" routerLink="/" (click)="onTopPage()">トップ</a>
        <a mat-menu-item class="mb-menu" routerLink="/{{ Constant.rpLpStartguide }}">はじめての方へ</a>
        <a mat-menu-item class="mb-menu" routerLink="/{{ Constant.rpLpPage }}/1">企業を探す</a>
        <a mat-menu-item class="mb-menu" routerLink="/{{ Constant.rpLpColumn}}">就活準備コラム</a>
        <div class="mb-menu-btn">
          <a mat-button class="btn login-btn" routerLink="/{{ Constant.rpLogin }}" (click)="onClickGAEvent(Constant.gaActionMenuLogin)">
            ログイン
          </a>
          <a mat-button class="btn new-account-btn" routerLink="/{{ Constant.rpSignup }}" (click)="onClickGAEvent(Constant.gaActionMenuAccount)">
            会員登録（無料）
          </a>
        </div>
      </div>
    </mat-menu>
  </div>
</nav>
