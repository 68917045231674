import { Injectable } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Constant } from '../constant';

import Quill from 'quill';
import { Subject } from 'rxjs';

const BlockEmbed = Quill.import('blots/block/embed');

class TocBlot extends BlockEmbed {
    static create (value) {
      let node = super.create(value);
      node.innerHTML = value;
      node.setAttribute('contenteditable', 'false');
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }
}

TocBlot['blotName'] = 'toc-div';
TocBlot['className'] = 'toc-div';
TocBlot['tagName'] = 'div';
Quill.register(TocBlot);


export class OgpBlot extends BlockEmbed {
  static create (value) {
    let node = super.create();

    node.setAttribute('contenteditable', 'false');
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }
}

OgpBlot['blotName'] = 'ogp-div';
OgpBlot['className'] = 'ogp-div';
OgpBlot['tagName'] = 'div';
Quill.register(OgpBlot);

export class MyImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('data-path', value['data-path']);
    node.setAttribute('src', value.src);
    return node;
  }
  static value(node) {
    return {
        'data-path': node.getAttribute('data-path'),
        src: node.getAttribute('src') ? node.getAttribute('src') : 'data:image/png;base64,'
    };
  }
}

MyImageBlot['blotName'] = 'image';
MyImageBlot['tagName'] = 'img';
Quill.register(MyImageBlot);

const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');
class SmartBreak extends Break {
  length () {
    return 1
  }
  value () {
    return '\n'
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref)
  }
}
SmartBreak.blotName = 'sbreak';
SmartBreak.tagName = 'BR'
Quill.register(SmartBreak)


@Injectable({
  providedIn: 'root'
})
export class QuillService {
  private sharedQuillUpdate = new Subject<boolean>();
  public sharedQuillUpdate$ = this.sharedQuillUpdate.asObservable();

  constructor(private common: CommonService) { }

  // 再表示時の画像、OGP表示
  checkReloadQuill(quill) {
    const contents = quill.getContents();
    let tocCreateFlg = false;
    contents.ops.forEach(element => {
      if (element.insert?.image?.['data-path'] && element.insert.image['data-path'] !== 'null') {
        // 画像のURL取得
        this.getImageUrl(element.insert.image['data-path'], quill);
      } else if (element.insert['ogp-div']) {
        // OGP情報取得
        const url = element.insert['ogp-div'];
        this.getOgpHtml(url).then(html => {
          this.setOgpData(html, url);

        }).catch(err => {
          // OGP取得エラーの場合、何もしない

        })
      } else if (element.insert['toc-div'] && !tocCreateFlg) {
        // 目次の作成
        tocCreateFlg = true;
        const tocDiv = document.querySelectorAll(
          '.ql-container .toc-div'
        );
        if (tocDiv.length > 0) {
          const tocHtml = this.createToc();
          tocDiv.forEach(div => {
            div.innerHTML = tocHtml;
          })
        }
      }
    });
  }

  // 画像のURL取得
  private getImageUrl(path, quill) {
    // 画像のURL取得
    const apiPath = '/contents/editor/imageurl';
    const data = {
      path: path
    };
    this.common.apiPostBeforeLogin(apiPath, data).then(res => {
      if (res.data.url) {
        this.updateImageUrl(path, res.data.url, quill);
      }
    })
    .catch(err => {
      this.common.debug().log(err);
    });
  }

  // 画像のsrc差し替え
  private updateImageUrl(path, url, quill) {
    const imgDiv = document.querySelectorAll(
      '.ql-container img'
    );
    if (imgDiv.length > 0) {
      imgDiv.forEach(img => {
        const datapath = img.getAttribute('data-path')
        if (datapath === path) {
          img.setAttribute('src', url);
          //　quillアップデートの通知（コンテンツ詳細向け）
          this.sharedQuillUpdate.next(true);
        }
      })
    }
  }

  // OGP取得
  getOgpHtml(url) {
    return new Promise((resolve, reject) => {
      const apiPath = '/ogp';
      const data = {
        url: url
      };
      this.common.apiPostBeforeLogin(apiPath, data).then(res => {
        const html = this.createOgpHtml(url, res.data);
          resolve(html);
      })
      .catch(err => {
        reject(err);
      });
    });

  }

  // OGP取得後、HTML作成
  private createOgpHtml(url, ogp) {
    let ogpHtml = '<a href="' + url + '" target="_blank"><strong>' + ogp['og-title'] + '</strong>';
    if (ogp['og-description']) {
      ogpHtml += '<span class="desc">' + ogp['og-description'] + '</span>';
    }
    ogpHtml += '<span class="url">' + url + '</span></a>';
    ogpHtml += '<a href="' + url + '" target="_blank" class="og-image"><img src="';
    if (ogp['og-image']) {
      ogpHtml += ogp['og-image'];
    } else {
      ogpHtml += 'assets/common/ogp-empty.svg';
    }
    ogpHtml += '"></a>';

     //　quillアップデートの通知（コンテンツ詳細向け）
     this.sharedQuillUpdate.next(true);

    return ogpHtml;
  }

  // OGPのHTMLを反映
  private setOgpData(html, url) {
    const ogpDiv = document.querySelectorAll(
      '.ql-container .ogp-div'
    );
    if (ogpDiv.length > 0) {
      ogpDiv.forEach(div => {
        if (div.innerHTML === url) {
          div.innerHTML = html;
        }
      })
    }
  }

  // 目次作成
  private createToc() {
    const headingTagElementsAll = document.querySelectorAll<HTMLElement>(
      '.ql-container h3, .ql-container h4'
    );

    // 改行のみの見出しを削除
    const headingTagElements = [];
    headingTagElementsAll.forEach((headingTagElement) => {
      if (headingTagElement.innerText !== Constant.elementNewLine) {
        headingTagElements.push(headingTagElement);
      }
    });
    // HTML作成
    let tocHtml = '';
    headingTagElements.forEach((headingTagElement, index) => {
      // if (headingTagElement.innerText !== '<br>') {
        headingTagElement.setAttribute('id', 'tocheader-' + index);
        tocHtml  += '<div class="header-' + headingTagElement.tagName + '" onclick="onToc(' + index + ')">' + headingTagElement.innerText + '</div>'
        if (index != headingTagElements.length -1) {
          tocHtml  += '<hr class="header-' + headingTagElement.tagName + '">';
        }
      // }
    });
    if (tocHtml === '') {
      tocHtml = '<div class="toc-title bottom0">目次<span>（見出しを作ると項目が表示されます）</span></div>' + tocHtml;
    } else {
      tocHtml = '<div class="toc-title">目次</div>' + tocHtml;
    }

    return tocHtml;
  }
}
