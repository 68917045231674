<app-header [class.header-hide]="lp"></app-header>
<app-company-header *ngIf="companyHeader"></app-company-header>
<div [class.all]="!lp">
  <div class="display-flex">
    <!-- 左カラム　メインコンテンツ -->
    <div class="left-col" [class.width-all]="widthAll">
      <router-outlet></router-outlet>
    </div>
    <!-- 右カラム サイドメニュー-->
    <div class="right-col" [class.side-menu-hide]="widthAll">
      <app-side-menu></app-side-menu>
    </div>
  </div>
</div>
