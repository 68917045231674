<mat-dialog-content>
  <img src="assets/common/clear.svg" class="close" mat-dialog-close *ngIf="closeFlg && !blueFlg">
  <img src="assets/company/close.png" class="close" mat-dialog-close *ngIf="closeFlg && blueFlg">
  <h3 mat-dialog-title *ngIf="title" [innerHTML]='safeTitle'></h3>
  <div id="message-container" class="message-container">
    <div id="message" class="message" [innerHTML]='safeHtml'></div>
    <div *ngIf="subMessage" id="submessage" class="message">
      {{ subMessage }}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <div *ngIf="type == Constant.mdTypeYesNo" class="button-container">
    <button mat-raised-button (click)="onLeft()" class="half">
      {{ buttonText.left }}
    </button>
    <button mat-raised-button (click)="onRight()" color="accent" cdkFocusInitial class="half" *ngIf="!blueFlg">
      {{ buttonText.right }}
    </button>
    <button mat-raised-button (click)="onRight()" cdkFocusInitial class="half blue" *ngIf="blueFlg">
      {{ buttonText.right }}
    </button>
  </div>
  <div *ngIf="type == Constant.mdTypeOK" class="button-container">
    <button mat-raised-button (click)="onOK()" color="accent" class="ok-btn" *ngIf="!blueFlg">OK</button>
    <button mat-raised-button (click)="onOK()" class="ok-btn blue" *ngIf="blueFlg">OK</button>
  </div>
  <div *ngIf="type == Constant.mdTypeTsunagaru" class="button-container">
    <button mat-raised-button (click)="onTsunagaru()" class="tsunagaru-btn">つながる</button>
  </div>
  <div *ngIf="type == Constant.mdTypeReload" class="button-container">
    <button mat-raised-button (click)="onOK()" color="accent" class="ok-btn">再ロード</button>
  </div>
</mat-dialog-actions>
