<mat-toolbar class="header" [class.fixed]="currentPage && currentPage.indexOf(Constant.rpMessagedetail)>-1">
  <ng-container *ngIf="!backList">
    <div class="left-container">
      <div class="header-logo" [class.void] = "voidFlg">
        <a routerLink='/' *ngIf="!auth.loggedIn.value" >
          <img src="assets/common/logo.png"
           alt="CaLin" class="logo" (click)="onClickGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogoCalin)" />
        </a>
        <img *ngIf="auth.loggedIn.value"
         src="assets/common/logo.png"
         alt="CaLin" class="logo" (click)="onCalinLogo()" />
      </div>
      <h1 class="header-text">
        はじめまして！<br>
        わたしの気になる会社
      </h1>
    </div>
  </ng-container>
  <ng-container *ngIf="backList">
    <div class="left-container">
      <div class="back" (click)="onBack()" *ngIf="!spqFlg">＜一覧</div>
      <div class="back" (click)="onMypage()" *ngIf="spqFlg">＜マイページ トップ</div>
      <div *ngIf="!spqFlg && title.length <= lengthCount" class="message-title">{{ title }}</div>
      <div *ngIf="!spqFlg && title.length > lengthCount" class="message-title long">{{ title }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="showMenu; else noMenu">
    <div class="right-container" [class.hide]="menuHideFlg">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        *ngIf="showMenu"
        class="menu-btn"
        [hidden]="!auth.loggedIn | async">
        <mat-icon class="menu-icon">menu</mat-icon>
        <div class="menu-text">メニュー</div>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="true" backdropClass="menu-backdrop">
        <button mat-menu-item (click)="onMypage()">
          <mat-icon><img src="assets/common/mypage_g.svg" class="icon"></mat-icon>
          <span>マイページ トップ</span>
        </button>
        <button mat-menu-item (click)="onProfile()">
          <mat-icon><img src="assets/common/profile_g.svg" class="icon"></mat-icon>
          <span>プロフィール</span>
        </button>
        <button mat-menu-item (click)="onMessage()">
          <mat-icon><img src="assets/common/message_g.svg" class="icon"></mat-icon>
          <span>メッセージ</span>
          <span class="unread" *ngIf="unreadMsg">未読あり</span>
        </button>
        <button mat-menu-item (click)="onCompanyInfo()">
          <mat-icon><img src="assets/common/menu_domain.svg" class="icon"></mat-icon>
          <span>候補企業</span>
        </button>
        <button mat-menu-item (click)="onColumn()">
          <mat-icon><img src="assets/common/menu_column.svg" class="icon"></mat-icon>
          <span>就活準備コラム</span>
        </button>
        <button mat-menu-item (click)="onAccount()">
          <mat-icon><img src="assets/common/account_g.svg" class="icon"></mat-icon>
          <span>アカウント</span>
        </button>
        <button mat-menu-item (click)="onHelp()">
          <mat-icon><img src="assets/common/howtouse_g.svg" class="icon"></mat-icon>
          <span>ご利用方法</span>
        </button>
        <button mat-menu-item (click)="onContact()">
          <mat-icon><img src="assets/common/contact_g.svg" class="icon"></mat-icon>
          <span>お問い合わせ <img class="resource-icon" src="assets/common/launch.svg" /></span>
        </button>
        <button mat-menu-item (click)="onLogout()">
          <mat-icon><img src="assets/common/logout_g.svg" class="icon"></mat-icon>
          <span>ログアウト</span>
        </button>
      </mat-menu>
    </div>
  </ng-container>
  <ng-template #noMenu>
    <div class="empty"></div>
  </ng-template>
</mat-toolbar>
<div *ngIf="(!backList || spqFlg) && title !== Constant.empty && !noTitle" class="title-area">
  <span class="title">{{ title }}</span>
</div>
