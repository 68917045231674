import { Injectable } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { Subject } from 'rxjs';

@Injectable()
export class EducationService {
  private apiPath = '/profile/education';
  private educations;

  // データ受け渡し用
  private sharedEducations = new Subject<any>();
  public sharedEducations$ = this.sharedEducations.asObservable();

  constructor(private common: CommonService) {}

  /* API */
  // 学歴取得
  getEducation(reset: boolean) {
    if (!reset && this.educations) {
      this.sharedEducations.next(this.educations);
      return;
    }

    this.common
      .apiGet(this.apiPath)
      .then(res => {
        this.educations = res.data;
        this.sharedEducations.next(this.educations);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.sharedEducations.next(Constant.apError);
      });
  }

  // 追加、更新
  postEducation(data, id?): Promise<any> {
    let apiPath = this.apiPath;
    if (id) {
      apiPath += '/' + id;
      return this.common.apiPut(apiPath, data);
    } else {
      return this.common.apiPost(apiPath, data);
    }
  }

  // 削除
  delEducation(id): Promise<any> {
    const apiPath = this.apiPath + '/' + id;
    return this.common.apiDel(apiPath);
  }
}
