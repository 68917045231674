<mat-dialog-content>
  <div class="message">
    <p>{{ name }}では、みなさまとのつながりを、<a [href]="Constant.footerUrlTalentLp" target="_blank">採用サービス CaLin<img src="assets/company/icon_launch.png" class="launch-icon"></a>
      で管理しています。</p>
    <p>{{ name }}とつながるには、CaLinにログインしてください。</p>
  </div>
  <div class="button-container">
    <button mat-raised-button (click)="onLeft()" color="accent" class="button">ログイン</button>
    <button mat-raised-button (click)="onRight()" color="accent" class="button">新規登録</button>
  </div>
  <div class="message">
    つながらずにCaLinにログイン/新規登録する場合は<a (click)="onLogin()">こちら<img src="assets/company/icon_launch.png" class="launch-icon"></a>
  </div>
</mat-dialog-content>
