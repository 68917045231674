import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ColumnService {

  constructor(
    private common: CommonService,
    private domSanitizer: DomSanitizer,
  ) { }

  // 一覧取得
  getColumnList() {
    const apiPath = '/column/startguidelist/1';

    return this.common.apiGetBeforeLoginForAdmin(apiPath).then(res => {
      return res.data;
    })
    .catch(err => {
      this.common.debug().log(err);
      return Promise.reject(err);
    });
  }

  // 詳細取得
  getColumnDetail(id) {
    const apiPath = '/column/startguide/' + id;

    return this.common.apiGetBeforeLoginForAdmin(apiPath).then(res => {
      if (!res.data) {
        return null;
      }
      const data = res.data;
      data.contentHtml = this.domSanitizer.bypassSecurityTrustHtml(data.content);
      return data;
    })
    .catch(err => {
      this.common.debug().log(err);
      return Promise.reject(err);
    });
  }

  // 本文省略
  ellipsisText(text: string, maxLen: number) {
    // HTMLタグを消去
    text = text.replace(/(<([^>]+)>)/gi, '');

    // 省略
    const len = text.length;
    if (len <= maxLen) {
      return text;
    } else {
      return text.substr(0, maxLen) + '… ';
    }
  }
}
