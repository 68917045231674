import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { CompanyService } from '../shared/company.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit, OnDestroy {

  public readonly Constant = Constant;
  public type: string;
  public voidFlg = false;
  public title = '';
  public unreadMsg = false;
  private subscriptionTitle: Subscription;
  private subscriptionMessage: Subscription;

  constructor(
    public auth: AuthService,
    private router: Router,
    private common: CommonService,
    private companyService: CompanyService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // ページタイプ判断のためにタイトルを取得
    this.subscriptionTitle = this.common.sharedTitle$.subscribe(data => {
      this.title = data;
      // アクティブ時に色変更するためにtitleをtypeを設定
      this.type = this.title;
    });

    // メッセージ未読チェックの購読
    this.checkUnreadMessage();

    // メニューの色変更、押下無効処理
    this.router.events.forEach((event: NavigationEvent) => {
      // ページ遷移後
      if (event instanceof NavigationEnd) {
        // profilenewページの場合はvoidFlgをtrueに変更
        if (event.urlAfterRedirects.includes(Constant.rpProfilenew)) {
          this.voidFlg = true;
        } else {
          this.voidFlg = false;
        }
        // メッセージ未読チェック
        this.companyService.getMessageList();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionTitle.unsubscribe();
    this.subscriptionMessage.unsubscribe();
  }

  onMypage() {
    this.common
      .preCheck()
      .then(res => {
        // voidFlgがtrueの時はリンク押下で遷移しないように設定
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpMypage]);
        }
      })
      .catch(err => {});
  }

  onCompanyInfo() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpCompanyInfo]);
        }
      })
      .catch(err => {});
  }

  onMessage() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpMessagelist]);
        }
      })
      .catch(err => {});
  }

  onColumn() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpMyColumn]);
        }
      })
      .catch(err => {});
  }

  onProfile() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpDesire]);
        }
      })
      .catch(err => {});
  }

  onAccount() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpAccount]);
        }
      })
      .catch(err => {});
  }

  onHelp() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          this.router.navigate([Constant.rpWalkThrough + '1']);
        }
      })
      .catch(err => {});
  }

  onContact() {
    if (this.voidFlg || !this.common.preCheckWindowOpen()) {
      return;
    }
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionContact);
    window.open(Constant.footerUrlContact);
  }

  onLogout() {
    this.common
      .preCheck()
      .then(res => {
        if (this.voidFlg === false) {
          // GAイベント
          this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogout);

          // 確認ダイアログ表示
          const dialogRef = this.dialog.open(MessageDialogComponent, {
            width: Constant.dlWidth,
            autoFocus: false,
            data: {
              message: Constant.msgConfirmLogout,
              type: Constant.mdTypeYesNo,
              common: this.common,
              buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === Constant.mdBtnRight) {
              this.auth.signOut();
            }
          });
        }
      })
      .catch(err => {});
  }

  // メッセージの未読チェック
  private checkUnreadMessage() {
    this.subscriptionMessage = this.companyService.sharedMessages$.subscribe(data => {
      this.unreadMsg = this.companyService.checkUnreadMessage(data);
    });
  }
}
