import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';

@Component({
  selector: 'app-profile-new-comp',
  templateUrl: './profile-new-comp.component.html',
  styleUrls: ['./profile-new-comp.component.css']
})
export class ProfileNewCompComponent implements OnInit {
  public showSpinner = true;
  public companyCode: string;
  public companyName: string;

  constructor(private common: CommonService, private router: Router) { }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlRegist);

    // ローカルストレージより流入元の企業コード取得
    this.companyCode = localStorage.getItem(Constant.lsRefererCompanyCode);
    if (this.companyCode) {
      this.getCompanyQuestion();
    } else {
      this.showSpinner = false;
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  onProfile() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpEducationEdit]);
      })
      .catch(err => { });
  }

  onMypage() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMypage]);
      })
      .catch(err => { });
  }

  // 質問を見る
  onQuestion() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpSpq, this.companyCode]);
      })
      .catch(err => { });
  }

  // 追加質問の有無取得
  private getCompanyQuestion() {
    const apiPath = '/question/check/' + this.companyCode;

    this.common
      .apiGet(apiPath)
      .then(res => {
        this.showSpinner = false;
        if (res.data?.name) {
          this.companyName = res.data.name;
        }
      })
      .catch(err => {
        this.showSpinner = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      });
  }
}
