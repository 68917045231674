import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
import { CommonService } from './shared/common.service';
import { AuthGuard } from './auth/auth.guard';
import { ProfileService } from './shared/profile.service';
import { WorkService } from './shared/work.service';
import { EducationService } from './shared/education.service';
import { CompanyService } from './shared/company.service';
import { ContactService } from './shared/contact.service';
import { CommonLpService } from './shared/common-lp.service';

import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ProfileNewComponent } from './profile-new/profile-new.component';
import { ProfileNewCompComponent } from './profile-new-comp/profile-new-comp.component';
import { SelectDialogComponent } from './dialog/select-dialog/select-dialog.component';
import { MessageDialogComponent } from './dialog/message-dialog/message-dialog.component';
import { ProfileCareerComponent } from './profile-career/profile-career.component';
import { ProfileCareerEditComponent } from './profile-career-edit/profile-career-edit.component';
import { ProfileEditButtonsComponent } from './profile-edit-buttons/profile-edit-buttons.component';
import { ProfileEducationComponent } from './profile-education/profile-education.component';
import { ProfileEducationEditComponent } from './profile-education-edit/profile-education-edit.component';
import { ProfileDesireComponent } from './profile-desire/profile-desire.component';
import { ProfileDesireEditComponent } from './profile-desire-edit/profile-desire-edit.component';
import { ProfileBasicComponent } from './profile-basic/profile-basic.component';
import { ProfileBasicEditComponent } from './profile-basic-edit/profile-basic-edit.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AccountComponent } from './account/account.component';
import { ConfirmMailComponent } from './confirm-mail/confirm-mail.component';
import { ResendMailcodeComponent } from './resend-mailcode/resend-mailcode.component';
import { PasswordForgetComponent } from './password-forget/password-forget.component';
import { ResendCodeComponent } from './resend-code/resend-code.component';
import { ProfileMessageButtonsComponent } from './profile-message-buttons/profile-message-buttons.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ProgressDialogComponent } from './dialog/progress-dialog/progress-dialog.component';
import { CompanyInfoListComponent } from './company-info-list/company-info-list.component';
import { MypageComponent } from './mypage/mypage.component';
import { LpComponent } from './lp/lp.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { WalkthroughComponent } from './walkthrough/walkthrough.component';
import { TsunagaruDialogComponent } from './dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { ContactTagDialogComponent } from './dialog/contact-tag-dialog/contact-tag-dialog.component';
import { NeedLoginDialogComponent } from './dialog/need-login-dialog/need-login-dialog.component';
import { PdfviewerDialogComponent } from './dialog/pdfviewer-dialog/pdfviewer-dialog.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { CmnPdfViewerComponent } from './common-component/cmn-pdf-viewer/cmn-pdf-viewer.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { LpJobofferListComponent } from './lp-joboffer-list/lp-joboffer-list.component';
import { LpFooterComponent } from './lp-footer/lp-footer.component';
import { SearchCompanyDialogComponent } from './dialog/search-company-dialog/search-company-dialog.component';
import { LpColumnComponent } from './lp-column/lp-column.component';
import { LpHeaderComponent } from './lp-header/lp-header.component';
import { LpStartComponent } from './lp-start/lp-start.component';
import { LpPageComponent } from './lp-page/lp-page.component';
import { LpSearchDialogComponent } from './dialog/lp-search-dialog/lp-search-dialog.component';
import { LpSearchBoxComponent } from './common-component/lp-search-box/lp-search-box.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ColumnComponent } from './column/column.component';
import { ProfileFileComponent } from './profile-file/profile-file.component';
import { ShowFileComponent } from './show-file/show-file.component';
import { UnsubscribeMailComponent } from './unsubscribe-mail/unsubscribe-mail.component';
import { SpecialQuestionComponent } from './special-question/special-question.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    ConfirmComponent,
    MessageListComponent,
    ProfileNewComponent,
    SelectDialogComponent,
    MessageDialogComponent,
    ProfileCareerComponent,
    ProfileEditButtonsComponent,
    ProfileEducationComponent,
    ProfileDesireComponent,
    ProfileBasicComponent,
    MessageDetailComponent,
    UnsubscribeComponent,
    AccountComponent,
    ConfirmMailComponent,
    PasswordForgetComponent,
    ProfileBasicEditComponent,
    ProfileCareerEditComponent,
    ResendCodeComponent,
    ProfileDesireEditComponent,
    ProfileEducationEditComponent,
    ProfileMessageButtonsComponent,
    ProfileNewCompComponent,
    PageNotFoundComponent,
    ResendMailcodeComponent,
    MaintenanceComponent,
    ProgressDialogComponent,
    CompanyInfoListComponent,
    MypageComponent,
    LpComponent,
    WalkthroughComponent,
    TsunagaruDialogComponent,
    SideMenuComponent,
    ContactTagDialogComponent,
    NeedLoginDialogComponent,
    LpJobofferListComponent,
    LpFooterComponent,
    PdfviewerDialogComponent,
    PdfViewerComponent,
    CmnPdfViewerComponent,
    SearchCompanyDialogComponent,
    LpColumnComponent,
    LpHeaderComponent,
    LpStartComponent,
    LpPageComponent,
    LpSearchDialogComponent,
    LpSearchBoxComponent,
    ColumnComponent,
    ProfileFileComponent,
    ShowFileComponent,
    UnsubscribeMailComponent,
    SpecialQuestionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPaginationModule,
    SharedModule,
  ],
  providers: [
    AuthService,
    CommonService,
    AuthGuard,
    ProfileService,
    WorkService,
    EducationService,
    CompanyService,
    ContactService,
    CommonLpService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, maxWidth: '90vw', scrollStrategy: new NoopScrollStrategy() }}
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
