<mat-dialog-content>
  <div class="message" *ngIf="data.type==Constant.enqTypePool">つながる前にアンケート（1問）にご協力ください。</div>
  <div class="message" *ngIf="data.type==Constant.enqTypeJoboffer">{{ data.typename }}の前にアンケート（1問）にご協力ください。</div>
  <div class="message" *ngIf="data.type==Constant.enqTypeLike"><img src="assets/company/icon_heart_R.png">いいね の前にアンケート（1問）にご協力ください。</div>
  <div class="description">{{ data.message }}</div>
  <div class="button-container">
    <button mat-stroked-button class="half" (click)="onLeft()">いいえ</button>
    <button mat-stroked-button class="half" (click)="onRight()">はい</button>
  </div>
  <div class="small" *ngIf="data.type==Constant.enqTypePool">アンケートに回答すると{{ data.companyName }}とつながります。</div>
  <div class="small" *ngIf="data.type==Constant.enqTypeJoboffer">{{ data.typename }}はまだ完了していません。アンケート回答で完了します。</div>
  <div class="small" *ngIf="data.type==Constant.enqTypeLike"><img src="assets/company/icon_heart_R.png">いいね はまだ完了していません。アンケート回答で完了します。</div>
</mat-dialog-content>
