import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Constant } from './../../constant';

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.css']
})
export class SelectDialogComponent implements OnInit {
  public form: FormGroup;
  public readonly Constant = Constant;
  public jobdata = [];
  public place_all = 'place_all';  // formControlName
  public graduate = 'graduate';    // formControlName
  public school = 'school';        // formControlName

  constructor(
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initForm();
  }

  /* テンプレート起動 */
  onSelect() {
    // 選択されたidを戻す
    const selected = [];
    if (this.data.type === Constant.sdTypeYear) {
      const val = this.form.value[this.graduate] !== null ? this.form.value[this.graduate] :  -1;
      selected.push(val);
    } else if (this.data.type === Constant.sdTypeSchool) {
      selected.push(this.form.value[this.school]);
    } else {
      this.data.items.forEach(element => {
        element.middle.forEach(middle => {
          if (this.form.value[element[Constant.apLargeId] + '-' + middle.id]) {
            selected.push(middle.all);
          }
        });
      });
    }
    this.dialogRef.close(selected);
  }

  // 大分類チェックで小分類のチェック
  onLargeId(checked, id) {
    this.data.items.forEach(element => {
      if (element[Constant.apLargeId] === id) {
        element.middle.forEach(middle => {
          this.form.get(element[Constant.apLargeId] + '-' + middle.id).setValue(checked);
        });
      }
    });
    if (this.data.type === Constant.sdTypePlace) {
      this.checkDefaultAll();
    }
  }

  // 小分類チェックで大分類のチェック
  onMiddelId(parentId) {
    let checkParent = true;
    this.data.items.forEach(element => {
      if (element[Constant.apLargeId] === parentId) {
        element.middle.forEach(middle => {
          if (!this.form.get(element[Constant.apLargeId] + '-' + middle.id).value) {
            checkParent = false;
          }
        });
        this.form.get(element[Constant.apLargeId].toString()).setValue(checkParent);
      }
    });
    if (this.data.type === Constant.sdTypePlace) {
      this.checkDefaultAll();
    }
  }

  // 全国チェック
  onPlaceAll(checked) {
    this.data.items.forEach(element => {
      this.form.get(element[Constant.apLargeId].toString()).setValue(checked);
      element.middle.forEach(middle => {
        this.form.get(element[Constant.apLargeId] + '-' + middle.id).setValue(checked);
      });
    });
  }

  // ラジオボタンのチェックボックスを外す
  onRadio(id, name, event) {
    if (event.target.className === 'mdc-label') {
      return;
    }
    if (id === this.form.value[name]) {
      this.form.get(name).reset();
      const elm = <HTMLElement>document.activeElement;
      elm.blur();
    }
  }

  // フォーム作成
  private initForm() {
    const formControl = {};
    if (this.data.type === Constant.sdTypeYear) {
      this.data.items.forEach(element => {
        formControl[this.graduate] = new FormControl(this.data.selected);
      });
    } else if (this.data.type === Constant.sdTypeSchool){
      this.data.items.forEach(element => {
        formControl[this.school] = new FormControl(this.data.selected);
      });
    } else {
      this.data.items.forEach(element => {
        let largeSelected = true;
        element.middle.forEach(middle => {
          const selected = this.checkDefault(middle.id);
          if (!selected) {
            largeSelected = false;
          }
          formControl[element[Constant.apLargeId] + '-' + middle.id] = new FormControl(selected);
        });
        formControl[element[Constant.apLargeId]] = new FormControl(largeSelected);
      });
    }
    // 勤務地選択に全国を追加
    if (this.data.type === Constant.sdTypePlace) {
      const all = this.data.selected && this.data.selected.length === Constant.prfPlaceZenkokuCnt ? true : false;
      formControl[this.place_all] = new FormControl(all);
    }
    this.form = new FormGroup(formControl);
  }

  private checkDefault(id): boolean {
    if (!this.data.selected || this.data.selected.length === 0) {
      return false;
    }

    if (this.data.type === Constant.sdTypePlace) {
      if (this.data.selected.indexOf(id) >= 0) {
        return true;
      } else {
        return false;
      }
    } else if (this.data.type === Constant.sdTypeJob) {
      // 職歴の場合
      const item = this.data.selected.filter(function(element) {
        return element.id === id;
      });
      if (item.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  // 勤務地選択：全国チェックの有無
  private checkDefaultAll() {
    let allChecked = true;
    this.data.items.forEach(element => {
      element.middle.forEach(middle => {
        if (!this.form.get(element[Constant.apLargeId] + '-' + middle.id).value) {
          allChecked = false;
        }
      });
    });
    this.form.get(this.place_all).setValue(allChecked);
  }
}
