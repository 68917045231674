import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Constant } from '../constant';
import { CommonService } from '../shared/common.service';
import { sprintf } from 'sprintf-js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lp-page',
  templateUrl: './lp-page.component.html',
  styleUrls: ['./lp-page.component.css']
})
export class LpPageComponent implements OnInit {
  public readonly Constant = Constant;
  public showSpinner = false;
  public companyInfoList = []; // 企業一覧
  public loading = true;
  private apiPath = '/company/';

  // ページング用
  public config: PaginationInstance = {
    id: 'companies',
    itemsPerPage: Constant.dataPerScroll,
    currentPage: 1,
    totalItems: 0
  };
  private fromno = 0;

  // 絞り込み用
  private selectedIndustry = [];
  private selectedPlace = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private common: CommonService,
    private title: Title,
  ) {
    // ページング
    const page = this.activatedRoute.snapshot.params['page'];
    if (page) {
      this.setPageNum(page);
    }
  }

  ngOnInit(): void {
    // タイトル設定
    this.title.setTitle(Constant.metaTitleLP);
    // 検索条件取得
    const cond =  JSON.parse(localStorage.getItem(Constant.lsLpSearchCondition));
    if (cond) {
      this.selectedPlace = cond.selectedPlace;
      this.selectedIndustry = cond.selectedIndustry;
    }

    // 企業一覧取得
    this.getCompanyInfo();

    setTimeout(() => {
      this.loading = false;
    });
  }

  // 検索コンポーネントより検索ボタン押下
  searchCompany(cond) {
    // GA
    this.onClickGAEvent(Constant.gaActionPageSearch);

    this.selectedPlace = cond.selectedPlace;
    this.selectedIndustry = cond.selectedIndustry,
    this.calcFromno(1);
    this.getCompanyInfo();
  }

  // ページングのボタンがクリックされた時の処理
  onPagingNum(page: number) {
    this.router.navigate([Constant.rpLpPage + '/' + page.toString()]);
    this.calcFromno(page);
    this.getCompanyInfo();
  }

  // GA
  onClickGAEvent(action) {
    this.common.sendGAEvent(Constant.gaCategoryButtons, action);
  }

  // 企業詳細クリックのGA
  onClickCompanyDetail(index) {
    const action = sprintf(Constant.gaActionPageCompany, this.config.currentPage, (index + 1));
    this.onClickGAEvent(action);
  }

  // テキストを省略
  ellipsisText(text, maxLength) {
    return this.common.ellipsisText(text, maxLength);
  }

  // 企業一覧取得
  private getCompanyInfo() {
    this.showSpinner = true;
    const apiPath = this.apiPath + 'all';
    const body = {
      from_no: this.fromno,
      desire_place: this.selectedPlace,
      company_industry: this.selectedIndustry,
    };

    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.common.apiPostBeforeLogin(apiPath, body).then(res => {
      // 2ページ目以降でデータ無しの場合、TOPへ飛ばす
      if (this.config.currentPage > 1 && res.data.list.length === 0) {
        this.router.navigate([Constant.rpLp]);
        return;
      }
      this.companyInfoList = res.data.list;
      this.config.totalItems = res.data.cnt;
      this.showSpinner = false;
      this.getImageData(this.companyInfoList);
    })
    .catch(err => {
      this.showSpinner = false;
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.common.debug().log(err);
      }
    });
  }

  // 企業ロゴと企業情報の画像を取得する
  private getImageData(currentData) {
    for (let i = 0; i < currentData.length; i++) {
      const companyCode = currentData[i].code;
      // コンテンツの画像を取得する
      const contentsCode = currentData[i].code;
      const urlApiPath = this.apiPath + 'info/' + companyCode + '/imageurl';
      this.common
        .apiGetBeforeLogin(urlApiPath)
        .then(res => {
          const data = res.data;
          if (data.url === '' || data.url === null) {
            currentData[i].pr_image_url = '';
          } else {
            currentData[i].pr_image_url = data.url;
          }
        })
        .catch(err => {
          this.common.debug().log(err);
        });

      // 企業のロゴ画像を取得する
      const logoUrlApiPath = this.apiPath + companyCode + '/logourl';
      this.common
        .apiGetBeforeLogin(logoUrlApiPath)
        .then(res => {
          const data = res.data;
          if (data.url === '' || data.url === null) {
            currentData[i].logo_url = '';
          } else {
            currentData[i].logo_url = data.url;
          }
        })
        .catch(err => {
          if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
            this.common.debug().log(err);
          }
        });
    }
  }

  // URLからのページ数セット
  private setPageNum(page: string) {
    const pageNum = parseInt(page, 10);

    // ページ数が適切でない場合はトップに飛ばす
    if (Number.isNaN(pageNum) || pageNum < 1) {
      this.router.navigate([Constant.rpLp]);
      return;
    }

    this.calcFromno(pageNum);
  }

  // 取得開始番号計算
  private calcFromno(page: number) {
    this.config.currentPage = page;
    this.fromno = (page - 1) * this.config.itemsPerPage;
  }

}
