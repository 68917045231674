import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { Constant } from './../../constant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-only-dialog',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './message-only-dialog.component.html',
  styleUrl: './message-only-dialog.component.css'
})
export class MessageOnlyDialogComponent {
  public readonly Constant = Constant;
  public message;
  public closeFlg = false;
  public blueFlg = false;

  constructor(
    public dialogRef: MatDialogRef<MessageOnlyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = this.data.message;
    if (this.data.closeFlg) {
      this.closeFlg = this.data.closeFlg;
    }
    if (this.data.blueFlg) {
      this.blueFlg = this.data.blueFlg;
    }
  }

}
