import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LpSearchDialogComponent } from '../../dialog/lp-search-dialog/lp-search-dialog.component';
import { ProfileService } from '../../shared/profile.service';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';

@Component({
  selector: 'app-lp-search-box',
  templateUrl: './lp-search-box.component.html',
  styleUrls: ['./lp-search-box.component.css']
})
export class LpSearchBoxComponent implements OnInit, OnDestroy {
  @Output() searchCompany = new EventEmitter();
  public readonly Constant = Constant;
  public btnTxtPlace = Constant.sdTypePlaceTitle;
  public btnTxtIndustry = Constant.sdTypeIndustryTitle;
  private selectedIndustry = [];
  private selectedPlace = [];
  private industryMaster = []; // 業種マスタ
  private placeMaster = []; // 勤務地マスタ
  private subscriptionMaster: Subscription;

  constructor(
    private common: CommonService,
    private dialog: MatDialog,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    // 検索条件取得
    const cond =  JSON.parse(localStorage.getItem(Constant.lsLpSearchCondition));
    if (cond) {
      this.selectedPlace = cond.selectedPlace;
      this.selectedIndustry = cond.selectedIndustry;
      this.btnTxtPlace = cond.btnTxtPlace;
      this.btnTxtIndustry = cond.btnTxtIndustry;

      // 保存した検索条件を削除
    localStorage.removeItem(Constant.lsLpSearchCondition);
    }
    // マスタ取得
    this.getSelectMaster();
  }

  ngOnDestroy(): void {
    this.subscriptionMaster.unsubscribe();
  }

  // 勤務地・業種選択
  onSelect(type) {
    let master;
    let selected;
    if (type === Constant.sdTypePlace) {
      master = this.placeMaster;
      selected = this.selectedPlace;
    } else if (type === Constant.sdTypeJob) {
      master = this.industryMaster;
      selected = this.selectedIndustry;
    }
    this.common.preCheck().then(res => {
      const dialogRef = this.dialog.open(LpSearchDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        disableClose: true,
        data: {
          type: type,
          selectedPlace: this.selectedPlace,
          selectedIndustry: this.selectedIndustry,
          masterPlace: this.placeMaster,
          masterIndustry: this.industryMaster,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        // 勤務地設定
        this.selectedPlace = [];
        if (result[Constant.apDesirePlace].length === 0) {
          this.btnTxtPlace = Constant.sdTypePlaceTitle;
        } else if (typeof(result[Constant.apDesirePlace][0]) === 'number') {
          // 勤務地変更なしの場合
          this.selectedPlace = result[Constant.apDesirePlace];
        } else if (result[Constant.apDesirePlace].length === Constant.prfPlaceZenkokuCnt) {
          // 全国選択の場合
          this.btnTxtPlace = Constant.prfPlaceZenkokuTxt;
          result[Constant.apDesirePlace].forEach(element => {
            this.selectedPlace.push(element.id);
          });
        } else {
          // その他
          this.btnTxtPlace = '';
          result[Constant.apDesirePlace].forEach(element => {
            this.selectedPlace.push(element.id);
            this.btnTxtPlace += element.item_value + ',';
          });
          this.btnTxtPlace = this.btnTxtPlace.slice(0, -1);
        }

        // 業種設定
        this.selectedIndustry = result[Constant.apCompanyIndustry];
        if (this.selectedIndustry.length === 0) {
          this.btnTxtIndustry = Constant.sdTypeIndustryTitle;
        } else {
          this.btnTxtIndustry = '';
          this.selectedIndustry.forEach(element => {
            const industry = this.industryMaster.find(item => item.id === element);
            if (industry)  {
              this.btnTxtIndustry += industry.item_value + ','
            }
          });
          this.btnTxtIndustry = this.btnTxtIndustry.slice(0, -1);
        }

        // 検索
        if (result[Constant.lpSearchFlg]) {
          this.onSearch();
        }
      });
    })
    .catch(err => {});
  }

  // 検索
  onSearch() {
    const cond = {
      selectedPlace: this.selectedPlace,
      selectedIndustry: this.selectedIndustry,
      btnTxtPlace: this.btnTxtPlace,
      btnTxtIndustry: this.btnTxtIndustry,
    };
    this.searchCompany.emit(cond);
  }

  private getSelectMaster() {
    this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
      data.forEach(element => {
        if (element.type === Constant.pmCompanyIndustry) {
          this.industryMaster = element.data;
        } else if (element.type === Constant.pmDesireJobPlaceType) {
          // this.placeMaster = element.data;
          this.placeMaster = this.common.convertJobMaster(element.data);
        }
      });
    });

    const reset = false;
    this.profileService.getMaster(reset);
  }

}
