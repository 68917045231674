<div class="profile-message">

  <!-- トップ -->
  <div class="tab profile {{ type == Constant.tlTopPage ? 'selected' : '' }}" (click)="onMypage()">
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" width="24" height="24">
        <style>
          .b{fill:#30a9b7;}
        </style>
        <g transform="matrix(0.0390625,0,0,0.03975599,1.999767,1.8224671)">
          <path class="b"
            d="M 256,29.619 0.828,219.412 0.125,219.936 v 0 L 0,220.034 l 20.262,27.242 20.262,27.195 7.958,
            -5.906 4.648,-3.336 V 482.381 H 458.919 V 346.138 l 0.094,-80.953 12.507,9.285 20.215,-27.195 L 512,220.034 Z M 424.984,
            247.275 H 391.05 V 414.517 H 314.894 V 275.779 H 197.102 v 138.738 h -76.11 V 247.275 H 87.058 81.464 l -4.226,-0.121 9.82,
            -7.297 33.934,-25.254 7.125,-5.285 127.883,-95.109 127.926,95.11 7.125,5.332 33.934,25.207 5.578,4.145 4.203,3.273 h -9.782 z" />
        </g>
      </svg>
    </div>
    <div class="text">トップ</div>
  </div>

  <!-- プロフィール -->
  <div class="tab profile {{ type == Constant.tlProfile ? 'selected' : '' }}" (click)="onProfile()">
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" width="24" height="24">
        <style>
          .b{fill:#30a9b7;}
        </style>
        <g transform="matrix(0.03906258,0,0,0.04404015,2.0000015,0.72572179)">
          <path
            d="M 475.442,51.641 H 36.558 C 16.398,51.641 0,68.039 0,88.203 v 335.598 c 0,20.16 16.398,
            36.558 36.558,36.558 h 438.883 c 20.156,0 36.558,-16.398 36.558,-36.558 V 88.203 C 512,68.039 495.598,
            51.641 475.442,51.641 Z M 468.32,416.762 H 43.598 V 233.93 L 177.488,95.243 H 468.32 Z M 124.637,
            95.352 77.496,144.18 43.598,179.125 V 95.242 h 47.871 z"
            class="b" />
          <path
            d="m 176.801,159.242 c -10.16,0 -27.359,12 -34.242,24.398 -6.878,12.481 -8.961,16.961 -5.438,32.481 0.321,
            1.121 0.637,2 0.958,2.64 -1.199,1.039 -2.879,3.762 -2.481,7.598 0.402,3.844 1.523,18.321 8.641,18.321 0.562,0 1.281,
            -0.078 2,-0.321 0.16,1.282 0.801,4.723 2.082,8.242 1.278,3.602 3.199,7.199 6,8.719 v 8.082 l -0.242,15.122 v 0.238 h -0.958 c -2.722,
            0.641 -42.562,24.481 -57.121,38.078 -2,1.762 -3.121,3.363 -3.359,4.481 -1.122,5.679 -2.883,14.082 -3.52,20.082 -0.082,1.199 0.879,
            2.321 2.078,2.321 l 27.199,1.839 c 4.16,0.161 8.242,0.238 12.32,0.398 2,0.078 4,0.161 6,0.161 8.082,0.238 16.082,0.402 24.161,
            0.48 2.32,0.078 4.562,0.078 6.878,0.078 3.922,0.082 7.762,0.16 11.684,0.16 20.156,0 40.398,-0.398 60.558,-1.278 l 27.199,
            -1.762 c 1.282,-0.078 2.242,-1.199 2.078,-2.398 -0.637,-5.922 -2.317,-14.402 -3.438,-20.082 -0.242,-1.118 -1.442,-2.719 -3.442,
            -4.481 -14.481,-13.598 -54.32,-37.438 -57.121,-38.078 h -1.199 v -6.312 l 0.441,-17.438 c 0.118,-0.09 0.254,-0.18 0.36,-0.25 0.16,
            -0.16 0.32,-0.238 0.48,-0.402 4.801,-3.886 6.25,-11.93 6.726,-15.105 0.055,-0.305 0.122,-0.574 0.172,-0.891 0.715,0.238 1.348,
            0.317 1.981,0.317 7.121,0 8.242,-14.477 8.641,-18.321 0.398,-3.835 -1.282,-6.558 -2.481,-7.598 0.242,-0.64 0.562,-1.519 0.961,
            -2.64 0.801,-2.719 1.602,-6.559 1.84,-10.719 0.481,-6.082 -0.078,-12.641 -2.961,-16.722 -0.961,-1.438 -2.558,-3.199 -4.321,-5.039 -4.16,
            -4.078 -9.598,-8.238 -12.718,-8.238 -5.199,-9.281 -11.758,-15.281 -24,-16.082 -0.718,-0.078 -1.442,-0.078 -2.238,-0.078 h -0.158 z"
            class="b" />
          <path
            d="M 319.442,203.402 H 419.52 c 1.84,0 3.282,-1.442 3.282,-3.281 V 183.32 c 0,-1.758 -1.442,-3.282 -3.282,
            -3.282 H 319.442 c -1.844,0 -3.282,1.523 -3.282,3.282 v 16.801 c 0,1.84 1.438,3.281 3.282,3.281 z"
            class="b" />
          <path
            d="M 319.442,272.762 H 419.52 c 1.84,0 3.282,-1.519 3.282,-3.281 V 252.68 c 0,-1.84 -1.442,-3.278 -3.282,
            -3.278 H 319.442 c -1.844,0 -3.282,1.438 -3.282,3.278 v 16.801 c 0,1.761 1.438,3.281 3.282,3.281 z"
            class="b" />
          <path
            d="M 319.442,342.039 H 419.52 c 1.84,0 3.282,-1.438 3.282,-3.278 v -16.722 c 0,-1.836 -1.442,-3.278 -3.282,
            -3.278 H 319.442 c -1.844,0 -3.282,1.442 -3.282,3.278 v 16.722 c 0,1.841 1.438,3.278 3.282,3.278 z"
            class="b" />
        </g>
      </svg>
    </div>
    <div class="text">プロフィール</div>
  </div>

  <!-- メッセージタブ -->
  <div class="tab message {{ type == Constant.tlMessagelist ? 'selected' : '' }}" (click)="onMessage()">
    <!-- <img src="assets/common/menu_mail@2x.svg" class="icon"> -->
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" width="24" height="24">
        <style>
          .b{fill:#30a9b7;}
        </style>
        <g transform="matrix(0.0390625,0,0,0.04253,1.9999987,1.112339)">
          <path
            d="m 255.943,326.926 c 14.059,0 27.381,-5.491 37.312,-15.481 L 345.677,259.085 511.39,93.368 C 508.864,
            78.875 496.222,67.897 481.049,67.897 H 30.833 c -15.168,0 -27.814,10.978 -30.341,
            25.471 l 218.077,218.077 c 9.99,9.99 23.249,15.481 37.374,15.481 z"
            class="b" />
          <polygon
            points="113.908,259.085 47.212,192.424 0.16,145.236 0.16,145.396 0,145.236 0,372.994 "
            class="b" />
          <path
            d="m 371.941,285.345 v 0 l -0.113,-0.114 -52.363,52.364 c -17.02,16.957 -39.53,26.334 -63.522,26.334 -23.99,
            0 -46.564,-9.377 -63.525,-26.334 l -52.242,-52.25 h -0.004 v 0 L 140.059,285.231 1.726,423.564 c 4.253,
            11.966 15.664,20.538 29.106,20.538 H 481.05 c 13.442,0 24.854,-8.572 29.107,-20.538 L 371.945,285.345 Z"
            class="b" />
          <polygon
            points="512,145.286 511.886,145.4 511.886,145.173 397.974,259.085 511.886,372.994 511.886,239.628 "
            class="b" />
        </g>
      </svg>
    </div>
    <div class="unread" *ngIf="unread"><span>未読あり</span></div>
    <div class="text">メッセージ</div>
  </div>

  <!-- 候補企業タブ -->
  <div class="tab profile {{ type == Constant.tlCompanyInfo ? 'selected' : '' }}" (click)="onCompanyInfo()">
    <!-- <img src="assets/common/menu_domain.svg" class="icon"> -->
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
        <defs>
          <style>.a{fill:none;}.b{fill:#30a9b7;}</style>
        </defs>
        <path class="a" d="M0,0H32V32H0Z"/>
        <path class="b" d="M15.333,8.333V3H2V27H28.667V8.333Zm-8,16H4.667V21.667H7.333Zm0-5.333H4.667V16.333H7.333Zm0-5.333H4.667V11H7.333Zm0-5.333H4.667V5.667H7.333Zm5.333,16H10V21.667h2.667Zm0-5.333H10V16.333h2.667Zm0-5.333H10V11h2.667Zm0-5.333H10V5.667h2.667ZM26,24.333H15.333V21.667H18V19H15.333V16.333H18V13.667H15.333V11H26ZM23.333,13.667H20.667v2.667h2.667Zm0,5.333H20.667v2.667h2.667Z" transform="translate(0.667 1)"/>
      </svg>
    </div>
    <div class="text">候補企業</div>
  </div>

  <!-- 就活コラム -->
  <div class="tab profile {{ type == Constant.tlColumn ? 'selected' : '' }}" (click)="onColumn()">
    <!-- <img src="assets/common/menu_assignment_ind.svg" class="icon"> -->
    <div class="icon">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 24px; height: 24px; opacity: 1;">
        <style type="text/css">
          .b{fill:#30a9b7;}
        </style>
        <g>
          <path class="b" d="M506.488,132.424l-11.586-1.547h-21.957v-19.898c0-4.562-2.11-8.871-5.711-11.672l-25.461-19.793
            c-2.653-2.062-5.821-3.098-9.062-3.098l-1.477,0.074H286.656c-11.496,0-22.402,4.277-30.656,11.938
            c-8.324-7.734-19.16-11.938-30.726-11.938H80.394l-1.179-0.074c-3.242,0-6.41,1.035-8.989,3.098l-25.582,19.89
            c-3.574,2.778-5.664,7.047-5.664,11.574v19.898H17.023L5.504,132.42C2.351,132.846,0,135.533,0,138.713v289.031
            c0,3.18,2.351,5.871,5.504,6.293l11.519,1.547h9.211l9.504-7.367l2.875-2.211h186.66c4.71,0,9.21,0.734,13.41,2.211
            c4.864,1.621,9.286,4.199,13.114,7.367h8.254c3.833-3.242,8.258-5.746,13.122-7.367c4.274-1.477,8.77-2.211,13.484-2.211h186.657
            l2.874,2.211l9.504,7.367h9.211c2.058,0,7.504-0.774,11.699-1.418c3.106-0.477,5.398-3.141,5.398-6.282V138.717
            C512,135.537,509.645,132.846,506.488,132.424z M258.402,109.83c0.469-1.098,0.988-2.168,1.578-3.199
            c0.808-1.329,1.695-2.653,2.726-3.906c5.602-7,14.218-11.496,23.949-11.496h145.977l0.078-0.074l25.496,19.824v259.946v1.434
            h-10.539l-15.035-11.645H286.656c-9.73,0-18.347,4.496-23.949,11.496l-0.149,0.149h-13.266
            c-5.672-7.074-14.297-11.645-24.019-11.645H79.215l-15.032,11.645H53.718V110.978l25.497-19.824v0.074h146.058
            c8.473,0,16.137,3.39,21.734,9.066c0.809,0.808,1.546,1.621,2.285,2.504l1.398,1.914c0.446,0.664,0.887,1.402,1.254,2.066
            c1.328,2.211,2.285,4.641,2.949,7.149c0.321,1.214,0.566,2.469,0.742,3.754c0.004,0.058,0.019,0.11,0.023,0.164
            c0.172,1.313,0.266,2.656,0.266,4.039c0-4.137,0.817-8.031,2.278-11.598C258.266,110.134,258.336,109.982,258.402,109.83z"></path>
          <polygon class="b" points="252.535,138.244 252.535,145.611 252.535,336.713 252.535,337.932 252.535,337.932 252.535,338.092
            259.461,338.092 259.461,145.611 259.461,138.244 259.461,137.947 252.535,137.947 	"></polygon>
          <polygon class="b" points="78.848,108.99 78.848,130.877 78.848,138.244 78.848,145.611 78.848,343.42 78.848,344.858
            78.848,344.858 78.848,345.018 82.535,345.018 82.535,145.611 82.535,138.244 82.535,130.877 82.535,108.99 	"></polygon>
          <polygon class="b" points="429.469,344.858 429.469,345.018 433.152,345.018 433.152,145.611 433.152,138.244 433.152,130.877
            433.152,108.99 429.469,108.99 429.469,130.877 429.469,138.244 429.469,145.611 429.469,343.42 429.465,344.858 	"></polygon>
          <path class="b" d="M214.094,145.611c-0.336-0.218-0.797-0.293-1.195-0.293H124.16c-0.398,0-0.859,0.074-1.195,0.293
            c-1.125,0.516-1.922,1.77-1.922,3.172v18.125c0,1.918,1.39,3.465,3.117,3.465h88.738c1.726,0,3.118-1.546,3.118-3.465v-18.125
            C216.016,147.381,215.218,146.127,214.094,145.611z"></path>
          <path class="b" d="M212.898,208.252H124.16c-1.726,0-3.117,1.547-3.117,3.461v18.129c0,1.914,1.39,3.461,3.117,3.461h88.738
            c1.726,0,3.118-1.546,3.118-3.461v-18.129C216.016,209.799,214.625,208.252,212.898,208.252z"></path>
          <path class="b" d="M212.898,271.182H124.16c-1.726,0-3.117,1.547-3.117,3.465v18.125c0,1.918,1.39,3.465,3.117,3.465h88.738
            c1.726,0,3.118-1.546,3.118-3.465v-18.125C216.016,272.729,214.625,271.182,212.898,271.182z"></path>
          <path class="b" d="M303.156,170.373h88.672c1.789,0,3.184-1.546,3.184-3.465v-18.125c0-1.402-0.797-2.656-1.926-3.172
            c-0.398-0.218-0.797-0.293-1.258-0.293h-88.672c-0.465,0-0.863,0.074-1.195,0.293c-1.129,0.516-1.926,1.77-1.926,3.172v18.125
            C300.035,168.826,301.43,170.373,303.156,170.373z"></path>
          <path class="b" d="M303.156,233.302h88.672c1.789,0,3.184-1.546,3.184-3.461v-18.129c0-1.914-1.395-3.461-3.184-3.461h-88.672
            c-1.726,0-3.121,1.547-3.121,3.461v18.129C300.035,231.756,301.43,233.302,303.156,233.302z"></path>
          <path class="b" d="M303.156,296.236h88.672c1.789,0,3.184-1.546,3.184-3.465v-18.125c0-1.918-1.395-3.465-3.184-3.465h-88.672
            c-1.726,0-3.121,1.547-3.121,3.465v18.125C300.035,294.69,301.43,296.236,303.156,296.236z"></path>
        </g>
      </svg>
    </div>
    <div class="text">就活コラム</div>
  </div>

</div>
