import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';

@Component({
  selector: 'app-lp-search-dialog',
  templateUrl: './lp-search-dialog.component.html',
  styleUrls: ['./lp-search-dialog.component.css']
})
export class LpSearchDialogComponent implements OnInit {
  public form: FormGroup;
  public readonly Constant = Constant;
  public place_all = 'place_all';  // formControlName
  private selectedPlace;
  public masterPlace;
  private selectedIndustry;
  public masterIndustry;

  constructor(
    public dialogRef: MatDialogRef<LpSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private common: CommonService
  ) { }

  ngOnInit(): void {
    this.selectedPlace = this.data.selectedPlace;
    this.selectedIndustry = this.data.selectedIndustry;
    this.masterPlace = this.data.masterPlace;
    this.masterIndustry = this.data.masterIndustry;
    this.initForm();

    // ダイアログ外クリック
    this.dialogRef.backdropClick().subscribe(event => {
      event.stopPropagation();
      this.onClose();
    });
  }

  // 絞り込む
  onSelect() {
    this.common.preCheck().then(res => {
      const ret = {};
      // 勤務地
      const selectedPlace = [];
      this.masterPlace.forEach(element => {
        element.middle.forEach(middle => {
          if (this.form.value[element[Constant.apLargeId] + '-' + middle.id]) {
            selectedPlace.push(middle.all);
          }
        });
      });
      ret[Constant.apDesirePlace] = selectedPlace;

      // 業種
      const selectedIndustry = [];
      this.masterIndustry.forEach(element => {
        if (this.form.value[element.id]) {
          selectedIndustry.push(element.id);
        }
      });
      ret[Constant.apCompanyIndustry] = selectedIndustry;

      ret[Constant.lpSearchFlg] = true;
      this.dialogRef.close(ret);
    })
    .catch(err => {});
  }

  // type変更
  onChangeType(type) {
    if (this.data.type === Constant.sdTypePlace) {
      const selectedPlace = [];
      this.masterPlace.forEach(element => {
        element.middle.forEach(middle => {
          if (this.form.value[element[Constant.apLargeId] + '-' + middle.id]) {
            selectedPlace.push(middle.all);
          }
        });
      });
      this.selectedPlace = selectedPlace;
    } else if (this.data.type === Constant.sdTypeJob) {
      const selectedIndustry = [];
      this.masterIndustry.forEach(element => {
        if (this.form.value[element.id]) {
          selectedIndustry.push(element.id);
        }
      });
      this.selectedIndustry = selectedIndustry;
    }
    this.data.type = type;
  }

  // 勤務地：大分類チェックで小分類のチェック
  onLargeId(checked, id) {
    this.masterPlace.forEach(element => {
      if (element[Constant.apLargeId] === id) {
        element.middle.forEach(middle => {
          this.form.get(element[Constant.apLargeId] + '-' + middle.id).setValue(checked);
        });
      }
    });
    if (this.data.type === Constant.sdTypePlace) {
      this.checkDefaultAll();
    }
  }

  // 勤務地：小分類チェックで大分類のチェック
  onMiddelId(parentId) {
    let checkParent = true;
    this.masterPlace.forEach(element => {
      if (element[Constant.apLargeId] === parentId) {
        element.middle.forEach(middle => {
          if (!this.form.get(element[Constant.apLargeId] + '-' + middle.id).value) {
            checkParent = false;
          }
        });
        this.form.get(element[Constant.apLargeId].toString()).setValue(checkParent);
      }
    });
    if (this.data.type === Constant.sdTypePlace) {
      this.checkDefaultAll();
    }
  }

  // 全国チェック
  onPlaceAll(checked) {
    this.masterPlace.forEach(element => {
      this.form.get(element[Constant.apLargeId].toString()).setValue(checked);
      element.middle.forEach(middle => {
        this.form.get(element[Constant.apLargeId] + '-' + middle.id).setValue(checked);
      });
    });
  }

  // 閉じる場合、設定済みの検索条件を戻す
  onClose() {
    this.common.preCheck().then(res => {
      const ret = {};
      ret[Constant.apDesirePlace] = this.selectedPlace;
      ret[Constant.apCompanyIndustry] = this.selectedIndustry;
      ret[Constant.lpSearchFlg] = false;

      this.dialogRef.close(ret);
    })
    .catch(err => {});
  }

  // フォーム作成
  private initForm() {
    const formControl = {};

    // 勤務地
    const all = this.selectedPlace && this.selectedPlace.length === Constant.prfPlaceZenkokuCnt ? true : false;
    formControl[this.place_all] = new FormControl(all);

    this.masterPlace.forEach(element => {
      let largeSelected = true;
      element.middle.forEach(middle => {
        const selected = this.checkDefault(middle.id, this.selectedPlace);
        if (!selected) {
          largeSelected = false;
        }
        formControl[element[Constant.apLargeId] + '-' + middle.id] = new FormControl(selected);
      });
      formControl[element[Constant.apLargeId]] = new FormControl(largeSelected);
    });


    // 業種
    this.masterIndustry.forEach(element => {
      const selected = this.checkDefault(element.id, this.selectedIndustry);
      formControl[element.id] = new FormControl(selected);
    });

    this.form = new FormGroup(formControl);
  }

  private checkDefault(id, selected): boolean {
    if (!selected || selected.length === 0) {
      return false;
    }

    if (selected.indexOf(id) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  // 勤務地選択：全国チェックの有無
  private checkDefaultAll() {
    let allChecked = true;
    this.masterPlace.forEach(element => {
      element.middle.forEach(middle => {
        if (!this.form.get(element[Constant.apLargeId] + '-' + middle.id).value) {
          allChecked = false;
        }
      });
    });
    this.form.get(this.place_all).setValue(allChecked);
  }

}
