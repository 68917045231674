import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonLpService {

  constructor() { }

  // canonicalタグ設定
  createCanonicalURL(dom, url) {
    const link: HTMLLinkElement = dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

  // canonicalタグ削除
  deleateCanonicalURL(dom) {
    const doms = Array.from(dom.head.querySelectorAll('[rel=\'canonical\']'));
    doms.forEach((elm: any) => {
      dom.head.removeChild(elm);
    });
  }
}
