import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from './../constant';
import { CommonService } from './../shared/common.service';
import { CompanyService } from '../shared/company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-message-buttons',
  templateUrl: './profile-message-buttons.component.html',
  styleUrls: ['./profile-message-buttons.component.css']
})
export class ProfileMessageButtonsComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public type;
  public unread = false;
  private subscriptionMessage: Subscription;

  constructor(
    private router: Router,
    private common: CommonService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.type = this.common.bottomMenu;

    // 未読チェックのため、メッセージ一覧取得
    this.subscriptionMessage = this.companyService.sharedMessages$.subscribe(data => {
      this.unread = this.companyService.checkUnreadMessage(data);
    });
  }

  ngOnDestroy() {
    if (this.subscriptionMessage) {
      this.subscriptionMessage.unsubscribe();
    }
  }

  /* テンプレート起動 */
  onMypage() {
    this.common.preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMypage]);
      })
      .catch(err => {});
  }
  onProfile() {
    this.common.preCheck()
      .then(res => {
        this.router.navigate([Constant.rpDesire]);
      })
      .catch(err => {});
  }
  onCompanyInfo() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpCompanyInfo]);
      })
      .catch(err => {});
  }

  onMessage() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMessagelist]);
      })
      .catch(err => {});
  }

  onColumn() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMyColumn]);
      })
      .catch(err => {});
  }
}
