import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { ProfileService } from './../shared/profile.service';
import { CompanyService } from './../shared/company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public mailAddress: string;
  public errorMessage: string;
  public mode: number;
  public formMail: FormGroup;
  public formPass: FormGroup;
  public loading = true;
  private companys;
  private subscriptionProfile: Subscription;
  private subscriptionCompany: Subscription;
  public mailSended = false;
  private passwordChanging = false;

  constructor(
    private common: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private companyService: CompanyService
  ) {
    // モード設定
    const mode = this.activatedRoute.snapshot.data['mode'];
    if (mode) {
      this.mode = mode;
    } else {
      this.mode = Constant.acModeShow;
    }

    // タイトル設定
    this.common.setTitle(this.getTitle());
    this.common.bottomMenu = Constant.tlAccount;
  }

  ngOnInit() {
    // プロフィール取得
    this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
      if (data === Constant.apError) {
        this.loading = false;
      } else {
        this.loading = false;
        this.mailAddress = data[Constant.apMailAddress];
      }
    });
    this.profileService.getProfile(true);

    // 登録企業一覧取得
    this.subscriptionCompany = this.companyService.sharedCompanys$.subscribe(data => {
      this.companys = data;
    });
    this.companyService.getList();

    // フォーム作成
    this.initForm();
  }

  ngOnDestroy() {
    this.subscriptionProfile.unsubscribe();
    this.subscriptionCompany.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // メールアドレス変更
  onMail() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpAccountMail]);
      })
      .catch(err => { });
  }

  // パスワード変更
  onPassword() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpAccountPass]);
      })
      .catch(err => { });
  }

  // 確認メールを送信
  onMailConfirm(newEmail: string) {
    this.errorMessage = '';

    this.common
      .preCheck()
      .then(res => {
        this.mailConfirmExec(newEmail);
      })
      .catch(err => { });
  }

  // パスワード変更
  onPasswordChange() {
    this.errorMessage = '';

    this.common
      .preCheck()
      .then(res => {
        this.passwordChangeExec();
      })
      .catch(err => { });
  }

  // プライバシーポリシー
  onPrivacy() {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPolicy);
    window.open(Constant.footerUrlPrivacy);
  }

  // 利用規約
  onAgreement() {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionAgreement);
    window.open(Constant.footerUrlAgreement);
  }

  // 退会
  onUnsubscribe() {
    this.common
      .preCheck()
      .then(res => {
        this.unsubscribeExec();
      })
      .catch(err => { });
  }

  /* プライベート */
  private initForm() {
    // メールアドレス変更
    this.formMail = new FormGroup({
      newEmail: new FormControl('', [Validators.required, Validators.email])
    });

    // パスワード変更
    this.formPass = new FormGroup(
      {
        oldPass: new FormControl('', [Validators.required, Validators.minLength(Constant.fvPasswordMin)]),
        newPass: new FormControl('', [Validators.required, Validators.minLength(Constant.fvPasswordMin)]),
        newPassConfirm: new FormControl('', [
          Validators.required,
          Validators.minLength(Constant.fvPasswordMin)
        ])
      },
      this.pwdMatchValidator
    );
  }

  private getTitle() {
    if (this.mode === Constant.acModeShow) {
      return Constant.tlAccount;
    } else if (this.mode === Constant.acModeChangeMail) {
      return Constant.tlChangeMail;
    } else {
      return Constant.tlChangePass;
    }
  }

  private mailConfirmExec(newEmail: string) {
    if (newEmail === this.mailAddress) {
      // メールアドレス変更なし
      this.errorMessage = Constant.msgErrorMailUnChange;
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.mailSended = true;
        Auth.updateUserAttributes(user, {
          email: newEmail
        })
          .then(data => {
            if (data === Constant.awsSuccess) {
              this.common.showCompleteDialog(Constant.msgMailChangeComplete).subscribe(res => {
                this.router.navigate([Constant.rpConfirmmail]);
              });

              // メールアドレス変更完了通知（配信停止チェック）
              const body = { email: newEmail };
              this.common.apiPost('/profile/emailchange', body)
              .then(res => {
                this.common.debug().log(res);
              })
              .catch(err => {
                this.common.debug().log(err);
              });

            }
          })
          .catch(error => {
            this.common.debug().log(error);
            if (error.code === Constant.awsErrorAliasExistsException) {
              this.errorMessage = Constant.msgErrorMailAlreadyRegist;
            } else {
              this.errorMessage = Constant.msgErrorMailChange;
            }
            this.mailSended = false;
          });
      })
      .catch(err => this.common.debug().log(err));
  }

  private passwordChangeExec() {
    if (this.oldPass.value === this.newPass.value) {
      // 変更なし
      this.errorMessage = Constant.msgErrorPassUnChange;
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.passwordChanging = true;
        Auth.changePassword(user, this.oldPass.value, this.newPass.value)
          .then(data => {
            if (data === Constant.awsSuccess) {
              this.common.showCompleteDialog(Constant.msgPassChangeComplete).subscribe(res => {
                this.router.navigate([Constant.rpAccount]);
              });
            }
          })
          .catch(error => {
            this.common.debug().log(error);
            if (error.code === Constant.awsErrorNotAuthorizedException) {
              this.errorMessage = Constant.msgErrorPassMissmatch;
            } else if (error.code === Constant.awsErrorInvalidPasswordException) {
              this.errorMessage = Constant.msgErrorPasswordFormat;
            } else if (error.code === Constant.awsErrorLimitExceededException) {
              this.errorMessage = Constant.msgErrorPassChangeLimitTimes;
            } else {
              this.errorMessage = Constant.msgErrorPassChange;
            }
            this.passwordChanging = false;
          });
      })
      .catch(err => {
        this.common.debug().log(err);
        this.errorMessage = err.message;
      });
  }

  private unsubscribeExec() {
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionToUnsubscribe);

    // タレントプール解除確認
    if (this.companys && this.companys.length > 0) {
      const message = Constant.msgErrorUnsubscribe;
      this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: { message: message, type: Constant.mdTypeOK }
      });
    } else {
      this.router.navigate([Constant.rpUnsubscribe]);
    }
  }

  /* フォーム関連 */
  get newEmail() {
    return this.formMail.get('newEmail');
  }
  get oldPass() {
    return this.formPass.get('oldPass');
  }
  get newPass() {
    return this.formPass.get('newPass');
  }
  get newPassConfirm() {
    return this.formPass.get('newPassConfirm');
  }
  private pwdMatchValidator(frm: FormGroup) {
    if (frm.get('newPass').value !== frm.get('newPassConfirm').value) {
      return { mismatch: true };
    }
    return null;
  }
}
