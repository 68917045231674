<div class="title-container">
  <h3 class="title">{{ Constant.tlBasicEdit }}</h3>
</div>

<div class="content">
  <form [formGroup]="form" *ngIf="form">
    <div class="label">
      <span class="required">名前</span>
    </div>
    <div class="input">
      <div>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>姓</mat-label>
          <textarea matInput
            formControlName="last_name"
            type="text"
            required
            #lastName
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'lastNameMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="lastNameMaxLength">
              {{ countInputText(lastName.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>名</mat-label>
          <textarea matInput
            formControlName="first_name"
            type="text"
            required
            #firstName
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'firstNameMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="firstNameMaxLength">
              {{ countInputText(firstName.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>姓 ひらがな入力</mat-label>
          <textarea matInput
            formControlName="last_name_kana"
            type="text"
            required
            #lastNameKana
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'lastNameKanaMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="lastNameKanaMaxLength">
              {{ countInputText(lastNameKana.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>名 ひらがな入力</mat-label>
          <textarea matInput
            formControlName="first_name_kana"
            type="text"
            required
            #firstNameKana
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'firstNameKanaMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="firstNameKanaMaxLength">
              {{ countInputText(firstNameKana.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="label">
      <span class="required">性別</span>
    </div>
    <div class="input bottom">
      <mat-radio-group formControlName="sex_id" class="radio-btn" required>
        <mat-radio-button *ngFor="let item of sexTypeMaster; let f = first"
          [value]="item.id"
          [class]="f ? 'half-left' : 'half-right'">{{ item[Constant.apItemValue] }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="label">
      <span class="required">生年月日</span><span class="prefix">半角数字</span>
    </div>
    <div class="input">
      <div class="period-area">
      <mat-form-field class="input-short"
        hideRequiredMarker="true">
        <mat-label>西暦</mat-label>
        <input matInput
          formControlName="birthday_year"
          type="tel"
          min="{{ Constant.fvMinYear }}"
          max="{{ Constant.fvMaxYear }}"
          (keyup)="onNextFocus(4, $event.target.value, birthdaymonth)"
          (keydown.enter)="onKeyDown($event)"
          maxlength="4"
          required /> </mat-form-field>
      <span class="date">年</span>
      <mat-form-field class="input-short" hideRequiredMarker="true">
        <mat-label></mat-label>
        <input matInput
          formControlName="birthday_month"
          type="tel"
          min="{{ Constant.fvMinMonth }}"
          max="{{ Constant.fvMaxMonth }}"
          #birthdaymonth
          (keyup)="onNextFocus(2, $event.target.value, birthdayday)"
          (keydown.enter)="onKeyDown($event)"
          maxlength="2"
          required /> </mat-form-field>
          <span class="date">月</span>
      <mat-form-field class="input-short" hideRequiredMarker="true">
        <mat-label></mat-label>
        <input matInput
          formControlName="birthday_day"
          type="tel"
          min="{{ Constant.fvMinDay }}"
          max="{{ Constant.fvMaxDay }}"
          #birthdayday
          (keyup)="onNextFocus(2, $event.target.value, address)"
          (keydown.enter)="onKeyDown($event)"
          maxlength="2"
          required /> </mat-form-field>
          <span class="date">日</span>
      </div>
      <mat-error *ngIf="errorMessageDate" class="term">{{ errorMessageDate }}</mat-error>
    </div>

    <div class="label">
      <span class="required">居住地</span>
    </div>
    <div class="input">
      <mat-form-field class="input-middle-s">
        <mat-label>都道府県</mat-label>
        <mat-select formControlName="address1"
          #address>
          <mat-option *ngFor="let item of desireJobPlaceType"
            [value]="item.item_value">
            {{ item.item_value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-middle-l">
        <mat-label>市区町村</mat-label>
        <textarea matInput
          formControlName="address2"
          type="text"
          #address2
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'address2MaxLength', Constant.fvMaxLengthCityName)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="address2MaxLength">
            {{ countInputText(address2.value) }} / {{ Constant.fvMaxLengthCityName }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="label">
      <span class="required">電話番号</span>
    </div>
    <div class="input">
      <mat-form-field class="input-tel">
        <mat-label>半角数字 ハイフンなし</mat-label>
        <input matInput
          formControlName="telno"
          maxlength="11"
          type="tel"
          (keydown.enter)="onKeyDown($event)" />
      </mat-form-field>
    </div>

    <div class="label">
      <span class="required">メールアドレス</span>
    </div>
    <div class="input bottom">
      <span class="gray">{{ profile.mail_address }}</span>
      <button type="button"
        mat-button
        (click)="onChangeMail()"
        class="select-btn">変更する</button>
    </div>

    <div class="label">
      <span>プロフィール画像</span>
      <span class="image-desc"> ※png/jpg　正方形サイズ推奨</span>
    </div>
    <div class="input">
      <img *ngIf="imageUrl"
        [src]="imageUrl"
        class="profile-image" />
      <div *ngIf="imageUrl == ''"
        class="profile-no-image">
        <div class="no-image-text">No Image</div>
      </div>
      <button type="button"
        mat-button
        (click)="imgFileInput.click()"
        class="select-btn">写真を選択</button>
      <input hidden
        type="file"
        #imgFileInput
        accept="image/png,image/jpeg"
        (change)="onChangePhoto($event)" />
      <mat-error *ngIf="errorMessageImage">{{ errorMessageImage }}</mat-error>
    </div>

    <div *ngIf="dataSending" class="sending-spinner-row">
      <div class="spinner-half"></div>
      <div class="spinner-half spinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
    <div class="button-container">
      <button type="button"
        (click)="onCancel()"
        mat-raised-button
        class="half"
        [disabled]="dataSending">
        キャンセル
      </button>
      <button (click)="onRegist()"
        mat-raised-button
        type=button
        class="half"
        color="accent"
        [disabled]="form.invalid || isSexIdValid() || dataSending">
        更新
      </button>
    </div>
  </form>
</div>
