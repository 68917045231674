<app-profile-edit-buttons></app-profile-edit-buttons>
<div mat-icon-button class="data-update-button" (click)="onMovePageTop()">
  <img src="assets/common/data_update.svg" />
</div>
<h3 class="subheader">{{ Constant.tlCareer }}</h3>
<div class="right-button">
  <button mat-flat-button (click)="onAddWork()" class="add-btn">追加</button>
</div>

<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<div class="no-data" *ngIf="works && works.length == 0">
  職歴の登録がありません。
  <br />
  右上の「追加」から職歴を登録しましょう。
</div>

<div class="content">
  <div class="history-line" *ngIf="works && works.length != 0"></div>
  <table class="profile-table">
    <ng-container *ngFor="let work of works">
      <tr>
        <td class="icon" rowspan="8">
          <img class="icon-small icon-circle" src="assets/common/career_circle.png" />
        </td>
        <td>
          <div class="name pre-line">{{ work.company_name }}</div>
          <div class="date" *ngIf="work.exit_date">{{ work.join_date }}〜{{ work.exit_date }}</div>
          <div class="date" *ngIf="!work.exit_date">{{ work.join_date }}〜現在</div>
        </td>
        <td class="td-edit" rowspan="2">
          <button mat-flat-button (click)="onEditWork(work)" class="add-btn">編集</button>
        </td>
      </tr>
      <tr>
        <td>
          <div class="label">雇用形態</div>
          <div class="text">{{ work.employeement_type }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">年収</div>
          <div class="text"><span *ngIf="work.annual_income > -1">{{ work.annual_income }}万円</span></div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">役職</div>
          <div class="text pre-line">{{ work.position }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">部署</div>
          <div class="text pre-line">{{ work.department }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">主な職種</div>
          <div class="job-content text">
            <ng-container *ngFor="let job of work.jobName">
              <div class="large">{{ job.large_name }}</div>
              <ul class="middle">
                <li *ngFor="let middle of job.middle_name">
                  <span>{{ middle }}</span>
                </li>
              </ul>
            </ng-container>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">主な活動</div>
          <div class="text pre-line">{{ work.job_detail }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">活かしたスキル</div>
          <div class="text pre-line bottom">{{ work.skill }}</div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
<div class="spacer"></div>

<app-profile-message-buttons></app-profile-message-buttons>
