import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';

@Component({
  selector: 'app-confirm-mail',
  templateUrl: './confirm-mail.component.html',
  styleUrls: ['./confirm-mail.component.css']
})
export class ConfirmMailComponent implements OnInit {
  public form: FormGroup;
  public errorMessage: string;
  public expired = false;
  public registing = false;
  private apiPath = '/profile/emailnotice';

  constructor(private router: Router, private common: CommonService) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlConfirmMail);

    this.initForm();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* API */
  private postNotice() {
    this.common
      .apiPost(this.apiPath)
      .then(res => {})
      .catch(err => {});
  }

  /* テンプレート起動 */
  onSubmitCode(code) {
    this.errorMessage = '';
    this.expired = false;
    this.registing = true;

    this.common
      .preCheck()
      .then(res => {
        this.submitCodeExec(code);
      })
      .catch(err => {
        this.registing = false;
      });
  }

  // メールアドレスを再度変更する
  onChangeMail() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpAccountMail]);
      })
      .catch(err => {});
  }

  // 認証コード再発行
  onResendMailcode() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpResendmail]);
      })
      .catch(err => {});
  }

  /* プライベート */
  private initForm() {
    // 確認コード入力
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required])
    });
  }

  private submitCodeExec(code) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.verifyUserAttributeSubmit(user, 'email', code)
          .then(data => {
            if (data === Constant.awsSuccess) {
              // 成功
              // 完了API呼び出し
              this.postNotice();
              // 完了トースト表示
              this.common.showToastMessage(Constant.msgConfirmComplete);
              // アカウント画面に戻る
              this.router.navigate([Constant.rpAccount]);
            } else {
              // 失敗
              this.registing = false;
            }
          })
          .catch(error => {
            this.common.debug().log(error);
            this.registing = false;
            if (error.code === Constant.awsErrorCodeMismatchException) {
              this.errorMessage = Constant.msgErrorCodeMissmatchMail;
            } else if (error.code === Constant.awsErrorExpiredCodeException) {
              this.expired = true;
            } else {
              this.errorMessage = Constant.msgErrorConfirm;
            }
          });
      })
      .catch(err => this.common.debug().log(err));
  }

  get code() {
    return this.form.get('code');
  }
}
