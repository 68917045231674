<ng-container *ngIf="mode == Constant.acModeShow">
  <div class="content">
    <div *ngIf="loading" class="spinner">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <table *ngIf="!loading">
      <tr>
        <td>
          <div class="label">メールアドレス</div>
          <div>{{ mailAddress }}</div>
        </td>
        <td class="operation">
          <button (click)="onMail()" class="edit-btn">変更</button>
        </td>
      </tr>
      <tr>
        <td>
          <div class="label">パスワード</div>
          <div>********</div>
        </td>
        <td class="operation">
          <button (click)="onPassword()" class="edit-btn">変更</button>
        </td>
      </tr>
    </table>

    <div class="link">
      <a (click)="onAgreement()">利用規約</a>
    </div>
    <div class="link">
      <a (click)="onPrivacy()">プライバシーポリシー</a>
    </div>
  </div>
  <div class="subscribe-title">
    キャリンの退会は
    <a (click)="onUnsubscribe()">こちら</a>
  </div>
  <div class="subscribe-content">
    退会の前に、すべての企業とのつながりを解消してください。
  </div>
  <app-profile-message-buttons></app-profile-message-buttons>
</ng-container>

<div class="form-container" *ngIf="mode == Constant.acModeChangeMail">
  <div class="text">
    新しいメールアドレスを入力してください。
    <div class="mail-attention">
      プライベートのメールアドレスでご登録ください。<span class="orange-text">学校や企業のメールアドレスでのご登録はオススメしていません。</span>
    </div>
  </div>
  <form [formGroup]="formMail" class="form">
    <mat-form-field class="input-area">
      <mat-label>メールアドレス</mat-label>
      <input matInput type="email" formControlName="newEmail" name="email" required />
      <mat-error *ngIf="newEmail.invalid && newEmail.dirty">メールアドレスは正しい形式で入力してください。</mat-error>
    </mat-form-field>

    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>

    <div class="button-container">
      <button
        (click)="onMailConfirm(newEmail.value)"
        mat-raised-button
        color="accent"
        [disabled]="!formMail.valid || mailSended"
      >
        確認メールを送信
      </button>
    </div>
  </form>
</div>

<div class="form-container" *ngIf="mode == Constant.acModeChangePass">
  <div class="text">パスワード変更は1日に3回まで可能です。</div>
  <form [formGroup]="formPass" class="form">
    <mat-form-field class="input-area">
      <mat-label>現在のパスワード</mat-label>
      <input
        matInput
        type="password"
        formControlName="oldPass"
        name="oldPass"
        required
      />
      <mat-error *ngIf="oldPass.invalid && oldPass.dirty">{{ Constant.msgErrorPasswordFormat }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="input-area">
      <mat-label>新しいパスワード</mat-label>
      <input
        matInput
        type="password"
        formControlName="newPass"
        name="newPass"
        required
      />
      <mat-hint align="end">※英字と数字の組み合わせ、8桁以上</mat-hint>
      <mat-error *ngIf="newPass.invalid && newPass.dirty">{{ Constant.msgErrorPasswordFormat }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="input-area">
      <mat-label>新しいパスワード（確認用）</mat-label>
      <input
        matInput
        type="password"
        formControlName="newPassConfirm"
        name="newPassConfirm"
        required
      />
    </mat-form-field>
    <div class="confirm-error">
      <mat-error *ngIf="newPassConfirm.dirty && formPass.errors">パスワードとパスワード（確認）が異なります。
      </mat-error>
    </div>
    <div class="password-error">
      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    </div>

    <div class="button-container">
      <button
        (click)="onPasswordChange()"
        mat-raised-button
        color="accent"
        [disabled]="!formPass.valid || passwordChanging"
      >
        変更
      </button>
    </div>
  </form>
</div>
