import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Constant } from '../constant';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-lp-header',
  templateUrl: './lp-header.component.html',
  styleUrls: ['./lp-header.component.css']
})
export class LpHeaderComponent {
  @Input() page;
  public readonly Constant = Constant;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private common: CommonService,
  ) { }

  // トップページ
  onTopPage() {
    window.scroll(0, 0);
    this.trigger.closeMenu();
  }

  onMenuOpen() {
    this.trigger.openMenu();
  }

  onMenuClose() {
    this.trigger.closeMenu();
  }

  onDisabledMenu(event) {
    event.stopPropagation();
  }

  // GA
  onClickGAEvent(action) {
    this.common.sendGAEvent(Constant.gaCategoryButtons, action);
  }
}
