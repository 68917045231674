import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Constant } from './../constant';
import { CommonService } from '../shared/common.service';
import { FileService } from './../shared/file.service';
import { environment } from '../../environments/environment';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-profile-file',
  templateUrl: './profile-file.component.html',
  styleUrls: ['./profile-file.component.css']
})
export class ProfileFileComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  public readonly Constant = Constant;
  public loading = true;
  public errFlg = false;
  public uploading = false;
  public deleting = false;
  public deletingId = 0;
  public files = [];
  private apiPath = '/profile/file';

  constructor(
    private common: CommonService,
    private fileService: FileService,
    private dialog: MatDialog,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);
    this.common.profileMenu = Constant.tlFile;
    this.common.bottomMenu = Constant.tlProfile;
    this.getFiles();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  @HostListener('window:online', ['$event'])
  onOnline() {
    // オフライン後再接続された際、ページ最下であればデータを取得する
    if (this.errFlg) {
      this.getFiles();
    }
  }

  // ページトップに移動
  onMovePageTop() {
    window.scroll(0, 0);
  }

  // ファイル確認ビューのボタン
  onFileClick() {
    if (this.common.preCheckWindowOpen()) {
      this.fileInput.nativeElement.click();
    }
  }

  // ファイル変更イベント
  onChangeFile(evt) {
    const file = evt.target.files[0];

    // 形式チェック
    if (file.type === Constant.imageFormatJpg || file.type === Constant.imageFormatPng || file.type === Constant.fileFormatPdf
      || file.type === Constant.fileFormatPpt || file.type === Constant.fileFormatPptx || file.type === Constant.fileFormatXls
      || file.type === Constant.fileFormatXlsx || file.type === Constant.fileFormatDoc || file.type === Constant.fileFormatDocx ) {
        // OK

    } else {
      this.showErrorUpload();
      return;
    }

    // サイズチェック
    if (file.size > Constant.fileMaxSize) {
      this.showErrorUpload();
      return;
    }

    this.uploading = true;
    // S3にアップロード
    this.common.preCheck().then(res => {
      const config = environment.amplify.Storage.profileFile;
      this.fileService.uploadFileToS3(file, file.name, file.type, config).then(path => {
        if (!this.uploading) {
          // タイムアウト後なら何もしない
          return;
        }
        // DB更新
        const addFile = {file_path: path, file_name: file.name};
        this.fileUploadExec(addFile);
      })
      .catch(err => {
        if (!this.uploading) {
          // タイムアウト後なら何もしない
          return;
        }
        this.common.debug().log(err);
        this.showErrorUpload();
      });


      // タイムアウトの設定
      setTimeout(() => {
        if (this.uploading) {
          this.uploading = false;
          this.showErrorUpload();
        }
      }, Constant.apiTimeoutSecond);
    })
    .catch(err => {
      this.uploading = false;
      this.fileInput.nativeElement.value = '';
    });
  }

  // ダウンロード可否
  canDownload(filename) {
    const ext = filename.split('.').pop().toLocaleLowerCase();
    if (ext === Constant.fileExtensionPng || ext === Constant.fileExtensionJpeg
      || ext === Constant.fileExtensionJpg || ext === Constant.fileExtensionPdf) {
      return false;
    } else {
      return true;
    }
  }

  // ファイルダウンロード用のURL取得
  getFileUrl(id) {
    if (this.uploading || !this.common.preCheckWindowOpen()) {
      return false;
    }

    const apiPath = '/profile/file/' + id;
    this.common.apiGet(apiPath).then(result => {
      const url = result.data.url;
      const filename = result.data.name;
      if (!url) {
        this.common.showToastMessage(Constant.msgFileDownloadError);
        return;
      }
      this.downloadFile(url, filename);
    })
    .catch(err => {
      this.common.debug().log(err);
      this.common.showToastMessage(Constant.msgFileDownloadError);
    });

    return false;
  }

  // ファイルダウンロード
  private downloadFile(url, fileName) {
    this.http.get(url, {responseType: 'arraybuffer'}).subscribe(data => {
      const blob = new Blob([data], {type: Constant.fileFormatDownload});
      saveAs(blob, fileName);

    }, err => {
      this.common.debug().log(err);
      this.common.showToastMessage(Constant.msgFileDownloadError);
    });
    return false;
  }

  // ファイル一覧の取得
  private getFiles() {
    this.loading = true;
    this.errFlg = false;
    this.files = [];

    this.common.preCheck().then(check => {
      this.common.apiGet(this.apiPath).then(res => {
        this.files = res.data;
        this.loading = false;
      })
      .catch(err2 => {
        this.loading = false;
        this.errFlg = true;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      })
    })
    .catch(err => {
      this.loading = false;
      this.errFlg = true;
    });
  }

  // ファイルの削除
  public onDelFile(id) {
    if (this.uploading || this.deleting) {
      return;
    }

    this.common.preCheck().then(res => {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: {
          message: Constant.msgConfirmDelAttachedFile,
          type: Constant.mdTypeYesNo,
          common: this.common,
          buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === Constant.mdBtnRight) {
          this.deleteFileExec(id);
        }
      });
    });
  }

  // ファイル名クリックでファイル閲覧ページを開く
  onUploadFileName() {
    if (this.uploading || !this.common.preCheckWindowOpen()) {
      return false;
    }
  }

  // アップロードしたファイルをDB登録
  private fileUploadExec(file) {
    const body = {
      attached_file_data: file
    };

    this.common.apiPost(this.apiPath, body).then(result => {
      this.uploading = false;
      this.fileInput.nativeElement.value = '';
      if (result.status === Constant.OK) {
        this.common.showMessageGeneral(Constant.msgFileUploadComplete);
        this.getFiles();
      } else {
        this.showErrorUpload();
      }
    })
    .catch(err => {
      this.common.debug().log(err);
      this.showErrorUpload();
    });
  }

  // アップロードしたファイルを削除
  private deleteFileExec(id) {
    this.common.preCheck().then(res => {
      this.deleting = true;
      this.deletingId = id;
      const apiPath = this.apiPath + '/' + id;
      this.common.apiDel(apiPath).then(result => {
        this.deleting = false;
        this.common.showToastMessage(Constant.msgDeleteComplete);
        // 最新取得
        this.getFiles();
      })
      .catch(err => {
        this.common.debug().log(err);
        this.deleting = false;
        if (err.data && err.data.deleted === 1) {
          // 削除済みエラー
          this.common.showToastMessage(Constant.msgDeleteFileAlreadyError);
          // 最新取得
          this.getFiles();
        } else {
          // その他エラー
          this.common.showToastMessage(Constant.msgDeleteError);
        }
      });
    })
    .catch(err => { });
  }

  // アップロード失敗ダイアログの表示
  private showErrorUpload() {
    this.common.showCompleteDialog(Constant.msgFileUploadError).subscribe(res => {
      this.uploading = false;
      this.fileInput.nativeElement.value = '';
      this.getFiles();
    });
  }
}
