<div class="content-all" [class.hide]="inputMode" [class.invisible]="messageInvisible" [class.stop]="loading" *ngIf="registPool">
  <div class="message-scroll">
    <div *ngIf="loading" class="spinner loading">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="scroll-message" [hidden]="isHideScrollMessage">↑スクロールしてメッセージを取得する↑</div>
    <div class="start-message" [hidden]="isHideStartMessage"> - start of message -</div>
    <div class="message-container" *ngFor="let msg of messages" #chatColumns>
      <div class="message type{{ msg.type_id }} message-id-{{ msg.id }} ">
        <span class="text" [innerHtml]="msg.message"></span>
        @if (msg.attached_file) {
          <mat-divider *ngIf="msg.message" [class.gray-text]="deleting"></mat-divider>
          @for (file of msg.attached_file; track file.attached_file_id) {
          <div class="flex file-area" *ngIf="file.attached_delete_flag == 0 && file.attached_error_flag == 0">
            <ng-container *ngIf="msg.type_id == Constant.msgTypeIdTalent">
              <div *ngIf="!deleting || deletingId != file.attached_file_id" (click)="onDelMessageFile(file.attached_file_id)" [class.gray-text]="deleting">
                <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
                <g>
                  <path class="st0" d="M442.258,60.422H356l-8.32-35.824C344.312,10.114,331.574,0,316.707,0H195.269
                    c-14.878,0-27.609,10.121-30.965,24.606l-8.324,35.816H69.742c-33.488,0-60.73,27.242-60.73,60.73v31.637
                    c0,19.73,16.054,35.778,35.782,35.778h5.102l33.254,290.328C84.992,497.718,100.715,512,119.711,512h272.578
                    c19.109,0,34.801-14.18,36.5-32.988l33.312-290.446h5.106c19.726,0,35.782-16.047,35.782-35.778v-31.637
                    C502.988,87.664,475.746,60.422,442.258,60.422z M195.269,6.785c-11.703,0-21.718,7.958-24.355,19.352l-2.926,12.586l0,0
                    l2.922-12.586C173.547,14.742,183.566,6.785,195.269,6.785z M421.856,182.222l-33.149,289.074H123.246L90.14,182.222H421.856z
                    M324.937,67.207H187.043l8.226-35.418l121.446,0.004L324.937,67.207z M335.281,15.004c-3.172-3.52-7.352-5.992-12.008-7.254
                    C327.929,9.012,332.109,11.484,335.281,15.004L335.281,15.004z M49.719,121.152c0-11.042,8.98-20.023,20.023-20.023h372.516
                    c11.042,0,20.023,8.981,20.023,20.023v26.711H49.719V121.152z" style="fill: rgb(48, 169, 183);"></path>
                  <path class="st0" d="M166.203,389.73h11.446c6.098,0,11.039-4.946,11.039-11.043V254.246c0-6.098-4.942-11.039-11.039-11.039
                    h-11.446c-6.098,0-11.039,4.942-11.039,11.039v124.441C155.164,384.785,160.105,389.73,166.203,389.73z" style="fill: rgb(48, 169, 183);"></path>
                  <path class="st0" d="M250.269,389.73h11.442c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
                    h-11.442c-6.098,0-11.042,4.942-11.042,11.039v124.441C239.227,384.785,244.172,389.73,250.269,389.73z" style="fill: rgb(48, 169, 183);"></path>
                  <path class="st0" d="M334.332,389.73h11.446c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
                    h-11.446c-6.094,0-11.038,4.942-11.038,11.039v124.441C323.293,384.785,328.238,389.73,334.332,389.73z" style="fill: rgb(48, 169, 183);"></path>
                </g>
                </svg>
              </div>
              <mat-spinner *ngIf="deleting && deletingId == file.attached_file_id" diameter="20"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="msg.type_id != Constant.msgTypeIdTalent">
              <a *ngIf="!canDownload(file.attached_file_name)" routerLink="/{{Constant.rpMsgFile}}/{{file.attached_file_id}}" target="_blank" (click)="onUploadFileName(file.attached_file_id)"><img src="assets/common/file.svg" style="width: 20px; height: 20px;"></a>
              <a *ngIf="canDownload(file.attached_file_name)" (click)="getFileUrl(file.attached_file_id)"><img src="assets/common/file.svg" style="width: 20px; height: 20px;"></a>
            </ng-container>
            <a *ngIf="!canDownload(file.attached_file_name)" class="msg-file-name" routerLink="/{{Constant.rpMsgFile}}/{{file.attached_file_id}}" target="_blank" (click)="onUploadFileName(file.attached_file_id)" [class.gray-text]="deleting && deletingId == file.attached_file_id">{{ file.attached_file_name }}</a>
            <a *ngIf="canDownload(file.attached_file_name)" class="msg-file-name" (click)="getFileUrl(file.attached_file_id)" [class.gray-text]="deleting && deletingId == file.attached_file_id">{{ file.attached_file_name }}</a>
          </div>
          <div class="file-area gray-text" *ngIf="file.attached_delete_flag == 1">このファイルは削除されました。</div>
          <div class="file-area gray-text" *ngIf="file.attached_error_flag == 1">セキュリティ上の理由によりブロックされました。</div>
          }
        }
      </div>
      <div class="date type{{ msg.type_id }} ">{{ showDate(msg.created_at) }}</div>
    </div>
    <div *ngIf="lodingLastMessage" class="spinner loading">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div [style.height.px]="spacerHeight"></div>
  </div>
  <div class="input-container">
    <form [formGroup]="form" class="message-input">
      <mat-form-field class="message-form-field">
        <textarea
          #message1
          formControlName="message"
          matInput
          placeholder="メッセージを入力してください。"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="10"
          class="message-textarea"
          (input)="onInput($event.target)"
          (keyup)="onKeyUp()"
        ></textarea>
        <mat-hint align="end">
          <span class="message-length" [class.red]="textMaxLength"> {{ countInputText(message1.value) }} / {{ maxLenSendMessage }} </span>
        </mat-hint>
      </mat-form-field>
      <div class="sendbutton-container">
        <div class="send-spinner">
          <ng-container *ngIf="messageSending" class="send-spinner">
            <mat-spinner diameter="30"></mat-spinner>
          </ng-container>
        </div>
        <button (click)="onSend()" mat-raised-button class="send-btn" type="button"
          [disabled]="form.invalid || textMaxLength || messageSending || uploading || deleting">
          送信
        </button>
      </div>
    </form>
    <ng-container *ngTemplateOutlet="upload"></ng-container>
  </div>
  <div class="input-btn-container" (click)="onInputMessage()"
    [class.wait]="waitFlag">メッセージ作成・編集</div>
</div>
<!-- モバイル用入力画面 -->
<div class="message-view" [class.show]="inputMode" *ngIf="registPool">
  <div class="white">
    <mat-toolbar class="header">
      <div class="title" [class.long]="title.length > titleLengthCount">{{ title }}</div>
    </mat-toolbar>
    <form [formGroup]="form" class="input-message-container">
      <mat-form-field class="message-form-field">
      <textarea
        #message2
        formControlName="message"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="12"
        placeholder="メッセージを入力してください。"
        class="message-textarea"
        (input)="onInput($event.target)"
      ></textarea>
      </mat-form-field>
    </form>
    <div class="message-length" [class.red]="textMaxLength">{{ countInputText(message2.value) }} / {{ maxLenSendMessage }}</div>

    <ng-container *ngTemplateOutlet="upload"></ng-container>
    <div *ngIf="messageSending" class="sending-spinner-row">
      <div class="spinner-half"></div>
      <div class="spinner-half spinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
    <div class="button-container">
      <button (click)="onCancel()" mat-raised-button class="half">キャンセル</button>
      <button (click)="onSend()" mat-raised-button class="half" color="warn" type="button"
        [disabled]="form.invalid || textMaxLength || messageSending || uploading || deleting">
        送信
      </button>
    </div>
    <div class="description">
      メッセージ履歴を確認する場合は、キャンセルボタンを押してください。作成中のメッセージは残ります。
      ただし、他ページに遷移、ページ更新、一定時間経過などがあった場合、メッセージは消失します。
    </div>
  </div>
</div>
<div *ngIf="registPool" class="back"></div>
<div *ngIf="!registPool && companyName" class="unregist-view">
  メッセージを表示するには、{{ companyName }}とつながる必要があります。
</div>
<!-- ファイルアップロード確認ビュー -->
<div class="file-upload-back" [class.show]="confirmFlg" (click)="onHideConfirmView($event.target)">
  <div class="file-upload-confirm">
    <h3>ファイルを添付します。</h3>
    <ul>
      <li>送信できるファイルの拡張子は、pdf、jpg、png、ppt、pptx、xls、xlsx、doc、docxで、容量は5Mまでです。</li>
      <li>1回のメッセージにつき、1つのファイルを添付できます。</li>
      <li>送信したファイルは削除できます。</li>
    </ul>
    <div class="button-container" *ngIf="confirmFlg">
      <button mat-raised-button (click)="onFileConfrm(false)" class="half">いいえ</button>
      <button mat-raised-button (click)="onFileConfrm(true)" color="accent" class="half">はい</button>
    </div>
  </div>
</div>
<!-- ファイルアップロードのフォーム -->
<ng-template #upload>
  <div class="upload-area" (click)="onUpload()" *ngIf="!uploading && !uploadFileName">
    <div [class.gray-text]="messageSending">
      <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
        <g>
          <path class="st0" d="M480.216,71.601c-42.379-42.382-111.34-42.382-153.719,0L147.911,250.187
            c-27.503,27.508-27.503,72.266,0,99.77c27.508,27.508,72.266,27.508,99.774,0l125.012-125.012l-39.91-39.906L207.775,310.051
            c-5.5,5.5-14.454,5.5-19.954,0c-5.5-5.504-5.5-14.453,0-19.957l178.586-178.586c20.375-20.371,53.523-20.375,73.898,0
            c20.375,20.375,20.375,53.527,0,73.902L208.454,417.262c-34.734,34.734-91.254,34.734-125.988,0s-34.734-91.254,0-125.989
            l239.09-239.09l-39.91-39.91l-239.09,239.09c-56.742,56.742-56.738,149.066,0,205.809c56.742,56.738,149.066,56.742,205.809,0
            L480.216,225.32C522.595,182.937,522.595,113.98,480.216,71.601z" style="fill: rgb(48, 169, 183);"></path>
        </g>
        </svg>
    </div>
    <span [class.gray-text]="messageSending">ファイルを添付</span>
  </div>
  <div class="upload-area uploading" *ngIf="uploading">
    <mat-spinner diameter="20"></mat-spinner><span>ファイルをアップロードしています。</span>
  </div>
  <div class="upload-area" [class.uploading]="deleting" [class.gray-text]="deleting || messageSending" *ngIf="uploadFileName">
    <!-- 画像、PDFは別タブで表示 -->
    <ng-container *ngIf="!canDownload(uploadFileName)">
      <a *ngIf="!deleting" (click)="onUploadFileName(0)" routerLink="/{{Constant.rpTmpFile}}" target="_blank">
        <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
          <g>
            <path class="st0" d="M480.216,71.601c-42.379-42.382-111.34-42.382-153.719,0L147.911,250.187
              c-27.503,27.508-27.503,72.266,0,99.77c27.508,27.508,72.266,27.508,99.774,0l125.012-125.012l-39.91-39.906L207.775,310.051
              c-5.5,5.5-14.454,5.5-19.954,0c-5.5-5.504-5.5-14.453,0-19.957l178.586-178.586c20.375-20.371,53.523-20.375,73.898,0
              c20.375,20.375,20.375,53.527,0,73.902L208.454,417.262c-34.734,34.734-91.254,34.734-125.988,0s-34.734-91.254,0-125.989
              l239.09-239.09l-39.91-39.91l-239.09,239.09c-56.742,56.742-56.738,149.066,0,205.809c56.742,56.738,149.066,56.742,205.809,0
              L480.216,225.32C522.595,182.937,522.595,113.98,480.216,71.601z" style="fill: rgb(48, 169, 183);"></path>
          </g>
        </svg>
      </a>
      <mat-spinner *ngIf="deleting" diameter="20"></mat-spinner>
      <a routerLink="/{{Constant.rpTmpFile}}" target="_blank" class="file-text" (click)="onUploadFileName(0)" [class.gray-text]="deleting || messageSending">{{ onShowFileName(uploadFileName) }}</a>
    </ng-container>
    <!-- その他はダウンロード -->
    <ng-container *ngIf="canDownload(uploadFileName)">
      <a *ngIf="!deleting" (click)="getFileUrl(0)">
        <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
          <g>
            <path class="st0" d="M480.216,71.601c-42.379-42.382-111.34-42.382-153.719,0L147.911,250.187
              c-27.503,27.508-27.503,72.266,0,99.77c27.508,27.508,72.266,27.508,99.774,0l125.012-125.012l-39.91-39.906L207.775,310.051
              c-5.5,5.5-14.454,5.5-19.954,0c-5.5-5.504-5.5-14.453,0-19.957l178.586-178.586c20.375-20.371,53.523-20.375,73.898,0
              c20.375,20.375,20.375,53.527,0,73.902L208.454,417.262c-34.734,34.734-91.254,34.734-125.988,0s-34.734-91.254,0-125.989
              l239.09-239.09l-39.91-39.91l-239.09,239.09c-56.742,56.742-56.738,149.066,0,205.809c56.742,56.738,149.066,56.742,205.809,0
              L480.216,225.32C522.595,182.937,522.595,113.98,480.216,71.601z" style="fill: rgb(48, 169, 183);"></path>
          </g>
        </svg>
      </a>
      <mat-spinner *ngIf="deleting" diameter="20"></mat-spinner>
      <a class="file-text" (click)="getFileUrl(0)" [class.gray-text]="deleting || messageSending">{{ onShowFileName(uploadFileName) }}</a>
    </ng-container>
    <div class="delete" (click)="onDelUploadFile()">
      <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
        <g>
          <path class="st0" d="M442.258,60.422H356l-8.32-35.824C344.312,10.114,331.574,0,316.707,0H195.269
            c-14.878,0-27.609,10.121-30.965,24.606l-8.324,35.816H69.742c-33.488,0-60.73,27.242-60.73,60.73v31.637
            c0,19.73,16.054,35.778,35.782,35.778h5.102l33.254,290.328C84.992,497.718,100.715,512,119.711,512h272.578
            c19.109,0,34.801-14.18,36.5-32.988l33.312-290.446h5.106c19.726,0,35.782-16.047,35.782-35.778v-31.637
            C502.988,87.664,475.746,60.422,442.258,60.422z M195.269,6.785c-11.703,0-21.718,7.958-24.355,19.352l-2.926,12.586l0,0
            l2.922-12.586C173.547,14.742,183.566,6.785,195.269,6.785z M421.856,182.222l-33.149,289.074H123.246L90.14,182.222H421.856z
             M324.937,67.207H187.043l8.226-35.418l121.446,0.004L324.937,67.207z M335.281,15.004c-3.172-3.52-7.352-5.992-12.008-7.254
            C327.929,9.012,332.109,11.484,335.281,15.004L335.281,15.004z M49.719,121.152c0-11.042,8.98-20.023,20.023-20.023h372.516
            c11.042,0,20.023,8.981,20.023,20.023v26.711H49.719V121.152z" style="fill: rgb(48, 169, 183);"></path>
          <path class="st0" d="M166.203,389.73h11.446c6.098,0,11.039-4.946,11.039-11.043V254.246c0-6.098-4.942-11.039-11.039-11.039
            h-11.446c-6.098,0-11.039,4.942-11.039,11.039v124.441C155.164,384.785,160.105,389.73,166.203,389.73z" style="fill: rgb(48, 169, 183);"></path>
          <path class="st0" d="M250.269,389.73h11.442c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
            h-11.442c-6.098,0-11.042,4.942-11.042,11.039v124.441C239.227,384.785,244.172,389.73,250.269,389.73z" style="fill: rgb(48, 169, 183);"></path>
          <path class="st0" d="M334.332,389.73h11.446c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
            h-11.446c-6.094,0-11.038,4.942-11.038,11.039v124.441C323.293,384.785,328.238,389.73,334.332,389.73z" style="fill: rgb(48, 169, 183);"></path>
        </g>
        </svg>
    </div>
  </div>
  <input hidden type="file" #fileInput [accept]="Constant.fileTypeAttached" (change)="onChangeFile($event)" />
</ng-template>
