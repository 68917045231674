<div class="error-text">
  お探しのページは見つかりませんでした。<br />
  URLが変更・削除されたか、一時的に閲覧できない可能性があります。
</div>
<div class="button-container">
  <button mat-raised-button (click)="onLp()" class="button">CaLin紹介ページ</button>
  <div class="login-btn-area">
    <button mat-raised-button (click)="onMypage()" class="button">CaLinマイページ</button>
    <div>ログインしていない場合はログインページに遷移します。</div>
  </div>
</div>
