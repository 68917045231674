<mat-dialog-content>
  <img src="assets/common/clear.svg" class="close" mat-dialog-close *ngIf="closeFlg && !blueFlg">
  <img src="assets/company/close.png" class="close" mat-dialog-close *ngIf="closeFlg && blueFlg">
  <div class="message-container">
    <div class="message">
      {{ message }}
    </div>

  </div>
</mat-dialog-content>
