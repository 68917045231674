export class Constant {
  static readonly OK = 'OK';
  static readonly NG = 'NG';
  static readonly VersionNG = 'VersionNG';
  static readonly MaintenanceNG = 'MaintenanceNG';

  // ローカルストレージのキー
  static readonly lsCompanyCode = 'lsCompanyCode';
  static readonly lsContactCode = 'lsContactCode';
  static readonly lsMailAddress = 'lsMailAddress';
  static readonly lsLoginStatus = 'lsLoginStatus';
  static readonly lsCompanyName = 'lsCompanyName';
  static readonly lsLoginCompFlg = 'lsLoginCompFlg';
  static readonly lsLoginPVTime = 'lsLoginPVTime';
  static readonly lsLoginPVTag = 'lsLoginPVTag';
  static readonly lsRefererCompanyCode = 'lsRefererCompanyCode';
  static readonly lsLpSearchCondition = 'lsLpSearchCondition';
  static readonly lsShowFilePath = 'lsShowFilePath';
  static readonly lsShowFileName= 'lsShowFileName';
  static readonly lsTempTalentId = 'lsTempTalentId';
  static readonly lsTrue = 'true';
  static readonly lsFalse = 'false';

  // API名
  static readonly apiNameTalent = 'talent';

  // ルーティングパス
  static readonly rpLogin = 'login';
  static readonly rpLoginForm = 'loginform';
  static readonly rpSignup = 'signup';
  static readonly rpConfirm = 'confirm';
  static readonly rpResend = 'resend';
  static readonly rpPassForget = 'password';
  static readonly rpHome = 'home';
  static readonly rpCompanyInfo = 'company-info';
  static readonly rpContents = 'contents';
  static readonly rpContentsList = 'contents-list';
  static readonly rpContentsCategory = 'contents-category';
  static readonly rpContentsDetail = 'contents-detail';
  static readonly rpContentsTag = 'contents-tag';
  static readonly rpJobOfferList = 'joboffer-list';
  static readonly rpJobOfferDetail = 'joboffer-detail';
  static readonly rpCareer = 'career';
  static readonly rpCareerEdit = 'career-edit';
  static readonly rpEducation = 'education';
  static readonly rpEducationEdit = 'education-edit';
  static readonly rpDesire = 'desire';
  static readonly rpDesireEdit = 'desire-edit';
  static readonly rpBasic = 'basic';
  static readonly rpBasicEdit = 'basic-edit';
  static readonly rpAttachment = 'attachment';
  static readonly rpProfilenew = 'profilenew';
  static readonly rpMessagelist = 'messagelist';
  static readonly rpMessagedetail = 'messagedetail';
  static readonly rpAccount = 'account';
  static readonly rpAccountMail = 'account/mail';
  static readonly rpAccountPass = 'account/pass';
  static readonly rpConfirmmail = 'confirmmail';
  static readonly rpResendmail = 'resendmail';
  static readonly rpUnsubscribe = 'unsubscribe';
  static readonly rpError = 'error';
  static readonly rpMaintenance = 'maintenance';
  static readonly rpMypage = 'mypage';
  static readonly rpLp = '/';
  static readonly rpLpStartguide = 'startguide';
  static readonly rpLpColumn = 'column';
  static readonly rpLpPage = 'page';
  static readonly rpMyColumn = 'article';
  static readonly rpWalkThrough = 'walkthrough';
  static readonly rpLpJobofferList = 'joblist-all/page';
  static readonly rpTmpFile = 'tmpfile';
  static readonly rpMsgFile = 'msgfile';
  static readonly rpSpq = 'spq';
  static readonly rpCompanyTop = 'companytop';
  static readonly rpCompanyAbout = 'about';
  static readonly rpMailUnsubscribe = 'mail/unsubscribe';

  // タイトル
  static readonly tlLogin = 'ログイン';
  static readonly tlSignup = '新規アカウント登録';
  static readonly tlConsent = '登録';
  static readonly tlConfirmMail = '認証コード（メールアドレス変更用）入力';
  static readonly tlConfirmNew = '認証コード（新規登録用）入力';
  static readonly tlResendMail = '認証コード（メールアドレス変更用）再発行';
  static readonly tlResendNew = '認証コード（新規登録用）再発行';
  static readonly tlPassForget = 'パスワードを忘れた方';
  static readonly tlPassForgetConfirm = '認証コード（パスワード再設定用）入力';
  static readonly tlRegist = '情報登録';
  static readonly tlProfile = 'プロフィール';
  static readonly tlCareer = '職歴';
  static readonly tlCareerEdit = '職歴を編集する';
  static readonly tlCareerAdd = '職歴を追加する';
  static readonly tlEducation = '学歴';
  static readonly tlEducationEdit = '学歴を編集する';
  static readonly tlEducationAdd = '学歴を追加する';
  static readonly tlDesire = '就業/転職 希望情報';
  static readonly tlDesireEdit = '就業/転職 希望情報を編集する';
  static readonly tlBasic = '基本情報';
  static readonly tlBasicEdit = '基本情報を編集する';
  static readonly tlMessagelist = 'メッセージ一覧';
  static readonly tlShowFile = '添付ファイル';
  static readonly tlAccount = 'アカウント';
  static readonly tlChangeMail = 'メールアドレス変更';
  static readonly tlChangePass = 'パスワード変更';
  static readonly tlUnsubscribe = '退会';
  static readonly tlNotFound = 'エラーページ';
  static readonly tlMaintenance = 'メンテナンス';
  static readonly tlCompanyAbout = '会社について';
  static readonly tlContents = 'コンテンツ';
  static readonly tlJobOffer = '求人情報';
  static readonly tlCompanyInfo = '新しいつながり候補企業';
  static readonly tlTopPage = 'マイページ トップ';
  static readonly tlTsunagaru = 'つながる';
  static readonly tlCompanyDetail = '企業情報';
  static readonly tlWalkThrough = 'CaLinの使い方';
  static readonly tlColumn = '就活準備コラム';
  static readonly tlFile = 'ファイル添付';
  static readonly tlMailUnsubscribe = '配信停止';

  // AWS
  static readonly awsSuccess = 'SUCCESS';
  static readonly awsCode = 'code';
  static readonly awsMessage = 'message';
  static readonly awsErrorNotAuthorizedException = 'NotAuthorizedException';
  static readonly awsErrorUserNotConfirmedException = 'UserNotConfirmedException';
  static readonly awsErrorUsernameExistsException = 'UsernameExistsException';
  static readonly awsErrorCodeMismatchException = 'CodeMismatchException';
  static readonly awsErrorUserNotFoundException = 'UserNotFoundException';
  static readonly awsErrorAliasExistsException = 'AliasExistsException';
  static readonly awsErrorInvalidPasswordException = 'InvalidPasswordException';
  static readonly awsErrorExpiredCodeException = 'ExpiredCodeException';
  static readonly awsErrorLimitExceededException = 'LimitExceededException';
  static readonly awsErrorInvalidParameterException = 'InvalidParameterException';

  // エラーメッセージ、ダイアログメッセージ
  static readonly msgError = '失敗しました。';
  static readonly msgErrorNetwork = '通信に失敗しました。通信環境をご確認の上、再度実行するか、ページを更新してください。';
  static readonly msgErrorNetworkShort = '通信環境をご確認の上、再度実行してください。';
  static readonly msgErrorNetworkReload = '通信環境をご確認の上、再度実行するか、ページを更新してください。';
  static readonly msgErrorUnsubscribe = 'すべての企業とのつながりを解消してください。';
  static readonly msgErrorReload = 'セッションが切断されました。\n再ロードしてください。';
  static readonly msgErrorPasswordFormat = 'パスワードは英字と数字を含む8桁以上で設定してください。';
  static readonly msgErrorPasswordReset =
    'このメールアドレスはユーザー認証ができていないため、パスワードの再発行ができません。';
  static readonly msgErrorMailUnChange = 'メールアドレスが変更されていません。';
  static readonly msgErrorPassUnChange = 'パスワードが変更されていません。';
  static readonly msgErrorMailAlreadyRegist = 'このメールアドレスはすでに登録されています。';
  static readonly msgErrorMailAlreadyConfirmed = 'このメールアドレスは既に会員登録済みです。';
  static readonly msgErrorMailChange =
    'メールアドレスの変更に失敗した可能性があります。しばらく経ってから再度お手続きをお願いいたします。';
  static readonly msgErrorPassChange =
    'パスワードの変更に失敗した可能性があります。しばらく経ってから再度お手続きをお願いいたします。';
  static readonly msgErrorPassChangeLimitTimes =
    'パスワード変更回数が制限を超えました。しばらく経ってから再度お手続きをお願いいたします。';
  static readonly msgErrorLimitExceeded = '送信回数の制限を超えました。しばらくしてからお試しください。';
  static readonly msgErrorPassMissmatch = '現在のパスワードが間違っています。';
  static readonly msgErrorCodeMissmatchMail = '認証コード（メールアドレス変更用）が異なります。';
  static readonly msgErrorCodeMissmatchShinki = '認証コード（新規登録用）が異なります。';
  static readonly msgErrorCodeMissmatchPassword = '認証コード（パスワード再設定用）が異なります。';
  static readonly msgErrorUserNotFound = 'ユーザーが見つかりません。';
  static readonly msgErrorMailNotFound = 'メールアドレスが違います。';
  static readonly msgErrorUserAlreadyConfirm = 'このメールアドレスは既に会員登録済みです。';
  static readonly msgErrorConfirm = '認証に失敗しました。';
  static readonly msgErrorInputMailPass = 'メールアドレスとパスワードは両方入力してください。';
  static readonly msgErrorFormInValid = 'エラーを修正してください。';
  static readonly msgErrorInValidMailPass = 'メールアドレスかパスワードが異なります。';
  static readonly msgErrorLoginAttemptExceeded = '連続してログインに失敗した為、一時的にログインが制限されています。';
  static readonly msgErrorCreateAccount = '新規アカウント作成に失敗しました。';
  static readonly msgErrorInvalidTerm = '期間の入力が不正です。';
  static readonly msgErrorInvalidDate = '正しい日付を入力してください。';
  static readonly msgErrorInvalidFile = 'ファイル形式が不正です。';
  static readonly msgErrorInputAllTerm = '開始年と月、終了年と月、すべてを入力してください。';
  static readonly msgErrorInputEndTerm = '期末を登録する場合は年と月を入力してください。';
  static readonly msgConfirmUnsubscribe = '本当に退会しますか？';
  static readonly msgConfirmLogout = 'ログアウトしますか？';
  static readonly msgConfirmCancelPool = 'つながりを解消しますか？';
  static readonly msgConfirmDelProfile = 'を削除しますか？\n削除したデータは元には戻せません。';
  static readonly msgPoolNewTitle = 'とつながりました！';
  static readonly msgPoolNewMessage =
    'おめでとうございます！\n早速プロフィールを登録しましょう！';
  static readonly msgPoolAlreadyTitle = 'と既につながっています！';
  static readonly msgPoolAlreadyMessage = 'プロフィールを更新して採用担当者にアピールしましょう！';
  static readonly msgPoolAddQuestionMessage = '特別な質問が届いています。'.fontcolor('red') + '\n早速、見てみましょう！';
  static readonly msgPoolAddQuestionMessageWithCompany = '%sから特別な質問が届いています。'.fontcolor('red') + '\n早速、見てみましょう！';
  static readonly msgPoolAlreadyMessageForJobOffer = '%sはまだ完了していません。\nOKを押して完了します。';
  static readonly msgPassChangeComplete = 'パスワードを変更しました。';
  static readonly msgMailChangeComplete = 'メールアドレスを変更しました。';
  static readonly msgDelPoolComplete = 'つながりを解消しました。';
  static readonly msgConfirmComplete = 'アカウントを認証しました。';
  static readonly msgUpdateComplete = '更新しました。';
  static readonly msgDelCareerComplete = '職歴を削除しました。';
  static readonly msgDelCareerError = '職歴の削除に失敗しました。';
  static readonly msgDelEducationComplete = '学歴を削除しました。';
  static readonly msgDelEducationError = '学歴の削除に失敗しました。';
  static readonly msgDeleteComplete = '削除しました。';
  static readonly msgDeleteFileAlreadyError = 'このファイルは既に削除済みです。';
  static readonly msgDeleteError = '削除に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgUpdateError = '更新に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgTsunagaruError = '「つながる」に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgMsgSendRepeat =
    '同一のメッセージが連続して送信されました。重複したメッセージの送信はキャンセルされました。';
  static readonly msgSendErrorLong =
    '送信に失敗した可能性があります。念のため' + '作成内容を保存'.fontcolor('red') + 'してから、ページを更新して送信状況をご確認ください。';
  static readonly msgDeleteContentGood = '<img src="assets/company/icon_heart_R.png">いいね をキャンセルします。\nよろしいですか？';
  static readonly msgDeleteContentGoodComp = 'いいねをキャンセルしました。';
  static readonly msgDeleteContentGoodFailed = 'いいねのキャンセルに失敗しました。通信状況をご確認の上、再度実行してください。';
  static readonly msgContentGoodComp = '%sに <img src="assets/company/icon_heart_R.png">いいね しました。';
  static readonly msgContentGoodFailed = '<img src="assets/company/icon_heart_R.png">いいね に失敗しました。';
  static readonly msgContentGoodBeforeLogin = '<img src="assets/company/icon_heart_R.png"><span>いいね ありがとうございます！</span>';
  static readonly msgContentGoodToTsunagaru = 'とつながって、最新情報をキャッチしませんか？';
  static readonly msgWhatIsTalentpool1 =
    '「つながる」は、あなたと%sが知り合い、お互いを理解するための第一歩です。\n年齢・経験・応募意欲などを問わず、誰でも自由に%sつながることができます。';
  static readonly msgWhatIsTalentpool2 =
    'つながると出来ること\n・限定のコンテンツや求人情報の閲覧\n・求人情報への応募\n・担当者とチャット（相談や質問も可）\n\n';
  static readonly msgWhatIsTalentpool3 =
    'また、つながった企業から直接スカウトが届く場合があります。\nスカウトされる可能性を高めるには、プロフィールを充実させましょう！';
  static readonly msgWhatIsTalentpool4 =
    'また、つながることで、%sから直接スカウトが届く場合があります。';
  static readonly msgAlreadyVarified = 'メールアドレスは認証済みです。';
  static readonly msgUseTalentPoolOfCalin =
    'では、みなさまとのつながりを、キャリン株式会社のタレントプール採用サービス「CaLin」で管理しています。\n当社とつながるにはCaLinのアカウントを作成する必要があります。\nCaLinのご利用は完全無料です。';
  static readonly msgAlreadyLogined = '既にログイン済みです。マイページに遷移しました。';
  static readonly msgErrorSendMessage = 'メッセージの送信に失敗しました。';
  static readonly msgSucsessSendMessage = 'メッセージの送信に成功しました。';
  static readonly msgApiResSendRepeat = '最新のメッセージと同じ内容なので登録しません。';
  static readonly msgErrorSendMessageNoPool = 'つながりが解消されているため、メッセージを送信できませんでした。';
  static readonly msgErrorUnregistPool = '企業プールに未登録です。';
  static readonly msgLoginComplete = '<p>ログインしました。</p><p><b>%sと早速つながりましょう！<b></p>';
  static readonly msgFileUploadComplete = 'ファイルのアップロードに成功しました。';
  static readonly msgFileUploadError = '<span style="color: #ff5252;">ファイルのアップロードに失敗しました。</span>\n\nファイル形式、容量、通信状態などを確認してください。';
  static readonly msgFileDownloadError = 'ファイルのダウンロードに失敗しました。ファイル取得元のページを更新して再度実行してください。';
  static readonly msgConfirmDelSendFile = '送信済ファイルを削除しますか?';
  static readonly msgConfirmDelAttachedFile = '添付ファイルを削除しますか?';
  static readonly msgConfirmActionNoPool = 'この求人に「気になる」や「応募する」には、%sとつながる必要があります。';
  static readonly msgCancelAddQuestion = '質問の回答更新をキャンセルします。\nよろしいですか？';
  static readonly msgConfirmAddQuestion = '質問の回答を更新しますか？';
  static readonly msgFailedAddQuestion = '更新に失敗した可能性があります。\n通信状況をご確認の上、再度実行してください。\n何度も失敗する場合は、ページを更新して再度回答し直してください。\n\n'
  + 'なお、ページ更新によって入力中の内容は失われますのでご注意ください。'.fontcolor('red');
  static readonly msgSuccessLinkCopy = 'URLをクリップボードにコピーしました。';
  static readonly msgCopyError = 'コピーに失敗しました。';
  static readonly msgDisabledShare = 'この%sは限定公開のため、シェアなどはお控えください。';

  // ログインモード
  static readonly lgModeConsent = 1;
  static readonly lgModeLogin = 2;
  static readonly lgModeSignup = 3;

  // パスワード忘れモード
  static readonly pfModeSend = 0;
  static readonly pfModeChange = 1;

  // フォームバリデーション
  static readonly fvPasswordMin = 8;
  static readonly fvActiveStartYear = 2018;
  static readonly fvMinYear = 1930;
  static readonly fvMaxYear = 2017;
  static readonly fvMinMonth = 1;
  static readonly fvMaxMonth = 12;
  static readonly fvMinDay = 1;
  static readonly fvMaxDay = 31;
  static readonly fvMaxSendMessage = 1000;
  static readonly fvMinLengthTelNo = 10;
  static readonly fvMaxLengthName = 10;
  static readonly fvMaxLengthCityName = 30;
  static readonly fvMaxLengthFifty = 50;
  static readonly fvMaxLengthTwoHundred = 200;
  static readonly fvMaxQuestionText = 1000;

  // フッター
  static readonly footerUrlAgreement = 'https://www.corp.calin.co.jp/agreement-talent';
  static readonly footerUrlPrivacy = 'https://www.corp.calin.co.jp/privacy';
  static readonly footerUrlContact = 'https://www.corp.calin.co.jp/contact';
  static readonly footerUrlCalin = 'https://www.corp.calin.co.jp';
  static readonly footerUrlCompanyLp = 'https://company.calin.co.jp/';
  static readonly footerUrlTalentLp = 'https://calin.co.jp/';

  // プロフィールマスター
  static readonly pmDesireEmployeementType = 'DESIRE_EMPLOYEEMENT_TYPE';
  static readonly pmDesireJobType = 'DESIRE_JOB_TYPE';
  static readonly pmDesireJobPlaceType = 'DESIRE_JOB_PLACE_TYPE';
  static readonly pmBunriType = 'BUNRI_TYPE';
  static readonly pmSexType = 'SEX_TYPE';
  static readonly pmCareerType = 'CAREER_TYPE';
  static readonly pmGraduateYearType = 'GRADUATE_YEAR_TYPE';
  static readonly pmCompanyIndustry = 'COMPANY_INDUSTRY';
  static readonly pmSchoolType = 'SCHOOL_TYPE';

  // ダイアログの幅
  static readonly dlWidth = '400px';
  static readonly dlWidthTsunagaru = '780px';
  static readonly dlWidthNeedLogin = '442px';
  static readonly dlWidthPdf = '100%';

  // 選択ダイアログ
  static readonly sdTypeJob = 'job';
  static readonly sdTypePlace = 'place';
  static readonly sdTypeYear = 'year';
  static readonly sdTypeJobTitle = '職種';
  static readonly sdTypePlaceTitle = '勤務地';
  static readonly sdTypeIndustryTitle = '業種';
  static readonly sdTypeYearTitle = '大学、大学院、短大、専門学校など、卒業（予定）の年を選択してください。';
  static readonly sdTypeSchool = 'school';
  static readonly sdTypeSchoolTitle = '学校区分';

  // メッセージダイアログ
  static readonly mdTypeMsg = 'msg';
  static readonly mdTypeYesNo = 'yesno';
  static readonly mdTypeOK = 'ok';
  static readonly mdTypeReload = 'reload';
  static readonly mdTypeTsunagaru = 'tsunagaru';
  static readonly mdBtnLeft = 'left';
  static readonly mdBtnRight = 'right';
  static readonly mdBtnTextYes = 'はい';
  static readonly mdBtnTextNo = 'いいえ';
  static readonly mdBtnTextClose = '閉じる';

  // アンケートダイアログ
  static readonly enqTypePool = 1;
  static readonly enqTypeJoboffer = 2;
  static readonly enqTypeLike = 3;

  // APIパラメータ
  static readonly apiTimeoutSecond = 30000;
  static readonly apBase = 'base';
  static readonly apActivePlannedEnd = 'active_planned_end';
  static readonly apActivePlannedStart = 'active_planned_start';
  static readonly apActiveUndecidedFlag = 'active_undecided_flag';
  static readonly apGraduateYearType = 'graduate_year_type';
  static readonly apGraduateYearTypeName = 'graduate_year_type_name';
  static readonly apGraduateYearSkipFlag = 'graduate_year_skip_flag';
  static readonly apSchoolTypeId = 'school_type_id';
  static readonly apSchoolTypeName = 'school_type';
  static readonly apDropout = 'dropout_flag';
  static readonly apAddress1 = 'address1';
  static readonly apAddress2 = 'address2';
  static readonly apBirthday = 'birthday';
  static readonly apBirthdayYear = 'birthday_year';
  static readonly apBirthdayMonth = 'birthday_month';
  static readonly apBirthdayDay = 'birthday_day';
  static readonly apDesireEmployeement = 'desire_employeement';
  static readonly apDesireEmployeementId = 'desire_employeement_id';
  static readonly apDesirejob = 'desirejob';
  static readonly apDesirejobPlace = 'desirejob_place';
  static readonly apDesirejobUndecidedFlag = 'desirejob_undecided_flag';
  static readonly apDesireAnnualIncome = 'desire_annual_income';
  static readonly apFirstName = 'first_name';
  static readonly apFirstNameKana = 'first_name_kana';
  static readonly apImagePath = 'image_path';
  static readonly apLastName = 'last_name';
  static readonly apLastNameKana = 'last_name_kana';
  static readonly apMailAddress = 'mail_address';
  static readonly apSex = 'sex';
  static readonly apSexId = 'sex_id';
  static readonly apTelno = 'telno';
  static readonly apCategory = 'category';
  static readonly apJoinDate = 'join_date';
  static readonly apExitDate = 'exit_date';
  static readonly apAdmissionDate = 'admission_date';
  static readonly apWorkCareer = 'work_career';
  static readonly apWcDesirejob = 'wc_desirejob';
  static readonly apMainId = 'main_id';
  static readonly apItemValue = 'item_value';
  static readonly apLargeItemValue = 'large_item_value';
  static readonly apLargeId = 'large_id';
  static readonly apOnlyDesirePlace = 'only_desire_place';
  static readonly apCompanyIndustry = 'company_industry';
  static readonly apDesirePlace = 'desire_place';
  static readonly apTempTalentId = 'temp_talent_id';
  static readonly apError = 'err';

  // 開始終了年月
  static readonly tfStartYear = 'start_year';
  static readonly tfStartMonth = 'start_month';
  static readonly tfEndYear = 'end_year';
  static readonly tfEndMonth = 'end_month';

  // メッセージ
  static readonly msgUnReaded = 0;
  static readonly msgTypeIdNo = -1;
  static readonly msgTypeIdCompany = 1;
  static readonly msgTypeIdCompanyAll = 3;
  static readonly msgTypeIdTalent = 2;
  static readonly msgClickTypeUrl = 1;
  static readonly msgClickTypeFile = 2;

  // アカウント
  static readonly acModeShow = 1;
  static readonly acModeChangeMail = 2;
  static readonly acModeChangePass = 3;

  // Google Analytics
  static readonly gaEvent = 'event';
  static readonly gaEventName = 'action_buttons';
  static readonly gaEventCategory = 'event_category';
  static readonly gaEventLabel = 'event_label';
  static readonly gaCategoryButtons = 'Buttons';
  static readonly gaActionLoginMain = 'Login（Main）';
  static readonly gaActionLoginCompany = 'Login（Company）';
  static readonly gaActionLoginContact = 'Login（Company・Add URL）';
  static readonly gaActionSigninMain = 'Signup（Main）';
  static readonly gaActionSigninCompany = 'Signup（Company）';
  static readonly gaActionSigninContact = 'Signup（Company・Add URL）';
  static readonly gaActionMailSend = 'Mail Send（New Account）';
  static readonly gaActionConfirmMain = 'Confirm（Main）';
  static readonly gaActionConfirmCompany = 'Confirm（Company）';
  static readonly gaActionConfirmContact = 'Confirm（Company・Add URL）';
  static readonly gaActionTermUpdate = 'Action term Update';
  static readonly gaActionTermUpdateYes = 'Yes（Action term Update）';
  static readonly gaActionLogout = 'Logout';
  static readonly gaActionPolicy = 'Policy';
  static readonly gaActionAgreement = 'Agreement';
  static readonly gaActionCompany = 'Company';
  static readonly gaActionContact = 'Contact';
  static readonly gaActionUpdateProfileDesire = 'Profile（Desire） Edit Save / OK';
  static readonly gaActionUpdateProfileCareerEdit = 'Profile（Career） Edit Save / OK';
  static readonly gaActionUpdateProfileCareerAdd = 'Profile（Career） Add Save / OK';
  static readonly gaActionUpdateProfileEducationEdit = 'Profile（Education） Edit Save / OK';
  static readonly gaActionUpdateProfileEducationAdd = 'Profile（Education） Add Save / OK';
  static readonly gaActionUpdateProfileBasic = 'Profile（Basic） Edit Save / OK';
  static readonly gaActionMessageList = 'Message List';
  static readonly gaActionMessageSend = 'Message Send';
  static readonly gaActionToUnsubscribe = 'To Unsubscribe';
  static readonly gaActionUnsubscribe = 'Unsubscribe';
  static readonly gaActionUnsubscribeYes = 'Unsubscribe / OK';
  static readonly gaActionMypageProfile = 'Mypage Profile';
  static readonly gaActionMypageWhatsTalentpool = 'Mypage Whats Talentpool';
  static readonly gaActionCompanyInfoWhatsTalentpool = 'Company Info Whats Talentpool';
  static readonly gaActionCompanyInfoTalentpoolEntry = 'Company Info Talentpool Entry';
  static readonly gaActionContentsCompanyGood = 'Contents（Company） Good';
  static readonly gaActionJobDetailCompanyName = 'Job Detail Company Name';
  static readonly gaActionJobDetailAction = 'Job Detail %s %s / OK';
  static readonly gaActionJobDetailCheck = 'Check';
  static readonly gaActionJobDetailApply = 'Apply';
  static readonly gaActionCompanyListName = 'Companylist Company Name';
  static readonly gaActionCompanyListContents = 'Companylist Contents';
  static readonly gaActionCompanyListJobOffer = 'Companylist Job';
  static readonly gaActionCompanyListEntryCancel = 'Companylist Entry Cancel';
  static readonly gaActionCompanyListEntryCancelOK = 'Companylist Entry Cancel / OK';
  static readonly gaActionLogoCalin = 'Logo CaLin';
  static readonly gaActionContactTagDefault = 'Default URL Talentpool Entry';
  static readonly gaActionContactTagDefaultWhatpool = 'Default URL Whats Talentpool';
  static readonly gaActionContactTagQuestionWhatpool = 'Add URL Whats Talentpool';
  static readonly gaActionContactTagQuestionYes = 'Add URL Talentpool Entry Question / Yes';
  static readonly gaActionContactTagQuestionNo = 'Add URL Talentpool Entry Question / No';
  static readonly gaActionMenuLogin = 'Menu login';
  static readonly gaActionMenuAccount = 'Menu account';
  static readonly gaActionFtAgreement = 'Ft agreement';
  static readonly gaActionFtPrivacy = 'Ft privacy';
  static readonly gaActionFtCalin = 'Ft calin';
  static readonly gaActionFtContact = 'Ft contact';
  static readonly gaActionFtCompanyLP = 'Ft company LP';
  static readonly gaActionTopWhat = 'Top what';
  static readonly gaActionTopAccount = 'Top account';
  static readonly gaActionTopSearch = 'Top search';
  static readonly gaActionTopLoginSP = 'Top login SP';
  static readonly gaActionTopPickupCompany = 'Top pickup company';
  static readonly gaActionPageSearch = 'Page search';
  static readonly gaActionPageCompany = 'Page%s company%s';
  static readonly gaActionPageAccount = 'Page account';

  // プロフィール
  static readonly prfSexNotSelected = -1;
  static readonly prfFlagOn = 1;
  static readonly prfFlagOnStr = '1';
  static readonly prfPlaceZenkokuCnt = 47;
  static readonly prfPlaceZenkokuTxt = '全国';
  static readonly prfNotRegisteredId = -1;

  // 企業情報
  static readonly companyMainCopyEllipsisLength = 210;
  static readonly companyMainColor = '25A99F';

  // 採用PRコンテンツ
  static readonly ctsListTitleSome = 'のコンテンツ一覧';
  static readonly contentsEllipsisLength = 80;
  static readonly contentsLikeDescription = '<p><img src="assets/company/icon_heart_R.png">いいね をすると、興味・関心を%sに手軽に伝えることができます。</p>';
  static readonly contentsLikeConfirmTitle = '%sに <img src="assets/company/icon_heart_R.png">いいね しますか？'

  // 求人情報
  static readonly joActionKindApply = 252;
  static readonly joActionKindMind = 253;
  static readonly joActionKindOther = -1;
  static readonly joActionKindMindText = '「気になる」';
  static readonly joActionKindApplyText = '「応募」';
  static readonly joActionSubDescription = 'とつながっていない場合、つながります。';
  static readonly joActionComplete = '現在、%sとつながっています。';
  static readonly joActionFailedTitle = '「応募」または「気になる」に失敗しました。';
  static readonly joListTitleSome = 'の求人一覧';
  static readonly joActionBtnTop = 1;
  static readonly joActionBtnBottom = 2;

  // 追加質問
  static readonly aqAnswerTypeTextId = 360;
  static readonly aqAnswerTypeOneSelectId = 361;
  static readonly aqAnswerTypeMultiSelectId = 362;

  // ファイル閲覧
  static readonly fileTypeMessage = 'message';
  static readonly fileTypeProfile = 'profile';

  // LP
  static readonly lpMenuStyleFixed = 'fixed';
  static readonly lpMainCopyMaxLength = 80;
  static readonly lpSearchFlg = 'lpSearchFlg';

  // SEO title description
  static readonly metaDesc =　'会社選びは【CaLin（キャリン）】。あいさつ感覚の「つながる」で、気になる会社の情報とスカウトをゲット！就活前の1年生も・中途の社会人も利用できます。';
  static readonly metaDescCompany =　'とつながって、特別な情報やオファーを得ましょう。応募の意思に関係なく、誰でも自由につながることができます。';
  static readonly metaDescJob = 'への今すぐの「応募」はもちろん、いつかご検討したい方も「つながる」から特別な情報やオファーを得てください。';
  static readonly metaDescContents = 'のビジョン・文化・社風・働き方・社員紹介・インタビュー・ストーリーなどのコンテンツを掲載しています。';
  static readonly metaTitleLP = 'CaLin - はじめまして！わたしの気になる会社';
  static readonly metaCalin = ' - CaLin';
  static readonly metaDescMaxLen = 200;

  // 共通
  static readonly dataPerScroll = 10;
  static readonly messageTmpComfirm1 = '%sに%sしますか？';
  static readonly messageTmpResult1 = '%sに%sしました。';
  static readonly empty = '';
  static readonly space = ' ';
  static readonly dot = '.';
  static readonly coron = ':';
  static readonly slash = '/';
  static readonly comma = ',';
  static readonly textYear = '年';
  static readonly textMonth = '月';
  static readonly pixel = 'px';
  static readonly textKigyo = '企業';
  static readonly textTousha = '当社';
  static readonly textKakkoLeft = '（';
  static readonly textKakkoRight = '）';
  static readonly textKininaruKigyo = '、気になる企業と';
  static readonly textTsunagaru = 'とつながる';
  static readonly textOfMessage = 'のメッセージ';
  static readonly textOfSpq = 'からの質問';
  static readonly newLine = '\n';
  static readonly asterisk = '※';
  static readonly imageFormatJpg = 'image/jpeg';
  static readonly imageFormatPng = 'image/png';
  static readonly fileFormatPdf = 'application/pdf';
  static readonly fileFormatPpt = 'application/vnd.ms-powerpoint';
  static readonly fileFormatPptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  static readonly fileFormatXls = 'application/vnd.ms-excel';
  static readonly fileFormatXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  static readonly fileFormatDoc = 'application/msword';
  static readonly fileFormatDocx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  static readonly fileFormatBinary = 'binary/octet-stream';
  static readonly fileFormatDownload = 'application/octet-stream';
  static readonly fileExtensionPdf = 'pdf';
  static readonly fileExtensionPng = 'png';
  static readonly fileExtensionJpg = 'jpg';
  static readonly fileExtensionJpeg = 'jpeg';
  static readonly fileMaxSize = 5242880;
  static readonly fileTypeAttached = 'image/png,image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  static readonly linkyOption = {stripPrefix: false, urls: { schemeMatches: true, tldMatches: false, ipV4Matches: false }};

  // element
  static readonly elementIdMessage = '#message';
  static readonly elementIdSubMessage = '#submessage';
  static readonly elementIdMessageContainer = '#message-container';
  static readonly elementIdBottomLine = '#bottom-line';
  static readonly elementIdFooterBtn = 'footer-btn';
  static readonly elementGeneralFontSize = 14;
  static readonly elementFontSize = 'font-size';
  static readonly elementTextAlign = 'text-align';
  static readonly elementIdLpMaincopy = 'lp-maincopy';
  static readonly elementIdLpMaincopyAll = 'lp-maincopy-all';
  static readonly elementDisplay = 'display';
  static readonly elementDisplayNone = 'none';
  static readonly elementDisplayInline = 'inline';
  static readonly elementIdPrefix = '#';
  static readonly elementIdCompanyName = 'companyname';
  static readonly elementIdContentsRightMenu = 'right-menu';
  static readonly elementIdContentsRightMenuArea = 'rightmenu-float-area';
  static readonly elementFontSizeVW = 'vw';
  static readonly elementClassScrollBlock = 'cdk-global-scrollblock';
  static readonly elementClassMyScrollBlock = 'scroll-block';
  static readonly elementClassHidden = 'hidden';
  static readonly elementClassAndroid = 'android';
  static readonly elementNewLine = '\n';
  static readonly mobileWidth = 793;

  // SNSシェア
  static readonly snsShareUrlX = 'https://x.com/intent/tweet';
  static readonly snsShareUrlFb = 'https://www.facebook.com/sharer/sharer.php';
  static readonly snsShareUrlLinkedin = 'https://www.linkedin.com/sharing/share-offsite';
  static readonly snsShareUrlNote = 'https://note.mu/intent/post';
  static readonly snsShareUrlLine = 'https://social-plugins.line.me/lineit/share';
  static readonly tooltipMessageCopy = 'URLをコピー';
  static readonly tooltipMessageSnsShare = 'でシェア';
  static readonly snsX = 'X';
  static readonly snsFb = 'Facebook';
  static readonly snsLinkedin = 'LinkedIn';
  static readonly snsLine = 'LINE';
  static readonly shareCopyId = 380;
  static readonly shareFbId = 381;
  static readonly shareXId = 382;
  static readonly shareLinkedinId = 383;
  static readonly shareLineId = 384;
}
