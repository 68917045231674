<div *ngIf="showSpinner" class="spinner" [class.blue]="pdfParam.blueFlg">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div class="area">
<!-- 横長・モバイル用PDF -->
<pdf-viewer *ngIf="!tate" [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="pdfRealPage" [render-text]="false"
[fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true"
(after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()" [class.hidden]="spinner"></pdf-viewer>
<!-- 縦長用PDF -->
<pdf-viewer *ngIf="tate" [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="pdfRealPage" [render-text]="false"
[fit-to-page]="true" [original-size]="true" [autoresize]="true"
(after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"></pdf-viewer>
<div class="last-page" [class.show]="pdfTotalPage > 0 && pdfShowPage > 1 && pdfShowPage > pdfTotalPage">
  <div class="more-contents-btn" (click)="onLink(Constant.rpJobOfferList)">
    求人情報を見る
  </div>
</div>
</div>
<div class="pdf-viewer-navi" [class.blue]="pdfParam.blueFlg" *ngIf="pdfTotalPage>0">
  <div class="leftbtn">
    <button mat-flat-button (click)="prevBtn()" [disabled]="pdfShowPage==1 || pdfBtnDisabled">＜</button>
    <button mat-flat-button (click)="nextBtn()" [disabled]="pdfShowPage==pdfTotalPage + 1 || pdfBtnDisabled">＞</button>
  </div>
  <span>{{ pdfShowPage }} / {{ pdfTotalPage + 1 }}</span>
  <div class="rightbtn">
    <button mat-icon-button (click)="close()"><mat-icon>fullscreen_exit</mat-icon></button>
  </div>
</div>
<div class="pdf-viewer-navi empty" *ngIf="!pdfTotalPage"></div>
