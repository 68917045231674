<div class="cal-outer" [class.job]="data.type == Constant.tlJobOffer">
  <div class="cal-inner" id="cal-inner" #calElm>
    <div class="contents box" [class.noshadow]="data.type == Constant.tlJobOffer"
    *ngFor="let contents of data.list; index as i" [class.opacity]="i>sliderNo+2 || i<sliderNo">
    <div class="rect">
      <img src="assets/company/rank{{i+1}}.png" class="rank-icon" *ngIf="data.type == Constant.rpContentsList">
      <div class="image-container">
        <a *ngIf="contents.image_url" routerLink="/{{detailUrl}}/{{data.companyCode}}/{{contents.code}}">
          <img class="image" [src]="contents.image_url" appImageLoadingError />
        </a>
        <a *ngIf="contents.image_url===''" routerLink="/{{detailUrl}}/{{data.companyCode}}/{{contents.code}}">
          <img class="image noimage" lazyLoad="assets/common/noimage.png" appImageLoadingError />
          <div class="image-back-noimage"></div>
        </a>
      </div>
      <div class="title-container">
        <a class="title" routerLink="/{{detailUrl}}/{{data.companyCode}}/{{contents.code}}">{{ contents.title }}</a>
      </div>
      <div class="like-area" *ngIf="detailUrl == Constant.rpContentsDetail">
        <div class="like" (click)="onLikeButton(contents)" [class.liked]="contents.good_flag == 1" [class.disabled]="isLikePosting">
          <img src="assets/company/icon_heart_R.png" *ngIf="contents.good_flag != 1">
          <img src="assets/company/icon_heart_W.png" *ngIf="contents.good_flag == 1">
          <div>{{ contents.good_cnt }}</div>
        </div>
      </div>
      <div *ngIf="data.type == Constant.tlJobOffer && (contents.ismind != '' || contents.isapply != '')" class="like-area action">
        <div *ngIf="contents.ismind != '' && contents.isapply == ''">
          <span>気になる済 </span><span class="actiondate">{{ contents.ismind }}</span>
        </div>
        <div *ngIf="contents.isapply != ''">
          <span>応募済 </span><span class="actiondate">{{ contents.isapply }}</span>
        </div>
      </div>
    </div>
      <div class="box-bottom" *ngIf="data.type == Constant.tlContents">
        <a routerLink="/{{Constant.rpContentsCategory}}/{{data.companyCode}}/{{contents.category_url}}" *ngIf="contents.category_url">
          <div class="category">{{ contents.category }}</div>
        </a>
      </div>
      <div class="box-bottom" *ngIf="data.type == Constant.rpContentsDetail || data.type == Constant.rpContentsList">
        <div class="tag-area">
          <a *ngIf="contents.tag1Text" class="tag" (click)="onTag(contents.tag1)">{{ contents.tag1Text }}</a>
          <a *ngIf="contents.tag2Text" class="tag" (click)="onTag(contents.tag2)">{{ contents.tag2Text }}</a>
          <a *ngIf="contents.tag3Text" class="tag" (click)="onTag(contents.tag3)">{{ contents.tag3Text }}</a>
          <a *ngIf="contents.tag4Text" class="tag" (click)="onTag(contents.tag4)">{{ contents.tag4Text }}</a>
          <a *ngIf="contents.tag5Text" class="tag" (click)="onTag(contents.tag5)">{{ contents.tag5Text }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="cal-prev" (click)="prevSlide()" *ngIf="sliderNo>0">
    <!-- <img src="/assets/company/slide_left.svg"> -->
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="19" transform="rotate(-180 19 19)" fill="#7F7F7F" fill-opacity="0.6"/>
      <path d="M22.7031 14.41L18.1231 19L22.7031 23.59L21.2931 25L15.2931 19L21.2931 13L22.7031 14.41Z" fill="white"/>
    </svg>
  </div>
  <div class="cal-next" (click)="nextSlide()" *ngIf="sliderNo+3<data.list.length">
    <!-- <img src="/assets/company/slide_right.svg"> -->
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="19" fill="#7F7F7F" fill-opacity="0.6"/>
      <path d="M15.2969 23.59L19.8769 19L15.2969 14.41L16.7069 13L22.7069 19L16.7069 25L15.2969 23.59Z" fill="white"/>
    </svg>
  </div>
</div>
