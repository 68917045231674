import { Constant } from './../constant';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  public form: FormGroup;
  public companyCode: string;
  public errorMessage: string;
  public mailAddress: string;
  public showSpinner = false;
  public expired = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private common: CommonService
  ) {
    // companycode
    this.companyCode = activatedRoute.snapshot.params['code'];
    if (!this.companyCode) {
      // パラメータになければローカルストレージより取得
      this.companyCode = localStorage.getItem(Constant.lsCompanyCode);
    }
  }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlConfirmNew);

    // ログイン済み確認
    if (this.companyCode) {
      this.auth.checkLoginToPage(this.companyCode);
    } else {
      this.auth.checkAlreadyLogin();
    }

    // メールアドレスをストレージより取得
    this.mailAddress = localStorage.getItem(Constant.lsMailAddress);
    this.initForm();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログイン済み確認
    if (this.companyCode) {
      this.auth.checkLoginToPage(this.companyCode);
    } else {
      this.auth.checkAlreadyLogin();
    }
  }

  // フォーム作成
  initForm() {
    this.form = new FormGroup({
      email: new FormControl(this.mailAddress, [
        Validators.required,
        Validators.email
      ]),
      code: new FormControl('', [Validators.required])
    });
  }

  // 登録ボタン押下
  onRegist() {
    this.errorMessage = '';
    this.expired = false;

    this.common
      .preCheck()
      .then(res => {
        this.registExec();
      })
      .catch(err => {});
  }

  // 新規アカウント登録
  onSignUp() {
    this.common
      .preCheck()
      .then(res => {
        this.signUpExec();
      })
      .catch(err => {});
  }

  // 認証コード再発行
  onResend() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpResend]);
      })
      .catch(err => {});
  }

  /* プライベート */
  private registExec() {
    // GAイベント
    if (this.companyCode) {
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionConfirmCompany);
    } else {
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionConfirmMain);
    }

    this.showSpinner = true;
    this.auth.confirmSignUp(this.email.value, this.code.value).subscribe(
      result => {
        this.common.debug().log(result);

        // 完了トースト表示
        this.common.showToastMessage(Constant.msgConfirmComplete);

        // メールアドレスを保存
        localStorage.setItem(Constant.lsMailAddress, this.email.value);

        // ログイン画面に遷移
        if (this.companyCode) {
          this.router.navigate([Constant.rpLoginForm, this.companyCode]);
        } else {
          this.router.navigate([Constant.rpLogin]);
        }
      },
      error => {
        this.common.debug().log(error);
        this.showSpinner = false;
        const code = error[Constant.awsCode];
        if (code === Constant.awsErrorCodeMismatchException) {
          this.errorMessage = Constant.msgErrorCodeMissmatchShinki;
        } else if (code === Constant.awsErrorUserNotFoundException) {
          this.errorMessage = Constant.msgErrorMailNotFound;
        } else if (error.code === Constant.awsErrorExpiredCodeException) {
          this.expired = true;
        } else if (error.code === Constant.awsErrorNotAuthorizedException) {
          this.errorMessage = Constant.msgErrorMailAlreadyConfirmed;
        } else {
          this.errorMessage = Constant.msgErrorConfirm;
        }
      }
    );
  }

  private signUpExec() {
    if (this.companyCode) {
      this.router.navigate([Constant.rpSignup, this.companyCode]);
    } else {
      this.router.navigate([Constant.rpSignup]);
    }
  }

  get email() {
    return this.form.get('email');
  }
  get code() {
    return this.form.get('code');
  }
}
