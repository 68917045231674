<app-profile-edit-buttons></app-profile-edit-buttons>
<div mat-icon-button class="data-update-button" (click)="onMovePageTop()">
  <img src="assets/common/data_update.svg" />
</div>
<h3 class="subheader">{{ Constant.tlDesire }}</h3>
<div class="right-button">
  <button mat-flat-button (click)="onEdit()" class="add-btn">編集</button>
</div>

<div class="content">
  <div *ngIf="loading" class="spinner">
    <mat-spinner diameter="60"></mat-spinner>
  </div>
  <table class="profile-table" *ngIf="profile">
    <tr>
      <td>
        <div class="label">希望 雇用形態</div>
        <div class="text">{{ profile[Constant.apDesireEmployeement] }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">希望 職種</div>
        <div class="text">
          <ng-container *ngIf="profile[Constant.apDesirejobUndecidedFlag]">未定</ng-container>
          <div class="job-content" *ngIf="!profile[Constant.apDesirejobUndecidedFlag]">
            <ng-container *ngFor="let job of jobName">
              <div class="large">{{ job.large_name }}</div>
              <ul class="middle">
                <li *ngFor="let middle of job.middle_name">
                  <span>{{ middle }}</span>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">希望 勤務地</div>
        <div class="text">{{ profile[Constant.apDesirejobPlace] }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">希望 年収</div>
        <div class="text"><span *ngIf="profile[Constant.apDesireAnnualIncome] > -1">{{ profile[Constant.apDesireAnnualIncome] }}万円</span></div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">活動予定時期</div>
        <ol>
          <li>就活（新卒）の対象となる卒業年</li>
          <div class="year-text-area blue" *ngIf="profile[Constant.apGraduateYearSkipFlag]">未登録</div>
          <div class="year-text-area" *ngIf="!profile[Constant.apGraduateYearSkipFlag]" [class.blue]="profile[Constant.apGraduateYearTypeName]">{{profile[Constant.apGraduateYearTypeName]}}</div>
          <li>しごと探しに動き出す予定の期間</li>
          <div class="year-text-area" [class.blue]="profile[Constant.apActiveUndecidedFlag] !== ''">
            <ng-container *ngIf="profile[Constant.apActiveUndecidedFlag] != Constant.prfFlagOn && profile[Constant.apActivePlannedStart] && profile[Constant.apActivePlannedEnd]">
              {{ profile[Constant.apActivePlannedStart] }}〜{{ profile[Constant.apActivePlannedEnd] }}
            </ng-container>
            <ng-container *ngIf="profile[Constant.apActiveUndecidedFlag] == Constant.prfFlagOn">未定</ng-container>
          </div>
        </ol>
      </td>
    </tr>
  </table>
</div>
<div class="spacer"></div>

<app-profile-message-buttons></app-profile-message-buttons>
