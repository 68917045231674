<div class="title-container">
  <h3 class="title">{{ Constant.tlDesireEdit }}</h3>
</div>

<div class="content">
  <form [formGroup]="form" *ngIf="setFormFlag"
    (keydown.enter)="onKeyDown($event)">
    <div class="label">
      <span class="required">希望 雇用形態</span>
    </div>
    <div class="input bottom">
      <mat-checkbox *ngFor="let item of master[Constant.pmDesireEmployeementType]"
        formControlName="desire_employeement_{{ item.id }}"
        class="button-type">{{ item[Constant.apItemValue] }}</mat-checkbox>
    </div>

    <div class="label">
      <span class="required">希望 職種</span>
    </div>
    <div class="input bottom">
      <button type="button" [disabled]="form.value[Constant.apDesirejobUndecidedFlag]==Constant.prfFlagOn"
        (click)="onDesireJob()"
        mat-raised-button
        class="select-btn">選択する</button>
      <div class="desirejob-undecided">
        <mat-checkbox labelPosition="before" [formControlName]="Constant.apDesirejobUndecidedFlag">未定の場合はこちらにチェック</mat-checkbox>
      </div>
      <div class="job-content">
        <ng-container *ngFor="let job of selectedDesireJobName">
          <div class="large" [class.disabled]="form.value[Constant.apDesirejobUndecidedFlag]==Constant.prfFlagOn">{{ job.large_name }}</div>
          <ul class="middle">
            <li *ngFor="let middle of job.middle_name">
              <span>{{ middle }}</span>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>

    <div class="label">
      <span class="required">希望 勤務地</span>
    </div>
    <div class="input bottom">
      <button type="button"
        (click)="onDesirePlace()"
        mat-raised-button
        class="select-btn">選択する</button>
      <div class="place-name" *ngIf="selectedDesirePlaceName.length===Constant.prfPlaceZenkokuCnt">
        全国
      </div>
      <div class="place-name" *ngIf="selectedDesirePlaceName.length<Constant.prfPlaceZenkokuCnt">
        {{ selectedDesirePlaceName }}
      </div>
    </div>

    <div class="label">
      <span>希望 年収</span>
      <span class="prefix">半角数字</span>
      <span class="string-count">({{ desire_annual_income.value.length }}/5)</span>
    </div>
    <div class="input input-income">
      <mat-form-field class="income no-placeholder">
        <input matInput
          formControlName="desire_annual_income"
          #desire_annual_income
          type="tel"
          min="0"
          maxlength="5"
          (keydown.enter)="onKeyDown($event)" />
      </mat-form-field>
      <span class="unit">万円</span>
    </div>

    <div class="label">
      活動予定時期 <span class="red-text">※1,2ともに必須</span>
    </div>
    <ol>
      <li>就活（新卒）について卒業年を選択してください。新卒採用が対象外（中途など）の方も選択してください。</li>
      <button type="button"
        (click)="onGraduateYear()"
        mat-raised-button
        class="select-btn"
        [disabled]="form.value[Constant.apGraduateYearSkipFlag]==Constant.prfFlagOn">選択する</button>
        <div class="desirejob-undecided">
          <mat-checkbox labelPosition="before" [formControlName]="Constant.apGraduateYearSkipFlag">回答をスキップする</mat-checkbox>
        </div>
        <ng-container *ngIf="profile">
          <div class="graduate-year" [class.blue]="profile[Constant.apGraduateYearTypeName]" [class.disabled]="form.value[Constant.apGraduateYearSkipFlag]==Constant.prfFlagOn">
            {{ profile[Constant.apGraduateYearTypeName] }}
          </div>
        </ng-container>
      <li class="top">
        しごと探しに動く予定をご登録ください。※半角数字<br>
        <span class="font-small">期間を登録すると企業からのスカウトが届きやすくなります。</span>
      </li>
      <div class="input-period">
        <div class="input-period-short">
          <mat-form-field class="input-short"
            hideRequiredMarker="true">
            <mat-label>西暦</mat-label>
            <input matInput
              [formControlName]="Constant.tfStartYear"
              min="{{ Constant.fvMinYear }}"
              type="tel"
              (keyup)="onNextFocus(4, $event.target.value, startmonth)"
              maxlength="4" /> </mat-form-field><span class="date">年</span>
          <mat-form-field class="input-short-s">
            <mat-label></mat-label>
            <input matInput
              [formControlName]="Constant.tfStartMonth"
              min="{{ Constant.fvMinMonth }}"
              max="{{ Constant.fvMaxMonth }}"
              type="tel"
              #startmonth
              (keyup)="onNextFocus(2, $event.target.value, endyear)"
              maxlength="2" /> </mat-form-field><span class="date">月 〜</span>
          <mat-form-field class="input-short"
            hideRequiredMarker="true">
            <mat-label>西暦</mat-label>
            <input matInput
              [formControlName]="Constant.tfEndYear"
              min="{{ Constant.fvMinYear }}"
              type="tel"
              #endyear
              (keyup)="onNextFocus(4, $event.target.value, endmonth)"
              maxlength="4" /> </mat-form-field><span class="date">年</span>
          <mat-form-field class="input-short-s">
            <mat-label></mat-label>
            <input matInput
              [formControlName]="Constant.tfEndMonth"
              min="{{ Constant.fvMinMonth }}"
              max="{{ Constant.fvMaxMonth }}"
              type="tel"
              #endmonth
              maxlength="2" /> </mat-form-field><span class="date">月</span>
        </div>
        <div class="active-undecided">
          <mat-checkbox labelPosition="before" [formControlName]="Constant.apActiveUndecidedFlag"
            (change)="onChangeUndecided()">未定の場合はこちらにチェック</mat-checkbox>
        </div>
      </div>
      <mat-error *ngIf="errorMessageTerm" class="term">{{ errorMessageTerm }}</mat-error>
    </ol>

    <div *ngIf="dataSending" class="sending-spinner-row">
      <div class="spinner-half"></div>
      <div class="spinner-half spinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
    <div class="button-container">
      <button type="button"
        (click)="onCancel()"
        mat-raised-button
        class="half"
        [disabled]="dataSending">
        キャンセル
      </button>
      <button (click)="onRegist()"
        mat-raised-button
        class="half"
        color="accent"
        [disabled]="
          form.invalid ||
          selectedDesirePlaceName.length == 0 ||
          isDesireJobValid() ||
          isActivePlanValid() ||
          desireEmploymentTypeValidation() ||
          isGraduateValid() ||
          dataSending
        ">
        更新
      </button>
    </div>
  </form>
</div>
