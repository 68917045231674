import { CommonModule } from '@angular/common';
import { Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TsunagaruComponent } from './../common-component/tsunagaru/tsunagaru.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../shared/common.service';
import { CommonCompanyService } from '../shared/common-company.service';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { SliderComponent } from './../common-component/slider/slider.component';
import { SliderCategoryComponent } from '../common-component/slider-category/slider-category.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contents-tag',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    SliderComponent,
    TsunagaruComponent,
    SliderCategoryComponent
  ],
  templateUrl: './contents-tag.component.html',
  styleUrl: './contents-tag.component.css'
})
export class ContentsTagComponent {
  public readonly Constant = Constant;
  public companyCode = '';
  public companyInfo;
  public isLogin = false;
  public showSpinner = true;  // 初期表示スピナー
  public isDataGetting = false; // もっと見る用スピナー
  public contentList
  public tagTitle
  public tsunagaruData
  public categoryListData;
  public categoryParam;
  public tagListData;
  public isLikePosting = false
  private tagId = 0
  private progressRow = { content_code: null, endFlg: false };
  private release_date = '';
  private showCnt = 12;
  private likeContents
  private subscriptionLike: Subscription;
  private currentScrollPosition = 0;
  private scrollTop = 50
  private scrollBottom = 20

  @ViewChildren('tagContents') tagContents: QueryList<any>;
  @ViewChildren('rightMenuTag') rightMenuTag: QueryList<any>;
  @ViewChildren('rightMenuCategory') rightMenuCategory: QueryList<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private common: CommonService,
    private auth: AuthService,
    private commonCompany: CommonCompanyService,
    private router: Router
  ) {
    this.companyCode = this.activatedRoute.snapshot.params['code'];
    if (this.router.getCurrentNavigation().extras.state?.tag_id) {
      this.tagId = this.router.getCurrentNavigation().extras.state.tag_id
    }
  }

  ngOnInit(): void {
    // タイトル設定
    this.common.setTitle(Constant.tlContents);
    this.commonCompany.setCompanyHeaderPage(Constant.rpContentsList);
    // ログインチェック
    this.common.preCheck().then(res => {
      this.checkLogin();
    })
    .catch(err => {
      this.showSpinner = false
    });

    // 初期化
    this.initialize()
  }

  ngAfterViewInit(): void {
    // 高さ合わせ
    this.tagContents.changes.subscribe(data => {
      this.equalBoxHeight();
      // this.commonCompany.contentsRightMenuFixed(this.currentScrollPosition, this.scrollTop, this.scrollBottom)
      window.scrollBy(0, 1)
    });
    this.rightMenuCategory.changes.subscribe(data => {
      this.commonCompany.contentsEqualRightMenuHeight()
    });
    this.rightMenuTag.changes.subscribe(data => {
      this.commonCompany.contentsEqualRightMenuHeight()
    });

    // いいね処理の終了
    this.subscriptionLike = this.commonCompany.sharedLikeExit$.subscribe(data => {
      this.isLikePosting = false;
      this.commonCompany.progressViewDetach();
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionLike) {
      this.subscriptionLike.unsubscribe();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログインチェック
    this.commonCompany.onFocusCheckLogin(this.isLogin).subscribe(res => {
      this.isLogin = res;
      if (this.tsunagaruData) {
        this.tsunagaruData.isLogin = this.isLogin
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.equalBoxHeight();
    this.commonCompany.contentsEqualRightMenuHeight()
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
     //右メニュー固定
    this.commonCompany.contentsRightMenuFixed(this.currentScrollPosition, this.scrollTop, this.scrollBottom)
    this.currentScrollPosition = window.scrollY;
  }

  /* テンプレート起動 */

  // いいねボタン
  onLikeButton(contents) {
    this.isLikePosting = true;
    this.likeContents = contents
    this.commonCompany.progressViewAttach();

    this.common.preCheck().then(res => {
      if (contents.good_flag === 1) {
        // いいね解除
        this.commonCompany.delGoodWithConfirm(this.likeContents, this.isLogin)
        return;
      }

      if (!this.isLogin) {
        // 未ログインの場合、いいね実行
        this.commonCompany.execLikeBeforeLogin(contents, this.companyInfo.name)
        return;
      }

      // ログイン済みの場合、確認ダイアログ、いいね処理開始
      this.commonCompany.startLikeAction(contents, this.companyInfo.name)

    })
    .catch(err => {
      this.isLikePosting = false;
      this.commonCompany.progressViewDetach();
    });
  }

  // コンテンツをもっと見る
  onNextData() {
    this.common.preCheck().then(res => {
      this.isDataGetting = true;
      this.getConentsData();
    }, err => {});
  }

  // タグ押下
  onTag(id) {
    if (id !== this.tagId) {
      this.router.navigate(
        [Constant.rpContentsTag, this.companyCode],
        { state: {tag_id: id} }
      )
      this.tagId = id
      this.initialize()
      this.getConentsData()
      // PV登録
      this.commonCompany.postEntrypageview(this.companyCode, this.isLogin, Constant.rpContentsTag, this.tagId);

    } else {
      this.common.preCheck()
    }
    window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200);

    // 右メニューの位置調整
    const rightMenu = document.getElementById(Constant.elementIdContentsRightMenu)
    if (rightMenu) {
      rightMenu.setAttribute('style', 'position:absolute; top: 0; bottom:auto;')
    }
  }

  /* private */

  // ログインチェック
  private checkLogin() {
    this.auth.isAuthenticated().subscribe(result => {
      if (result) {
        // ログイン済み
        this.isLogin = true;
      } else {
        // PV記録のため、仮のID設定
        this.commonCompany.createTempTalentId()
      }

      // 企業情報取得
      this.getCompanyInfo();
      // PV登録
      if (this.tagId !== 0) {
        this.commonCompany.postEntrypageview(this.companyCode, this.isLogin, Constant.rpContentsTag, this.tagId);
      }
    })
  }

  // 初期化処理
  private initialize() {
    this.showSpinner = true
    this.isDataGetting = false;
    this.initializeData();
  }
  private initializeData() {
    this.progressRow.content_code = null;
    this.progressRow.endFlg = true;
    this.contentList = null;
    this.tagTitle = ''
    this.release_date = '';
    this.categoryListData = null
    this.tagListData = null
  }

  // 企業情報を取得
  private getCompanyInfo() {
    this.commonCompany.getCompanyInfo(this.companyCode).then(data => {
      if (!data) {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.showSpinner = false
        return;
      }
      if (!this.companyInfo) {
        this.companyInfo = data;
        this.companyInfo.page = Constant.rpContentsCategory
      }

      // メインカラー
      this.commonCompany.setCompanyMainColor(this.companyInfo.main_color)

      // ヘッダ用データ
      this.commonCompany.setCompanyHeaderData(this.companyCode, data, Constant.rpContentsList);

      // つながる用データ
      this.tsunagaruData = {
        companyCode: this.companyCode,
        name: this.companyInfo.name,
        display_name: this.companyInfo.display_name,
        isLogin: this.isLogin,
      }

      // コンテンツデータ取得
      this.getConentsData();

      // SEO設定
      this.setSeoMeta();
    })
    .catch(err => {
      this.showSpinner = false
    });
  }

  // 公開コンテンツ情報を取得する
  private getConentsData() {
    let apiPath = '/contents/' + this.companyCode;
    const body = {
      release_date: this.release_date,
      cnt: this.showCnt + 1,
      is_tag: true,
      tag_id: this.tagId
    };

    if (this.isLogin) {
      // コンテンツを取得
      this.common.apiPost(apiPath, body)
        .then(res => {
          this.afterGetContentData(res);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.showSpinner = false
          this.isDataGetting = false;
          this.common.showToastMessage(Constant.msgErrorNetwork);
        });
    } else {
      apiPath += '/noauth';
      const tempId = localStorage.getItem(Constant.lsTempTalentId)
      if (tempId) {
        body[Constant.apTempTalentId] = tempId
      }
      this.common.apiPostBeforeLogin(apiPath, body)
        .then(res => {
          this.afterGetContentData(res);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.showSpinner = false
          this.isDataGetting = false;
          this.common.showToastMessage(Constant.msgErrorNetwork);
        });
    }
  }

  // コンテンツ取得後の処理
  private afterGetContentData(res) {
    if (!this.contentList) {
      this.contentList = [];
      // コンテンツカテゴリ一覧取得
      this.getCategoryList()
      // タグ一覧取得
      this.getTagList()
    }

    // タイトル
    if (res.data.tag) {
      this.tagTitle = res.data.tag.title
    }

    // タグIDが指定なしだった場合、タグIDを取得してからPV登録
    if (this.tagId === 0 && res.data.tag) {
      this.tagId = res.data.tag.id
      this.commonCompany.postEntrypageview(this.companyCode, this.isLogin, Constant.rpContentsTag, this.tagId);
    }

    // newの次のデータ有無判断
    if (res.data.contents) {
      if (res.data.contents.length > 0) {
        // 次のデータ有無判断
        if (res.data.contents.length < this.showCnt + 1) {
          // 指定件数以下
          this.progressRow.endFlg = true;
        } else {
          // 1件多く取得できた場合は次のデータあり。最後のデータを削除
          res.data.contents.pop();
          this.progressRow.endFlg = false;
        }

        // 取得したデータをマージする
        this.margeNewContentsData(res.data.contents);
        // コンテンツの画像を取得する
        this.getContentsImage(res.data.contents)
      }
    }

    this.showSpinner = false
    this.isDataGetting = false;
  }

  // コンテンツ画像取得
  private getContentsImage(currentData) {
    for (let i = 0; i < currentData.length; i++) {
      // コンテンツの画像を取得する
      const contentsCode = currentData[i].code;
      const apiPath = '/content/' + contentsCode + '/url';
      this.common.apiGetBeforeLogin(apiPath)
        .then(res => {
          this.afterGetContentsImage(currentData, res, i);
        })
        .catch(err => {
          this.common.debug().log(err);
        });
    }
  }

  // コンテンツ画像取得後の処理
  private afterGetContentsImage(currentData, res, i) {
    const data = res.data;
    if (data.url === '' || data.url === null) {
      currentData[i].image_url = '';
    } else {
      currentData[i].image_url = data.url;
    }
  }

  // コンテンツデータをマージ
  private margeNewContentsData(currentData) {
    if (this.contentList.length > 0) {
      this.contentList.pop()

      // 右メニューの位置調整
      const rightMenu = document.getElementById(Constant.elementIdContentsRightMenu)
      const rightMenuArea = document.getElementById(Constant.elementIdContentsRightMenuArea)
      if (rightMenu && rightMenuArea) {
        const top = rightMenu.getBoundingClientRect().top - rightMenuArea.getBoundingClientRect().top
        rightMenu.setAttribute('style', 'position:absolute; top:' + top + 'px; bottom:auto;')
      }
    }
    this.contentList.push(...currentData);
    this.contentList.push(this.progressRow);
    if (currentData.length === this.showCnt) {
      this.release_date = currentData[this.showCnt - 1].release_date;
    }
  }

  // カテゴリー一覧取得
  private getCategoryList() {
    const apiPath = '/contents/categorylist/' + this.companyInfo.id;
    this.common
      .apiGetBeforeLogin(apiPath)
      .then(res => {
        this.categoryListData = res.data;
        this.categoryParam = {
          list: this.categoryListData,
          companyCode: this.companyCode
        }
      })
      .catch(err => {
        this.showSpinner = false
        this.common.debug().log(err);
      });
  }

  // タグ一覧取得
  private getTagList() {
    const apiPath = '/contents/master/' + this.companyInfo.id;
    this.common
      .apiGetBeforeLogin(apiPath)
      .then(res => {
        // カテゴリ別連想配列に変換
        this.tagListData = this.commonCompany.convTagListAry(res.data)
      })
      .catch(err => {
        this.showSpinner = false
        this.common.debug().log(err);
      });
  }

  // SEOのメタタグ設定
  private setSeoMeta() {
    let title = this.companyInfo.name + Constant.ctsListTitleSome;
    const description = this.companyInfo.name + Constant.metaDescContents;
    this.commonCompany.setSeoMeta(title, description);
  }

  // コンテンツの高さ合わせ
  private equalBoxHeight() {
    const elements = document.getElementsByClassName('contentrect') as HTMLCollectionOf<HTMLElement>

    // リセット
    for (let i = 0 ; i < elements.length ; i ++ ) {
      elements[i].style.height = 'auto';
    }

    // 高さ調整なし（600px以下）
    if (window.innerWidth <= 600) {
      return;
    }

    const startNo = 0

    for (let i = startNo ; i < elements.length ; i ++ ) {
      let height;
      let showColNum = 3
      if (window.innerWidth >= 1074 || window.innerWidth <= Constant.mobileWidth) {
        showColNum = 2
      }

      if (i % showColNum === startNo) {
        height = elements[i].clientHeight;

        for (let n = i + 1; n <= i + showColNum - 1; n++) {
          if (n >= elements.length) {
            break;
          }
          if (elements[n].clientHeight && height < elements[n].clientHeight) {
              height = elements[n].clientHeight;
          }
        }

        for (let n = i; n <= i + showColNum - 1; n++) {
          if (n >= elements.length) {
            break;
          }
          elements[n].style.height = height + 'px';
        }
      }
    }
  }

}
