<div *ngIf="showSpinner" class="spinner blue">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div *ngIf="companyInfo" class="top-all">
  <img *ngIf="prImageUrl" class="primage" src="{{ prImageUrl }}" appImageLoadingError />
  <div id="message">
    <div class="sidemenu" #sidemenu [class.fix]="fixSideMenu">
      <div class="flex" [class.active]="activeSideMenu==menuTop"><a (click)="onMenuClick(menuTop)">TOP</a></div>
      <div class="flex" [class.active]="activeSideMenu==menuMessage"><a (click)="onMenuClick(menuMessage)">MESSAGE</a></div>
      <div class="flex" [class.active]="activeSideMenu==menuContents" *ngIf="contentsListData"><a (click)="onMenuClick(menuContents)">CONTENTS</a></div>
      <div class="flex" [class.active]="activeSideMenu==menuJoboffer" *ngIf="jobofferListData"><a (click)="onMenuClick(menuJoboffer)">RECRUIT</a></div>
      <div class="flex" [class.active]="activeSideMenu==menuProfile"><a (click)="onMenuClick(menuProfile)">PROFILE</a></div>
    </div>
    <div class="header-title">MESSAGE</div>
    <div class="rect main">
      <div class="message-title text-center">{{ companyInfo.message_headline }}</div>
      <div class="message-desc">{{ companyInfo.message_desc }}</div>
      <div class="text-center">
        <a (click)="onLink('about')" class="link-arrow">会社について</a>
      </div>
    </div>
    <ng-container *ngIf="document?.document_url">
      <div class="header-sub">ー PICK	UP 資料 ー</div>
      <div class="rect pdf">
        <div class="overlay-area">
        <div *ngIf="pdfTotalPage==0 && documentShowSpinner" class="spinner blue">
          <mat-spinner diameter="60"></mat-spinner>
        </div>
        <pdf-viewer [src]="document.document_url" class="pdf-viewer" [show-all]="false" [page]="pdfRealPage" [render-text]="false"
        [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true"
        (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"
        (page-rendered)="pdfRendered()"></pdf-viewer>
        <div class="last-page" [class.show]="pdfTotalPage > 0 && pdfShowPage > 1 && pdfShowPage > pdfTotalPage">
          <div class="more-contents-btn" (click)="onLink(Constant.rpJobOfferList)">
            求人情報を見る
          </div>
        </div>
        <div class="pdf-viewer-navi" *ngIf="pdfTotalPage>0">
          <div class="leftbtn">
            <button mat-flat-button (click)="prevBtn()" [disabled]="pdfShowPage==1 || pdfBtnDisabled">＜</button>
            <button mat-flat-button (click)="nextBtn()" [disabled]="pdfShowPage==pdfTotalPage+1 || pdfBtnDisabled">＞</button>
          </div>
          <span>{{ pdfShowPage }} / {{ pdfTotalPage+1 }}</span>
          <div class="rightbtn">
            <button mat-icon-button (click)="onPdfDialog()" class="pc"><mat-icon>fullscreen</mat-icon></button>
          </div>
        </div>
        </div>
        <h3 class="pdf-title-area">{{ document.title }}</h3>
        <div class="action-area">
          <div class="like-area">
            <div class="like" (click)="onLikeButton(document)" [class.liked]="document.good_flag == 1">
              <img src="assets/company/icon_heart_R.png" *ngIf="document.good_flag != 1">
              <img src="assets/company/icon_heart_W.png" *ngIf="document.good_flag == 1">
              <div class="cnt">{{ document.good_cnt }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="companyInfo.doc_cnt > 1">
        <a (click)="onMaterial()" class="link-arrow pdf">資料を見る</a>
      </div>
    </ng-container>
  </div>
  <div id="contents" *ngIf="contentsListData">
    <div class="header-title">CONTENTS</div>
    <app-slider [data]="contentsListData"></app-slider>
    <div class="text-center">
      <a (click)="onLink('contents-list')" class="link-arrow">記事を見る</a>
    </div>
  </div>

  <app-tsunagaru [data]="tsunagaruData" *ngIf="tsunagaruData"></app-tsunagaru>

  <div id="joboffer" *ngIf="jobofferListData">
    <div class="header-title">RECRUIT</div>
    <app-slider [data]="jobofferListData"></app-slider>
    <div class="text-center">
      <a (click)="onLink('joboffer-list')" class="link-arrow">求人情報を見る</a>
    </div>
  </div>

  <div id="profile">
    <app-company-footer [companyInfo]="companyInfo"></app-company-footer>
  </div>

</div>
