import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from './../dialog/message-dialog/message-dialog.component';
import { Observable ,  of ,  Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CommonService {
  // タイトル連携
  private sharedTitle = new Subject<string>();
  public sharedTitle$ = this.sharedTitle.asObservable();

  // メニュー
  public profileMenu = '';
  public bottomMenu = '';

  // refreshToken
  public refershToken = '';

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient
  ) {}

  // ログ出力
  public debug() {
    if (!environment.production) {
      return console;
    } else {
      return new MockConsole();
    }
  }

  // APIヘッダ作成（ログイン前）
  public createApiHeaderBeforeLogin() {
    return {
      headers: {
        'x-api-key': environment.API_KEY,
        'Content-Type': 'application/json',
        version: environment.version
      },
      timeout: Constant.apiTimeoutSecond
    };
  }

  // APIヘッダ作成（ログイン後）
  public createApiHeader(session) {
    return {
      headers: {
        'x-api-key': environment.API_KEY,
        Authorization: session.idToken.jwtToken,
        'Content-Type': 'application/json',
        accesstoken: session.accessToken.jwtToken,
        version: environment.version
      },
      timeout: Constant.apiTimeoutSecond
    };
  }

  // 年月から日付作成
  public dateFromYearMonth(year: string, month: string) {
    if (!year || !month) {
      return '';
    }

    return year + '-' + ('00' + month).slice(-2) + '-01';
  }

  // 年月日から日付作成
  public dateFromYearMonthDay(year: string, month: string, day: string) {
    if (!year || !month || !day) {
      return '';
    }
    // 日付有効性確認
    const numY = parseInt(year, 10);
    const numM = parseInt(month, 10);
    const numD = parseInt(day, 10);
    const dt = new Date(numY, numM - 1, numD);
    if (dt.getFullYear() !== numY || dt.getMonth() !== numM - 1 || dt.getDate() !== numD) {
      return '';
    }

    return year + '-' + ('00' + month).slice(-2) + '-' + ('00' + day).slice(-2);
  }

  // 年月日時分秒から日付作成
  // yyyy-mm-dd hh:mi:ss
  public dateFromYearMonthDayTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const date = year + '-' + ('00' + month).slice(-2) + '-' + ('00' + day).slice(-2);
    const time =
      ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
    const dateTime = date + ' ' + time;
    return dateTime;
  }

  // 職種マスタの大分類、中分類を利用しやすい形式にする
  public convertJobMaster(jobMaster) {
    const newJobMaster = {};
    const retJobMaster = [];
    jobMaster.forEach(element => {
      if (newJobMaster[element[Constant.apLargeId]]) {
        newJobMaster[element[Constant.apLargeId]]['middle'].push({
          id: element.id,
          item_value: element[Constant.apItemValue],
          all: element
        });
      } else {
        newJobMaster[element[Constant.apLargeId]] = {
          large_id: element[Constant.apLargeId],
          large_item_value: element[Constant.apLargeItemValue],
          middle: [
            {
              id: element.id,
              item_value: element[Constant.apItemValue],
              all: element
            }
          ]
        };
      }
    });
    for (const key in newJobMaster) {
      if (newJobMaster.hasOwnProperty(key)) {
        const element = newJobMaster[key];
        retJobMaster.push(element);
      }
    }
    return retJobMaster;
  }

  // 職種の表示文字列作成
  setShowJobNameAry(jobArray) {
    const jobNameAry = {};
    if (jobArray && jobArray.length > 0) {
      jobArray.forEach(element => {
        if (jobNameAry[element[Constant.apLargeItemValue]]) {
          jobNameAry[element[Constant.apLargeItemValue]].push(element[Constant.apItemValue]);
        } else {
          jobNameAry[element[Constant.apLargeItemValue]] = [element[Constant.apItemValue]];
        }
      });
    }
    const retNameAry = [];
    for (const key in jobNameAry) {
      if (jobNameAry.hasOwnProperty(key)) {
        const element = jobNameAry[key];
        retNameAry.push({ large_name: key, middle_name: element });
      }
    }

    return retNameAry;
  }

  // 企業情報のテキスト変換
  convertCompanyInfoText(companyInfo) {
    // 設立年月
    // if (companyInfo.establishment) {
    //   const establishment = this.devideYMDAsSeparator(companyInfo.establishment, Constant.slash);
    //   companyInfo.establishment = establishment.year;
    // }
    // 業種
    companyInfo.industryAry = companyInfo.industry.split(',')
    return companyInfo;
  }

  // 期間入力のdisable制御
  disabledTerm(checked, form) {
    if (checked) {
      form.get(Constant.tfStartYear).disable();
      form.get(Constant.tfStartMonth).disable();
      form.get(Constant.tfEndYear).disable();
      form.get(Constant.tfEndMonth).disable();
    } else {
      form.get(Constant.tfStartYear).enable();
      form.get(Constant.tfStartMonth).enable();
      form.get(Constant.tfEndYear).enable();
      form.get(Constant.tfEndMonth).enable();
    }
  }

  // 指定長で次にフォーカス移動
  nextFocus(len, value, nextElm) {
    if (value.length >= len) {
      nextElm.focus();
    }
  }

  // 企業の公式ホームページを開く
  public openHomepage(url) {
    if (!this.preCheckWindowOpen()) {
      return;
    }
    window.open(url);
  }

  // はい/いいえボタン付きの確認ダイアログ表示
  public showDelConfirm(message: string, blueFlg: boolean = false): Observable<boolean> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        message: message,
        type: Constant.mdTypeYesNo,
        common: this,
        buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes },
        blueFlg: blueFlg
      }
    });

    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result === Constant.mdBtnRight) {
          return true;
        } else {
          return false;
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  // プロフィール系更新時のメッセージ表示
  showMessage(result: boolean, title: string) {
    let message;
    if (result) {
      message = title + 'を更新しました。';
    } else {
      message = title + 'の更新に失敗しました。';
    }
    this.snackBar.open(message, '', {
      duration: 3000
    });
  }

  // トースト表示(汎用)
  showMessageGeneral(description: string) {
    this.snackBar.open(description, '', {
      duration: 3000
    });
  }

  // ヘッダータイトル設定
  setTitle(title: string) {
    this.sharedTitle.next(title);
  }

  // アナリティクスのイベント送信
  sendGAEvent(categoryName: string, actionName: string) {
    gtag(Constant.gaEvent, Constant.gaEventName, {
      [Constant.gaEventCategory] : categoryName,
      [Constant.gaEventLabel] : actionName
    });
  }

  // オンラインなどの事前チェック
  public preCheck(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        // 通信状態の更新にはタイムラグがあったため、実際にアクセスしてチェックする
        const date = Date.now();
        this.http.get('/check.txt?' +  date.toString()).subscribe(res => {
          resolve();
        }, err => {
          this.snackBar.open(Constant.msgErrorNetwork, '', {
            duration: 3000
          });
          reject();
        });
      } else {
        this.snackBar.open(Constant.msgErrorNetwork, '', {
          duration: 3000
        });
        reject();
      }
    });
  }

  // オンラインなどの事前チェック(window.openの時はAPI接続しない)
  public preCheckWindowOpen(): Boolean {
    if (navigator.onLine) {
      return true;
    } else {
      this.snackBar.open(Constant.msgErrorNetwork, '', {
        duration: 3000
      });
      return false;
    }
  }

  // refreshTokenチェック
  public checkRefreshToken(): void {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (
          user.signInUserSession.refreshToken.token &&
          this.refershToken &&
          user.signInUserSession.refreshToken.token !== this.refershToken
        ) {
          this.showReloadDialog();
        }
      })
      .catch(error => {
        this.showReloadDialog();
      });
  }

  // 再ロードダイアログ表示
  public showReloadDialog(): void {
    const dialogId = 'reload';
    const reload = this.dialog.getDialogById(dialogId);
    if (reload) {
      return;
    }
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      disableClose: true,
      id: dialogId,
      data: { message: Constant.msgErrorReload, type: Constant.mdTypeReload }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        location.reload();
      }
    });
  }

  // 完了ダイアログ表示
  showCompleteDialog(msg: string): Observable<boolean> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: { message: msg, type: Constant.mdTypeOK }
    });
    return dialogRef.afterClosed().pipe(
      map(result => {
        return true;
      })
    );
  }

  // 完了ダイアログ表示（閉じるクリック無効）
  showCompleteDialogDisableClose(msg: string): Observable<boolean> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      disableClose: true,
      data: { message: msg, type: Constant.mdTypeOK }
    });
    return dialogRef.afterClosed().pipe(
      map(result => {
        return true;
      })
    );
  }

  // トースト表示
  showToastMessage(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000
    });
  }
  // トースト表示(長文用10秒表示)
  showToastMessageLong(message: string) {
    this.snackBar.open(message, '', {
      duration: 10000
    });
  }

  // 候補企業、コンテンツ、求人情報ページのスクロール監視
  onWindowScroll(htmlElement): Boolean {
    if (window.pageYOffset + window.innerHeight >= htmlElement.offsetTop) {
      return true;
    } else {
      return false;
    }
  }

  // API GET
  apiGet(apiPath): Promise<any> {
    return Auth.currentSession()
      .then(session => {
        const date = Date.now();
        const path = apiPath + '?' + date.toString();
        const options = this.createApiHeader(session);

        return API.get(Constant.apiNameTalent, path, options).then(response => {
          this.debug().log(response);
          return this.returnApiResponse(response);
        });
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // API POST
  apiPost(apiPath, body?): Promise<any> {
    return Auth.currentSession()
      .then(session => {
        const options = this.createApiHeader(session);
        if (body) {
          options['body'] = body;
        }

        return API.post(Constant.apiNameTalent, apiPath, options).then(response => {
          this.debug().log(response);
          return this.returnApiResponse(response);
        });
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // API PUT
  apiPut(apiPath, body?): Promise<any> {
    return Auth.currentSession()
      .then(session => {
        const options = this.createApiHeader(session);
        if (body) {
          options['body'] = body;
        }

        return API.put(Constant.apiNameTalent, apiPath, options).then(response => {
          this.debug().log(response);
          return this.returnApiResponse(response);
        });
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  apiDel(apiPath): Promise<any> {
    return Auth.currentSession()
      .then(session => {
        const options = this.createApiHeader(session);

        return API.del(Constant.apiNameTalent, apiPath, options).then(response => {
          this.debug().log(response);
          return this.returnApiResponse(response);
        });
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  apiDelBeforeLogin(apiPath, body?): Promise<any> {
    const options = this.createApiHeaderBeforeLogin();
    if (body) {
      options['body'] = body;
    }

    return API.del(Constant.apiNameTalent, apiPath, options)
      .then(response => {
        this.debug().log(response);
        return this.returnApiResponse(response);
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // API POST ログイン前
  apiPostBeforeLogin(apiPath, body?): Promise<any> {
    const options = this.createApiHeaderBeforeLogin();
    if (body) {
      options['body'] = body;
    }

    return API.post(Constant.apiNameTalent, apiPath, options)
      .then(response => {
        this.debug().log(response);
        return this.returnApiResponse(response);
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // API GET ログイン前
  apiGetBeforeLogin(apiPath): Promise<any> {
    const date = Date.now();
    const path = apiPath + '?' + date.toString();
    const options = this.createApiHeaderBeforeLogin();

    return API.get(Constant.apiNameTalent, path, options)
      .then(response => {
        this.debug().log(response);
        return this.returnApiResponse(response);
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // API PUT ログイン前
  apiPutBeforeLogin(apiPath, body?): Promise<any> {
    const options = this.createApiHeaderBeforeLogin();
    if (body) {
      options['body'] = body;
    }

    return API.put(Constant.apiNameTalent, apiPath, options)
      .then(response => {
        this.debug().log(response);
        return this.returnApiResponse(response);
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // API GET ログイン前 コラム取得用
  apiGetBeforeLoginForAdmin(apiPath): Promise<any> {
    const date = Date.now();
    const path = apiPath + '?' + date.toString();
    const options = {
      headers: {
        'x-api-key': environment.API_KEY_ADMIN,
        'Content-Type': 'application/json',
        version: environment.version
      },
      timeout: Constant.apiTimeoutSecond
    };

    return API.get('admin', path, options)
      .then(response => {
        this.debug().log(response);
        return this.returnApiResponse(response);
      })
      .catch(err => {
        this.debug().log(err);
        return Promise.reject(err);
      });
  }

  // APIのレスポンス処理
  private returnApiResponse(response) {
    return new Promise((resolve, reject) => {
      if (response.status === Constant.OK) {
        return resolve(response);
      } else if (response.status === Constant.VersionNG) {
        // バージョンエラー
        this.showReloadDialog();
        return reject(response);
      } else if (response.status === Constant.MaintenanceNG) {
        // メンテナンス中
        this.router.navigate([Constant.rpMaintenance]);
        return reject(response);
      } else {
        // そのほかのエラー
        return reject(response);
      }
    });
  }

  /**
   * 年月日を指定された区切り文字で分割する
   * @param {String} date      - 年月日の文字列(月日は0埋め)
   * @param {String} separator - 区切り文字
   * @return {Object}          - { year: 年, month: 月, date:日 }
   */
  public devideYMDAsSeparator(date, separator) {
    let result = { year: '', month: '', date: '' };
    const dividedData = date.split(separator);
    if (dividedData.length === 3) {
      // 年月日の場合
      result = {
        year: dividedData[0] === '0000' ? '' : dividedData[0],
        month: dividedData[1] === '00' ? '' : dividedData[1],
        date: dividedData[2] === '00' ? '' : dividedData[2]
      };
    }
    return result;
  }

  /**
   * 年月を指定された区切り文字で分割する
   * @param {String} date      - 年月の文字列(月日は0埋め)
   * @param {String} separator - 区切り文字
   * @return {Object}          - { year: 年, month: 月 }
   */
  public devideYMAsSeparator(date, separator) {
    let result = { year: '', month: '' };
    const dividedData = date.split(separator);
    if (dividedData.length === 2) {
      // 年月の場合
      result = {
        year: dividedData[0] === '0000' ? '' : dividedData[0],
        month: dividedData[1] === '00' ? '' : dividedData[1]
      };
    }
    return result;
  }

  /**
   * 月日を指定された区切り文字で分割する
   * @param {String} date      - 月日の文字列(月日は0埋め)
   * @param {String} separator - 区切り文字
   * @return {Object}          - { month: 月, date:日 }
   */
  public devideMDAsSeparator(date, separator) {
    let result = { month: '', date: '' };
    const dividedData = date.split(separator);
    if (dividedData.length === 2) {
      // 月日の場合
      result = {
        month: dividedData[0] === '00' ? '' : dividedData[0],
        date: dividedData[1] === '00' ? '' : dividedData[1]
      };
    }
    return result;
  }

  /**
   * 指定された２つの日付を比較する
   * @param {String} target      - 比較したい日付(yyyy/mm/dd hh/mi/ss.ffffff)
   * @param {String} comparison  - 比較対象(yyyy/mm/dd hh/mi/ss.ffffff)
   * @return {Boolean}           - 比較対象より新しければtrue、古ければfalse
   */
  // 現在は使用していない関数
  // FIXME: targetとcomparisonの値が空白、nullの場合を考慮できていないので、使用する際は修正する必要がある
  public isTargetDateNewer(target, comparison) {
    const strTargetDate = target.split(Constant.space);
    const strComparisonDate = comparison.split(Constant.space);
    // まずは年月日を比較
    const spTargetDate = this.devideYMDAsSeparator(strTargetDate[0], Constant.slash);
    const spComparisonDate = this.devideYMDAsSeparator(strComparisonDate[0], Constant.slash);

    const targetDate = Number(spTargetDate.year + spTargetDate.month + spTargetDate.date);
    const comparisonDate = Number(spComparisonDate.year + spComparisonDate.month + spComparisonDate.date);

    if (targetDate !== comparisonDate) {
      // 年月日が異なった場合には処理終了
      return targetDate > comparisonDate;
    }

    if (!strTargetDate[1] || !strComparisonDate[1]) {
      // 年月日までしか指定されていなかった場合はfalseを返却する
      return false;
    }

    // 年月日が同じ場合には秒まで比較する
    const strTargetSec = strTargetDate[1].split(Constant.dot);
    const strComparisonSec = strComparisonDate[1].split(Constant.dot);
    const spTargetSec = this.devideYMDAsSeparator(strTargetSec[0], Constant.coron);
    const spComparisonSec = this.devideYMDAsSeparator(strComparisonSec[0], Constant.coron);

    const targetSec = spTargetSec.year + spTargetSec.month + spTargetSec.date + strTargetSec[1];
    const comparisonSec =
      spComparisonSec.year + spComparisonSec.month + spComparisonSec.date + strComparisonSec[1];

    return targetSec > comparisonSec;
  }

  /**
   * Masterのidとitem_valueを変換する
   * @param {Array} targetIds    - 変換したいmasterのid配列
   * @param {Object} masterData  - 変換元になるマスターデータ
   * @param {String} key         - masterのtype
   * @return {Array}
   */
  public convertMasterIdToItemValues(targetIds, masterData, key) {
    const itemValues = [];
    targetIds.forEach(id => {
      const selectedData = masterData[key].filter(function (data) {
        return data.id === Number(id);
      });
      if (selectedData.length > 0) {
        itemValues.push(selectedData[0].item_value);
      }
    });
    return itemValues;
  }

  /**
   * Masterのidとitem_valueを変換する
   * @param {String} targetId    - 変換したいmasterのid
   * @param {Object} masterData  - 変換元になるマスターデータ
   * @param {String} key         - masterのtype
   * @return {String}
   */
  public convertMasterIdToItemValue(targetId, masterData, key) {
    let itemValue =  '';
    const selectedData = masterData[key].filter(function (data) {
      return data.id === Number(targetId);
    });
    if (selectedData.length > 0) {
      itemValue = selectedData[0].item_value;
    }
    return itemValue;
  }

  /**
   * テキストを指定された区切り文字を使用して連結する
   * @param {Array} textArray      - 連結したい文字列
   * @param {String} delimiter - 区切りたい文字
   * @return {String}
   */
  public concatenateTexts(textArray, delimiter) {
    let concatenatedText = '';
    textArray.forEach(text => {
      if (concatenatedText !== '') {
        concatenatedText = concatenatedText + delimiter;
      }
      concatenatedText = concatenatedText + text;
    });
    return concatenatedText;
  }

  /**
   * テキストを指定された文字数で省略する
   * @param {String} text - 省略前のテキスト
   * @param {Number} maxLen - 最大文字数
   * @return {String}
   */
  public ellipsisText(text, maxLen) {
    const len = text.length;

    if (len <= maxLen) {
      return text;
    } else {
      return text.substr(0, maxLen) + '…';
    }
  }

  // 本文省略(HTML)
  ellipsisHtml(text: string, maxLen: number) {
    // 空目次対応
    text = text.replaceAll('<div class="toc-title bottom0">目次<span>（見出しを作ると項目が表示されます）</span>', '目次 ')

    // HTMLタグを消去
    text = text.replace(/(<([^>]+)>)/gi, '');

    const len = text.length;
    if (len <= maxLen) {
      return text;
    } else {
      return text.substring(0, maxLen) + '…';
    }
  }

  /**
   * 企業ロゴを取得する
   * @param {String} companyCode - 企業コード
   */
  public getCompanyLogo(companyCode) {
    const logoUrlApiPath = '/company/' + companyCode + '/logourl';
    return this.apiGetBeforeLogin(logoUrlApiPath)
    .then(res => {
      const data = res.data;
      if (data.url === '' || data.url === null) {
        return '';
      } else {
        return data.url;
      }
    })
    .catch(err => {
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.debug().log(err);
      }
      return Constant.apError;
    });
  }

  /**
   * 企業のPR画像を取得する
   * @param {String} companyCode - 企業コード
   */
  public getCompanyPrImage(companyCode) {
    const prImageUrlApiPath = '/company/info/' + companyCode + '/imageurl';
    return this.apiGetBeforeLogin(prImageUrlApiPath)
    .then(res => {
      const data = res.data;
      if (data.url === '' || data.url === null) {
        return '';
      } else {
        return data.url;
      }
    })
    .catch(err => {
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.debug().log(err);
      }
      return Constant.apError;
    });
  }

  /**
   * 求人情報の画像URLを取得する
   * @param {String} jobofferCode - 求人詳細コード
   */
  public getJobOfferImage(jobofferCode) {
    const urlApiPath = '/joboffer/detail/' + jobofferCode + '/url';
    return this.apiGetBeforeLogin(urlApiPath)
    .then(res => {
      const data = res.data;
      if (data.url === '' || data.url === null) {
        return '';
      } else {
        return data.url;
      }
    })
    .catch(err => {
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.debug().log(err);
        return '';
      }
    });
  }

  /**
   * 求人基本情報の画像URLを取得する
   * @param {String} companyCode - 企業コード
   */
  public getJobOfferBasicImage(companyCode) {
    const urlApiPath = '/joboffer/' + companyCode + '/url';
    return this.apiGetBeforeLogin(urlApiPath)
    .then(res => {
      const data = res.data;
      if (data.url === '' || data.url === null) {
        return '';
      } else {
        return data.url;
      }
    })
    .catch(err => {
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.debug().log(err);
        return '';
      }
    });
  }

  /**
   * コンテンツの画像URLを取得する
   * @param {String} code - コンテンツ詳細コード
   */
   public getContentsImage(code) {
    const urlApiPath = '/content/' + code + '/url';
    return this.apiGetBeforeLogin(urlApiPath)
    .then(res => {
      const data = res.data;
      if (data.url === '' || data.url === null) {
        return '';
      } else {
        return data.url;
      }
    })
    .catch(err => {
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.debug().log(err);
        return '';
      }
    });
  }

  // windowsで表示されるL SEPというスペース置換
  replaceSpace(text) {
    if (typeof text === 'string') {
      return text.replace(/\u2028|\u2029|\u0085/g, ' ');
    } else {
      return text;
    }
  }
}

/**
 * consoleのモック
 * @class MockConsole
 */
class MockConsole {
  log(...v: any[]): void { }
  info(...v: any[]): void { }
  warn(...v: any[]): void { }
  error(...v: any[]): void { }
  debug(...v: any[]): void { }
  group(...v: any[]): void { }
  groupEnd(...v: any[]): void { }
  table(...v: any[]): void { }
  dir(...v: any[]): void { }
  time(...v: any[]): void { }
  timeEnd(...v: any[]): void { }
  assert(...v: any[]): void { }
  trace(...v: any[]): void { }
}
