<div class="title-container"
  *ngIf="selectedEducationId; else addTitle">
  <h3 class="title">{{ Constant.tlEducationEdit }}</h3>
  <button mat-flat-button (click)="onDel()"
    class="del-btn" [disabled]="dataSending">
    削除
  </button>
</div>
<ng-template #addTitle>
  <h3 class="title">{{ Constant.tlEducationAdd }}</h3>
</ng-template>

<div class="content">
  <form [formGroup]="form" *ngIf="!loading">
    <div class="label">
      <span class="required">学校名</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="school_name"
          #schoolName
          required
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'schoolNameMaxLength', Constant.fvMaxLengthFifty)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="schoolNameMaxLength">
            {{ countInputText(schoolName.value) }} / {{ Constant.fvMaxLengthFifty }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="label">
      <span class="required">学校区分</span>
    </div>
    <div class="input">
      <button type="button"
        (click)="onSelectSchoolType()"
        mat-raised-button
        class="select-btn">選択する</button>
      <ng-container *ngIf="selectedSchoolType">
        <div class="schoolType-name">
          {{ selectedSchoolType }}
        </div>
      </ng-container>
    </div>
    <div class="label-period">
      <span class="required">期間</span><span class="prefix">半角数字</span>
    </div>
    <div class="input">
      <div class="period">
        <mat-form-field class="input-short"
          hideRequiredMarker="true">
          <mat-label>西暦</mat-label>
          <input matInput
            [formControlName]="Constant.tfStartYear"
            min="{{ Constant.fvMinYear }}"
            type="tel"
            required
            (keyup)="onNextFocus(4, $event.target.value, startmonth)"
            (keydown.enter)="onKeyDown($event)"
            maxlength="4" /> </mat-form-field>
            <span class="date">年</span>
        <mat-form-field class="input-short-s" hideRequiredMarker="true">
          <mat-label></mat-label>
          <input matInput
            [formControlName]="Constant.tfStartMonth"
            min="{{ Constant.fvMinMonth }}"
            max="{{ Constant.fvMaxMonth }}"
            type="tel"
            required
            #startmonth
            (keyup)="onNextFocus(2, $event.target.value, endyear)"
            (keydown.enter)="onKeyDown($event)"
            maxlength="2" /> </mat-form-field>
            <span class="date">月 〜</span>
        <mat-form-field class="input-short"
          hideRequiredMarker="true">
          <mat-label>西暦</mat-label>
          <input matInput
            [formControlName]="Constant.tfEndYear"
            min="{{ Constant.fvMinYear }}"
            type="tel"
            required
            #endyear
            (keyup)="onNextFocus(4, $event.target.value, endmonth)"
            (keydown.enter)="onKeyDown($event)"
            maxlength="4" /> </mat-form-field>
            <span class="date">年</span>
        <mat-form-field class="input-short-s" hideRequiredMarker="true">
          <mat-label></mat-label>
          <input matInput
            [formControlName]="Constant.tfEndMonth"
            min="{{ Constant.fvMinMonth }}"
            max="{{ Constant.fvMaxMonth }}"
            type="tel"
            required
            #endmonth
            maxlength="2"
            (keydown.enter)="onKeyDown($event)" /> </mat-form-field>
            <span class="date">月</span>
      </div>
      <mat-error *ngIf="errorMessageTerm" class="term">{{ errorMessageTerm }}</mat-error>
      <div class="desc">※在学中の場合は卒業予定を記入してください</div>
      <div class="desc-check">
        <mat-checkbox class="font-check" labelPosition="before" formControlName="dropout_flag">※中退・転学の場合はこちらをチェック</mat-checkbox>
      </div>
    </div>

    <div class="label-bunri">
      <span class="required">文理</span>
    </div>
    <div class="input bottom">
      <mat-radio-group formControlName="bunri_type_id" class="radio-btn"
        required>
        <mat-radio-button *ngFor="let item of master[Constant.pmBunriType]; let f = first"
          [value]="item.id"
          [class]="f ? 'half-left' : 'half-right'">{{ item[Constant.apItemValue] }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="label">
      <span class="required">学部・専攻など</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="department"
          #department
          required
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'departmentMaxLength', Constant.fvMaxLengthFifty)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="departmentMaxLength">
            {{ countInputText(department.value) }} / {{ Constant.fvMaxLengthFifty }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="label">
      <span>主な活動</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="main_activities"
          #mainActivities
          cdkTextareaAutosize
          cdkAutosizeMaxRows="5"
          (input)="onInput($event.target, 'mainActivitiesMaxLength', Constant.fvMaxLengthTwoHundred)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="mainActivitiesMaxLength">
            {{ countInputText(mainActivities.value) }} / {{ Constant.fvMaxLengthTwoHundred }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="dataSending" class="sending-spinner-row">
      <div class="spinner-half"></div>
      <div class="spinner-half spinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
    <div class="button-container">
      <button type="button"
        (click)="onCancel()"
        mat-raised-button
        class="half"
        [disabled]="dataSending">
        キャンセル
      </button>
      <button (click)="onRegist()"
        mat-raised-button
        type=button
        class="half"
        color="accent"
        [disabled]="form.invalid ||
        !selectedSchoolType ||
        dataSending
        ">
        更新
      </button>
    </div>
  </form>
</div>
