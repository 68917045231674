import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { CompanyService } from '../shared/company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public loading = true;
  public messages; // メッセージ一覧
  private subscriptionMessage: Subscription;

  constructor(
    private common: CommonService,
    private router: Router,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlMessagelist);
    this.common.bottomMenu = Constant.tlMessagelist;

    // メッセージ一覧取得
    this.subscriptionMessage = this.companyService.sharedMessages$.subscribe(data => {
      if (data !== Constant.apError) {
        this.loading = false;
        this.messages = data;
      } else {
        this.loading = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }
    });
    this.companyService.getMessageList();
  }

  ngOnDestroy() {
    this.subscriptionMessage.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  onSelect(companyCode) {
    this.common
      .preCheck()
      .then(res => {
        // GAイベント
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMessageList);
        this.router.navigate([Constant.rpMessagedetail, companyCode]);
      })
      .catch(err => {});
  }

  // 長いメッセージを省略
  showCutMsg(msg: string, maxLen: number): string {
    const len = msg.length;

    if (len <= maxLen) {
      return msg;
    } else {
      return msg.substr(0, maxLen) + '…';
    }
  }
  // 日付形式を変更
  showDate(date: string): string {
    const array = date.split(' ');
    return array[0];
  }
}
