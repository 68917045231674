import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { APP_OVERLAY_DATA } from '../overlay/pdf-overlay.service';
import { AppOverlayRef } from './../overlay/overlay-ref';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  public pdfParam;
  public resizeFlg = false;

  constructor(
    @Inject(APP_OVERLAY_DATA) readonly data: any,
    @Inject(AppOverlayRef) readonly appOverlayRef: AppOverlayRef,
  ) {}

  ngOnInit(): void {
    this.pdfParam = {
      url: this.data.url,
      blueFlg: this.data.blueFlg
    };
  }

  public close() {
    this.appOverlayRef.close();
  }

  // 画面回転時に再描画
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    // 一度非表示にしてPDFveiwerのサイズを調整する
    this.resizeFlg = true;
    setTimeout(() => {
      this.resizeFlg = false;
    });
  }
}
