import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';

@Component({
  selector: 'app-search-company-dialog',
  templateUrl: './search-company-dialog.component.html',
  styleUrls: ['./search-company-dialog.component.css']
})
export class SearchCompanyDialogComponent implements OnInit {
  public form: FormGroup;
  public readonly Constant = Constant;

  constructor(
    public dialogRef: MatDialogRef<SearchCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private common: CommonService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  // 絞り込む
  onSelect() {
    this.common.preCheck().then(res => {
      const ret = {};
      // 勤務地
      ret[Constant.apOnlyDesirePlace] = this.form.value[Constant.apOnlyDesirePlace];

      // 業種
      const selectedIndustry = [];
      this.data.items.forEach(element => {
        if (this.form.value[element.id]) {
          selectedIndustry.push(element.id);
        }
      });
      ret[Constant.apCompanyIndustry] = selectedIndustry;
      this.dialogRef.close(ret);
    })
    .catch(err => {});
  }

  // フォーム作成
  private initForm() {
    const formControl = {};
    // 勤務地
    formControl[Constant.apOnlyDesirePlace] = new FormControl(this.data[Constant.apOnlyDesirePlace]);

    // 業種
    this.data.items.forEach(element => {
      const selected = this.checkDefault(element.id, this.data.selectedIndustry);
      formControl[element.id] = new FormControl(selected);
    });
    this.form = new FormGroup(formControl);
  }

  private checkDefault(id, selected): boolean {
    if (!selected || selected.length === 0) {
      return false;
    }

    if (selected.indexOf(id) >= 0) {
      return true;
    } else {
      return false;
    }
  }
}
