import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';
import { CommonCompanyService } from './../../shared/common-company.service';
import { TsunagaruDialogComponent } from './../../dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tsunagaru',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './tsunagaru.component.html',
  styleUrl: './tsunagaru.component.css'
})
export class TsunagaruComponent {
  @Input() data;
  public registering = false;  // プール登録中フラグ
  private contact: string;
  private contactQuestion;  // 接点の質問
  private subscriptionTsunagaruInfo: Subscription;

  constructor(
    private common: CommonService,
    private commonCompany: CommonCompanyService,
    private dialog: MatDialog,
  ) { }

  ngAfterViewInit() {
    // つながる処理の終了
    this.subscriptionTsunagaruInfo = this.commonCompany.sharedTsunagaruExit$.subscribe(data => {
      this.registering = false;
      this.commonCompany.progressViewDetach();
      if (!data) {
        this.common.showToastMessage(Constant.msgTsunagaruError);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionTsunagaruInfo) {
      this.subscriptionTsunagaruInfo.unsubscribe();
    }
  }

  // つながる
  onTsunagaru() {
    this.registering = true;
    this.commonCompany.progressViewAttach();

    this.common.preCheck().then(res => {
      this.contact = localStorage.getItem(Constant.lsContactCode  + this.data.companyCode);
      if (this.contact) {
        // 質問取得
        this.commonCompany.getContactInfo(this.data.companyCode, this.contact).then(res => {
          this.contactQuestion = res.data.question;
          this.commonCompany.onTsunagaru(
            this.data.companyCode, this.data.name, this.data.isLogin, this.contact, this.contactQuestion
          );
        })
        .catch(err => {
          this.registering = false;
          this.common.showToastMessage(Constant.msgTsunagaruError);
          this.commonCompany.progressViewDetach();
        });

      } else {
        this.commonCompany.onTsunagaru(this.data.companyCode, this.data.name, this.data.isLogin, null, null);
      }
    })
    .catch(err => {
      this.registering = false;
      this.commonCompany.progressViewDetach();
    });
  }

  // つながるとは　ダイアログ表示
  onWhatIsTalentPool() {
    const data = {companyName: this.data.name};
    this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
      data: data
    });
  }

}
