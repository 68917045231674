<div class="progress-container">
  <img src="assets/profilenew/setting04.png" />
</div>
<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div class="content" *ngIf="!showSpinner">
  <div class="title">
    お疲れ様でした！
    <br />
    プロフィールの簡易登録が完了しました。
  </div>
  <div class="desc text" *ngIf="companyName">
    <span class="orange-text">{{ companyName }}から<br>
      特別な質問が届いています。<br></span>
    早速、見てみましょう！
    <div class="button-container">
      <button (click)="onQuestion()" mat-raised-button color="accent">質問を見る</button>
    </div>
  </div>
  <div class="desc" *ngIf="!companyName">
    引き続き、学歴や職歴などのプロフィールを登録して採用担当者にアピールしましょう！
    <div class="button-container half">
      <button (click)="onMypage()" mat-raised-button>マイページ トップ</button>
      <button (click)="onProfile()" mat-raised-button color="accent">プロフィールを編集</button>
    </div>

  </div>
</div>
