import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { EducationService } from './../shared/education.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-education',
  templateUrl: './profile-education.component.html',
  styleUrls: ['./profile-education.component.css']
})
export class ProfileEducationComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public loading = true;
  public educations; // 学歴
  private subscriptionEducations: Subscription;

  constructor(
    private common: CommonService,
    private router: Router,
    private educationService: EducationService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);
    this.common.bottomMenu = Constant.tlProfile;
    this.common.profileMenu = Constant.tlEducation;

    // イベント登録
    this.subscriptionEducations = this.educationService.sharedEducations$.subscribe(data => {
      if (data !== Constant.apError) {
        this.loading = false;
        this.educations = data;
      } else {
        this.loading = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }
    });

    // 学歴取得
    this.educationService.getEducation(true);
  }

  ngOnDestroy() {
    this.subscriptionEducations.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // 追加
  onAdd() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpEducationEdit]);
      })
      .catch(err => {});
  }

  // 編集
  onEdit(item) {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpEducationEdit, item.id]);
      })
      .catch(err => {});
  }

  // ページトップに移動
  onMovePageTop() {
    window.scroll(0, 0);
  }
}
