import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';
import { CommonCompanyService } from './../../shared/common-company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cmn-pdf-viewer',
  templateUrl: './cmn-pdf-viewer.component.html',
  styleUrls: ['./cmn-pdf-viewer.component.css']
})
export class CmnPdfViewerComponent implements OnInit {
  @Input() pdfParam;
  @Output() closePdf = new EventEmitter();
  public readonly Constant = Constant;
  public showSpinner = true;
  public documentUrl;  // 会社説明資料PDFのURL
  public pdfRealPage = 1;  // PDF表示ページ
  public pdfShowPage = 1;  // PDF表示ページ ダミーを含む
  public pdfTotalPage = 0;  // PDF全ページ数
  public pdfBtnDisabled = false
  public tate = false;

  constructor(
    private router: Router,
    private common: CommonService,
    private commonCompany: CommonCompanyService,
  ) { }

  ngOnInit(): void {
    this.documentUrl = this.pdfParam.url;
    this.pdfShowPage = this.pdfParam.showPage ? this.pdfParam.showPage : 1
    this.pdfRealPage = this.pdfParam.realPage ? this.pdfParam.realPage : 1

    // 30秒経過し読み込みできないなら閉じる
    setTimeout(() => {
      this.pdfError();
    }, Constant.apiTimeoutSecond);
  }

  // 閉じる
  public close() {
    this.closePdf.emit();
  }

  public pdfLoadComplete(pdfData) {
    this.pdfTotalPage = pdfData.numPages;

    // PDFの高さを合わせる
    setTimeout(() => {
      const canvas = document.querySelector('app-cmn-pdf-viewer .canvasWrapper');
      if (!this.tate && window.innerWidth > Constant.mobileWidth && canvas.clientHeight / canvas.clientWidth > 1) {
        // PC表示で縦長PDFなら再表示
        this.tate = true;
        return;
      }
      const viewer = document.querySelector('app-cmn-pdf-viewer .pdf-viewer') as HTMLElement;
      if (viewer && canvas) {
        const height = Math.ceil(canvas.clientHeight)
        viewer.style.height = height + 'px';

      }
      const navi = document.querySelector('.cdk-overlay-pane.pdf-viewer-dialog') as HTMLElement;
      if (navi && canvas) {
        navi.style.width = canvas.clientWidth + 'px';
      }
      this.showSpinner = false;
    }, 200);
  }
  public prevBtn() {
    if (!this.common.preCheckWindowOpen()) {
      return
    }

    this.pdfBtnDisabled = true
    // PV登録
    this.commonCompany.postDocumentViewTime(
      this.pdfParam.companyId,
      this.pdfParam.isLogin,
      this.pdfShowPage,
      this.pdfShowPage - 1,
      this.pdfTotalPage,
      this.pdfParam.documentId,
      this.pdfParam.sessionId)

    this.pdfShowPage--
    if (this.pdfShowPage < this.pdfTotalPage) {
      this.pdfRealPage--;
    }

    setTimeout(() => {
      this.pdfBtnDisabled = false
    }, 100);
  }
  public nextBtn() {
    if (!this.common.preCheckWindowOpen()) {
      return
    }

    this.pdfBtnDisabled = true

    // PV登録
    this.commonCompany.postDocumentViewTime(
      this.pdfParam.companyId,
      this.pdfParam.isLogin,
      this.pdfShowPage,
      this.pdfShowPage + 1,
      this.pdfTotalPage,
      this.pdfParam.documentId,
      this.pdfParam.sessionId)

    this.pdfShowPage++
    if (this.pdfShowPage <= this.pdfTotalPage) {
      this.pdfRealPage++;
    }

    setTimeout(() => {
      this.pdfBtnDisabled = false
    }, 100);

  }
  public pdfError() {
    if (this.pdfTotalPage === 0) {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.close();
    }
  }

  onLink(url) {
    this.router.navigate([url, this.pdfParam.companyCode]);
    this.close();
  }
}
