import { Directive, HostListener } from '@angular/core';
import { Constant } from '../constant';
import { CommonService } from './../shared/common.service';

@Directive({
  selector: '[appImageLoadingError]'
})
export class ImageLoadingErrorDirective {

  constructor(private common: CommonService) { }

  @HostListener('error') showErrToast() {
    // 通信エラートースト表示
    this.common.showToastMessage(Constant.msgErrorNetwork);
  }

}
