import { Component, OnInit, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { TsunagaruDialogComponent } from '../dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { Subscription } from 'rxjs';
import { ProfileService } from '../shared/profile.service';
import { SearchCompanyDialogComponent } from '../dialog/search-company-dialog/search-company-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-info-list',
  templateUrl: './company-info-list.component.html',
  styleUrls: ['./company-info-list.component.css']
})
export class CompanyInfoListComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public allCompanyInfoList;
  public registering = false;
  public registeringCampany: number;
  private progressRow = { code: null, endFlg: false };
  private fromno = 0;
  public isDataGetting = false;
  private htmlElement;
  private bottomLineElement;
  private inScroll = false;
  private apiPath = '/company/';
  private timer;  // スクロールイベント管理用タイマー

  // 絞り込み用
  private onlyDesirePlaceFlg = 1;
  private selectedIndustry = [];
  private industryMaster = []; // 業種マスタ
  private subscriptionMaster: Subscription;

  constructor(
    private router: Router,
    private common: CommonService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private profileService: ProfileService
  ) {
    this.htmlElement = elementRef.nativeElement;
  }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlCompanyInfo);
    this.common.bottomMenu = Constant.tlCompanyInfo;
    // ページ最下の設定
    this.bottomLineElement = this.htmlElement.querySelector(Constant.elementIdBottomLine);
    // データ初期化
    this.initializeData();
    // コンテンツデータ取得処理
    this.getCompanyInfo();
    // 絞り込み用マスタ取得
    this.getSelectMaster();
  }

  ngOnDestroy() {
    this.subscriptionMaster.unsubscribe();
  }

  @HostListener('window:online', ['$event'])
  onOnline(event: any) {
    // オフライン後再接続された際、ページ最下であればデータを取得する
    if (!this.progressRow.endFlg && this.common.onWindowScroll(this.bottomLineElement)) {
      this.onScroll();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  // 上に戻るボタンがタップされた際の処理
  onPageTop() {
    window.scroll(0, 0);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.progressRow.endFlg) {
      return;
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.common.onWindowScroll(this.bottomLineElement)) {
        this.onScroll();
      }
    }, 100);
  }
  // スクロールイベント
  onScroll() {
    if (this.isDataGetting || this.inScroll) {
      return;
    }
    this.inScroll = true;
    this.common
      .preCheck()
      .then(res => {
        this.scrollExec();
      })
      .catch(err => {
        this.inScroll = false;
      });
  }

  onRegistPool(idx) {
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyInfoTalentpoolEntry);
        const apiPath = '/pool/' + this.allCompanyInfoList[idx].code;
        this.common
          .apiPost(apiPath)
          .then(res => {
            this.checkQuestion(this.allCompanyInfoList[idx].code, this.allCompanyInfoList[idx].name, false);
          })
          .catch(err => {
            this.registering = false;
            this.common.showToastMessage(Constant.msgTsunagaruError);
            this.common.debug().log(err);
          });
  }

  // タレントプール登録確認
  checkRegistPool(idx) {
    const companyCode = this.allCompanyInfoList[idx].code;
    const companyName = this.allCompanyInfoList[idx].name;
    this.registering = true;
    this.registeringCampany = idx;

    this.common
    .preCheck()
    .then(response => {
      const apiPath = '/pool/' + companyCode;
      this.common
      .apiGet(apiPath)
      .then(res => {
        if (!res.data.registered) {
          // プール未登録と確認できたら登録処理
          this.onRegistPool(idx);
        } else {
          // プール登録済みであれば質問チェック＆ダイアログを表示
          this.checkQuestion(companyCode, companyName, true);
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.registering = false;
      });
    })
    .catch(err => {
      this.common.debug().log(err);
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.registering = false;
    });
  }

  // 追加質問チェック
  private checkQuestion(companyCode, companyName, registerd) {
    const apiPath = '/question/check/' + companyCode;
    this.common
      .apiGet(apiPath)
      .then(res => {
        if (res.data?.name) {
          this.showTsunagaruCompDialog(companyCode, companyName, registerd, true);
        } else {
          this.showTsunagaruCompDialog(companyCode, companyName, registerd, false);
        }
      })
      .catch(err => {
        this.showTsunagaruCompDialog(companyCode, companyName, registerd, false)
      });
  }


  private showTsunagaruCompDialog(companyCode, companyName, registerd, addQuestion) {
    let title = companyName + Constant.msgPoolNewTitle;
    let message = Constant.msgPoolAlreadyMessage;
    let btnText = 'プロフ更新';
    let disableClose = false;

    if (registerd) {
      title = companyName + Constant.msgPoolAlreadyTitle;
    }

    if (addQuestion) {
      btnText = '質問を見る';
      message = Constant.msgPoolAddQuestionMessage;
      disableClose = true;
    }

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      disableClose: disableClose,
      data: {
        title: title,
        message: message,
        type: Constant.mdTypeYesNo,
        common: this.common,
        buttonText: { left: Constant.mdBtnTextClose, right: btnText }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.registering = false;
      if (result === Constant.mdBtnRight) {
        // 質問ページ or プロフィール学歴
        if (!addQuestion) {
          this.router.navigate([Constant.rpEducation]);
        } else {
          this.router.navigate([Constant.rpSpq, companyCode]);
        }
      } else {
        // データを取り直す
        this.initializeData();
        this.getCompanyInfo();
      }
    });
  }

  // タレントプール、つながるの説明
  onWhatIsTalentPool() {
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyInfoWhatsTalentpool);
    this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
    });
  }

  // 公式ホームページ
  onHomepage(url) {
    this.common.openHomepage(url);
  }

  // 誘導ページへ
  onMoreInfo(code) {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    window.open(Constant.rpLogin + '/' + code);
  }

  // 検索条件の変更
  onSearch() {
    this.common.preCheck().then(res => {
      const dialogRef = this.dialog.open(SearchCompanyDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: {
          items: this.industryMaster,
          only_desire_place: this.onlyDesirePlaceFlg,
          selectedIndustry: this.selectedIndustry
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        }
        this.onlyDesirePlaceFlg = result[Constant.apOnlyDesirePlace];
        this.selectedIndustry = result[Constant.apCompanyIndustry];

        // 再検索
        this.initializeData();
        this.getCompanyInfo();
      });

    })
    .catch(err => {});
  }

  // テキストを省略
  ellipsisText(text, maxLength) {
    return this.common.ellipsisText(text, maxLength);
  }

  private getCompanyInfo() {
    this.isDataGetting = true;
    const apiPath = this.apiPath + 'infolist';
    const body = {
      from_no: this.fromno,
      only_desire_place: this.onlyDesirePlaceFlg,
      company_industry: this.selectedIndustry,
    };
    let currentData;
    this.common
      .preCheck()
      .then(response => {
        this.common
          .apiPost(apiPath, body)
          .then(res => {
            currentData = res.data;
            this.common.debug().log(currentData);
            if (!this.allCompanyInfoList) {
              this.allCompanyInfoList = [];
            }
            if (currentData.length > 0) {
              this.getImageData(currentData);
              // 変換が必要なテキストの変換を実施
              for (let i = 0; i < currentData.length; i++) {
                currentData[i] = this.common.convertCompanyInfoText(currentData[i]);
              }
              // 取得したデータをマージする
              this.margeContentsData(currentData);
            } else {
            }
            if (currentData.length < 10) {
              this.progressRow.endFlg = true;
            } else {
              this.progressRow.endFlg = false;
            }
            this.isDataGetting = false;
            this.inScroll = false;
          })
          .catch(err => {
            this.common.showToastMessage(Constant.msgErrorNetwork);
            this.inScroll = false;
            this.isDataGetting = false;
            if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
              this.common.debug().log(err);
            }
          });
      })
      .catch(err => {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.inScroll = false;
        this.isDataGetting = false;
        this.common.debug().log(err);
      });
  }

  // 企業ロゴと企業情報の画像を取得する
  private getImageData(currentData) {

    for (let i = 0; i < currentData.length; i++) {
      const companyCode = currentData[i].code;
      // 企業PR画像を取得する
      this.common.getCompanyPrImage(companyCode)
      .then(url => {
        if (url !== Constant.apError) {
          currentData[i].pr_image_url = url;
        } else {
          // 返ってきた結果が'err'だった場合
          currentData[i].pr_image_url = Constant.apError;
        }
      });
      // 企業ロゴ画像を取得する
      this.common.getCompanyLogo(companyCode)
      .then(url => {
        if (url !== Constant.apError) {
          currentData[i].logo_url = url;
        } else {
          // 返ってきた結果が'err'だった場合
          currentData[i].logo_url = Constant.apError;
        }
      });
    }
  }

  private scrollExec() {
    if (this.progressRow.endFlg === false) {
      // 一番下までスクロールしたら次のメッセージを取得
      this.getCompanyInfo();
    }
  }

  private initializeData() {
    this.progressRow.code = null;
    this.progressRow.endFlg = true;

    this.allCompanyInfoList = null;
    this.fromno = 0;
  }

  private margeContentsData(currentData) {
    if (this.allCompanyInfoList.length > 0) {
      this.allCompanyInfoList.pop();
    }
    this.allCompanyInfoList.push(...currentData);
    this.allCompanyInfoList.push(this.progressRow);

    if (currentData.length === Constant.dataPerScroll) {
      this.fromno += Constant.dataPerScroll;
    }
  }

  // 絞り込み用業種マスタ取得
  private getSelectMaster() {
    this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
      data.forEach(element => {
        if (element.type === Constant.pmCompanyIndustry) {
          this.industryMaster = element.data;
        }
      });
    });

    const reset = false;
    this.profileService.getMaster(reset);
  }
}
