import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { SelectDialogComponent } from '../dialog/select-dialog/select-dialog.component';
import { WorkService } from './../shared/work.service';
import { ProfileService } from '../shared/profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-career-edit',
  templateUrl: './profile-career-edit.component.html',
  styleUrls: ['./profile-career-edit.component.css']
})
export class ProfileCareerEditComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public works;
  public form: FormGroup;
  public errorMessageTerm: string;
  public employeementMaster; // 雇用形態のマスタ
  private jobMaster; // 職種のマスタ
  public selectedWorkId: number;
  public selectedJob = [];
  public selectedJobName = [];
  public dataSending = false; // データ送信中・削除中のフラグ
  public loading = true;
  public companyNameMaxLength = false; // 「企業名」の入力制御
  public positionMaxLength = false; // 「役職」の入力制御
  public departmentMaxLength = false; // 「部署」の入力制御
  public jobDetailMaxLength = false; // 「主な活動」の入力制御
  public skillMaxLength = false; // 「活かしたスキル」の入力制御

  private subscriptionWorks: Subscription;
  private subscriptionMaster: Subscription;

  constructor(
    private common: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private workService: WorkService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);

    // IDセット
    const id = this.activatedRoute.snapshot.params['id'];
    if (id) {
      this.selectedWorkId = parseInt(id, 10);
    }

    // マスタ取得
    this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
      data.forEach(element => {
        if (element.type === Constant.pmDesireJobType) {
          this.jobMaster = this.common.convertJobMaster(element.data);
        } else if (element.type === Constant.pmDesireEmployeementType) {
          this.employeementMaster = element.data;
        }
      });
    });
    this.profileService.getMaster(false);

    // フォーム作成
    this.initForm();

    // 職歴取得
    if (this.selectedWorkId) {
      this.subscriptionWorks = this.workService.sharedWorks$.subscribe(data => {
        let isValidWorkId = false;
        data.forEach(element => {
          if (element.id === this.selectedWorkId) {
            isValidWorkId = true;
            setTimeout(() => {
              this.setValue(element);
              this.loading = false;
            });
          }
        });
        if (!isValidWorkId) {
          this.router.navigate([Constant.rpError]);
        }
      });
      this.workService.getWork(false);
    } else {
      setTimeout(() => {
        this.loading = false;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptionMaster.unsubscribe();
    if (this.subscriptionWorks) {
      this.subscriptionWorks.unsubscribe();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // キャンセル
  onCancel() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpCareer]);
      })
      .catch(err => {});
  }

  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  onInput(target, maxLenFlg, maxLenCnt) {
    const formName = target.getAttribute('formControlName');
    const beforeText = target.value;
    const changeText = beforeText.replace(/\r?\n/g, '');
    this.form.patchValue({[formName]: beforeText});
    // 改行不可の入力欄に改行入りテキストがペーストされた場合は改行無しに変換したテキストに置き換える
    if (formName === 'company_name' ||
        formName === 'position' ||
        formName === 'department') {
      if (beforeText !== changeText) {
        this.form.patchValue({[formName]: changeText});
      }
    }
    // 入力内容が最大文字数制限を超えた場合は各フラグをtrueにする
    if (target.value.length > maxLenCnt) {
      this[maxLenFlg] = true;
    } else {
      this[maxLenFlg] = false;
    }
  }

  // 文字数カウント
  countInputText(txt) {
    return txt.length;
  }

  // フォーカス移動
  onNextFocus(len, value, nextElm) {
    this.common.nextFocus(len, value, nextElm);
  }

  // 職種選択
  onSelectJob() {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      width: Constant.dlWidth,
      data: {
        type: Constant.sdTypeJob,
        title: Constant.sdTypeJobTitle,
        items: this.jobMaster,
        selected: this.selectedJob
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.selectedJob = [];
      this.selectedJobName = [];
      if (result.length > 0) {
        this.selectedJob = result;
        this.selectedJobName = this.common.setShowJobNameAry(result);
      }
    });
  }

  // 削除
  onDel() {
    this.common
      .preCheck()
      .then(res => {
        this.delExec();
      })
      .catch(err => {});
  }

  // 登録
  onRegist() {
    this.errorMessageTerm = '';

    this.common
      .preCheck()
      .then(res => {
        this.registExec();
      })
      .catch(err => {});
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      start_year: new FormControl('', [
        Validators.required,
        Validators.min(Constant.fvMinYear),
        Validators.pattern(/^[0-9]*$/)
      ]),
      start_month: new FormControl('', [
        Validators.required,
        Validators.min(Constant.fvMinMonth),
        Validators.max(Constant.fvMaxMonth),
        Validators.pattern(/^[0-9]*$/)
      ]),
      end_year: new FormControl('', [Validators.min(Constant.fvMinYear), Validators.pattern(/^[0-9]*$/)]),
      end_month: new FormControl('', [
        Validators.min(Constant.fvMinMonth),
        Validators.max(Constant.fvMaxMonth),
        Validators.pattern(/^[0-9]*$/)
      ]),
      company_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthFifty)
      ]),
      employeement_type_id: new FormControl(''),
      annual_income: new FormControl('', [Validators.min(0), Validators.pattern(/^[0-9]*$/)]),
      department: new FormControl('', [
        Validators.maxLength(Constant.fvMaxLengthFifty)
      ]),
      position: new FormControl('', [
        Validators.maxLength(Constant.fvMaxLengthFifty)
      ]),
      job_detail: new FormControl('', [
        Validators.maxLength(Constant.fvMaxLengthTwoHundred)
      ]),
      skill: new FormControl('', [
        Validators.maxLength(Constant.fvMaxLengthTwoHundred)
      ])
    });
  }

  // フォームに値をセット
  private setValue(data) {
    const joinAry = data.join_date.split('/');
    const exitAry = data.exit_date.split('/');
    if (joinAry.length <= 1) {
      joinAry[1] = '';
    }
    if (exitAry.length <= 1) {
      exitAry[1] = '';
    }

    this.form.setValue({
      start_year: joinAry[0],
      start_month: joinAry[1],
      end_year: exitAry[0],
      end_month: exitAry[1],
      company_name: data.company_name,
      employeement_type_id: data.employeement_type_id,
      annual_income: data.annual_income === -1 ? '' : data.annual_income,
      department: data.department,
      position: data.position,
      job_detail: data.job_detail,
      skill: data.skill
    });

    // 職種
    this.selectedJobName = data.jobName;
    this.selectedJob = data.category;
  }

  private registExec() {
    const joinDate = this.common.dateFromYearMonth(
      this.form.get(Constant.tfStartYear).value,
      this.form.get(Constant.tfStartMonth).value
    );
    const exitDate = this.common.dateFromYearMonth(
      this.form.get(Constant.tfEndYear).value,
      this.form.get(Constant.tfEndMonth).value
    );
    if ((this.form.get(Constant.tfEndYear).value || this.form.get(Constant.tfEndMonth).value) && !exitDate) {
      this.errorMessageTerm = Constant.msgErrorInputEndTerm;
      window.scroll(0, 0);
      return;
    }
    if (exitDate && joinDate > exitDate) {
      this.errorMessageTerm = Constant.msgErrorInvalidTerm;
      window.scroll(0, 0);
      return;
    }

    // データ送信中スピナー表示
    this.dataSending = true;

    // GAイベント
    if (this.selectedWorkId) {
      // 職歴更新
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUpdateProfileCareerEdit);
    } else {
      // 職歴追加
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUpdateProfileCareerAdd);
    }

    const data = [];
    for (const formName in this.form.value) {
      if (
        formName === Constant.tfStartYear ||
        formName === Constant.tfStartMonth ||
        formName === Constant.tfEndYear ||
        formName === Constant.tfEndMonth ||
        formName === Constant.apCategory
      ) {
        continue;
      }

      if (this.form.value.hasOwnProperty(formName)) {
        data.push({ key: formName, value: this.common.replaceSpace(this.form.value[formName]) });
      }
    }

    // 期間
    data.push({ key: Constant.apJoinDate, value: joinDate });
    data.push({ key: Constant.apExitDate, value: exitDate });

    // 職種
    const desire_job = [];
    this.selectedJob.forEach(element => {
      desire_job.push({
        large_key: 'id',
        large_value: element[Constant.apLargeId],
        key: 'id',
        value: element.id
      });
    });
    data.push({ key: Constant.apCategory, value: desire_job });

    const msgTitle = Constant.tlCareer;
    this.workService
      .postWork(data, this.selectedWorkId)
      .then(result => {
        this.common.debug().log(result);
        this.dataSending = false;
        if (result.status === Constant.OK) {
          this.common.showMessage(true, msgTitle);
          this.router.navigate([Constant.rpCareer]);
        } else {
          // APIエラーの場合は「更新に失敗しました」トーストを表示
          this.common.showMessage(false, msgTitle);
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.dataSending = false;
        // オフライン等で結果が取得出来なかった場合は「失敗した可能性があります」トーストを表示
        this.common.showToastMessage(Constant.msgUpdateError);
      });
  }

  // 削除実行
  private delExec() {
    // 確認ダイアログ表示
    const message = Constant.tlCareer + Constant.msgConfirmDelProfile;
    this.common.showDelConfirm(message).subscribe(res => {
      if (res) {
        this.dataSending = true;
        this.workService
          .delWork(this.selectedWorkId)
          .then(response => {
            this.common.debug().log(response);
            this.dataSending = false;
            if (response.status === Constant.OK) {
              this.common.showToastMessage(Constant.msgDelCareerComplete);
              this.router.navigate([Constant.rpCareer]);
            } else {
              // APIエラーの場合は「削除に失敗しました」トーストを表示
              this.common.showToastMessage(Constant.msgDelCareerError);
            }
          })
          .catch(error => {
            this.common.debug().log(error);
            this.dataSending = false;
            // オフライン等で結果が取得出来なかった場合は「失敗した可能性があります」トーストを表示
            this.common.showToastMessage(Constant.msgDeleteError);
          });
      }
    });
  }
}
