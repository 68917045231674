<div class="content">
  退会の前に、すべての企業とのつながりを解消する必要があります。
  <br />
  <br />
  また、ご登録いただいたプロフィールなど全てのデータが削除され、今後一切の復旧ができません。
</div>
<div class="button-container">
  <button mat-raised-button (click)="onUnsbscribe()" color="accent" class="us-button">退会する</button>
</div>
<app-profile-message-buttons></app-profile-message-buttons>
