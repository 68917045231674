import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../constant';
import { Subscription } from 'rxjs';
import { ContactService } from '../shared/contact.service';
import { CommonCompanyService } from '../shared/common-company.service';
import { TsunagaruDialogComponent } from '../dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public form: FormGroup;
  public mode: number;
  public companyCode: string;
  public companyName: string;
  public contact: string;
  public errorMessage: string;
  public showSpinner: boolean;
  public mailAddress: string;
  public readonly Constant = Constant;
  private subscriptionContactInfo: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private auth: AuthService,
    private common: CommonService,
    private contactService: ContactService,
    private commonCompany: CommonCompanyService,
  ) {
    this.form = null;

    // companycode,接点,モード
    this.companyCode = this.activatedRoute.snapshot.params['code'];
    this.contact = this.activatedRoute.snapshot.params['contact'];
    const mode = parseInt(this.activatedRoute.snapshot.data['mode'], 10);

    if (mode) {
      this.mode = mode;
    } else if (!this.companyCode) {
      this.mode = Constant.lgModeLogin;
    }

    // タイトル設定
    this.common.setTitle(this.getTitle());

    // 企業コードをストレージに保存
    if (this.companyCode) {
      localStorage.setItem(Constant.lsCompanyCode, this.companyCode);

      // 企業情報取得
      this.showSpinner = true;
      this.getCompanyInfo();

    } else {
      localStorage.removeItem(Constant.lsCompanyCode);
    }
  }

  ngOnInit() {
    // ログインチェック
    if (this.companyCode) {
      this.auth.checkLoginToPage(this.companyCode);
    } else {
      this.auth.checkAlreadyLogin();
    }

    // 接点タグ
    if (this.contact) {
      // 接点タグがあれば、ローカルストレージに保存
      localStorage.setItem(Constant.lsContactCode  + this.companyCode, this.contact);

      // 接点のイベント登録
      this.setContactEvent();
      // 接点の有効性確認、質問取得
      this.contactService.getInfo(this.companyCode, this.contact);
    }

    // メールアドレスをストレージより取得
    this.mailAddress = localStorage.getItem(Constant.lsMailAddress);
    if (!this.mailAddress) {
      this.mailAddress = '';
    }
    // ログインステータス初期化
    localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
    // フォーム作成
    setTimeout(() => {
      this.initForm();
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionContactInfo) {
      this.subscriptionContactInfo.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    // ログイン画面、企業コードあり、接点タグなしの場合、PV登録
    if (this.mode === Constant.lgModeLogin && this.companyCode && !this.contact) {
      this.auth.isAuthenticated().subscribe(result => {
        if (!result) {
          this.postPageview();
        }
      });
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログインチェック
    if (this.companyCode) {
      this.auth.checkLoginToPage(this.companyCode);
    } else {
      this.auth.checkAlreadyLogin();
    }
  }

  /* API */
  // プロフィール取得　ログイン後の遷移先決定のため
  private getProfile() {
    const apiPath = '/profile';
    this.common.apiGet(apiPath).then(res => {
      if (res.data.last_name === '') {
        // プロフィール新規登録
        this.router.navigate([Constant.rpProfilenew + 1]);
      } else {
        // マイページへ遷移
        this.router.navigate([Constant.rpMypage]);
      }
    })
    .catch(err => {
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.common.debug().log(err);
        this.common.showToastMessage(Constant.msgErrorNetworkShort);
      }
    });
  }

  // 企業情報取得
  private getCompanyInfo() {
    this.commonCompany.getCompanyInfo(this.companyCode).then(data => {
      this.showSpinner = false;
      if (!data) {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        return;
      }
      this.companyName = data.name;
    });
  }

  // フォーム作成
  private initForm() {
    if (this.mode === Constant.lgModeSignup) {
      this.form = new FormGroup({
        email: new FormControl(this.mailAddress, [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(Constant.fvPasswordMin)])
      });
    } else {
      this.form = new FormGroup({
        email: new FormControl(this.mailAddress, [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required])
      });
    }
  }

  /* テンプレート起動 */
  getTitle() {
    if (this.mode === Constant.lgModeLogin) {
      return Constant.tlLogin;
    } else if (this.mode === Constant.lgModeSignup) {
      return Constant.tlSignup;
    } else {
      return Constant.empty;
    }
  }

  // ログインボタン押下
  onLogin() {
    this.errorMessage = '';

    this.common
      .preCheck()
      .then(res => {
        this.loginExec();
      })
      .catch(err => { });
  }

  // 同意して確認メール送信押下
  onConfirm() {
    this.errorMessage = '';

    this.common
      .preCheck()
      .then(res => {
        this.confirmExec();
      })
      .catch(err => { });
  }

  // 新規登録ボタン押下
  onSignUp() {
    this.common
      .preCheck()
      .then(res => {
        this.signupExec();
      })
      .catch(err => { });
  }

  // すでにアカウントをお持ちの方
  onAlreadyAccount() {
    this.common
      .preCheck()
      .then(res => {
        this.alreadyAcountExec();
      })
      .catch(err => { });
  }

  // パスワード忘れ
  onForgetPass() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpPassForget]);
      })
      .catch(err => { });
  }

  onCompanyTop() {
    const tag = localStorage.getItem(Constant.lsContactCode  + this.companyCode);
    this.common
      .preCheck()
      .then(res => {
        if (tag) {
          this.router.navigate([Constant.rpLogin, this.companyCode, tag]);
        } else {
          this.router.navigate([Constant.rpLogin, this.companyCode]);
        }
      })
      .catch(err => { });
  }

  // プライバシーポリシー
  onPrivacy() {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPolicy);
    window.open(Constant.footerUrlPrivacy);
  }

  // 利用規約
  onAgreement() {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionAgreement);
    window.open(Constant.footerUrlAgreement);
  }

  // つながるとは　ダイアログ表示
  onWhatIsTalentPool() {
    const data = {companyName: this.companyName, blueFlg: false};
    this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
      data: data
    });
  }

  /* プライベート */
  private toConfirm(): void {
    this.routerNavigate(Constant.rpConfirm);
  }

  private loginExec() {
    // GAイベント
    if (this.companyCode) {
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLoginCompany);
    } else {
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLoginMain);
    }

    // 未入力チェック
    if (!this.email.value || !this.password.value) {
      this.errorMessage = Constant.msgErrorInputMailPass;
      return;
    }

    // フォームのエラーチェック
    if (this.form.invalid) {
      this.errorMessage = Constant.msgErrorFormInValid;
      return;
    }

    const loginStatus = localStorage.getItem(Constant.lsLoginStatus);
    if (loginStatus === Constant.lsTrue) {
      this.common.showReloadDialog();
      return;
    }
    localStorage.setItem(Constant.lsLoginStatus, Constant.lsTrue);
    localStorage.setItem(Constant.lsMailAddress, this.email.value);

    // くるくる表示
    this.showSpinner = true;
    this.auth.signIn(this.email.value, this.password.value).subscribe(result => {
      this.common.debug().log(result);
      if (result) {
        // ログイン成功で企業コード、メールアドレスをストレージより削除
        localStorage.removeItem(Constant.lsCompanyCode);
        localStorage.removeItem(Constant.lsMailAddress);

        // 仮タレントIDのPV等を更新
        this.commonCompany.updateTempActionPV()

        // 企業コードありの場合、誘導ページへ
        if (this.companyCode) {
          // ダイアログ表示のためローカルストレージに保存
          localStorage.setItem(Constant.lsLoginCompFlg, Constant.lsTrue);
          this.routerNavigate(Constant.rpLogin);
        } else {
          // プロフィール取得し、マイページへ遷移
          this.getProfile();
        }
      } else {
        const code = this.auth.error[Constant.awsCode];
        const msg = this.auth.error[Constant.awsMessage];

        if (
          code === Constant.awsErrorNotAuthorizedException ||
          code === Constant.awsErrorUserNotFoundException
        ) {
          if (msg.indexOf('exceeded') > -1) {
            this.errorMessage = Constant.msgErrorLoginAttemptExceeded;
          } else {
            this.errorMessage = Constant.msgErrorInValidMailPass;
          }
        } else if (code === Constant.awsErrorUserNotConfirmedException) {
          // 未認証　認証コード入力画面へ遷移
          this.toConfirm();
        } else {
          this.errorMessage = msg;
        }
        this.showSpinner = false;
        localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
      }
    });
  }

  private confirmExec() {
    // GAイベント
    if (this.companyCode) {
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSigninCompany);
    } else {
      this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSigninMain);
    }

    const loginStatus = localStorage.getItem(Constant.lsLoginStatus);
    if (loginStatus === Constant.lsTrue) {
      this.common.showReloadDialog();
      return;
    }

    this.showSpinner = true;
    const email = this.form.get('email').value;
    const password = this.form.get('password').value;

    this.auth.signUp(email, password).subscribe(
      result => {
        this.common.debug().log(result);
        // メールアドレスを保存
        localStorage.setItem(Constant.lsMailAddress, email);

        // 認証コード入力画面へ遷移
        this.toConfirm();
      },
      error => {
        this.common.debug().log(error);
        this.showSpinner = false;
        if (error.code === Constant.awsErrorUsernameExistsException) {
          this.errorMessage = Constant.msgErrorMailAlreadyRegist;
        } else if (error.code === Constant.awsErrorInvalidPasswordException) {
          this.errorMessage = Constant.msgErrorPasswordFormat;
        } else {
          this.errorMessage = Constant.msgErrorCreateAccount;
        }
      }
    );
  }

  private signupExec() {
    // メールアドレスを保存
    localStorage.setItem(Constant.lsMailAddress, this.email.value);

    this.routerNavigate(Constant.rpSignup);
  }

  private alreadyAcountExec() {
    localStorage.setItem(Constant.lsMailAddress, this.email.value);
    this.routerNavigate(Constant.rpLoginForm);
  }

  // 企業コード有無での遷移先決定
  private routerNavigate(path) {
    if (this.companyCode) {
      this.router.navigate([path, this.companyCode]);
    } else {
      this.router.navigate([path]);
    }
  }

  // 接点のイベント登録
  private setContactEvent() {
    // 接点が有効かどうか
    this.subscriptionContactInfo = this.contactService.sharedInfo$.subscribe(data => {
      if (data.status === Constant.OK) {
        // 有効
        // PV登録
        if (this.mode === Constant.lgModeLogin) {
          this.auth.isAuthenticated().subscribe(result => {
            if (!result) {
              this.postPageview();
            }
          });
        }

      } else if (data.status === Constant.NG) {
        // 無効
        localStorage.removeItem(Constant.lsContactCode + this.companyCode);
        this.router.navigate([Constant.rpLogin, this.companyCode]);
      } else {
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }
    });
  }

  // ページビューを登録する
  private postPageview() {
    const pvSetTime = sessionStorage.getItem(Constant.lsLoginPVTime + this.companyCode);
    const pvSetTag = sessionStorage.getItem(Constant.lsLoginPVTag + this.companyCode);
    let tag = localStorage.getItem(Constant.lsContactCode  + this.companyCode);
    if (!tag) {
      tag = Constant.empty;
    }
    if (Number(pvSetTime) > new Date().getTime() - 10 * 60 * 1000 && pvSetTag === tag) {
      // 10分以内＆接点タグが同じなら登録しない
      return;
    }
    const apiPath = '/company/loginpageview';
    const body = {
      companycode: this.companyCode,
      contacttag: tag
    };
    this.common.apiPostBeforeLogin(apiPath, body);

    // セッションストレージにPV記録日時を保存
    sessionStorage.setItem(Constant.lsLoginPVTime + this.companyCode, new Date().getTime().toString());
    sessionStorage.setItem(Constant.lsLoginPVTag + this.companyCode, tag);
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }
}
