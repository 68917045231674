import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from './../constant';
import { ProfileService } from './../shared/profile.service';
import { Subscription } from 'rxjs';
import { CommonService } from './../shared/common.service';

@Component({
  selector: 'app-profile-basic',
  templateUrl: './profile-basic.component.html',
  styleUrls: ['./profile-basic.component.css']
})
export class ProfileBasicComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public profile; // プロフィール
  public imageUrl; // プロフィール画像URL
  public loading = true;

  private subscriptionProfile: Subscription;
  private subscriptionImage: Subscription;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private common: CommonService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);
    this.common.bottomMenu = Constant.tlProfile;
    this.common.profileMenu = Constant.tlBasic;
    // プロフィール情報の取得
    this.getProfileData();
  }

  ngOnDestroy() {
    this.subscriptionProfile.unsubscribe();
    this.subscriptionImage.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // 編集
  onEdit() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpBasicEdit]);
      })
      .catch(err => {});
  }

  // ページトップに移動
  onMovePageTop() {
    window.scroll(0, 0);
  }

  // プロフィールとプロフ画像を取得する
  private getProfileData() {
    const promiseArray = [];
    const reset = true;

    const excutePromisePrf = () => {
      return new Promise<void>((resolve, reject) => {
        // プロフィール情報を取得する
        this.profileService.getProfile(reset);
        this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
          if (data !== Constant.apError) {
            this.profile = data;
            resolve();
          } else {
            reject();
          }
        });
      });
    };
    const excutePromiseImg = () => {
      return new Promise<void>((resolve, reject) => {
        // プロフィール画像を取得する
        this.profileService.getImage(reset);
        this.subscriptionImage = this.profileService.sharedImage$.subscribe(data => {
          if (data !== Constant.apError) {
            this.imageUrl = data;
            resolve();
          } else {
            this.imageUrl = Constant.apError;
            reject();
          }
        });
      });
    };

    promiseArray.push(excutePromisePrf());
    promiseArray.push(excutePromiseImg());
    Promise.all(promiseArray)
    .then(() => {
      this.loading = false;
    })
    .catch(() => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.loading = false;
    });
  }

}
