<mat-dialog-content>
  <img src="assets/common/clear.svg" class="close" mat-dialog-close *ngIf="!blueFlg">
  <img src="assets/company/close.png" class="close" mat-dialog-close *ngIf="blueFlg">
  <div class="title">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" [class.blue]="blueFlg"><defs><style>.a{fill:#eb7655;}</style></defs><path class="a" d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm.9,15.3H10.1V15.5h1.8Zm1.863-6.975-.81.828A3.064,3.064,0,0,0,11.9,13.7H10.1v-.45A3.623,3.623,0,0,1,11.153,10.7l1.116-1.134A1.76,1.76,0,0,0,12.8,8.3a1.8,1.8,0,0,0-3.6,0H7.4a3.6,3.6,0,0,1,7.2,0A2.864,2.864,0,0,1,13.763,10.325Z" transform="translate(-2 -2)"/></svg>
    <h3 mat-dialog-title>つながるとは</h3>
  </div>
  <div class="description">
    つながるとは、あなたと{{ name }}が知り合う、気軽な挨拶のようなものです。
  </div>
  <div class="row" [class.blue]="blueFlg">
    <div class="col">
      <div class="img-container">
        <img src="assets/company/assets_what1.png" class="show-pc">
        <img src="assets/company/assets_what1_sp.png" class="show-sp">
      </div>
      <div class="right">
        <div class="col-title">誰でも・いつでもOK</div>
        <div class="text">年齢・経験・応募意欲を問わず、誰でもつながれます。</div>
      </div>
    </div>
    <div class="col">
      <div class="img-container">
        <img src="assets/company/assets_what2.png">
      </div>
      <div class="right">
        <div class="col-title">企業から情報が届く</div>
        <div class="text">特別な情報やスカウトなどが届くことがあります。</div>
      </div>
    </div>
    <div class="col">
      <div class="img-container">
        <img src="assets/company/assets_what3.png" class="show-pc">
        <img src="assets/company/assets_what3_sp.png" class="show-sp">
      </div>
      <div class="right">
        <div class="col-title">つながりは自在に管理</div>
        <div class="text">必要がなくなれば、つながりは自由に解消できます。</div>
      </div>
    </div>
  </div>
</mat-dialog-content>
