import { Meta, Title } from '@angular/platform-browser';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from '../constant';
import { environment } from './../../environments/environment';
import { CommonService } from './../shared/common.service';
import { ColumnService } from './../shared/column.service';
import { CommonLpService } from '../shared/common-lp.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-lp-column',
  templateUrl: './lp-column.component.html',
  styleUrls: ['./lp-column.component.css']
})
export class LpColumnComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly Constant = Constant;
  public columnImgUrl = environment.COLUMN_IMG_URL;
  public columnList = [];
  public showSpinner = true;
  public columnId = 0;
  public detailData;
  public loading = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private common: CommonService,
    private columnService: ColumnService,
    private title: Title,
    private meta: Meta,
    private commonLp: CommonLpService,
    @Inject(DOCUMENT) private dom
  ) {
    const id = this.activatedRoute.snapshot.params['id'];
    if (id) {
      this.columnId = parseInt(id, 10);
    }

    this.getColumn();
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    setTimeout(() => {
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    // URLが変更されたら再読み込みする
    this.activatedRoute.url.subscribe(params => {
      if (params[1] && params[1].path !== this.columnId.toString()) {
        this.columnId = parseInt(params[1].path, 10);
        this.detailData = null;
        this.commonLp.deleateCanonicalURL(this.dom);
        this.getColumn();
        window.scroll(0, 0);
      }
    });
  }

  ngOnDestroy(): void {
    // canonicalタグ削除
    this.commonLp.deleateCanonicalURL(this.dom);
  }

  public ellipsisText(text: string, maxLen: number) {
    text = this.columnService.ellipsisText(text, maxLen);
    return text;
  }

  private getColumn() {
    let url = '';
    if (this.columnId) {
      this.getColumnDetail();
      url = this.dom.location.origin + '/' + Constant.rpLpColumn + '/' + this.columnId;
    } else {
      this.getColumnList();
      this.setSeoMeta(Constant.metaTitleLP, Constant.metaDesc);
      url = this.dom.location.origin + '/' + Constant.rpLpColumn;
    }

    // canonicalタグ設定
    this.commonLp.createCanonicalURL(this.dom, url);
  }

  // 一覧取得
  private getColumnList() {
    this.columnService.getColumnList().then(res => {
      this.columnList = res;
      this.showSpinner = false;
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.showSpinner = false;
    });
  }

  // 詳細取得
  private getColumnDetail() {
    this.columnService.getColumnDetail(this.columnId).then(res => {
      if (!res || res.release_status !== 1) {
        // データが取得できない場合、非公開の場合は一覧に飛ばす
        this.router.navigate([Constant.rpLpColumn]);
        return;
      }
      this.detailData = res;
      this.showSpinner = false;
      this.setSeoMeta(this.detailData.title + Constant.metaCalin, this.columnService.ellipsisText(this.detailData.content, 200));
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.showSpinner = false;
    });
  }

  // SEOのメタタグ設定
  private setSeoMeta(title, description) {
    this.title.setTitle(title);
    this.meta.updateTag({name: 'description', content: description});
  }
}
