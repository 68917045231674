<app-profile-edit-buttons></app-profile-edit-buttons>
<div mat-icon-button class="data-update-button" (click)="onMovePageTop()">
  <img src="assets/common/data_update.svg" />
</div>
<h3 class="subheader">{{ Constant.tlEducation }}</h3>
<div class="right-button">
  <button mat-flat-button (click)="onAdd()" class="add-btn">追加</button>
</div>

<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<div class="no-data" *ngIf="educations && educations.length == 0">
  学歴の登録がありません。
  <br />
  右上の「追加」から学歴を登録しましょう。
</div>

<div class="content">
  <div class="history-line" *ngIf="educations && educations.length != 0"></div>
  <table class="profile-table">
    <ng-container *ngFor="let education of educations">
      <tr>
        <td class="icon" rowspan="4">
          <img class="icon-small icon-circle" src="assets/common/career_circle.png" />
        </td>
        <td>
          <div class="name pre-line">{{ education.school_name }}
            <span *ngIf="education.school_type != ''" class ="school-name">（{{ education.school_type}}）</span>
          </div>
          <div class="date">{{ education.admission_date }}〜{{ education.exit_date }}
            <span *ngIf="education.dropout_flag == 1" class="dropout">（中退・転学）</span>
          </div>
        </td>
        <td class="td-edit" rowspan="2">
          <button mat-flat-button (click)="onEdit(education)" class="add-btn">編集</button>
        </td>
      </tr>
      <tr>
        <td>
          <div class="label">文理</div>
          <div class="text">{{ education.bunri_type }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">学部・専攻など</div>
          <div class="text pre-line">{{ education.department }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="label">主な活動</div>
          <div class="text pre-line bottom">{{ education.main_activities }}</div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
<div class="spacer"></div>

<app-profile-message-buttons></app-profile-message-buttons>
