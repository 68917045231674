import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../constant';
import { CommonCompanyService } from '../shared/common-company.service';

@Component({
  selector: 'app-unsubscribe-mail',
  templateUrl: './unsubscribe-mail.component.html',
  styleUrls: ['./unsubscribe-mail.component.css']
})
export class UnsubscribeMailComponent implements OnInit {
  public readonly Constant = Constant;
  public page = 0;
  public form: FormGroup;
  public errorMessage: string;
  public showSpinner = true;
  public registing = false;
  public companyCode;
  public companyInfo;
  public title;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private common: CommonService,
    private commonCompany: CommonCompanyService,
  ) {
    this.companyCode = this.activatedRoute.snapshot.params['code'];
  }

  ngOnInit(): void {
    // タイトル設定
    this.common.setTitle(Constant.tlMailUnsubscribe);
    this.title = Constant.tlMailUnsubscribe;

    // 企業情報取得
    this.getCompanyInfo();

    // フォーム作成
    this.initForm();
  }

  onRegist() {
    this.errorMessage = '';
    this.registing = true;

    this.common
      .preCheck()
      .then(res => {
        this.registExec();
      })
      .catch(err => {
        this.registing = false;
      });
  }

  onCalinLogo() {
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogoCalin);
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMypage]);
      })
      .catch(err => {});
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  // 企業名取得
  private getCompanyInfo() {
    this.common.preCheck().then(res => {
      this.commonCompany.getCompanyInfo(this.companyCode).then(data => {
        if (!data) {
          this.showSpinner = false;
          return;

        }
        this.companyInfo = data;
        this.showSpinner = false;

      })
      .catch (err => {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.showSpinner = false;
      });
    })
    .catch();
  }

  // 登録実行
  private registExec() {
    const body = {
      company_code: this.companyCode,
      email: this.email.value
    };
    this.common.apiPostBeforeLogin('/mail/unsubscribe', body)
    .then(res => {
      if (res.status === Constant.OK) {
        window.scroll(0, 0);
        this.page = 1;
        // タイトル設定
        this.title = Constant.tlMailUnsubscribe + '完了';
        this.common.setTitle(this.title);

      } else {
        this.common.showToastMessage(Constant.msgError);
        this.registing = false;
      }
    })
    .catch(err => {
      this.common.debug().log(err.error);
      this.common.showToastMessage(Constant.msgError);
      this.registing = false;
    });
  }

  get email() {
    return this.form.get('email');
  }

}
