<div class="index" *ngIf="!loading">
  <app-lp-header page="top"></app-lp-header>
  <div class="contents">
    <div class="top">
      <picture>
        <source srcset="assets/lp/img_top.webp" type="image/webp" media="(min-width: 751px)">
        <source srcset="assets/lp/img_top_trim.webp" type="image/webp" media="(max-width: 750px)">
        <img src="assets/lp/img_top.jpg" class="show-pc">
        <img src="assets/lp/img_top_trim.jpg" class="show-sp">
      </picture>
      <div class="title-area">
        <div class="midashi-font">就活前の1年生も <br class="show-sp">中途の社会人も</div>
        <div class="sp-back">
          <div class="desc">
            <span class="bold">CaLin</span>は、気になる会社とオンラインで、<br class="show-pc">気軽に<span class="bold orange">「つながる」</span>サービスです。
          </div>
          <div class="what-tsunagaru-area">
            <img src="assets/common/question.svg" width="20" height="20" (click)="onWhatIsTalentPool()">
            <div (click)="onWhatIsTalentPool()">つながるとは</div>
          </div>
        </div>
        <div class="calin-registration show-pc">
          <a mat-button class="registration-btn" routerLink='/{{ Constant.rpSignup }}' (click)="onClickGAEvent(Constant.gaActionTopAccount + '1')">
            会員登録（無料）
          </a>
        </div>
      </div>
    </div>
    <div class="show-sp btn-area">
      <div class="flex">
        <a class="rect-btn doc" routerLink="/{{Constant.rpLogin}}" (click)="onClickGAEvent(Constant.gaActionTopLoginSP)">ログイン</a>
        <a class="rect-btn account" routerLink='/{{ Constant.rpSignup }}' (click)="onClickGAEvent(Constant.gaActionTopAccount + '1')">会員登録（無料）</a>
      </div>
    </div>
    <section id="search">
      <img src="/assets/lp/txt_search_color.png" height="50">
      <div class="title-text">気になる企業を探す</div>
      <app-lp-search-box (searchCompany)="searchCompany($event)"></app-lp-search-box>
    </section>
    <section id="user" *ngIf="companyInfoList.length>0">
      <img src="/assets/lp/txt_companies_color.png" height="50">
      <div class="title-text">注目の企業</div>
      <div class="flex">
        <div class="col" *ngFor="let companyInfo of companyInfoList; index as i;">
          <a class="name-container" routerLink='/{{ Constant.rpLogin }}/{{companyInfo.code}}' target="_blank" (click)="onClickGAEvent(Constant.gaActionTopPickupCompany + (i+1))">
            <img *ngIf="companyInfo.logo_url != Constant.empty" class="logo" [lazyLoad]="companyInfo.logo_url">
            <span class="name"
                  [class.small]="companyInfo.name.length>14 && companyInfo.name.length<=16"
                  [class.mini]="companyInfo.name.length>16">
                    {{ companyInfo.name }}
            </span>
          </a>
          <div *ngIf="companyInfo.pr_image_url != Constant.empty" class="image-container">
            <a routerLink="/{{ Constant.rpLogin }}/{{companyInfo.code}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopPickupCompany + (i+1))">
              <img class="image" [lazyLoad]="companyInfo.pr_image_url" />
            </a>
          </div>
          <div class="detailinfo-area">
            <div *ngIf="companyInfo.employment_area && companyInfo.employment_area != Constant.empty">
              <div class="title">勤務地</div>
              <div class="value">{{ companyInfo.employment_area }}</div>
            </div>
            <div *ngIf="companyInfo.industry && companyInfo.industry != Constant.empty">
              <div class="title">業種</div>
              <div class="value">{{ companyInfo.industry }}</div>
            </div>
          </div>
          <div class="main-copy-area" *ngIf="companyInfo.summary">{{ companyInfo.summary }}</div>
          <div class="more-btn-area">
            <a routerLink='/{{ Constant.rpLogin }}/{{companyInfo.code}}' target="_blank" class="more-btn" (click)="onClickGAEvent(Constant.gaActionTopPickupCompany + (i+1))">
              <div *ngIf="companyInfo.document_path" class="document">スライド資料あり</div>
              <div [class.more-btn-top]="companyInfo.document_path">企業の詳細ページへ</div>
              <img src="assets/lp/arrow-white.svg" />
            </a>
          </div>
        </div>
      </div>

      <a mat-button class="blue-btn" routerLink="/{{ Constant.rpLpPage }}/1">
        企業一覧
      </a>
    </section>
    <section id="uniqueness">
      <img src="/assets/lp/txt_uniqueness_color.png" height="50">
      <div class="title-text">CaLinの特徴</div>
      <div class="flex">
        <div class="col">
          <div class="text">
            就活や転職活動スタート前から<br>気になる企業と「つながる」
          </div>
          <div class="image margin-top">
            <picture>
              <img lazyLoad="/assets/lp/talent01.png" width="180" />
            </picture>
          </div>
        </div>
        <div class="col">
          <div class="text">
            気になる企業の情報収集や<br>業界研究に役立つ情報が満載
          </div>
          <div class="image">
            <picture>
              <img lazyLoad="/assets/lp/talent02.png" width="70" />
            </picture>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="col">
          <div class="text">
            つながった企業だけと情報交換<br>知らない企業からのスカウト0
          </div>
          <div class="image">
            <picture>
              <img lazyLoad="/assets/lp/talent03.png" width="160" />
            </picture>
          </div>
        </div>
        <div class="col">
          <div class="text">
            つながりは望む限りずっと続く<br>企業との縁はキャリアの財産に
          </div>
          <div class="image margin-top">
            <picture>
              <img lazyLoad="/assets/lp/talent04.png" width="160" />
            </picture>
          </div>
        </div>
      </div>
      <a mat-button class="blue-btn" routerLink="/{{ Constant.rpLpStartguide }}">
        さらに詳しく
      </a>
    </section>
    <!-- 就活準備コラム -->
    <section id="column" *ngIf="columnList.length>0">
      <img src="/assets/lp/txt_column_color.png" height="50">
      <div class="title-text">就活準備コラム</div>
      <div class="flex">
        <div class="col" *ngFor="let column of columnList">
          <a routerLink="/column/{{ column.id }}"><img *ngIf="column.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ column.image_path }}"></a>
          <a class="title" routerLink="/column/{{ column.id }}">{{ column.title }}</a>
          <div class="content">{{ ellipsisText(column.content, 99) }}</div>
        </div>
      </div>
      <a mat-button class="blue-btn" routerLink="/{{ Constant.rpLpColumn }}">
        コラム一覧
      </a>
    </section>
    <section id="media">
      <div class="title-text">掲載メディア</div>
      <div class="item">
        <a href="https://www.recme.jp/careerhigh/entry/calin" target="_blank"><img lazyLoad="/assets/lp/media/careerhigh.jpg"></a>
        <a href="https://hrtech-guide.jp/calin/" target="_blank"><img lazyLoad="/assets/lp/media/HR Tech guide.png"></a>
        <a href="https://busiconet.co.jp/evowork/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/EvoWork_logo.png"></a>
        <a href="https://2b-connect.jp/tensyoku-connect/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/connect_logo.png"></a>
        <a href="https://hakenreco.com/tenshokusite-recommend/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/CareeReco_logo.png"></a>
        <a href="https://appart.co.jp/upcareer/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/UpCareer_logo.png"></a>
        <a href="https://jobtv.jp/mediatop/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/tt_logo.png"></a>
      </div>
    </section>
  </div>
  <section id="footer">
    <div class="title">企業もあなたとの<br class="show-sp">つながりを待っています</div>
    <div class="btn-area">
      <a mat-button class="registration-btn" routerLink='/{{ Constant.rpSignup }}' (click)="onClickGAEvent(Constant.gaActionTopAccount + '2')">
        会員登録（無料）
      </a>
    </div>
    <div class="desc">
      企業各社とつながるには、キャリン株式会社が運営するタレントプール採用サービス「CaLin」のアカウントを作成する必要があります。CaLinのご利用は完全無料です。
    </div>
  </section>
  <!-- footer -->
  <app-lp-footer></app-lp-footer>
</div>
