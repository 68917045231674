<div class="progress-container">
  <img *ngIf="page == 1"
    src="assets/profilenew/setting01.png" />
  <img *ngIf="page == 2"
    src="assets/profilenew/setting02.png" />
  <img *ngIf="page == 3"
    src="assets/profilenew/setting03.png" />
</div>
<div *ngIf="page == 1 && form1"
  class="content">
  <form [formGroup]="form1">
    <h3 class="title">お名前と連絡先を教えてください</h3>

    <div class="label">
      <span class="required">名前</span>
    </div>
    <div class="input">
      <div>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>姓</mat-label>
          <textarea matInput
            formControlName="last_name"
            type="text"
            required
            #lastName
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'form1', 'lastNameMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="lastNameMaxLength">
              {{ countInputText(lastName.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>名</mat-label>
          <textarea matInput
            formControlName="first_name"
            type="text"
            required
            #firstName
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'form1', 'firstNameMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="firstNameMaxLength">
              {{ countInputText(firstName.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>姓 ひらがな入力</mat-label>
          <textarea matInput
            formControlName="last_name_kana"
            type="text"
            required
            #lastNameKana
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'form1', 'lastNameKanaMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="lastNameKanaMaxLength">
              {{ countInputText(lastNameKana.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="input-middle"
          hideRequiredMarker="true">
          <mat-label>名 ひらがな入力</mat-label>
          <textarea matInput
            formControlName="first_name_kana"
            type="text"
            required
            #firstNameKana
            cdkTextareaAutosize
            cdkAutosizeMaxRows="2"
            (keydown.enter)="onKeyDown($event)"
            (input)="onInput($event.target, 'form1', 'firstNameKanaMaxLength', Constant.fvMaxLengthName)">
          </textarea>
          <mat-hint align="end">
            <span class="length-hint" [class.red]="firstNameKanaMaxLength">
              {{ countInputText(firstNameKana.value) }} / {{ Constant.fvMaxLengthName }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="label">
      <span class="required">電話番号</span>
    </div>
    <div class="input">
      <mat-form-field class="input-tel">
        <mat-label>半角数字 ハイフンなし</mat-label>
        <input matInput
          formControlName="telno"
          maxlength="11"
          type="tel"
          (keydown.enter)="onKeyDown($event)" />
      </mat-form-field>
    </div>
  </form>
</div>
<div *ngIf="page == 2 && form2"
  class="content">
  <form [formGroup]="form2">
    <h3 class="title">性別、生年月日、居住地を教えてください</h3>

    <div class="label">
      <span class="required">性別</span>
    </div>
    <div class="input bottom">
      <mat-radio-group formControlName="sex_id" class="radio-btn"
        required>
        <mat-radio-button *ngFor="let item of master['SEX_TYPE']; let f = first"
          [value]="item.id"
          [class]="f ? 'half-left' : 'half-right'">{{ item[Constant.apItemValue] }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="label">
      <span class="required">生年月日</span><span class="prefix">半角数字</span>
    </div>
    <div class="input">
      <div class="period-area">
      <mat-form-field class="input-short"
        hideRequiredMarker="true">
        <mat-label>西暦</mat-label>
        <input matInput
          formControlName="birthday_year"
          type="tel"
          min="{{ Constant.fvMinYear }}"
          max="{{ Constant.fvMaxYear }}"
          (keyup)="onNextFocus(4, $event.target.value, birthdaymonth)"
          (keydown.enter)="onKeyDown($event)"
          maxlength="4"
          required /> </mat-form-field>
          <span class="date">年</span>
      <mat-form-field class="input-short" hideRequiredMarker="true">
        <mat-label></mat-label>
        <input matInput
          formControlName="birthday_month"
          type="tel"
          min="{{ Constant.fvMinMonth }}"
          max="{{ Constant.fvMaxMonth }}"
          #birthdaymonth
          (keyup)="onNextFocus(2, $event.target.value, birthdayday)"
          (keydown.enter)="onKeyDown($event)"
          maxlength="2"
          required /> </mat-form-field>
          <span class="date">月</span>
      <mat-form-field class="input-short" hideRequiredMarker="true">
        <mat-label></mat-label>
        <input matInput
          formControlName="birthday_day"
          type="tel"
          min="{{ Constant.fvMinDay }}"
          max="{{ Constant.fvMaxDay }}"
          #birthdayday
          (keyup)="onNextFocus(2, $event.target.value, address)"
          (keydown.enter)="onKeyDown($event)"
          maxlength="2"
          required /> </mat-form-field>
          <span class="date">日</span>
        </div>
      <mat-error *ngIf="errorMessageDate" class="term">{{ errorMessageDate }}</mat-error>
    </div>

    <div class="label">
      <span class="required">居住地</span>
    </div>
    <div class="input">
      <mat-form-field class="input-middle-s"
        hideRequiredMarker="true">
        <mat-label>都道府県</mat-label>
        <mat-select formControlName="address1"
          #address>
          <mat-option *ngFor="let item of master[Constant.pmDesireJobPlaceType]"
            [value]="item.item_value">
            {{ item.item_value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-middle-l"
        hideRequiredMarker="true">
        <mat-label>市区町村</mat-label>
        <textarea matInput
          formControlName="address2"
          type="text"
          #address2
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'form2', 'address2MaxLength', Constant.fvMaxLengthCityName)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="address2MaxLength">
            {{ countInputText(address2.value) }} / {{ Constant.fvMaxLengthCityName }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </form>
</div>
<div *ngIf="page == 3 && form3"
  class="content">
  <form [formGroup]="form3"
    (keydown.enter)="onKeyDown($event)">
    <h3 class="title">就業/転職の<span class="red-text">希望情報</span>を教えてください</h3>
    <div class="label">
      <span class="required">希望 雇用形態</span>
    </div>
    <div class="input bottom">
      <mat-checkbox *ngFor="let item of master[Constant.pmDesireEmployeementType]"
        formControlName="desire_employeement_{{ item.id }}"
        class="button-type">
        {{ item[Constant.apItemValue] }}
      </mat-checkbox>
    </div>

    <div class="label">
      <span class="required">希望 職種</span>
    </div>
    <div class="input">
      <button type="button" [disabled]="form3.value[Constant.apDesirejobUndecidedFlag]==Constant.prfFlagOn"
        (click)="onDesireJob()"
        mat-raised-button
        class="select-btn">選択する</button>
      <div class="desirejob-undecided">
        <mat-checkbox labelPosition="before" [formControlName]="Constant.apDesirejobUndecidedFlag">未定の場合はこちらにチェック</mat-checkbox>
      </div>
      <div class="job-content">
        <ng-container *ngFor="let job of selectedDesireJobName">
          <div class="large" [class.disabled]="form3.value[Constant.apDesirejobUndecidedFlag]==Constant.prfFlagOn">{{ job.large_name }}</div>
          <ul class="middle">
            <li *ngFor="let middle of job.middle_name">
              <span>{{ middle }}</span>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>

    <div class="label">
      <span class="required">希望 勤務地</span>
    </div>
    <div class="input">
      <button type="button"
        (click)="onDesirePlace()"
        mat-raised-button
        class="select-btn">選択する</button>
      <div class="place-name" *ngIf="selectedDesirePlaceName.length===Constant.prfPlaceZenkokuCnt">
        全国
      </div>
      <div class="place-name" *ngIf="selectedDesirePlaceName.length<Constant.prfPlaceZenkokuCnt">
        {{ selectedDesirePlaceName }}
      </div>
    </div>

    <div class="label">
      希望 年収
      <span class="prefix">半角数字</span>
      <span class="string-count">({{ desire_annual_income.value.length }}/5)</span>
    </div>
    <div class="input input-income">
      <mat-form-field class="income no-placeholder">
        <input matInput
          formControlName="desire_annual_income"
          #desire_annual_income
          type="tel"
          min="0"
          maxlength="5"
          (keydown.enter)="onKeyDown($event)" />
      </mat-form-field>
      <span class="unit">万円</span>
    </div>

    <div class="label">
      活動予定時期 <span class="red-text">※1,2ともに必須</span>
    </div>
    <ol>
      <li>就活（新卒）について卒業年を選択してください。新卒採用が対象外（中途など）の方も選択してください。</li>
      <button type="button" (click)="onGraduateYear()" mat-raised-button class="select-btn" [disabled]="form3.value[Constant.apGraduateYearSkipFlag]==Constant.prfFlagOn">
        選択する
      </button>
      <div class="desirejob-undecided">
        <mat-checkbox labelPosition="before" [formControlName]="Constant.apGraduateYearSkipFlag">回答をスキップする</mat-checkbox>
      </div>
      <ng-container *ngIf="profile">
        <div class="graduate-year" [class.blue]="profile[Constant.apGraduateYearTypeName]" [class.disabled]="form3.value[Constant.apGraduateYearSkipFlag]==Constant.prfFlagOn">
          {{ profile[Constant.apGraduateYearTypeName] }}
        </div>
      </ng-container>
      <li class="top">
        しごと探しに動く予定をご登録ください。※半角数字<br>
        <span class="font-small">期間を登録すると企業からのスカウトが届きやすくなります。</span>
      </li>
      <div class="input-period">
        <div class="input-period-short">
          <mat-form-field class="input-short"
            hideRequiredMarker="true">
            <mat-label>西暦</mat-label>
            <input matInput
              [formControlName]="Constant.tfStartYear"
              min="{{ Constant.fvMinYear }}"
              type="tel"
              (keyup)="onNextFocus(4, $event.target.value, startmonth)"
              maxlength="4" /> </mat-form-field>
              <span class="date">年</span>
          <mat-form-field class="input-short-s" hideRequiredMarker="true">
            <mat-label></mat-label>
            <input matInput
              [formControlName]="Constant.tfStartMonth"
              min="{{ Constant.fvMinMonth }}"
              max="{{ Constant.fvMaxMonth }}"
              type="tel"
              #startmonth
              (keyup)="onNextFocus(2, $event.target.value, endyear)"
              maxlength="2" /> </mat-form-field>
              <span class="date">月 〜</span>
          <mat-form-field class="input-short"
            hideRequiredMarker="true">
            <mat-label>西暦</mat-label>
            <input matInput
              [formControlName]="Constant.tfEndYear"
              min="{{ Constant.fvMinYear }}"
              type="tel"
              #endyear
              (keyup)="onNextFocus(4, $event.target.value, endmonth)"
              maxlength="4" /> </mat-form-field>
              <span class="date">年</span>
          <mat-form-field class="input-short-s" hideRequiredMarker="true">
            <mat-label></mat-label>
            <input matInput
              [formControlName]="Constant.tfEndMonth"
              min="{{ Constant.fvMinMonth }}"
              max="{{ Constant.fvMaxMonth }}"
              type="tel"
              #endmonth
              maxlength="2" /> </mat-form-field>
              <span class="date">月</span>
        </div>
        <div class="active-undecided">
          <mat-checkbox labelPosition="before" [formControlName]="Constant.apActiveUndecidedFlag"
            (change)="onChangeUndecided()">未定の場合はこちらにチェック</mat-checkbox>
        </div>
      </div>
      <mat-error *ngIf="errorMessageTerm" class="term">{{ errorMessageTerm }}</mat-error>
    </ol>
  </form>
</div>
<mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
<div class="button-container">
  <button *ngIf="page == 1 && form1"
    (click)="onNext()"
    mat-raised-button
    type=button
    color="accent"
    [disabled]="form1.invalid || !isPageChanged">
    登録して次へ
  </button>
  <button *ngIf="page == 2 && form2"
    (click)="onNext()"
    mat-raised-button
    type=button
    color="accent"
    [disabled]="form2.invalid || !isPageChanged || isSexIdValid()">
    登録して次へ
  </button>
  <button *ngIf="page == 3 && form3"
    (click)="onNext()"
    mat-raised-button
    type=button
    color="accent"
    [disabled]="
      form3.invalid ||
      selectedDesirePlaceName.length == 0 ||
      isDesireJobValid() ||
      isActivePlanValid() ||
      desireEmploymentTypeValidation() ||
      isGraduateValid() ||
      !isPageChanged
    ">
    登録
  </button>
</div>
