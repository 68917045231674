import { Component, HostListener } from '@angular/core';
import { CommonCompanyService } from '../shared/common-company.service';
import { CommonService } from '../shared/common.service';
import { Constant } from '../constant';
import { QuillService } from '../shared/quill.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { TsunagaruComponent } from './../common-component/tsunagaru/tsunagaru.component';

@Component({
  selector: 'app-company-about',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TsunagaruComponent
  ],
  templateUrl: './company-about.component.html',
  styleUrl: './company-about.component.css'
})
export class CompanyAboutComponent {
  public readonly Constant = Constant;
  public showSpinner: boolean;
  public companyInfo;
  public aboutText;
  public loadFlg = false;
  public tsunagaruData
  private companyCode
  private isLogin = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonCompany: CommonCompanyService,
    private common: CommonService,
    private quillService: QuillService,
    private auth: AuthService,
  ) {
    this.companyCode = this.activatedRoute.snapshot.params['code'];
  }

  ngOnInit(): void {
    //　タイトル設定
    this.common.setTitle(Constant.tlCompanyAbout);
    this.commonCompany.setCompanyHeaderPage(Constant.rpCompanyAbout);

    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.showSpinner = true;
    this.getCompanyInfo();
    window['onToc'] = this.onToc;

    // ログインチェック
    this.auth.isAuthenticated().subscribe(result => {
      if (result) {
        this.isLogin = true;
      }
    });
  };

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログインチェック
    this.commonCompany.onFocusCheckLogin(this.isLogin).subscribe(res => {
      this.isLogin = res;
      if (this.tsunagaruData) {
        this.tsunagaruData.isLogin = this.isLogin
      }
    });
  }

  // 企業情報を取得
  private getCompanyInfo() {
    this.common.preCheck().then(res => {
      this.commonCompany.getCompanyInfo(this.companyCode).then(data => {
        if (!data) {
          this.showSpinner = false;
          this.common.showToastMessage(Constant.msgErrorNetwork);
          return;
        }
        this.companyInfo = data;

        // メインカラー
        this.commonCompany.setCompanyMainColor(this.companyInfo.main_color)

        // ヘッダ用データ
        this.commonCompany.setCompanyHeaderData(this.companyCode, data, Constant.rpCompanyAbout);

        // つながる用データ
        this.tsunagaruData = {
          companyCode: this.companyCode,
          name: this.companyInfo.name,
          display_name: this.companyInfo.display_name,
          isLogin: this.isLogin,
        }

        // SEOメタタグセット
        this.setSeoMeta(data.name, data.summary);

        this.showSpinner = false;
      })
      .catch (err => {
        this.showSpinner = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      });
    })
    .catch();
  }

  // SEOのメタタグ設定
  private setSeoMeta(companyName, summary) {
    const title = companyName + Constant.textTsunagaru
    let description = companyName + Constant.metaDescCompany
    if (summary) {
      description = summary
    }
    this.commonCompany.setSeoMeta(title, description)
  }

  /* エディタ関連 */
  onEditorCreated(quill) {
    // 画像とOGPの差し替え
    this.quillService.checkReloadQuill(quill);
  }

  onToc(index) {
    const header = document.getElementById('tocheader-' + index)
    let offset = -95
    if (window.innerWidth <= Constant.mobileWidth) {
      offset = -60
    }
    window.scroll({
      top: header.offsetParent['offsetTop'] + header.offsetTop + offset,
      behavior: 'smooth'
    })
  }

}

