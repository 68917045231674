<div class="spinner" *ngIf="showSpinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div *ngIf="companyName && registPool && questions?.length>0">
  <div class="header-description">
    特別な質問が届きました！回答後も更新できます。
  </div>
  <div class="content" *ngIf="form">
    <form [formGroup]="form">
      <ng-container *ngFor="let q of questions">
        <div class="label">
          <div class="title" [innerHtml]="q.question | linky: Constant.linkyOption"></div>
          <div class="select-type" *ngIf="q.type == Constant.aqAnswerTypeOneSelectId">※単一選択</div>
          <div class="select-type" *ngIf="q.type == Constant.aqAnswerTypeMultiSelectId">※複数選択</div>
        </div>
        <div class="input">
          <!-- テキスト入力 -->
          <mat-form-field class="no-placeholder" *ngIf="q.type == Constant.aqAnswerTypeTextId">
            <textarea matInput
              formControlName="answer{{ q.question_id }}"
              id="answer{{ q.question_id }}"
              #inputtext
              rows="1"
              (input)="onInput('answer'+q.question_id, inputtext.value, $event.target)"></textarea>
            <mat-hint align="end">
              <span class="length-hint" [class.red]="countInputText(inputtext.value) > Constant.fvMaxQuestionText">
                {{ countInputText(inputtext.value) }} / {{ Constant.fvMaxQuestionText }}
              </span>
            </mat-hint>
          </mat-form-field>

          <!-- 単一選択 -->
          <mat-radio-group formControlName="answer{{ q.question_id }}" *ngIf="q.type == Constant.aqAnswerTypeOneSelectId">
            <div class="radio-item" *ngFor="let item of q.select_item">
              <mat-radio-button class="" [value]="item.item_id">
                {{ item.item }}
              </mat-radio-button>
            </div>
          </mat-radio-group>

          <!-- 複数選択 -->
          <ng-container *ngIf="q.type == Constant.aqAnswerTypeMultiSelectId">
            <mat-checkbox *ngFor="let item of q.select_item" class="check-item"
              formControlName="answer{{ q.question_id }}_{{ item.item_id }}">
              {{ item.item }}
            </mat-checkbox>
          </ng-container>
        </div>
        <div class="skip-area">
          <mat-checkbox labelPosition="before" formControlName="skip{{ q.question_id }}"
          (change)="onSkip(q.question_id, $event.checked, q.type)">回答をスキップする</mat-checkbox>
        </div>
      </ng-container>

      <div *ngIf="dataSending" class="sending-spinner-row">
        <div class="spinner-half"></div>
        <div class="spinner-half spinner">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </div>
      <div class="button-container">
        <button type="button"
          (click)="onCancel()"
          mat-raised-button
          class="half"
          [disabled]="dataSending">
          キャンセル
        </button>
        <button (click)="onRegist()"
          mat-raised-button
          class="half"
          color="accent"
          [disabled]="
            form.invalid ||
            dataSending
          ">
          更新
        </button>
      </div>

    </form>
  </div>
</div>
<div *ngIf="registPool && companyName && questions?.length==0" class="unregist-view">
  質問はありません。
</div>
<div *ngIf="!registPool && companyName" class="unregist-view">
  質問を表示するには、{{ companyName }}とつながる必要があります。
</div>
