<div class="header-description">
  メッセージの送受信や確認は、つながっている企業とのみ可能です。
</div>
<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div class="content">
  <div class="no-data" *ngIf="messages && messages.length == 0">
    メッセージはありません。
    <br />
    メッセージをやりとりするには、まずは企業とつながりましょう！
  </div>
  <mat-divider *ngIf="messages && messages.length > 0"></mat-divider>
  <table class="message-table" *ngIf="messages">
    <ng-container *ngFor="let msg of messages">
      <tr>
        <td (click)="onSelect(msg.company_code)" class="companybutton">
          <div class="top">
            <div class="name">{{ msg.company_name }}</div>
          </div>
          <div class="middle">
            <div class="icon" *ngIf="msg.type_id != Constant.msgTypeIdNo">
              <div *ngIf="msg.type_id == Constant.msgTypeIdTalent; else msgReceive" class="sended">
                送信済
              </div>
              <ng-template #msgReceive>
                <div *ngIf="msg.readed_flag == Constant.msgUnReaded" class="unread">未読</div>
                <div
                  *ngIf="msg.readed_flag != Constant.msgUnReaded && msg.readed_flag != Constant.msgTypeIdNo"
                  class="readed"
                >
                  既読
                </div>
              </ng-template>
            </div>
            <div class="date" *ngIf="msg.type_id != Constant.msgTypeIdNo">{{ showDate(msg.created_at) }}</div>
          </div>
          <div class="no-message" *ngIf="msg.type_id == Constant.msgTypeIdNo">
            メッセージはまだありません。
          </div>
          <div class="bottom">{{ showCutMsg(msg.message, 55) }}</div>
        </td>
      </tr>
    </ng-container>
  </table>
  <div class="spacer"></div>
</div>

<app-profile-message-buttons></app-profile-message-buttons>
