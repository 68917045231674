<app-lp-header page="startguide"></app-lp-header>
<div class="wrap" *ngIf="!loading">
  <img src="/assets/lp/txt_startguide.png" class="title-img">
  <h2>はじめての方へ</h2>
  <div class="contents">
    <div class="title-area">
      <img src="/assets/lp/txt_uniqueness_color.png" height="50">
      <div class="text">CaLinの特徴</div>
    </div>
    <div class="flex">
      <div class="image">
        就活や転職活動スタート前から<br>気になる企業と「つながる」
        <picture>
          <img lazyLoad="/assets/lp/talent01.png" width="180" />
        </picture>
      </div>
      <div class="unique-text">
        「つながる」は企業へのはじめての挨拶。<br>
        エントリーや応募のような重みはないから、就活や転職活動にまだ本腰を入れていなくてもボタン一つで気軽にどうぞ。<br>
        ESや経歴書が無くても、志望理由がまだ思いつかなくても、面接準備がゼロでも問題なし！<br>
        企業も、まずはつながることを楽しみにしていますので、積極的に気になる企業とつながりましょう！
      </div>
    </div>
    <div class="flex">
      <div class="image">気になる企業の情報収集や<br>業界研究に役立つ情報が満載
        <picture>
          <img lazyLoad="/assets/lp/talent02.png" width="70" />
        </picture></div>
      <div class="unique-text">
        会社の雰囲気、会社紹介のスライド資料、求人情報やインターン情報など、企業理解に役立つ情報がたくさん！<br>
        つながった企業からは、特別なスカウトやカジュアル面談のお誘い、選考スタートの案内が届くことも。<br>
        <br>
        就活や転職活動が本格スタートする前に好きな企業を見つけておいて、会社選びをスムーズに進めよう！
      </div>
    </div>
    <div class="flex">
      <div class="image">つながった企業だけと情報交換<br>知らない企業からのスカウト0
        <picture>
          <img lazyLoad="/assets/lp/talent03.png" width="160" />
        </picture>
      </div>
      <div class="unique-text">
        自分のプロフィールを閲覧できるのは、つながっている企業だけ。<br>
        知らない企業にプロフィールが公開されないから安心。<br>
        つながっていない企業からはスカウトなども無し。だから情報に溺れず自分のペースで好きな企業とだけじっくり向き合えます。<br>
        <br>
        もし、つながってみてちょっと違うなと思ったら、企業とのつながりは自由に解消OK！
      </div>
    </div>
    <div class="flex">
      <div class="image">
        つながりは望む限りずっと続く<br>企業との縁はキャリアの財産に
        <picture>
          <img lazyLoad="/assets/lp/talent04.png" width="160" />
        </picture>
      </div>
      <div class="unique-text">
        就活や転職活動は多くの企業と知り合うことができるチャンスです。<br>
        CaLinでは、この時に育んだつながりを、就活や転職活動が終わった後も、ずっと継続することができます。<br>
        <br>
        将来転職するときの候補として、もしも会社選びに失敗したときの保険として・・・CaLinでのつながりはキャリア形成の財産になるでしょう。
      </div>
    </div>
    <div class="title-area faq">
      <img src="/assets/lp/txt_Questions_color.png" height="50">
      <div class="text">よくある質問</div>
    </div>
    <div class="faq">
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>就活の知識ゼロの大学1年生ですが、利用できますか？</div>
        </div>
        <div class="answer">
          ぜひご利用ください！<br>
          まずは「働くってこんな感じかぁ」というレベルの情報収集でOKです。
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>社会人ですが利用できますか？</div>
        </div>
        <div class="answer">
          もちろん、ご利用できます！中途正社員、契約社員、パート/アルバイト、業務委託など、どなたでもご利用いただけます。<br>
          実際、就活中につながった方が社会人になってもそのままつながり続けてますし、30代以上の方も新規でたくさんご登録いただいています。
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>料金はかかりますか？</div>
        </div>
        <div class="answer">
          サービスの利用は完全無料です。
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>プロフィールの登録は面倒ですか？</div>
        </div>
        <div class="answer">
          スマートフォンから簡単に登録できるよう、選択して登録できる項目を多くしています。<br>
          一部、学歴や職歴などでテキスト入力が必要な部分もありますが、ポイントとなる事柄だけを簡潔に登録できるよう入力文字数を制限しています。
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>新規アカウント登録の「認証コード」のメールが届かないのですが？</div>
        </div>
        <div class="answer">
          Yahooメール、Gmailなどをご利用の場合、メールが誤って迷惑メールフォルダに振り分けられる可能性がありますので、ご確認ください。<br>
          また、携帯電話のメールアドレスで受信できない場合、calin.co.jpのドメインを指定受信先に登録いただくことが必要です。<br>
          しばらくしても、メールが届かない場合、ご入力いただいたメールアドレスに間違いがあった可能性があります。お手数ですが再度、 新規アカウント登録を行ってください。
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>つながりの解消は簡単にできますか？</div>
        </div>
        <div class="answer">
          つながり管理メニューからボタン1つで自由に解消できます。<br>
          つながりを解消すると、メッセージの送受信ができなくなり、企業に公開していたプロフィールが非公開になります。
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="item">
        <div class="title">
          <div>Q.</div>
          <div>退会したいのですが？</div>
        </div>
        <div class="answer">
          アカウントメニューから自由に退会できます。ご安心ください。
        </div>
      </div>
    </div>
  </div>
</div>
<app-lp-footer></app-lp-footer>
