import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmnPdfViewerComponent } from './../../common-component/cmn-pdf-viewer/cmn-pdf-viewer.component'

@Component({
  selector: 'app-pdfviewer-dialog',
  templateUrl: './pdfviewer-dialog.component.html',
  styleUrls: ['./pdfviewer-dialog.component.css']
})
export class PdfviewerDialogComponent implements OnInit {
  public pdfParam;
  @ViewChild('cmnViewer')
  private cmnViewer: CmnPdfViewerComponent;

  constructor(
    public dialogRef: MatDialogRef<PdfviewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.pdfParam = this.data

    this.dialogRef.backdropClick().subscribe(_ => {
      this.close()
    })
  }

  // 閉じる
  public close() {
    const page = {
      real: this.cmnViewer.pdfRealPage,
      show: this.cmnViewer.pdfShowPage,
    }
    this.dialogRef.close(page);
  }
}
