import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  BehaviorSubject ,  from as fromPromise ,  of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { Constant } from './../constant';
import { CommonService } from './../shared/common.service';

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  public error;
  public companyName: string;
  public alreadyRegistFlg = false;

  constructor(private router: Router, private common: CommonService) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** ログイン状態の取得 */
  public isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser({bypassCache: true})).pipe(
      map(result => {
        if (result.signInUserSession) {
          // refreshToken保存
          if (!this.common.refershToken && result.signInUserSession.refreshToken.token) {
            this.common.refershToken = result.signInUserSession.refreshToken.token;
          }
          this.loggedIn.next(true);
          return true;
        } else {
          this.loggedIn.next(false);
          return false;
        }
      }),
      catchError(error => {
        this.common.debug().log(error);
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }

  /** サインアップ */
  public signUp(email, password): Observable<any> {
    return fromPromise(Auth.signUp(email, password, email));
  }

  /** 検証 */
  public confirmSignUp(email, code): Observable<any> {
    return fromPromise(Auth.confirmSignUp(email, code));
  }

  /** ログイン */
  public signIn(email, password): Observable<any> {
    return fromPromise(Auth.signIn(email, password)).pipe(
      map(result => {
        this.common.debug().log(result);
        if (result.signInUserSession) {
          this.loggedIn.next(true);
          return true;
        } else {
          this.loggedIn.next(false);
          return of(false);
        }
      }),
      catchError(error => {
        this.common.debug().log(error);
        this.error = error;
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }

  /** ログアウト */
  public signOut() {
    fromPromise(Auth.signOut()).subscribe(
      result => {
        this.loggedIn.next(false);
        this.common.refershToken = '';
        this.toLoginPage();
      },
      error => this.common.debug().log(error)
    );
  }

  /** パスワード忘れ */
  public forgotPassword(email): Observable<any> {
    return fromPromise(Auth.forgotPassword(email));
  }

  public forgotPasswordSubmit(email, code, password): Observable<any> {
    return fromPromise(Auth.forgotPasswordSubmit(email, code, password));
  }

  /* 認証コード再発行 */
  public resendSignUp(email): Observable<any> {
    return fromPromise(Auth.resendSignUp(email));
  }

  // ログインページへ遷移
  public toLoginPage(): void {
    this.router.navigate([Constant.rpLogin]);
  }

  // ログイン済みならマイページへ遷移
  public checkAlreadyLogin() {
    this.isAuthenticated().subscribe(result => {
      if (result) {
        this.common.preCheck().then(res => {
          this.common.showToastMessage(Constant.msgAlreadyLogined);
          this.router.navigate([Constant.rpMypage]);
        })
        .catch(err => {});
      }
    });
  }

  // ログイン済なら誘導ページへ遷移
  public checkLoginToPage(companycode): void {
    this.isAuthenticated().subscribe(result => {
      if (result) {
        // ログイン済み
        this.common.preCheck().then(res => {
          // 誘導ページへ遷移
          this.router.navigate([Constant.rpLogin, companycode]);
        })
        .catch(err => {});
      }
    });
  }
}
