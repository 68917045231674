<footer>
  <a class="logo" routerLink="/" (click)="onTop()">
    <img src="/assets/common/logo.png" width="100">
  </a>
  <div class="flex menu">
    <a class="col" routerLink="/" (click)="onTop()">トップ</a>
    <a class="col" routerLink="/{{ Constant.rpLpStartguide }}" >はじめての方へ</a>
    <a class="col" routerLink="/{{ Constant.rpLpPage }}/1" >企業を探す</a>
    <a class="col" routerLink="/{{ Constant.rpLpColumn }}" >就活準備コラム</a>
    <a class="col" href="{{Constant.footerUrlAgreement}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtAgreement)">利用規約 <img src="../../assets/lp/launch_green.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlPrivacy}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtPrivacy)">プライバシーポリシー <img src="../../assets/lp/launch_green.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlCalin}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtCalin)">運営企業 <img src="../../assets/lp/launch_green.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlContact}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtContact)">お問い合わせ <img src="../../assets/lp/launch_green.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlCompanyLp}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtCompanyLP)">採用企業の方はこちら  <img src="../../assets/lp/launch_green.png" width="13" height="13"></a>
  </div>
  <div class="copyright">©CaLin, Inc.</div>
</footer>
