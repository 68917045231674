import { Component, OnInit } from '@angular/core';
import { Constant } from './../constant';
import { Router } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { environment } from './../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { TsunagaruDialogComponent } from '../dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ColumnService } from '../shared/column.service';

@Component({
  selector: 'app-lp',
  templateUrl: './lp.component.html',
  styleUrls: ['./lp.component.css']
})
export class LpComponent implements OnInit {
  public readonly Constant = Constant;
  public companyInfoList = []; // 企業一覧
  public columnList = []; // 就活準備コラム一覧
  public columnImgUrl = environment.COLUMN_IMG_URL;
  public showSpinner = false;
  public loading = true;
  private apiPath = '/company/';

  constructor(
    private router: Router,
    private common: CommonService,
    private columnService: ColumnService,
    private title: Title,
    private meta: Meta,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.setSeoMeta();

    // 企業一覧取得
    this.getCompanyInfo();

    // コラム一覧取得
    this.getColumnList();

    localStorage.removeItem(Constant.lsLoginCompFlg);

    setTimeout(() => {
      this.loading = false;
    });
  }

  // テキスト省略
  ellipsisText(text: string, maxLen: number) {
    text = this.columnService.ellipsisText(text, maxLen);
    return text;
  }

  // 検索コンポーネントより検索ボタン押下
  searchCompany(cond) {
    // GA
    this.onClickGAEvent(Constant.gaActionTopSearch);

    localStorage.setItem(Constant.lsLpSearchCondition, JSON.stringify(cond));
    this.router.navigate([Constant.rpLpPage, 1]);
  }

  // 企業一覧取得
  private getCompanyInfo() {
    this.showSpinner = true;
    const apiPath = this.apiPath + 'all';
    const body = {
      from_no: 0,
      desire_place: [],
      company_industry: [],
    };

    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.common.apiPostBeforeLogin(apiPath, body).then(res => {
      this.companyInfoList = res.data.list.slice(0, 3);
      this.showSpinner = false;
      this.getImageData(this.companyInfoList);
    })
    .catch(err => {
      this.showSpinner = false;
      if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
        this.common.debug().log(err);
      }
    });
  }

  // コラム取得
  private getColumnList() {
    this.columnService.getColumnList().then(res => {
      this.columnList = res.slice(0, 6);
    })
    .catch(err => { });
  }

  // 企業ロゴと企業情報の画像を取得する
  private getImageData(currentData) {
    for (let i = 0; i < currentData.length; i++) {
      const companyCode = currentData[i].code;
      // コンテンツの画像を取得する
      const contentsCode = currentData[i].code;
      const urlApiPath = this.apiPath + 'info/' + companyCode + '/imageurl';
      this.common
        .apiGetBeforeLogin(urlApiPath)
        .then(res => {
          const data = res.data;
          if (data.url === '' || data.url === null) {
            currentData[i].pr_image_url = '';
          } else {
            currentData[i].pr_image_url = data.url;
          }
        })
        .catch(err => {
          this.common.debug().log(err);
        });

      // 企業のロゴ画像を取得する
      const logoUrlApiPath = this.apiPath + companyCode + '/logourl';
      this.common
        .apiGetBeforeLogin(logoUrlApiPath)
        .then(res => {
          const data = res.data;
          if (data.url === '' || data.url === null) {
            currentData[i].logo_url = '';
          } else {
            currentData[i].logo_url = data.url;
          }
        })
        .catch(err => {
          if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
            this.common.debug().log(err);
          }
        });
    }
  }

  // GA
  onClickGAEvent(action) {
    this.common.sendGAEvent(Constant.gaCategoryButtons, action);
  }

  // HTMLリンクの場合に、同じURLだと変化しないので、ページトップへ飛ばす
  onPageTop() {
    window.scrollTo(0, 0);
  }

  // つながるとは　ダイアログ表示
  onWhatIsTalentPool() {
    // GA
    this.onClickGAEvent(Constant.gaActionTopWhat);

    const dialogRef = this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
    });
  }

  // SEOのメタタグ設定
  private setSeoMeta() {
    this.title.setTitle(Constant.metaTitleLP);
    this.meta.updateTag({
      name: 'description',
      content: Constant.metaDesc
    });
  }
}
