import { Component, OnInit } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  constructor(private common: CommonService, private router: Router) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlNotFound);
  }

  onLp() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpLp]);
    })
    .catch(err => {});
  }

  onMypage() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpMypage]);
    })
    .catch(err => {});
  }
}
