import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from '../constant';
import { CommonService } from './../shared/common.service';

@Component({
  selector: 'app-profile-edit-buttons',
  templateUrl: './profile-edit-buttons.component.html',
  styleUrls: ['./profile-edit-buttons.component.css']
})
export class ProfileEditButtonsComponent implements OnInit {
  public readonly Constant = Constant;
  public type;

  constructor(private router: Router, private common: CommonService) {}

  ngOnInit() {
    this.type = this.common.profileMenu;
  }

  /* テンプレート起動 */
  onDesire() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpDesire]);
      })
      .catch(err => {});
  }

  onCareer() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpCareer]);
      })
      .catch(err => {});
  }

  onEducation() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpEducation]);
      })
      .catch(err => {});
  }

  onBasic() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpBasic]);
      })
      .catch(err => {});
  }

  onFile() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpAttachment]);
      })
      .catch(err => {});
  }
}
