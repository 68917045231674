<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<div class="yet-regist">
  会員登録はまだ完了しておりません。<br />
  メールアドレスに送信された認証コード（新規登録用）を入力してください。
</div>
<form [formGroup]="form" class="form">
  <div class="form-field">
    <mat-form-field floatLabel="always" class="input-area">
      <mat-label>メールアドレス</mat-label>
      <input matInput formControlName="email" name="email" required type="email">
      <mat-error *ngIf="email.hasError('required') || email.hasError('email')">メールアドレスは正しい形式で入力してください。
      </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" class="input-area">
      <mat-label>認証コード</mat-label>
      <input matInput formControlName="code" name="code" required>
      <mat-error *ngIf="code.invalid && code.dirty">認証コードを入力してください。
      </mat-error>
    </mat-form-field>

    <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
    <mat-error *ngIf="expired">入力された認証コード（新規登録用）は無効です。
      <br />有効期限（発行後24時間）が切れた場合は再発行してください。
      <br />
      <br />
      <a (click)="onResend()">認証コード（新規登録用）再発行</a>
    </mat-error>

    <div class="button-container">
      <button (click)="onRegist()" type="submit" mat-raised-button color="accent" [disabled]="!form.valid || showSpinner">登録</button>
    </div>
  </div>
</form>

<div class="sub-header red-text">メールが届かない場合</div>

<div class="description">
  Yahooメール、Gmailなどをご利用の場合、メールが誤って<span class="red-text">迷惑メールフォルダ</span>に振り分けられる可能性がありますのでご注意ください。
  <br /> また、携帯電話のメールアドレスで受信できない場合、calin.co.jpのドメインを指定受信先に登録いただくことが必要です。
  <br /> しばらくしても、メールが届かない場合、ご入力いただいたメールアドレスに間違いがあった可能性があります。お手数ですが再度、
  <a (click)="onSignUp()">新規アカウント登録</a>を行ってください。
</div>

<div class="description bottom">認証コード（新規登録用）を無くしてしまった場合は、
  <a (click)="onResend()">認証コード（新規登録用）の再発行</a>を行ってください。
</div>
