<div *ngIf="showSpinner" class="spinner blue">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div *ngIf="companyInfo">
  <img *ngIf="companyInfo.about_main_image_url" class="image" src="{{ companyInfo.about_main_image_url }}"/>
  <div class="value pre-line quill-area">
    <quill-view [content]="companyInfo.about_text"
      preserveWhitespace="true"
      style ="border-width: 0;"
      (onEditorCreated)="onEditorCreated($event)">
    </quill-view>
  </div>

  <app-tsunagaru [data]="tsunagaruData" *ngIf="tsunagaruData"></app-tsunagaru>

  <app-company-footer [companyInfo]="companyInfo"></app-company-footer>
</div>

