import { Injectable } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Subject } from 'rxjs';
import { Constant } from './../constant';

@Injectable()
export class ContactService {
  private apiPath = '/contact';

  // データ受け渡し用
  private sharedInfo = new Subject<any>();
  public sharedInfo$ = this.sharedInfo.asObservable();
  private sharedHistory = new Subject<any>();
  public sharedHistory$ = this.sharedHistory.asObservable();

  constructor(private common: CommonService) {}

  // 接点情報取得
  getInfo(company, tag) {
    const apiPath = this.apiPath + '/' + company + '/' + tag + '/info';
    this.common
      .apiGetBeforeLogin(apiPath)
      .then(res => {
        this.sharedInfo.next(res);
      })
      .catch(err => {
        this.sharedInfo.next(err);
      });
  }

  // 接点履歴取得
  getHistory(company, tag) {
    const apiPath = this.apiPath + '/' + company + '/' + tag;
    this.common
      .apiGet(apiPath)
      .then(res => {
        this.sharedHistory.next(true);
      })
      .catch(err => {
        if (err.status === Constant.NG) {
          this.sharedHistory.next(false);
        } else {
          this.sharedHistory.next(err);
        }
      });
  }

  // 追加
  addTag(company, tag): Promise<any> {
    const apiPath = this.apiPath + '/' + company;
    const data = {
      tag: tag
    };
    return this.common.apiPost(apiPath, data);
  }
}
