<app-profile-edit-buttons></app-profile-edit-buttons>
<div mat-icon-button class="data-update-button" (click)="onMovePageTop()">
  <img src="assets/common/data_update.svg" />
</div>

<h3 class="subheader">{{ Constant.tlFile }}</h3>
<div class="right-button">
  <button
    mat-flat-button
    (click)="onFileClick()"
    class="add-btn"
    [disabled] = "files.length >= 3 || loading || uploading || deleting">添付</button>
  <input hidden type="file" #fileInput [accept]="Constant.fileTypeAttached" (change)="onChangeFile($event)"/>
</div>

<div class="no-data" *ngIf="!loading && files.length == 0 && !errFlg">
  添付したファイルがありません。<br />ファイルを添付するとスカウトの可能性が高まります！
</div>
<div class="content">
  <div *ngIf="loading" class="spinner">
    <mat-spinner diameter="60"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <div class="file-list">
      <div class="file-area"  *ngFor="let file of files">
        <div class="file-icon-area">
          <a *ngIf="!canDownload(file.file_name)" routerLink="/{{Constant.rpAttachment}}/{{file.id}}" target="_blank">
            <img class="file-icon" *ngIf="!deleting && !uploading" src="assets/common/file.svg" (click)="onUploadFileName()" />
          </a>
          <a *ngIf="canDownload(file.file_name)">
            <img class="file-icon" *ngIf="!deleting && !uploading" src="assets/common/file.svg" (click)="getFileUrl(file.id)" />
          </a>
          <img class="file-icon delete-flag" *ngIf="deleting || uploading" src="assets/common/file_gray.svg" />
        </div>
        <a *ngIf="!canDownload(file.file_name)" routerLink="/{{Constant.rpAttachment}}/{{file.id}}" target="_blank"
          class="file-text"
          [class.delete-flag]="deleting || uploading"
          (click)="onUploadFileName()">
          {{file.file_name}}
        </a>
        <a *ngIf="canDownload(file.file_name)"
          class="file-text"
          [class.delete-flag]="deleting || uploading"
          (click)="getFileUrl(file.id)">
          {{file.file_name}}
        </a>
        <div class="trash-icon-area">
          <mat-spinner
            *ngIf="deleting && deletingId  == file.id"
            diameter="20"
          ></mat-spinner>
          <img
            class="trash-icon"
            src="assets/common/trash.svg"
            (click)="onDelFile(file.id)"
            *ngIf="!deleting || deletingId  != file.id"
            [class.delete-flag]="deleting || uploading"
          />
        </div>
      </div>

      <div class="file-area uploading" *ngIf="uploading">
        <div class="file-icon-area">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        <div class="file-text uploading">
          ファイルをアップロードしています。
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="explanation">
      <p>履歴書、オープンES、職務経歴書、スキルシート、ポートフォリオなど、3つまで添付できます。</p>
      <p>添付できるファイルの拡張子は、pdf、jpg、png、ppt、pptx、xls、xlsx、doc、docxで、容量は5Mまでです。</p>
      <p>添付したファイルは、つながっている企業のみが閲覧できます。</p>
    </div>
  </div>
</div>

<div class="spacer"></div>
<app-profile-message-buttons></app-profile-message-buttons>

