import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Constant } from '../constant';

@Component({
  selector: 'app-lp-start',
  templateUrl: './lp-start.component.html',
  styleUrls: ['./lp-start.component.css']
})
export class LpStartComponent implements OnInit {
  public loading = true;

  constructor(
    private title: Title,
  ) { }

  ngOnInit(): void {
    // タイトル設定
    this.title.setTitle(Constant.metaTitleLP);
    setTimeout(() => {
      this.loading = false;
    });
  }

}
