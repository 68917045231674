import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../constant';

@Component({
  selector: 'app-password-forget',
  templateUrl: './password-forget.component.html',
  styleUrls: ['./password-forget.component.css']
})
export class PasswordForgetComponent implements OnInit {
  public readonly Constant = Constant;
  public form: FormGroup;
  public formChange: FormGroup;
  public mode: number;
  public errorMessage: string;
  public mailAddress: string;
  public expired = false;
  public mailSended = false;
  public isPasswordChanging = false;
  private companyCode;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private common: CommonService
  ) { }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlPassForget);

    // ログイン済み確認
    this.auth.checkAlreadyLogin();

    // モード
    const mode = this.activatedRoute.snapshot.data['mode'];
    if (mode) {
      this.common.setTitle(Constant.tlPassForgetConfirm);
      this.mode = mode;
      // パラメータになければローカルストレージより取得
      this.companyCode = localStorage.getItem(Constant.lsCompanyCode);
    } else {
      this.mode = Constant.pfModeSend;
    }

    // メールアドレス
    this.mailAddress = localStorage.getItem(Constant.lsMailAddress);

    // フォーム作成
    this.initForm();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログイン済み確認
    this.auth.checkAlreadyLogin();
  }

  /* テンプレート起動 */
  // 確認コードを送信
  onSend() {
    this.errorMessage = '';

    this.common
      .preCheck()
      .then(res => {
        this.mailSended = true;
        this.sendExec();
      })
      .catch(err => { });
  }

  // パスワード変更
  onChange() {
    this.errorMessage = '';
    this.expired = false;

    this.common
      .preCheck()
      .then(res => {
        this.isPasswordChanging = true;
        this.changeExec();
      })
      .catch(err => { });
  }

  // パスワード忘れ再手続き
  onPassAgain() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpPassForget]);
      })
      .catch(err => { });
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.formChange = new FormGroup({
      email2: new FormControl(this.mailAddress, [Validators.required, Validators.email]),
      code: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(Constant.fvPasswordMin)])
    });
  }

  // 確認コードを送信実行
  private sendExec() {
    this.auth.forgotPassword(this.email.value).subscribe(
      result => {
        this.common.debug().log(result);
        localStorage.setItem(Constant.lsMailAddress, this.email.value);
        this.router.navigate([Constant.rpPassForget + '/1']);
      },
      error => {
        this.common.debug().log(error);
        if (error.code === Constant.awsErrorUserNotFoundException) {
          this.errorMessage = Constant.msgErrorUserNotFound;
        } else if (error.code === Constant.awsErrorInvalidParameterException) {
          this.errorMessage = Constant.msgErrorPasswordReset;
        } else if (error.code === Constant.awsErrorLimitExceededException) {
            this.errorMessage = Constant.msgErrorLimitExceeded;
        } else {
          this.errorMessage = error.message;
        }
        this.mailSended = false;
      }
    );
  }

  // パスワード変更実行
  private changeExec() {
    this.auth.forgotPasswordSubmit(this.email2.value, this.code.value, this.password.value).subscribe(
      result => {
        this.common.debug().log(result);
        this.common.showCompleteDialog(Constant.msgPassChangeComplete).subscribe(res => {
          if (this.companyCode) {
            this.router.navigate([Constant.rpLoginForm, this.companyCode]);
          } else {
            this.router.navigate([Constant.rpLogin]);
          }
        });
      },
      error => {
        this.common.debug().log(error);
        if (error.code === Constant.awsErrorInvalidPasswordException) {
          this.errorMessage = Constant.msgErrorPasswordFormat;
        } else if (error.code === Constant.awsErrorCodeMismatchException) {
          this.errorMessage = Constant.msgErrorCodeMissmatchPassword;
        } else if (error.code === Constant.awsErrorExpiredCodeException || error.code === Constant.awsErrorUserNotFoundException) {
          this.expired = true;
        } else {
          this.errorMessage = error.message;
        }
        this.isPasswordChanging = false;
      }
    );
  }

  get email() {
    return this.form.get('email');
  }
  get email2() {
    return this.formChange.get('email2');
  }
  get code() {
    return this.formChange.get('code');
  }
  get password() {
    return this.formChange.get('password');
  }
}
