import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { TsunagaruDialogComponent } from '../dialog/tsunagaru-dialog/tsunagaru-dialog.component';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.css']
})
export class WalkthroughComponent implements OnInit {

  public readonly Constant = Constant;
  public page: number;

  constructor(
    private common: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.page = this.activatedRoute.snapshot.data['page'];
  }

  ngOnInit(): void {
    // タイトル設定
    this.common.setTitle(Constant.tlWalkThrough + this.page);
    this.common.bottomMenu = Constant.tlWalkThrough;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  // 「次へ」ボタンの処理
  onNext() {
    this.common
      .preCheck()
      .then(res => {
        if (this.page === 1) {
          this.router.navigate([Constant.rpWalkThrough + '2']);
        } else if (this.page === 2) {
          this.router.navigate([Constant.rpWalkThrough + '3']);
        } else if (this.page === 3) {
          this.router.navigate([Constant.rpWalkThrough + '4']);
        } else if (this.page === 4) {
          this.router.navigate([Constant.rpEducation]);
        }
      })
      .catch(err => {});
  }

  // 「戻る」ボタンの処理
  onBack() {
    this.common
      .preCheck()
      .then(res => {
        if (this.page === 2) {
          this.router.navigate([Constant.rpWalkThrough + '1']);
        } else if (this.page === 3) {
          this.router.navigate([Constant.rpWalkThrough + '2']);
        } else if (this.page === 4) {
          this.router.navigate([Constant.rpWalkThrough + '3']);
        }
      })
      .catch(err => {});
  }

  // タレントプール、つながるの説明
  onWhatIsTalentPool() {
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMypageWhatsTalentpool);
    this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
    });
  }

}
