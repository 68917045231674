<div class="cal-outer">
  <div class="cal-inner" id="cal-inner" #calElm>
    <div class="item"
    *ngFor="let item of data.list; index as i" [class.opacity]="i>sliderNo+2 || i<sliderNo">
      <a routerLink="/{{Constant.rpContentsCategory}}/{{data.companyCode}}/{{item.url}}" (click)="onTop()">
        <div class="title">{{item.title}}</div>
        <img class="image" [src]="item.image_url" appImageLoadingError />
      </a>
    </div>
  </div>
  <div class="cal-prev" (click)="prevSlide()" *ngIf="sliderNo>0">
    <!-- <img src="/assets/company/slide_left.svg"> -->
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="19" transform="rotate(-180 19 19)" fill="#7F7F7F" fill-opacity="0.6"/>
      <path d="M22.7031 14.41L18.1231 19L22.7031 23.59L21.2931 25L15.2931 19L21.2931 13L22.7031 14.41Z" fill="white"/>
    </svg>
  </div>
  <div class="cal-next" (click)="nextSlide()" *ngIf="sliderNo+3<data.list.length">
    <!-- <img src="/assets/company/slide_right.svg"> -->
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="19" fill="#7F7F7F" fill-opacity="0.6"/>
      <path d="M15.2969 23.59L19.8769 19L15.2969 14.41L16.7069 13L22.7069 19L16.7069 25L15.2969 23.59Z" fill="white"/>
    </svg>
  </div>
</div>
