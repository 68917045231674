import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../constant';

@Component({
  selector: 'app-resend-code',
  templateUrl: './resend-code.component.html',
  styleUrls: ['./resend-code.component.css']
})
export class ResendCodeComponent implements OnInit {
  public readonly Constant = Constant;
  public form: FormGroup;
  public errorMessage: string;
  public mailSended = false;

  constructor(private router: Router, private auth: AuthService, private common: CommonService) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlResendNew);

    // ログイン済み確認
    this.auth.checkAlreadyLogin();

    // フォーム作成
    this.initForm();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログイン済み確認
    this.auth.checkAlreadyLogin();
  }

  /* テンプレート起動 */
  onSend() {
    this.errorMessage = '';

    this.common
      .preCheck()
      .then(res => {
        this.mailSended = true;
        this.sendExec();
      })
      .catch(err => {});
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  private sendExec() {
    this.auth.resendSignUp(this.email.value).subscribe(
      result => {
        this.common.debug().log(result);
        this.router.navigate([Constant.rpConfirm]);
      },
      error => {
        this.common.debug().log(error);
        if (error.code === Constant.awsErrorUserNotFoundException) {
          this.errorMessage = Constant.msgErrorUserNotFound;
        } else if (error.code === Constant.awsErrorInvalidParameterException) {
          this.errorMessage = Constant.msgErrorUserAlreadyConfirm;
        } else if (error.code === Constant.awsErrorLimitExceededException) {
          this.errorMessage = Constant.msgErrorLimitExceeded;
        } else {
          this.errorMessage = error.message;
        }
        this.mailSended = false;
      }
    );
  }

  get email() {
    return this.form.get('email');
  }
}
