<app-profile-edit-buttons></app-profile-edit-buttons>
<div mat-icon-button class="data-update-button" (click)="onMovePageTop()">
  <img src="assets/common/data_update.svg" />
</div>
<h3 class="subheader">{{ Constant.tlBasic }}</h3>
<div class="right-button">
  <button mat-flat-button (click)="onEdit()" class="add-btn">編集</button>
</div>

<div class="content">
  <div *ngIf="loading" class="spinner">
    <mat-spinner diameter="60"></mat-spinner>
  </div>

  <table class="profile-table" *ngIf="profile">
    <tr>
      <td>
        <div class="label">名前</div>
        <div class="text name">{{ profile.last_name }} {{ profile.first_name }}</div>
        <div class="text">{{ profile.last_name_kana }} {{ profile.first_name_kana }}</div>
      </td>
    </tr>

    <tr>
      <td>
        <div class="label">性別</div>
        <div class="text">{{ profile.sex }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">生年月日</div>
        <div class="text">{{ profile.birthday }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">居住地</div>
        <div class="text">{{ profile.address1 }} {{ profile.address2 }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">電話番号</div>
        <div class="text">{{ profile.telno }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">メールアドレス</div>
        <div class="text">{{ profile.mail_address }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="label">プロフィール画像</div>
        <img *ngIf="imageUrl" [src]="imageUrl" class="profile-image" appImageLoadingError />
        <div *ngIf="imageUrl == ''" class="profile-no-image">
          <div class="no-image-text">No Image</div>
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="spacer"></div>

<app-profile-message-buttons></app-profile-message-buttons>
