import { Injectable } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Subject } from 'rxjs';
import { Constant } from './../constant';

@Injectable()
export class ProfileService {
  private apiPath = '/profile';
  private profile;
  private master;
  private imageUrl;

  // データ受け渡し用
  private sharedProfile = new Subject<any>();
  public sharedProfile$ = this.sharedProfile.asObservable();
  private sharedMaster = new Subject<any>();
  public sharedMaster$ = this.sharedMaster.asObservable();
  private sharedImage = new Subject<string>();
  public sharedImage$ = this.sharedImage.asObservable();

  constructor(private common: CommonService) {}

  // プロフィール取得
  getProfile(reset?: boolean) {
    if (!reset && this.profile) {
      this.sharedProfile.next(this.profile);
      return;
    }

    this.common
      .apiGet(this.apiPath)
      .then(res => {
        this.profile = res.data;
        this.sharedProfile.next(res.data);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.sharedProfile.next(Constant.apError);
      });
  }

  // プロフィールマスタ取得
  getMaster(reset?: boolean) {
    if (!reset && this.master) {
      this.sharedMaster.next(this.master);
      return;
    }

    const apiPath = this.apiPath + '/master';
    this.common
      .apiGetBeforeLogin(apiPath)
      .then(res => {
        this.master = res.data;
        this.sharedMaster.next(res.data);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.sharedMaster.next(Constant.apError);
      });
  }

  // プロフィール画像取得
  getImage(reset: boolean) {
    if (!reset && this.imageUrl) {
      this.sharedImage.next(this.imageUrl);
      return;
    }

    const apiPath = this.apiPath + '/imageurl';
    this.common
      .apiGet(apiPath)
      .then(res => {
        this.imageUrl = res.data.url;
        this.sharedImage.next(res.data.url);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.sharedImage.next(Constant.apError);
      });
  }

  // プロフィール更新
  postProfile(data): Promise<any> {
    return this.common.apiPost(this.apiPath, data);
  }
}
