import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Constant } from '../constant';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-lp-joboffer-list',
  templateUrl: './lp-joboffer-list.component.html',
  styleUrls: ['./lp-joboffer-list.component.css']
})
export class LpJobofferListComponent implements OnInit {
  public readonly Constant = Constant;
  public showSpinner = false;
  public allJobList = [];
  public config: PaginationInstance = {
    id: 'joblist',
    itemsPerPage: Constant.dataPerScroll,
    currentPage: 1,
    totalItems: 0
  };
  private fromno = 0;

  constructor(
    private router: Router,
    private common: CommonService,
    private activatedRoute: ActivatedRoute,

  ) {
    // ページング
    const page = this.activatedRoute.snapshot.params['page'];
    this.setPageNum(page);
  }

  ngOnInit(): void {
    this.getJobList();
  }

  /* view action */
  // ページングのボタンがクリックされた時の処理
  onPagingNum(page: number) {
    this.router.navigate([Constant.rpLpJobofferList, page]);
    this.calcFromno(page);
    this.getJobList();
  }

  /* API */
  // 求人一覧取得
  private getJobList() {
    const apiPath = '/joboffer/all/' + this.fromno;
    this.showSpinner = true;

    this.common.preCheck().then(response => {
      this.common.apiGetBeforeLogin(apiPath).then(res => {
        // 2ページ目以降でデータ無しの場合、1ページ目へ飛ばす
        if (this.config.currentPage > 1 && res.data.list.length === 0) {
          this.onPagingNum(1);
          return;
        }

        this.allJobList = res.data.list;
        this.config.totalItems = res.data.cnt;
        this.getImages(this.allJobList);
        this.showSpinner = false;
      })
      .catch(err => {
        this.common.debug().log(err);
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.showSpinner = false;
      });
    })
    .catch(err => {
      this.showSpinner = false;
    });
  }

  // 求人画像取得
  private getImages(currentData) {
    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i].image_path === Constant.empty) {
        continue;
      }
      const jobofferCode = currentData[i].joboffer_code;
      this.common.getJobOfferImage(jobofferCode)
      .then(url => {
        if (url !== '' && url !== Constant.apError) {
          currentData[i].image_url = url;
        } else {
          currentData[i].image_url = Constant.apError;
        }
      });
    }
  }

  /* private */
  // 取得開始番号計算
  private calcFromno(page: number) {
    this.config.currentPage = page;
    this.fromno = (page - 1) * this.config.itemsPerPage;
  }

  // URLからのページ数セット
  private setPageNum(page: string) {
    const pageNum = parseInt(page, 10);

    // ページ数が適切でない場合はトップに飛ばす
    if (Number.isNaN(pageNum) || pageNum < 1) {
      this.router.navigate([Constant.rpLpJobofferList, 1]);
      return;
    }

    this.calcFromno(pageNum);
  }
}
