<div *ngIf="isShowProgress" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div *ngIf="!emailVerified" class="content no-confirm-mail">
  <a (click)="onConfirmMail()">
    メールアドレスの変更が未認証です。 <br />
    認証コード（メールアドレス変更用）を入力してください。
  </a>
</div>
<div *ngIf="profile" class="main-container">
  <div class="name-area">
    <div class="name-text">
      こんにちは！{{ profile.last_name }}{{ profile.first_name }}さん
    </div>
  </div>
  <div *ngIf="isUnread" class="header-message">
    <button type="button" mat-raised-button (click)="onMessage()" class="info-button">
      <img src="assets/common/info_r.svg" appImageLoadingError />
      <span class="text">未読メッセージがあります</span>
    </button>
  </div>
  <div class="title-area">
    <div class="title">つながっている企業</div>
    <button mat-button (click)="onCompanyList()" class="search-button">つながる企業を探す</button>
  </div>
  <div *ngIf="loading" class="spinner">
    <mat-spinner diameter="60"></mat-spinner>
  </div>
  <div class="no-data" *ngIf="companys && companys.length == 0">
    どの企業ともつながっていません。 <br />
    企業とつながりましょう!
  </div>
  <mat-divider *ngIf="companys && !loading && companys.length > 0"></mat-divider>
  <table class="company-table" *ngIf="companys && !loading">
    <ng-container *ngFor="let company of companys">
      <tr>
        <td class="company-row">
          <div class="name"><a (click)="onCompanyInfo(company.code)">{{ company.name }}</a></div>
          <div class="date">登録日：{{ company.created_at }}</div>
          <div class="operation">
            <div class="btn link-btn" (click)="onMessageCompany(company.code)"><div class="unread" *ngIf="company.unread"><span>未読あり</span></div><span [class.top-margin]="company.unread">メッセージ</span></div>
            <div class="btn link-btn" *ngIf="company.add_question" (click)="onSpq(company.code)" >
              <div class="unread answer" *ngIf="company.unanswer"><span>未回答あり</span></div>
              <span [class.top-margin]="company.unanswer">企業から質問</span>
            </div>
            <div class="btn link-btn mobile" *ngIf="company.document_url" (click)="onPdfOverlay(company.document_url)" >会社資料</div>
            <div class="btn link-btn pc" *ngIf="company.document_url" (click)="onPdfDialog(company.document_url)" >会社資料</div>
            <div class="btn link-btn" (click)="onContentInfo(company.code)" [class.icon]="company.contents_new">
              <img src="/assets/common/new.png" class="new-icon" *ngIf="company.contents_new">
              コンテンツ
            </div>
            <div class="btn link-btn" (click)="onJobofferInfo(company.code)" [class.icon]="company.joboffer_new">
              <img src="/assets/common/new.png" class="new-icon" *ngIf="company.joboffer_new">
              求人情報
            </div>
            <div class="btn leave-btn" (click)="onDelete(company.code)">つながり<br>解消</div>
          </div>
        </td>
      </tr>
      <mat-divider></mat-divider>
    </ng-container>
  </table>
  <div class="spacer"></div>
</div>

<app-profile-message-buttons></app-profile-message-buttons>
