<div class="title-container"
  *ngIf="selectedWorkId; else addTitle">
  <h3 class="title">{{ Constant.tlCareerEdit }}</h3>
  <button mat-flat-button (click)="onDel()"
    class="del-btn" [disabled]="dataSending">
    削除
  </button>
</div>
<ng-template #addTitle>
  <h3 class="title">{{ Constant.tlCareerAdd }}</h3>
</ng-template>

<div class="content">
  <form [formGroup]="form" *ngIf="!loading">
    <div class="label">
      <span class="required">企業名</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="company_name"
          #companyName
          required
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'companyNameMaxLength', Constant.fvMaxLengthFifty)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="companyNameMaxLength">
            {{ countInputText(companyName.value) }} / {{ Constant.fvMaxLengthFifty }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="label">
      <span class="required">期間</span><span class="prefix">半角数字</span>
    </div>
    <div class="input">
      <div class="period">
        <mat-form-field class="input-short"
          hideRequiredMarker="true">
          <mat-label>西暦</mat-label>
          <input matInput
            [formControlName]="Constant.tfStartYear"
            min="{{ Constant.fvMinYear }}"
            type="tel"
            required
            (keyup)="onNextFocus(4, $event.target.value, startmonth)"
            (keydown.enter)="onKeyDown($event)"
            maxlength="4" /> </mat-form-field>
        <span class="date">年</span>
        <mat-form-field class="input-short-s" hideRequiredMarker="true">
          <mat-label></mat-label>
          <input matInput
            [formControlName]="Constant.tfStartMonth"
            min="{{ Constant.fvMinMonth }}"
            max="{{ Constant.fvMaxMonth }}"
            type="tel"
            required
            #startmonth
            (keyup)="onNextFocus(2, $event.target.value, endyear)"
            (keydown.enter)="onKeyDown($event)"
            maxlength="2" /> </mat-form-field>
        <span class="date">月 〜</span>
        <mat-form-field class="input-short"
          hideRequiredMarker="true">
          <mat-label>西暦</mat-label>
          <input matInput
            [formControlName]="Constant.tfEndYear"
            min="{{ Constant.fvMinYear }}"
            type="tel"
            #endyear
            (keyup)="onNextFocus(4, $event.target.value, endmonth)"
            (keydown.enter)="onKeyDown($event)"
            maxlength="4" /> </mat-form-field>
        <span class="date">年</span>
        <mat-form-field class="input-short-s" hideRequiredMarker="true">
          <mat-label></mat-label>
          <input matInput
            [formControlName]="Constant.tfEndMonth"
            min="{{ Constant.fvMinMonth }}"
            max="{{ Constant.fvMaxMonth }}"
            type="tel"
            #endmonth
            maxlength="2"
            (keydown.enter)="onKeyDown($event)" /> </mat-form-field>
        <span class="date">月</span>
      </div>
      <mat-error *ngIf="errorMessageTerm" class="term">{{ errorMessageTerm }}</mat-error>
      <div class="desc">※継続中の場合は、期末を入力しないでください</div>
    </div>

    <div class="label">
      <span>雇用形態</span>
    </div>
    <div class="input bottom">
      <mat-radio-group formControlName="employeement_type_id" class="radio-btn">
        <mat-radio-button *ngFor="let item of employeementMaster"
          [value]="item.id"
          (keydown.enter)="onKeyDown($event)">{{ item[Constant.apItemValue] }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="label">
      <span>年収</span>
      <span class="prefix">半角数字</span>
      <span class="string-count">({{ annual_income.value.length }}/5)</span>
    </div>
    <div class="input input-income">
      <mat-form-field class="income no-placeholder">
        <input matInput
          formControlName="annual_income"
          #annual_income
          type="tel"
          min="0"
          maxlength="5"
          (keydown.enter)="onKeyDown($event)" />
      </mat-form-field>
      <span class="unit">万円</span>
    </div>
    <div class="label">
      <span>役職</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="position"
          #position
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'positionMaxLength', Constant.fvMaxLengthFifty)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="positionMaxLength">
            {{ countInputText(position.value) }} / {{ Constant.fvMaxLengthFifty }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="label">
      <span>部署</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="department"
          #department
          cdkTextareaAutosize
          cdkAutosizeMaxRows="2"
          (keydown.enter)="onKeyDown($event)"
          (input)="onInput($event.target, 'departmentMaxLength', Constant.fvMaxLengthFifty)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="departmentMaxLength">
            {{ countInputText(department.value) }} / {{ Constant.fvMaxLengthFifty }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="label">
      <span class="required">主な職種</span>
    </div>
    <div class="input">
      <button type="button"
        (click)="onSelectJob()"
        mat-raised-button
        class="select-btn">選択する</button>
      <div class="job-content">
        <ng-container *ngFor="let job of selectedJobName">
          <div class="large">{{ job.large_name }}</div>
          <ul class="middle">
            <li *ngFor="let middle of job.middle_name">
              <span>{{ middle }}</span>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
    <div class="label">
      <span>主な活動</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="job_detail"
          #jobDetail
          cdkTextareaAutosize
          cdkAutosizeMaxRows="5"
          (input)="onInput($event.target, 'jobDetailMaxLength', Constant.fvMaxLengthTwoHundred)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="jobDetailMaxLength">
            {{ countInputText(jobDetail.value) }} / {{ Constant.fvMaxLengthTwoHundred }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="label">
      <span>活かしたスキル</span>
    </div>
    <div class="input">
      <mat-form-field class="no-placeholder">
        <textarea matInput
          formControlName="skill"
          #skill
          cdkTextareaAutosize
          cdkAutosizeMaxRows="5"
          (input)="onInput($event.target, 'skillMaxLength', Constant.fvMaxLengthTwoHundred)">
        </textarea>
        <mat-hint align="end">
          <span class="length-hint" [class.red]="skillMaxLength">
            {{ countInputText(skill.value) }} / {{ Constant.fvMaxLengthTwoHundred }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="dataSending" class="sending-spinner-row">
      <div class="spinner-half"></div>
      <div class="spinner-half spinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
    <div class="button-container">
      <button type="button"
        (click)="onCancel()"
        mat-raised-button
        class="half"
        [disabled]="dataSending">
        キャンセル
      </button>
      <button (click)="onRegist()"
        mat-raised-button
        type=button
        class="half"
        color="accent"
        [disabled]="form.invalid || selectedJob.length == 0 || dataSending">
        更新
      </button>
    </div>
  </form>
</div>
