import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';

@Component({
  selector: 'app-cmn-pdf-viewer',
  templateUrl: './cmn-pdf-viewer.component.html',
  styleUrls: ['./cmn-pdf-viewer.component.css']
})
export class CmnPdfViewerComponent implements OnInit {
  @Input() pdfParam;
  @Output() closePdf = new EventEmitter();
  public showSpinner = true;
  public documentUrl;  // 会社説明資料PDFのURL
  public pdfPage = 1;  // PDF表示ページ
  public pdfTotalPage = 0;  // PDF全ページ数
  public tate = false;

  constructor(private common: CommonService) { }

  ngOnInit(): void {
    this.documentUrl = this.pdfParam.url;

    // 30秒経過し読み込みできないなら閉じる
    setTimeout(() => {
      this.pdfError();
    }, Constant.apiTimeoutSecond);
  }

  // 閉じる
  public close() {
    this.closePdf.emit();
  }

  public pdfLoadComplete(pdfData) {
    this.pdfTotalPage = pdfData.numPages;

    // PDFの高さを合わせる
    setTimeout(() => {
      const canvas = document.querySelector('app-cmn-pdf-viewer .canvasWrapper');
      if (!this.tate && window.innerWidth > Constant.mobileWidth && canvas.clientHeight / canvas.clientWidth > 1) {
        // PC表示で縦長PDFなら再表示
        this.tate = true;
        return;
      }
      const viewer = document.querySelector('app-cmn-pdf-viewer .pdf-viewer') as HTMLElement;
      if (viewer && canvas) {
        viewer.style.height = canvas.clientHeight + 'px';
      }
      const navi = document.querySelector('.cdk-overlay-pane.pdf-viewer-dialog') as HTMLElement;
      if (navi && canvas) {
        navi.style.width = canvas.clientWidth + 'px';
      }
      this.showSpinner = false;
    }, 200);
  }
  public prevBtn() {
    this.pdfPage--;
  }
  public nextBtn() {
    this.pdfPage++;
  }
  public pdfError() {
    if (this.pdfTotalPage === 0) {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.close();
    }
  }
}
