<div class="header">
  <mat-toolbar class="header">
    <div class="left-container">
      <div class="header-logo">
        <a routerLink='/login/{{ companyCode }}' *ngIf="!auth.loggedIn.value" >
          <img src="assets/common/logo.png"
           alt="CaLin" class="logo" />
        </a>
        <img *ngIf="auth.loggedIn.value"
         src="assets/common/logo.png"
         alt="CaLin" class="logo" (click)="onCalinLogo()" />
      </div>
      <h1 class="header-text">
        はじめまして！<br>
        わたしの気になる会社
      </h1>
    </div>
  </mat-toolbar>
</div>

<div class="content-area">
  <div class="title-area">
    <span class="title">{{ title }}</span>
  </div>
<div class="spinner" *ngIf="showSpinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-container *ngIf="page == 0 && companyInfo">
  <div class="text">{{ companyInfo["name"] }}からの情報配信を停止します。</div>
  <div class="text">対象のメールアドレスを入力してください。</div>
  <form [formGroup]="form" class="form">
    <div class="form-field">
      <mat-form-field floatLabel="always" class="input-area">
        <mat-label>メールアドレス</mat-label>
        <input matInput type="email" formControlName="email" name="email" required />
        <mat-error *ngIf="email.invalid && email.dirty">メールアドレスは正しい形式で入力してください。
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>

      <div class="button-container">
        <div class="spinner">
          <mat-spinner diameter="30" *ngIf="registing"></mat-spinner>
        </div>
        <button (click)="onRegist()" mat-raised-button color="accent" [disabled]="!form.valid || registing">
          配信を停止する
        </button>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="page == 1">
  <div class="text">
    {{ companyInfo["name"] }}からの情報配信を停止しました。<br><br>
    再び情報配信を受ける場合は、下記URLから<br>
    {{ companyInfo["name"] }}とつながってください。<br><br>
    <a href="/login/{{ companyCode }}">{{ Constant.footerUrlTalentLp }}login/{{ companyCode }}</a>
  </div>
</ng-container>
</div>
