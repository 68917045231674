import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdfviewer-dialog',
  templateUrl: './pdfviewer-dialog.component.html',
  styleUrls: ['./pdfviewer-dialog.component.css']
})
export class PdfviewerDialogComponent implements OnInit {
  public pdfParam;

  constructor(
    public dialogRef: MatDialogRef<PdfviewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.pdfParam = {
      url: this.data.url,
      blueFlg: this.data.blueFlg ? true : false
    };
  }

  // 閉じる
  public close() {
    this.dialogRef.close();
  }
}
