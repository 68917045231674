import { Component, OnInit } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  constructor(private common: CommonService, private router: Router) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlMaintenance);
  }
}
