<div class="text">
  認証コード（新規登録用）を再発行します。<br />
  メールアドレスを入力してください。
</div>
<form [formGroup]="form" class="form">
  <div class="form-field">
    <mat-form-field floatLabel="always" class="input-area">
      <mat-label>メールアドレス</mat-label>
      <input matInput type="email" formControlName="email" name="email" required />
      <mat-error *ngIf="email.invalid && email.dirty">メールアドレスは正しい形式で入力してください。
      </mat-error>
    </mat-form-field>

    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>

    <div class="button-container">
      <button (click)="onSend()" mat-raised-button color="accent" [disabled]="!form.valid || mailSended">
        認証コードを送信
      </button>
    </div>
  </div>
</form>
