import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CommonService } from './../shared/common.service';
import { CompanyService } from '../shared/company.service';
import { Subscription } from 'rxjs';
import { Constant } from './../constant';
import { ProfileService } from './../shared/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { TsunagaruDialogComponent } from '../dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.css']
})
export class MypageComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public loading = false;
  public isUnread = false;
  public profile;
  public emailVerified = false;
  public isShowProgress = true;
  public addQuestionCompanyCode;
  public companys;

  private subscriptionMessage: Subscription;
  private subscriptionProfile: Subscription;
  private subscriptionCompany: Subscription;

  constructor(
    private router: Router,
    private common: CommonService,
    private profileService: ProfileService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.getCompanyQuestion();
    this.dataInitialize();
    this.checkMail();
    this.getProfileData();
    this.getPooledCompany();
  }

  ngOnDestroy() {
    this.subscriptionMessage.unsubscribe();
    this.subscriptionProfile.unsubscribe();
    this.subscriptionCompany.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  // メールアドレス認証
  onConfirmMail() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpConfirmmail]);
      })
      .catch(err => { });
  }

  // 候補企業一覧
  onCompanyList() {
    this.common.preCheck()
      .then(res => {
        this.router.navigate([Constant.rpCompanyInfo]);
      })
      .catch(err => { });
  }

  // プロフィール
  onProfile() {
    this.common
      .preCheck()
      .then(res => {
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMypageProfile);
        this.router.navigate([Constant.rpDesire]);
      })
      .catch(err => { });
  }

  // タレントプール、つながるの説明
  onWhatIsTalentPool() {
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMypageWhatsTalentpool);
    this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
    });
  }

  // 企業情報
  onCompanyInfo(code) {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }

    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyListName);
    window.open(Constant.rpLogin + '/' + code);
  }

  // メッセージ
  onMessage() {
    this.common.preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMessagelist]);
      })
      .catch(err => { });
  }
  onMessageCompany(code) {
    this.common.preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMessagedetail, code]);
      })
      .catch(err => { });
  }

  // 企業からの質問
  onSpq(code) {
    this.common.preCheck()
      .then(res => {
        this.router.navigate([Constant.rpSpq, code]);
      })
      .catch(err => { });
  }

  // コンテンツ
  onContentInfo(code) {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyListContents);
    window.open(Constant.rpContentsList + '/' + code);
  }
  // 資料
  onMaterial(code, materialCode) {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyListContents);
    window.open(Constant.rpMaterial + '/' + code + '/' + materialCode);
  }
  // 求人情報
  onJobofferInfo(code) {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyListJobOffer);
    window.open(Constant.rpJobOfferList + '/' + code);
  }

  // 取消
  onDelete(code) {
    this.common.preCheck().then(res => {
      this.deletePool(code);
    })
    .catch(err => { });
  }

  // タレントプール登録完了のメッセージ
  private showRegistpoolMessage() {
    if (this.auth.companyName) {
      // メッセージ表示
      setTimeout(() => {
        let title;
        const name = this.auth.companyName;

        let btnText = 'プロフ更新';
        let disableClose = false;
        let message = Constant.msgPoolAlreadyMessage;
        if (this.addQuestionCompanyCode) {
          btnText = '質問を見る';
          disableClose = true;
          message = Constant.msgPoolAddQuestionMessage;
        }

        if (!this.auth.alreadyRegistFlg) {
          title = name + Constant.msgPoolNewTitle;
        } else {
          title = name + Constant.msgPoolAlreadyTitle;
        }
        this.auth.companyName = '';
        this.auth.alreadyRegistFlg = false;
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: Constant.dlWidth,
          autoFocus: false,
          disableClose: disableClose,
          data: {
            title: title,
            message: message,
            type: Constant.mdTypeYesNo,
            common: this.common,
            buttonText: { left: Constant.mdBtnTextClose, right: btnText }
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === Constant.mdBtnRight) {
            if (this.addQuestionCompanyCode) {
              // 質問ページへ
              this.router.navigate([Constant.rpSpq, this.addQuestionCompanyCode]);
            } else {
              this.router.navigate([Constant.rpEducation]);
            }
          }
        });
      });
    }
  }

  private dataInitialize() {
    this.common.bottomMenu = '';
    this.emailVerified = true;
    this.common.setTitle(Constant.tlTopPage);
    this.common.bottomMenu = Constant.tlTopPage;
  }

  // メールアドレスが認証済みかどうかチェックする
  private checkMail() {
    Auth.currentUserInfo()
      .then(data => {
        this.common.debug().log(data);
        if (data && data.attributes && !data.attributes.email_verified) {
          // メールアドレス未認証
          this.emailVerified = false;
        }
      })
      .catch(err => this.common.debug().log(err));
  }

  // 企業ごとの未読メッセージ
  private checkUnreadMessage() {
    this.subscriptionMessage = this.companyService.sharedMessages$.subscribe(data => {
      this.isUnread = this.companyService.checkUnreadMessage(data);

      this.companys.forEach(company => {
        const selectedData = data.find((v) => v.company_code === company.code);

        // 企業別未読のフラグセット
        if (selectedData && selectedData.readed_flag === Constant.msgUnReaded &&
          (selectedData.type_id === Constant.msgTypeIdCompany || selectedData.type_id === Constant.msgTypeIdCompanyAll)) {
            company.unread = true;
        }
      });

    });
    this.companyService.getMessageList();
  }

  // 情報の更新があるかどうかチェックする
  private checkNewInformation() {
    this.companys.forEach(company => {
      const url = '/mypage/checkupdate/' + company.code;
      this.common.apiGet(url).then(res => {
        if (res.data.joboffer && res.data.joboffer.id && res.data.joboffer.pv_cnt === 0) {
          company.joboffer_new = true;
        }
        if (res.data.contents && res.data.contents.id && res.data.contents.pv_cnt === 0) {
          company.contents_new = true;
        }
        if (res.data.material && res.data.material.id && res.data.material.pv_cnt === 0) {
          company.material_new = true;
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.common.showToastMessage(Constant.msgErrorNetwork);
      });

      // 質問の有無チェック
      const url2 = '/question/check/' + company.code;
      this.common.apiGet(url2).then(res => {
        if (res.data?.name) {
          company.add_question = true;
          company.unanswer = res.data.unanswer;
        } else {
          company.add_question = false;
        }
      })
      .catch(err => {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        company.add_question = false;
      });
    });
  }

  // プロフィール情報を取得する
  private getProfileData() {
    const reset = true;
    // const promiseArray = [];
    this.profileService.getProfile(reset);
      this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
        if (data !== Constant.apError) {
          this.profile = data;
        }
        this.isShowProgress = false;
      });
  }

  private getPooledCompany() {
    // 登録企業一覧取得
    this.subscriptionCompany = this.companyService.sharedCompanys$.subscribe(data => {
      if (data !== Constant.apError) {
        this.companys = data;

        // コンテンツ、求人のNEWチェック
        this.checkNewInformation();

        // メッセージ未読のチェック
        this.checkUnreadMessage();

        this.loading = false;
      } else {
        this.loading = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }
    });
    this.companyService.getList();
  }

  private deletePool(code) {
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyListEntryCancel);

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        message: Constant.msgConfirmCancelPool,
        type: Constant.mdTypeYesNo,
        common: this.common,
        buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === Constant.mdBtnRight) {
        // GAイベント
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyListEntryCancelOK);

        // タレントプール取消
        this.companyService.delPool(code)
          .then(response => {
            this.common.debug().log(response);
            if (response.status === Constant.OK) {
              this.common.showCompleteDialog(Constant.msgDelPoolComplete).subscribe();
              this.companyService.getList();
            } else {
              // エラー処理
            }
          })
          .catch(error => {
            this.common.debug().log(error);
            this.common.showToastMessage(Constant.msgErrorNetwork);
          });
      }
    });
  }

  // 追加質問の有無取得
  private getCompanyQuestion() {
    const companyCode = localStorage.getItem(Constant.lsRefererCompanyCode);
    if (!companyCode) {
      this.showRegistpoolMessage();
      return;
    }

    const apiPath = '/question/check/' + companyCode;

    this.common
      .apiGet(apiPath)
      .then(res => {
        if (res.data?.name) {
          this.addQuestionCompanyCode = companyCode;
        }
        this.showRegistpoolMessage();
      })
      .catch(err => {
        this.showRegistpoolMessage();
      });

    localStorage.removeItem(Constant.lsRefererCompanyCode);
  }
}
