import { Component, OnInit, ElementRef, AfterViewInit, HostListener, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '../shared/company.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { environment } from './../../environments/environment';
import { FileService } from '../shared/file.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.css']
})
export class MessageDetailComponent implements OnInit, AfterViewInit {
  // ngForの描画完了後に処理をするためにQueryListを取得
  @ViewChildren('chatColumns')
  chatColumns: QueryList<any>;
  @ViewChild('fileInput') fileInput: ElementRef;

  public readonly Constant = Constant;
  public loading = true; // メッセージ取得中フラグ
  public lodingLastMessage = false; // 送信直後の最新メッセージ取得中フラグ
  public uploading = false;
  public deleting = false;
  public deletingId = 0;
  public confirmFlg = false;
  public waitFlag = true; // SPの入力画面切替バーの押下無効フラグ
  public companyCode: string;
  public companyName: string;
  public messages = []; // 全メッセージ
  public messageSending = false;
  public isHideScrollMessage = false;
  public isHideStartMessage = true;
  public title = 'メッセージ作成';
  public inputMode = false;
  public messageInvisible = false;
  public titleLengthCount = 30;
  public registPool = true;
  public form: FormGroup;
  public textMaxLength = false;
  public spacerHeight = 0;
  public uploadFileName = null;
  public uploadFileUrl = null;
  private uploadFilePath = null;
  private textareaInitHeight = 58;
  private apiPath: string;
  private htmlElement;
  private inScroll = false;
  private inNextMessage = false;
  private unReadMessages = []; // 未読メッセージ
  private scrollHeight = 0;
  private formNameMessage = 'message';
  private spinnerHeight = 40; // ローディングスピナーの高さ
  private pageTopFlg = false; // ページトップになった時のフラグ
  private timer;
  private safariFlg = false;
  private regexp_url = /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;

  public maxLenSendMessage = Constant.fvMaxSendMessage;

  constructor(
    private common: CommonService,
    private companyService: CompanyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private fileService: FileService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
  ) {
    this.companyCode = activatedRoute.snapshot.params['code'];
    this.htmlElement = elementRef.nativeElement;
    this.apiPath = '/message/' + this.companyCode;
  }

  ngOnInit() {
    this.getCompany();
    this.getMessage();
    this.initForm();
    window['onMessageLink'] = this.onMessageLink.bind(this)
  }

  ngAfterViewInit() {
    // messagesにデータがセットされたらスクロール処理を実行
    this.chatColumns.changes
      .subscribe(data => {
        this.waitFlag = false;
        this.setMessageDivScroll();
        this.checkUnreadMessage();
      });
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');

    // safari判別
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
      this.safariFlg = true;
    }
  }

  @HostListener('window:online', ['$event'])
  onOnline(event: any) {
    // オフラインから再度オンラインになった際、スクロール位置がトップの場合はデータ再取得
    if (window.pageYOffset < 1 && !this.inNextMessage && !this.isHideScrollMessage) {
      this.inNextMessage = true;
      this.loading = true;
      setTimeout(() => {
        this.getMessage(this.messages[0].created_at_full);
      }, 500);
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
    setTimeout(() => {
      this.setMessageDivScroll();
    });
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
    this.pageTopFlg = true;
  }

  @HostListener('window:resize')
  onResize() {
    const element = <HTMLElement>document.activeElement;
    // モバイルからPC、PCからモバイルサイズにリサイズした場合にフォーカスを外す
    if (this.inputMode && window.innerWidth > Constant.mobileWidth) {
      this.inputMode = false;
      element.blur();
    } else if (!this.inputMode && window.innerWidth <= Constant.mobileWidth) {
      element.blur();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    if (this.inputMode || this.inNextMessage || this.waitFlag) {
      return;
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.onScroll();
    }, 10);
  }

  @HostListener('wheel', ['$event'])
  onWheel(event) {
    if (this.waitFlag) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  /* API */
  // 企業のメッセージ取得
  private getMessage(date?: string) {
    this.loading = true;
    this.waitFlag = true;
    let apiPath = this.apiPath;
    if (date) {
      apiPath += '/' + date;
    }

    this.common
      .apiGet(apiPath)
      .then(res => {
        this.loading = false;
        this.checkLength(res.data.length);
        // message内のURLをリンクに変換
        this.convertMessageLink(res.data)

        if (this.messages.length > 0 && res.data.length > 0) {
          this.messages = res.data.concat(this.messages);
          // スクロール量の保持
          this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
          if (this.safariFlg) {
            // safariではスピナーの高さ分位置ずれが起こるため、調整
            this.scrollHeight += this.spinnerHeight;
          }
          // 取得中フラグOFF
          this.inNextMessage = false;
        } else if (res.data.length > 0) {
          this.messages = res.data;
        } else if (this.messages.length > 0) {
          this.waitFlag = false;
          // データが無い場合もスクロール量は保持する
          this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
          if (this.safariFlg) {
            this.scrollHeight += this.spinnerHeight;
          }
        } else {
          // データが1件も無い場合
          this.waitFlag = false;
        }
        this.setUnReadMessage();
      })
      .catch(err => {
        this.loading = false;
        this.waitFlag = false;
        this.inNextMessage = false;
        // スクロール量の保持
        this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
        if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
          // メンテナンス中、バージョンエラー時は何もしない
          if (err.message === Constant.msgErrorUnregistPool) {
            // プール未登録の場合は専用メッセージを表示
            this.registPool = false;
          } else {
            this.common.showToastMessage(Constant.msgErrorNetwork);
            if (this.messages.length > 0) {
              this.isHideScrollMessage = false;
              this.isHideStartMessage = true;
            } else {
              this.isHideScrollMessage = true;
              this.isHideStartMessage = false;
            }
          }
        }
      });
  }

  private checkLength(len: number) {
    if (len < 10) {
      this.isHideScrollMessage = true;
      this.isHideStartMessage = false;
    } else {
      this.isHideScrollMessage = false;
      this.isHideStartMessage = true;
    }
  }

  private getCompany() {
    const apiPath = '/company';
    const body = { companycode: this.companyCode };

    this.common
      .apiPostBeforeLogin(apiPath, body)
      .then(res => {
        if (res.data.name) {
          this.companyName = res.data.name;
          this.common.setTitle(this.companyName + Constant.textOfMessage);
          this.title = this.companyName + 'へのメッセージ作成';
        } else {
          // 存在しない企業はエラーページへ遷移
          this.router.navigate([Constant.rpError]);
        }
      })
      .catch(err => {
        this.common.showToastMessage(Constant.msgErrorNetwork);
      });
  }

  // メッセージ登録
  private postMessage(msg) {
    const body = { message: this.common.replaceSpace(msg) };
    if (this.uploadFilePath) {
      body['attached_file_path'] = this.uploadFilePath;
      body['attached_file_name'] = this.uploadFileName;
    }
    this.common
      .apiPost(this.apiPath, body)
      .then(res => {
        // グルグル非表示
        this.messageSending = false;
        // 入力フォームを空にする
        this.form.patchValue({[this.formNameMessage]: ''});
        this.uploadFileName = null;
        this.uploadFilePath = null;
        this.uploadFileUrl = null;
        this.fileInput.nativeElement.value = '';
        // 送信成功ダイアログ表示
        this.common.showCompleteDialog(Constant.msgSucsessSendMessage).subscribe(result => {
          // ダイアログを閉じたらSPは履歴画面に切り替え
          if (this.inputMode) {
            this.onCancel();
          }
          // 最新メッセージを取得する
          this.getLastMessage();
        });
      })
      .catch(err => {
        this.messageSending = false;
        if (err.data && err.data.deleted) {
          // プール削除済み
          this.common.showCompleteDialog(Constant.msgErrorSendMessageNoPool).subscribe();

        // 内容重複でエラーになった場合
        } else if (err.message === Constant.msgApiResSendRepeat) {
          // 入力テキストを空にする
          this.form.patchValue({[this.formNameMessage]: ''});
          this.common.showCompleteDialog(Constant.msgMsgSendRepeat).subscribe(result => {
            // ダイアログを閉じたらSPは履歴画面に切り替え
            if (this.inputMode) {
              this.onCancel();
            }
            // 最新メッセージを取得する
            this.getLastMessage();
          });
        // その他のAPIエラー
        } else if (err.message === Constant.msgErrorSendMessage) {
          this.common.showCompleteDialog(Constant.msgErrorSendMessage).subscribe();
        // タイムアウト等でレスポンスを受信出来なかった場合
        } else {
          this.common.showCompleteDialogDisableClose(Constant.msgSendErrorLong).subscribe();
        }
        // エラー時は添付ファイルをクリアする
        this.uploadFileName = null;
        this.uploadFilePath = null;
        this.uploadFileUrl = null;
        this.fileInput.nativeElement.value = '';
      });
  }

  // メッセージ送信後、最新のメッセージを取得
  private getLastMessage() {
    this.lodingLastMessage = true;
    this.waitFlag = true;
    this.common
      .apiGet(this.apiPath)
      .then(res => {
        this.lodingLastMessage = false;
        if (res.data.length > 0) {
          this.convertMessageLink(res.data)
          this.messages = res.data;
          this.scrollHeight = 0;
          this.spacerHeight = 0;
          this.inNextMessage = false;
          this.checkLength(res.data.length);
          this.deleting = false;
          this.setUnReadMessage();
        }
      })
      .catch(err => {
        this.lodingLastMessage = false;
        this.waitFlag = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.deleting = false;
      });
  }

  // 既読チェック
  private readMessage(id: number) {
    const body = {
      message_id: id
    };
    this.common
      .apiPut(this.apiPath, body)
      .then(res => {
        // メニューの未読表示を消す
        this.companyService.getMessageList();
      })
      .catch(err => { });
  }

  /* テンプレート起動 */
  // 送信ボタン
  onSend() {
    const msg = this.form.get(this.formNameMessage).value;
    if (!msg && !this.uploadFileName) {
      return;
    }
    if (msg && msg.length > this.maxLenSendMessage) {
      return;
    }

    this.common
      .preCheck()
      .then(res => {
        // GAイベント
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMessageSend);
        this.messageSending = true;
        this.postMessage(msg);
      })
      .catch(err => { });
  }

  // ファイルアップロードボタン
  onUpload() {
    if (this.messageSending) {
      return;
    }
    this.common.preCheck().then(res => {
      // 確認ビュー表示
      this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
      this.confirmFlg = true;
    });
  }

  // 背景クリックで確認ビューを閉じる
  onHideConfirmView(target) {
    if (target.className.indexOf('file-upload-back') > -1) {
      this.confirmFlg = false;
    }
  }

  // ファイル確認ビューのボタン
  onFileConfrm(res) {
    this.confirmFlg = false;
    if (res) {
      this.fileInput.nativeElement.click();
    }
  }

  // ファイル変更イベント
  onChangeFile(evt) {
    this.uploading = true;
    const file = evt.target.files[0];

    // 形式チェック
    if (file.type === Constant.imageFormatJpg || file.type === Constant.imageFormatPng || file.type === Constant.fileFormatPdf
      || file.type === Constant.fileFormatPpt || file.type === Constant.fileFormatPptx || file.type === Constant.fileFormatXls
      || file.type === Constant.fileFormatXlsx || file.type === Constant.fileFormatDoc || file.type === Constant.fileFormatDocx ) {
        // OK

    } else {
      this.uploading = false;
      this.showErrorUpload();
      return;
    }

    // サイズチェック
    if (file.size > Constant.fileMaxSize) {
      this.uploading = false;
      this.showErrorUpload();
      return;
    }

    // S3にアップロード
    this.common.preCheck().then(res => {
      const config = environment.amplify.Storage.messageFile;
      this.fileService.uploadFileToS3(file, file.name, file.type, config).then(path => {
        if (!this.uploading) {
          // タイムアウト後なら何もしない
          return;
        }
        this.uploadFilePath = path;
        this.uploadFileName = file.name;
        this.uploading = false;
        this.setUploadFileUrl();
      })
      .catch(err => {
        this.common.debug().log(err);
        this.showErrorUpload();
      });

      // タイムアウトの設定
      setTimeout(() => {
        if (this.uploading) {
          this.uploading = false;
          this.fileInput.nativeElement.value = '';
          this.showErrorUpload();
        }
      }, Constant.apiTimeoutSecond);
    })
    .catch(err => {
      this.uploading = false;
      this.fileInput.nativeElement.value = '';
    });
  }

  // アップロードしたファイルの削除
  onDelUploadFile() {
    if (this.deleting || this.messageSending) {
      return;
    }

    this.deleting = true;
    this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
    const config = environment.amplify.Storage.messageFile;

    this.common.preCheck().then(ok => {
      this.fileService.removeFileFromS3(this.uploadFilePath, config).then(url => {
        this.uploadFileName = null;
        this.uploadFilePath = null;
        this.fileInput.nativeElement.value = '';
        this.deleting = false;
        this.common.showToastMessage(Constant.msgDeleteComplete);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.deleting = false;
        this.common.showToastMessage(Constant.msgDeleteError);
      });

      // タイムアウトの設定
      setTimeout(() => {
        if (this.deleting) {
          this.deleting = false;
          this.common.showToastMessage(Constant.msgDeleteError);
        }
      }, Constant.apiTimeoutSecond);
    })
    .catch(err => {
      this.deleting = false;
    });
  }
  // メッセージ内添付ファイル削除
  onDelMessageFile(id) {
    if (this.deleting || this.messageSending) {
      return;
    }
    this.common.preCheck().then(res => {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: {
          message: Constant.msgConfirmDelSendFile,
          type: Constant.mdTypeYesNo,
          common: this.common,
          buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === Constant.mdBtnRight) {
          this.execDelSendFile(id);
        }
      });
    });
  }
  // ファイル名クリック PDFviewer表示のためリンク先はaタグで設定
  onUploadFileName(id, type) {
    if ((this.deleting && this.deletingId === id) || this.messageSending || !this.common.preCheckWindowOpen()) {
      return false;
    }

    if (id === 0) {
      localStorage.setItem(Constant.lsShowFilePath, this.uploadFilePath);
      localStorage.setItem(Constant.lsShowFileName, this.uploadFileName);
    } else if (type === Constant.msgTypeIdCompany || type === Constant.msgTypeIdCompanyAll) {
      // 企業からのメッセージの場合、クリック履歴登録
      this.clickMessageLink(id, 1, Constant.msgClickTypeFile, '')
    }
  }

  // ファイル名が長い場合、前後9文字ずつにする
  onShowFileName(name) {
    if (name.length < 19) {
      return name;
    } else {
      return name.substr(0, 9) + '…' + name.substr(-9);
    }
  }
  // 戻るボタン
  onBack() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMessagelist]);
      })
      .catch(err => { });
  }

  // スクロールイベント
  onScroll() {
    if (this.inScroll || this.messages.length === 0) {
      return;
    } else {
      this.inScroll = true;
      this.scrollExec();
    }
  }

  // 日付形式を変更
  showDate(date: string): string {
    const array = date.split(' ');
    return array[0];
  }

  // 本文の入力イベント
  onInput(target) {
    this.form.patchValue({[this.formNameMessage]: target.value});

    if (target.value.length > this.maxLenSendMessage) {
      this.textMaxLength = true;
    } else {
      this.textMaxLength = false;
    }
  }

  // テキストエリア拡張時に下部に空白を入れる対応
  onKeyUp() {
    const inputContainer = this.htmlElement.querySelector('.message-textarea');
    this.spacerHeight = (inputContainer.clientHeight - this.textareaInitHeight);
  }

  // 文字数カウント
  countInputText(txt) {
    return txt.length;
  }

  // メッセージ作成・編集
  onInputMessage() {
    // データ取得中は入力画面への切替え不可
    if (this.waitFlag) {
      return;
    }
    // 送信後に履歴欄最下を表示させるために履歴欄の高さを保存しておく
    this.scrollHeight = document.documentElement.scrollHeight - window.pageYOffset;
    this.inputMode = true;
  }

  // メッセージ作成キャンセル
  onCancel() {
    // メッセージ欄のスクロールをするためvisible:invisibleにしてからdisplay:blockとする
    this.messageInvisible = true;
    setTimeout(() => {
      this.setMessageDivScroll();
      this.inputMode = false;
      this.messageInvisible = false;
    }, 500);
  }

  // ダウンロード可否
  canDownload(filename) {
    const ext = filename.split('.').pop().toLocaleLowerCase();
    if (ext === Constant.fileExtensionPng || ext === Constant.fileExtensionJpeg
      || ext === Constant.fileExtensionJpg || ext === Constant.fileExtensionPdf) {
      return false;
    } else {
      return true;
    }
  }

  // ファイルダウンロード用のURL取得
  getFileUrl(id, type) {
    if ((this.deleting && this.deletingId === id) || this.messageSending || !this.common.preCheckWindowOpen()) {
      return false;
    }

    if (id === 0) {
      // 未送信のファイル
      this.downloadFile(this.uploadFileUrl, this.uploadFileName);
    } else {
      const apiPath = '/message/file/' + id;
      this.common.apiGet(apiPath).then(result => {
        const url = result.data.url;
        const filename = result.data.name;
        if (!url) {
          this.common.showToastMessage(Constant.msgFileDownloadError);
          return;
        }
        this.downloadFile(url, filename);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.common.showToastMessage(Constant.msgFileDownloadError);
      });

      // クリック履歴登録
      if (type === Constant.msgTypeIdCompany || type === Constant.msgTypeIdCompanyAll) {
        this.clickMessageLink(id, 1, Constant.msgClickTypeFile, '')
      }
    }
  }

  /* プライベート */
  // 未読メッセージ配列作成
  private setUnReadMessage() {
    this.unReadMessages = [];
    this.messages.forEach(element => {
      if (
        element.readed_flag === Constant.msgUnReaded &&
        (element.type_id === Constant.msgTypeIdCompany || element.type_id === Constant.msgTypeIdCompanyAll)
      ) {
        this.unReadMessages.push(element.id);
      }
    });
  }

  // 未読メッセージの既読チェック
  private checkUnreadMessage() {
    if (this.unReadMessages.length === 0) {
      return;
    }

    const scrollContainerY = window.pageYOffset;
    const newUnReadMessages = [];

    // 未読メッセージをループして表示確認
    this.unReadMessages.forEach(element => {
      const messageItem = this.htmlElement.querySelector('.message-id-' + element);
      // メッセージ要素の上端Y座標 + メッセージ要素の高さの3割
      const messageY = messageItem.offsetTop + (messageItem.clientHeight / 3);
      if (messageY >= scrollContainerY) {
        // 表示されたので既読にする
        this.readMessage(element);
      } else {
        newUnReadMessages.push(element);
      }
    });
    this.unReadMessages = newUnReadMessages;
  }

  // メッセージ内URLのリンク化
  private convertMessageLink(data) {
    data.forEach(element => {
      console.log(element.message)
      var regexp_makeLinkClick = function(url, messageId, index) {
        return '<a href="' + url + '" target="_blank" rel="noopener noreferrer" onclick="onMessageLink(event,' + messageId + ',' + index + ')">' + url + '</a>';
      }
      var regexp_makeLink = function(url) {
        return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
      }

      if (element.message) {
        const urlAllMatches = element.message.match(this.regexp_url);
        if(urlAllMatches !== null){
          // const urlMatches = new Set(urlAllMatches);
          let index = 0;

          let newMessage = ''
          let oldMessage = element.message
          urlAllMatches.forEach(url => {
            index++
            let tmp
            if (element.type_id == Constant.msgTypeIdTalent) {
              tmp = oldMessage.replace(url, regexp_makeLink(url))
            } else {
              tmp = oldMessage.replace(url, regexp_makeLinkClick(url, element.id, index))
            }

            // </a>で分割
            const newMessageAry = tmp.split('</a>', 2)
            newMessage += newMessageAry[0] + '</a>'
            if (newMessageAry.length>1) {
              oldMessage = newMessageAry[1]
            } else {
              oldMessage = ''
            }
            console.log(oldMessage)
          });
          newMessage += oldMessage
          element.message = this.domSanitizer.bypassSecurityTrustHtml(newMessage)
        }
      }

    });
  }

  // メッセージ内のリンククリック
  onMessageLink(event, messageId, index) {
    console.log(event.target.innerText)
    const detailUrl = event.target.innerText
    this.clickMessageLink(messageId, index, Constant.msgClickTypeUrl, detailUrl)
  }

  // メッセージ内リンクor添付ファイルの既読チェックAPI
  private clickMessageLink(id: number, index: number, type: number, detail: string) {
    const body = {
      message_id: id,
      link_no: index,
      type: type,
      detail_url: detail
    };
    const apiPath = '/messageclick/' + this.companyCode
    this.common
      .apiPost(apiPath, body)
      .then(res => {
        console.log(res)
      })
      .catch(err => { });
  }

  // メッセージのスクロール
  private setMessageDivScroll() {
    const bottom = document.documentElement.scrollHeight - this.scrollHeight;
    window.scrollTo(0, bottom);
    this.pageTopFlg = true;
    this.loading = false;
  }

  private scrollExec() {
    // 履歴欄の一番上までスクロールしたら次のメッセージを取得
    if (window.pageYOffset < 1 && !this.inNextMessage && !this.isHideScrollMessage && !this.pageTopFlg) {
      this.inNextMessage = true;
      this.common
      .preCheck()
      .then(res => {
        this.waitFlag = true;
        this.loading = true;
        // スクロールを強制的に一時停止させるため、メッセージ取得処理開始までに0.5秒の間を設定
        setTimeout(() => {
          this.getMessage(this.messages[0].created_at_full);
        }, 500);
      })
      .catch(err => {
        this.inNextMessage = false;
      });
    // データが再取得されるのを防ぐためにpageTopFlgで回避
    } else if (this.pageTopFlg) {
      this.pageTopFlg = false;
    }

    // 既読チェック
    this.checkUnreadMessage();

    this.inScroll = false;
  }

  private initForm() {
    this.form = new FormGroup({
      [this.formNameMessage]: new FormControl('', [Validators.maxLength(this.maxLenSendMessage)])
    });
  }

  // アップロード失敗ダイアログの表示
  private showErrorUpload() {
    this.common.showCompleteDialog(Constant.msgFileUploadError).subscribe(res => {
      this.fileInput.nativeElement.value = '';
      this.uploading = false;
    });
  }

  // アップロードしたファイルのURL取得
  private setUploadFileUrl() {
    const bucket = environment.amplify.Storage.messageFile;
    this.fileService.getUrlFromS3(this.uploadFilePath, bucket).then(url => {
      this.uploadFileUrl = url;
    })
    .catch(err => {
      this.common.debug().log(err);
    });
  }

  // ファイルダウンロード
  private downloadFile(url, fileName) {
    this.http.get(url, {responseType: 'arraybuffer'}).subscribe(data => {
      const blob = new Blob([data], {type: Constant.fileFormatDownload});
      saveAs(blob, fileName);
    }, err => {
      this.common.showToastMessage(Constant.msgFileDownloadError);
      this.common.debug().log(err);
    });
    return false;
  }

  // 添付ファイル削除実行
  private execDelSendFile(id) {
    this.common.preCheck().then(res => {
      this.deleting = true;
      this.deletingId = id;
      const apiPath = '/message/file/' + id;
      this.common.apiDel(apiPath).then(result => {
          this.common.showToastMessage(Constant.msgDeleteComplete);
          // 最新メッセージ取得
          this.getLastMessage();
        })
        .catch(err => {
          this.common.debug().log(err);
          if (err.data && err.data.deleted === 1) {
            // 削除済みエラー
            this.common.showToastMessage(Constant.msgDeleteFileAlreadyError);
            // 最新取得
            this.getLastMessage();
          } else {
            // その他エラー
            this.common.showToastMessage(Constant.msgDeleteError);
            this.deleting = false;
          }
        });
    })
    .catch(err => { });
  }
}
