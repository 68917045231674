import { Component, HostListener, OnInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from '../constant';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-special-question',
  templateUrl: './special-question.component.html',
  styleUrls: ['./special-question.component.css']
})
export class SpecialQuestionComponent implements OnInit {
  public readonly Constant = Constant;
  public companyCode: string;
  public companyName: string;
  public registPool = true;
  public title;
  public form: FormGroup;
  public questions;
  public answers;
  public showSpinner = true;
  public dataSending = false;
  private companyId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
  ) {
    this.companyCode = this.activatedRoute.snapshot.params['code'];
  }

  ngOnInit(): void {
    this.common.setTitle(Constant.empty);
    this.getCompany();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  // 文字数カウント
  countInputText(txt) {
    return txt.length;
  }

  onInput(formName, text, target) {
    // 変換前テキストでもバリデーションさせる
    this.form.patchValue({[formName]: text});

    // 高さ調整
    target.style.height = 'auto';
    const scrollHeight = target.scrollHeight - 8;  // textareaのpaddingを引く
    target.style.height = scrollHeight + 'px';
  }

  // キャンセル
  onCancel() {
    this.common.preCheck().then(res => {
      // 確認ダイアログ
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: {
          message: Constant.msgCancelAddQuestion,
          type: Constant.mdTypeYesNo,
          common: this.common,
          buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === Constant.mdBtnRight) {
          this.router.navigate([Constant.rpMypage]);
        }
      });
    })
    .catch(err => {});
  }

  // 登録
  onRegist() {
    this.common.preCheck().then(res => {
      // 確認ダイアログ
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: {
          message: Constant.msgConfirmAddQuestion,
          type: Constant.mdTypeYesNo,
          common: this.common,
          buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === Constant.mdBtnRight) {
          this.registExec();
        }
      });
    })
    .catch(err => {});

  }

  // スキップ
  onSkip(id, checked, type) {
    if (type !== Constant.aqAnswerTypeMultiSelectId) {
      if (checked) {
        this.form.get('answer' + id).disable();
      } else {
        this.form.get('answer' + id).enable();
      }
    } else {
      const item = this.questions.filter(value => value.question_id === id);
      item[0].select_item.forEach(select => {
        if (checked) {
          this.form.get('answer' + id + '_' + select.item_id).disable();
        } else {
          this.form.get('answer' + id + '_' + select.item_id).enable();
        }
      });

    }
  }

  /* API */
  private getCompany() {
    const apiPath = '/company'
    const body = {
      companycode: this.companyCode
    };

    this.common
      .apiPostBeforeLogin(apiPath, body)
      .then(res => {
        if (res.data) {
          this.companyName = res.data.display_name;
          this.title = this.companyName + Constant.textOfSpq;
          this.common.setTitle(this.title);
          this.getQuestion();
          this.getQuestionAnswer();
        } else {
          // 存在しない企業はエラーページへ遷移
          this.router.navigate([Constant.rpError]);
        }
      })
      .catch(err => {
        this.showSpinner = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      });
  }

  private getQuestion() {
    const apiPath = '/question/' + this.companyCode;
    this.common.apiGet(apiPath).then(res => {
      this.companyId = res.data.company_id;
      this.convertQuestion(res.data.questions);
      this.showSpinner = false;
    })
    .catch(err => {
      this.showSpinner = false;
      if (err.message === Constant.msgErrorUnregistPool) {
        // プール未登録の場合は専用メッセージを表示
        this.registPool = false;
      } else {
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }

    });
  }

  private getQuestionAnswer() {
    const apiPath = '/question/answer/' + this.companyCode;
    this.common.apiGet(apiPath).then(res => {
      this.answers = res.data;
      this.setValue();
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
    });
  }

  // 登録実行
  private registExec() {
    this.dataSending = true;

    // 送信データ作成
    const datas = [];
    this.questions.forEach(q => {
      let data;
      if (q.type === Constant.aqAnswerTypeTextId &&
        (this.form.get('answer' + q.question_id).value || this.form.get('skip' + q.question_id).value)) {
        const text = this.form.get('answer' + q.question_id).value.substr( 0,  Constant.fvMaxQuestionText);

        data = {
          id: null,
          question_id: q.question_id,
          type: q.type,
          answer_text: text,
          answer_id: null,
          skip_flag: Number(this.form.get('skip' + q.question_id).value)
        };
        datas.push(data);

      } else if (q.type === Constant.aqAnswerTypeOneSelectId &&
        (this.form.get('answer' + q.question_id).value || this.form.get('skip' + q.question_id).value)) {
        data = {
          id: null,
          question_id: q.question_id,
          type: q.type,
          answer_text: '',
          answer_id: this.form.get('answer' + q.question_id).value,
          skip_flag: Number(this.form.get('skip' + q.question_id).value)
        };
        datas.push(data);

      } else if (q.type === Constant.aqAnswerTypeMultiSelectId) {
        let selectCnt = 0;
        q.select_item.forEach(item => {
          if (this.form.get('answer' + q.question_id + '_' + item.item_id).value) {
            data = {
              id: null,
              question_id: q.question_id,
              type: q.type,
              answer_text: '',
              answer_id: item.item_id,
              skip_flag: Number(this.form.get('skip' + q.question_id).value)
            };
            datas.push(data);
            selectCnt++;
          }
        });
        if (this.form.get('skip' + q.question_id).value && selectCnt === 0) {
          // スキップフラグのみ登録
          data = {
            id: null,
            question_id: q.question_id,
            type: q.type,
            answer_text: '',
            answer_id: null,
            skip_flag: 1
          };
          datas.push(data);
        }
      }
    });

    // 更新日時チェック用
    const updatedAtData = this.questions.map(value => {
      return {'id': value.question_id, 'updated_at': value.updated_at};
    });

    const body = {
      company_id: this.companyId,
      answer: datas,
      updated: updatedAtData
    };
    const apiPath = '/question/answer/' + this.companyCode;
    this.common.apiPost(apiPath, body).then(res => {
      this.common.showMessage(true, '質問の回答');
      // マイページへ
      this.router.navigate([Constant.rpMypage]);
    })
    .catch(err => {
      this.common.debug().log(err);
      this.dataSending = false;
      // 失敗ダイアログ表示
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: {
          message: Constant.msgFailedAddQuestion,
          type: Constant.mdTypeOK,
          common: this.common
        }
      });
    });
  }

  /* private */
  // フォーム作成
  private initForm() {
    const formControl = {};
    this.questions.forEach(element => {
      if (element.type === Constant.aqAnswerTypeTextId) {
        formControl['answer' + element.question_id] = new FormControl(
          '',
          [Validators.maxLength(Constant.fvMaxQuestionText)]
        );
      } else if (element.type === Constant.aqAnswerTypeOneSelectId) {
        formControl['answer' + element.question_id] = new FormControl();
      } else {
        element.select_item.forEach(item => {
          formControl['answer' + element.question_id + '_' + item.item_id] = new FormControl();
        });
      }
      formControl['skip' + element.question_id] = new FormControl();
    });
    this.form = new FormGroup(formControl);
    this.setValue();
  }

  // 質問形式変換
  private convertQuestion(questions) {
    // question_id別の配列作成
    const tmp = questions.reduce(function(rv, x) {
      (rv[x['question_id']] = rv[x['question_id']] || []).push({item_id: x['item_id'], item: x['item']});
        return rv;
    }, {});

    // question_idの重複を削除
    questions = questions.filter((item, index, self) => {
      const qlist = self.map(item => item['question_id']);
      // 重複を削除する
      if (qlist.indexOf(item.question_id) === index) {
        return item;
      }
    });

    // 選択肢配列を元の配列に組み込む
    questions.forEach(element => {
      if (element['type'] !== 360) {
        element['select_item'] = tmp[element['question_id']];
      }
    });

    this.questions = questions;

    this.initForm();

  }

  // 回答セット
  private setValue() {
    if (!this.form || !this.answers) {
      return;
    }

    const formValue = {};
    this.questions.forEach(q => {
      const ans = this.answers.filter(value => value.question_id === q.question_id);
      ans.forEach(item => {
        if (q.type === Constant.aqAnswerTypeTextId) {
          formValue['answer' + q.question_id] = item.answer_text;
        } else if (q.type === Constant.aqAnswerTypeOneSelectId) {
          // 非表示になった回答は更新しない
          const select_item_show = q.select_item.filter(value => value.item_id === item.answer_id);
          if (select_item_show.length > 0) {
            formValue['answer' + q.question_id] = item.answer_id;
          }
        } else {
          formValue['answer' + q.question_id + '_' + item.answer_id] = 1;
        }

        if (item.skip_flag === 1) {
          formValue['skip' + q.question_id] = item.skip_flag;
          this.onSkip(q.question_id, true, q.type);
        }
      });
    });
    this.form.patchValue(formValue);

    setTimeout(() => {
      this.questions.forEach(q => {
        const ans = this.answers.filter(value => value.question_id === q.question_id);
        ans.forEach(item => {
          if (q.type === Constant.aqAnswerTypeTextId && item.answer_text) {
            const textarea = this.elementRef.nativeElement.querySelector('#answer' + q.question_id);
            this.onInput('answer' + q.question_id, item.answer_text, textarea);
          }
        });
      });
    });
  }
}
