import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from '../constant';
import { CommonService } from '../shared/common.service';
import { environment } from './../../environments/environment';
import { saveAs } from 'file-saver';
import { FileService } from '../shared/file.service';

@Component({
  selector: 'app-show-file',
  templateUrl: './show-file.component.html',
  styleUrls: ['./show-file.component.css']
})
export class ShowFileComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public loading = true;
  public url;
  public trustUrl;
  public fileExt = '';
  public closeFlg = false;
  private type;
  private fileId = 0;
  private fileName;
  private blob;
  public firstLoad = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private common: CommonService,
    private fileService: FileService,
  ) {
    // タイトル設定
    this.common.setTitle(Constant.tlShowFile);
  }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.data['type'];
    const id = this.activatedRoute.snapshot.params['id'];
    const path = localStorage.getItem(Constant.lsShowFilePath);

    if (id) {
      // DB登録済みファイルの場合、URL取得
      this.fileId = parseInt(id, 10);
      if (isNaN(this.fileId)) {
        this.router.navigate([Constant.rpError]);
        return;
      }
      this.getFileUrl();
    } else if (path) {
      // 未送信・アップロードしたファイルの場合
      this.getFileUrlS3(path);
      this.fileName = localStorage.getItem(Constant.lsShowFileName);
    } else {
      // その他はエラー画面に飛ばす
      this.router.navigate([Constant.rpError]);
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem(Constant.lsShowFilePath);
    localStorage.removeItem(Constant.lsShowFileName);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  public pdfLoadComplete(pdfData) {
    // PDFの高さを合わせる
    setTimeout(() => {
      const canvas = document.getElementsByClassName('pdfViewer')  as HTMLCollectionOf<HTMLElement>;
      const h = canvas[0].clientHeight;
      document.documentElement.style.setProperty('--viewHeight', h + 'px');
      this.firstLoad = false;
    }, 200);
  }

  // PDF表示エラー
  pdfError() {
    this.trustUrl = null;
  }

  // ダウンロード
  onDownload() {
    saveAs(this.blob, this.fileName);
  }

  // 閉じる
  onClose() {
    this.closeFlg = true;
    this.blob = null;
    window.close();
  }

  onCalinLogo() {
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogoCalin);
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpMypage]);
    })
    .catch(err => {});
  }

  private getFileUrl() {
    this.common.preCheck().then(res => {
      const apiPath = '/' + this.type + '/file/' + this.fileId;
      this.common.apiGet(apiPath).then(result => {
          const url = result.data.url;
          this.fileName = result.data.name;
          if (!url) {
            this.loading = false;
            return;
          }
          this.checkUrl(url);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.loading = false;
        });
    })
    .catch(err2 => {
      this.loading = false;
    });
  }

  private getFileUrlS3(path) {
    let config;
    if (this.type === Constant.fileTypeMessage) {
      config = environment.amplify.Storage.messageFile;
    } else {
      config = environment.amplify.Storage.profileFile;
    }
    this.common.preCheck().then(res => {
      this.fileService.getUrlFromS3(path, config).then(url => {
        this.checkUrl(url);
      })
      .catch(err => {
        this.common.debug().log(err);
        this.loading = false;
      });
    })
    .catch(err2 => {
      this.loading = false;
    });
  }

  // 実際にアクセスしてファイル有無をチェックする
  private checkUrl(url) {
    this.http.get(url, {responseType: 'arraybuffer'}).subscribe(data => {
      this.blob = new Blob([data], {type: Constant.fileFormatDownload});
      this.fileExt = this.fileName.split('.').pop().toLocaleLowerCase();

      // サニタイズ
      this.trustUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.url = url;
      this.loading = false;

    }, err => {
      this.loading = false;
    });
  }
}
