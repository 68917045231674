import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from './../constant';
import { ProfileService } from './../shared/profile.service';
import { Subscription } from 'rxjs';
import { CommonService } from './../shared/common.service';

@Component({
  selector: 'app-profile-desire',
  templateUrl: './profile-desire.component.html',
  styleUrls: ['./profile-desire.component.css']
})
export class ProfileDesireComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public profile; // プロフィール
  public jobName = []; // 希望職種
  public loading = true;

  private subscriptionProfile: Subscription;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private common: CommonService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);
    this.common.bottomMenu = Constant.tlProfile;
    this.common.profileMenu = Constant.tlDesire;

    // イベント登録
    this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
      if (data !== Constant.apError) {
        this.loading = false;
        this.profile = data;
        this.jobName = this.common.setShowJobNameAry(this.profile[Constant.apDesirejob]);
      } else {
        this.loading = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }
    });

    // プロフィール取得
    this.profileService.getProfile(true);
  }

  ngOnDestroy() {
    this.subscriptionProfile.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // 編集
  onEdit() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpDesireEdit]);
      })
      .catch(err => {});
  }

  // ページトップに移動
  onMovePageTop() {
    window.scroll(0, 0);
  }
}
