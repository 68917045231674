import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, ViewChild, OnInit, HostListener } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonService } from './../../shared/common.service';
import { CommonCompanyService } from './../../shared/common-company.service';
import { Constant } from './../../constant';
import { sprintf } from 'sprintf-js';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.css'
})
export class SliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data;
  public readonly Constant = Constant;
  public sliderNo = 0
  public isLikePosting = false
  public detailUrl;
  private sliderPostion = 0
  private likeContents
  private apiPathLike
  private subscriptionLike: Subscription;

  @ViewChild('calElm') calElm;

  constructor(
    private common: CommonService,
    private commonCompany: CommonCompanyService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.data.type == Constant.tlJobOffer) {
      this.detailUrl = Constant.rpJobOfferDetail
    } else {
      this.detailUrl = Constant.rpContentsDetail
    }
  }

  ngAfterViewInit() {
    // ボックスの高さを合わせる
    if (this.data.type == Constant.tlContents || this.data.type == Constant.rpContentsDetail || this.data.type == Constant.rpContentsList) {
      this.equalBoxHeight()
    }

    // いいね処理の終了
    this.subscriptionLike = this.commonCompany.sharedLikeExit$.subscribe(data => {
      this.isLikePosting = false;
      this.commonCompany.progressViewDetach();
    });
  }

  ngOnDestroy(): void {
    this.commonCompany.progressViewDetach();
    if (this.subscriptionLike) {
      this.subscriptionLike.unsubscribe();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.equalBoxHeight()
  }

  prevSlide() {
    const col = this.calElm.nativeElement.querySelector('.contents.box')
    let moveCnt = 3
    if (this.sliderNo - moveCnt < 0) {
      moveCnt = this.sliderNo
    }
    this.sliderPostion += (col.clientWidth + 30) * moveCnt
    this.sliderNo -= moveCnt
    this.calElm.nativeElement.style.transform = 'translateX(' + this.sliderPostion.toString() + 'px)'
  }

  nextSlide() {
    const col = this.calElm.nativeElement.querySelector('.contents.box')
    let moveCnt = 3
    if (this.sliderNo + moveCnt * 2 > this.data.list.length) {
      moveCnt -= this.sliderNo + moveCnt * 2 - this.data.list.length
    }
    this.sliderPostion += (col.clientWidth + 30) * -1 * moveCnt
    this.sliderNo += moveCnt
    this.calElm.nativeElement.style.transform = 'translateX(' + this.sliderPostion.toString() + 'px)'

  }

  // いいねボタン
  onLikeButton(contents) {
    this.isLikePosting = true;
    this.commonCompany.progressViewAttach();
    this.likeContents = contents
    this.apiPathLike = '/content/' + this.likeContents.code + '/like'

    this.common.preCheck().then(res => {
      if (contents.good_flag === 1) {
        // いいね解除
        this.commonCompany.delGoodWithConfirm(this.likeContents, this.data.isLogin)
        return;
      }

      if (!this.data.isLogin) {
        // 未ログインの場合、いいね実行
        this.commonCompany.execLikeBeforeLogin(contents, this.data.companyName)
        return;
      }

      // ログイン済みの場合、確認ダイアログ表示、いいねスタート
      this.commonCompany.startLikeAction(contents, this.data.companyName)

    })
    .catch(err => {
      this.isLikePosting = false;
      this.commonCompany.progressViewDetach();
    });
  }

  // タグ押下
  onTag(id) {
    this.router.navigate(
      [Constant.rpContentsTag, this.data.companyCode],
      { state: {tag_id: id} }
    );
  }

  // カテゴリーの位置設定
  private equalBoxHeight() {
    const elements = this.calElm.nativeElement.getElementsByClassName('rect') as HTMLCollectionOf<HTMLElement>

    // リセット
    for (let i = 0 ; i < elements.length ; i ++ ) {
      elements[i].style.height = 'auto';
    }

    let height;
    for (let i = 0 ; i < elements.length ; i ++ ) {
      if (i === 0) {
        height = elements[i].clientHeight
      } else if (elements[i].clientHeight && height < elements[i].clientHeight) {
        height = elements[i].clientHeight;
      }
    }
    for (let i = 0 ; i < elements.length ; i ++ ) {
      elements[i].style.height = height + 'px';
    }
  }
}
