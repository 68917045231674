<div class="text">
  メールアドレスに送信された認証コード（メールアドレス変更用）を入力してください。
</div>
<form [formGroup]="form" class="form">
  <mat-form-field class="input-area">
    <mat-label>認証コード</mat-label>
    <input matInput formControlName="code" name="code" required>
    <mat-error *ngIf="code.invalid && code.dirty">認証コードを入力してください。
    </mat-error>
  </mat-form-field>

  <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
  <mat-error *ngIf="expired">入力された認証コード（メールアドレス変更用）は無効です。
    <br />認証コードの有効期限（発行後24時間）が切れた場合は再発行してください。
    <br />
    <br />
    <a (click)="onResendMailcode()">認証コード（メールアドレス変更用）再発行</a>
  </mat-error>

  <div class="button-container">
    <button (click)="onSubmitCode(code.value)" mat-raised-button color="accent" [disabled]="!form.valid || registing">登録</button>
  </div>

  <div class="rechange">
    <button type="button" mat-raised-button (click)="onChangeMail()" class="rechange-btn">メールアドレスを再度変更する</button>
  </div>
</form>
