import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CompanyHeaderComponent } from './../entry-common/company-header/company-header.component';
import { CompanyFooterComponent } from '../entry-common/company-footer/company-footer.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { LinkyModule } from 'ngx-linky';
import { QuillModule } from 'ngx-quill';
import { ImageLoadingErrorDirective } from '../directives/image-loading-error.directive';

// quill　引用内改行のため
import Quill from 'quill';
const Delta = Quill.import('delta');
function lineBreakMatcher() {
  var newDelta = new Delta();
  newDelta.insert({'sbreak': true});
  return newDelta;
}

@NgModule({
  declarations: [
    CompanyHeaderComponent,
    CompanyFooterComponent,
    ImageLoadingErrorDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    PdfViewerModule,
    LinkyModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        clipboard: {
          matchVisual: false,
          matchers: [
            ['BR', lineBreakMatcher]
          ]
        }
      }
    })
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    PdfViewerModule,
    CompanyHeaderComponent,
    CompanyFooterComponent,
    LinkyModule,
    QuillModule,
    ImageLoadingErrorDirective,
  ]
})
export class SharedModule { }
