import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthService } from './../auth/auth.service';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { CompanyService } from './../shared/company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  private subscriptionCompany: Subscription;
  private companys;

  constructor(
    private auth: AuthService,
    private common: CommonService,
    private companyService: CompanyService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlUnsubscribe);
    this.common.bottomMenu = '';

    // 登録企業一覧取得
    this.subscriptionCompany = this.companyService.sharedCompanys$.subscribe(data => {
      this.companys = data;
    });
    this.companyService.getList();
  }

  ngOnDestroy() {
    this.subscriptionCompany.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* API */
  // 退会
  private delete() {
    const apiPath = '';
    this.common
      .apiDel(apiPath)
      .then(res => {
        // サインアウト
        this.auth.signOut();
      })
      .catch(err => {
        // DBエラーでもawsからサインアウトする
        this.auth.signOut();
      });
  }

  /* テンプレート起動 */
  onUnsbscribe() {
    this.common
      .preCheck()
      .then(res => {
        this.unsubscribeExec();
      })
      .catch(err => { });
  }

  /* プライベート */
  private unsubscribeExec() {
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUnsubscribe);

    if (this.companys && this.companys.length > 0) {
      this.dialog.open(MessageDialogComponent, {
        width: Constant.dlWidth,
        autoFocus: false,
        data: { message: Constant.msgErrorUnsubscribe, type: Constant.mdTypeOK }
      });
      return;
    }

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      data: {
        message: Constant.msgConfirmUnsubscribe,
        type: Constant.mdTypeYesNo,
        common: this.common,
        buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === Constant.mdBtnRight) {
        // GAイベント
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUnsubscribeYes);

        // 退会
        this.delete();
      }
    });
  }
}
