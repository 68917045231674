import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { CommonService } from './../../shared/common.service';

@Component({
  selector: 'app-contact-tag-dialog',
  templateUrl: './contact-tag-dialog.component.html',
  styleUrls: ['./contact-tag-dialog.component.css']
})
export class ContactTagDialogComponent implements OnInit {
  public readonly Constant = Constant;
  private common: CommonService;

  constructor(
    public dialogRef: MatDialogRef<ContactTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.common = this.data.common;
  }

  ngOnInit(): void {
  }

  /* テンプレート起動 */
  onLeft() {
    this.common.preCheck().then(res => {
      this.dialogRef.close(Constant.lsFalse);
    }, err => {});
  }

  onRight() {
    this.common.preCheck().then(res => {
      this.dialogRef.close(Constant.lsTrue);
    }, err => {});
  }

}
