<mat-dialog-content class="dialog-content">
  <h2 mat-dialog-title>表示条件の変更</h2>
  <form [formGroup]="form" class="form">
    <div class="label">勤務地</div>
    <div class="place">
      <mat-checkbox [formControlName]="Constant.apOnlyDesirePlace" class="margin">希望勤務地を含む企業のみ表示</mat-checkbox>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>業種</mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container *ngFor="let item of data.items; last as last">
          <div class="item" [class.last]="last">
            <mat-checkbox [formControlName]="item.id">{{ item[Constant.apItemValue] }}</mat-checkbox>
          </div>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="button-container">
    <button class="half" mat-raised-button mat-dialog-close>キャンセル</button>
    <button class="half" mat-raised-button color="accent" (click)="onSelect()">絞り込む</button>
  </div>
</mat-dialog-actions>

