import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  /**
   * S3にファイルをアップロードする
   * @param {Object} file   - アップロードするファイルオブジェクト
   * @param {String} name   - ファイル名
   * @param {Object} type   - ファイルタイプ
   * @param {Object} config - S3のバケット設定
   * @return {Promise}
   */
   public uploadFileToS3(file, name, type, config): Promise<any> {
    return new Promise((resolve, reject) => {
      const ext = name.split('.').pop();
      Auth.currentUserCredentials().then(session => {
        const s3Config = {
          bucket: config.bucket,
          region: config.region,
          level: config.level,
          contentType: type
        }
        Storage.put(Date.now().toString() + '.' + ext, file, s3Config)
        .then(result => {
          const path = session.identityId + '/' + result['key'];
          return resolve(path);
        })
        .catch(err2 => reject(err2));
      })
      .catch(err => reject(err));
    });
  }

  /**
   * S3にアップロードしたファイルのURLを直接取得（DB保存前などに使用）
   * @param {String} path   - S3のパス（identityIdを含む）
   * @param {Object} config - S3のバケット設定
   * @return {Promise}
   */
   public getUrlFromS3(path, config): Promise<any> {
    return new Promise((resolve, reject) => {
      const key = path.split('/')[1];
      Auth.currentUserCredentials().then(session => {
        Storage.get(key, config).then(url => {
          return resolve(url);
        })
        .catch(err2 => reject());
      })
      .catch(err => reject());
    });
  }

  /**
   * S3にアップロードしたファイルを直接削除（DB保存前などに使用）
   * @param {String} path   - S3のパス（identityIdを含む）
   * @param {Object} config - S3のバケット設定
   * @return {Promise}
   */
   public removeFileFromS3(path, config): Promise<any> {
    return new Promise((resolve, reject) => {
      const key = path.split('/')[1];
      Auth.currentUserCredentials().then(session => {
        Storage.remove(key, config).then(result => {
          return resolve(result);
        })
        .catch(err2 => reject());
      })
      .catch(err => reject());
    });
  }
}
