import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-need-login-dialog',
  templateUrl: './need-login-dialog.component.html',
  styleUrls: ['./need-login-dialog.component.css']
})
export class NeedLoginDialogComponent implements OnInit {
  public readonly Constant = Constant;
  public name: string;

  constructor(
    public dialogRef: MatDialogRef<NeedLoginDialogComponent>,
    private common: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.name = this.data.companyName;
  }

  /* テンプレート起動 */
  onLeft() {
    this.common.preCheck().then(res => {
      this.dialogRef.close(Constant.rpLoginForm);
    })
    .catch(err => {});
  }

  onRight() {
    this.common.preCheck().then(res => {
      this.dialogRef.close(Constant.rpSignup);
    })
    .catch(err => {});
  }

  onLogin() {
    this.common.preCheck().then(res => {
      this.dialogRef.close(Constant.rpLogin);
    })
    .catch(err => {});
  }

}
