
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from '../constant';
import { ColumnService } from './../shared/column.service';
import { CommonService } from '../shared/common.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.css']
})
export class ColumnComponent implements OnInit {
  public readonly Constant = Constant;
  public columnImgUrl = environment.COLUMN_IMG_URL;
  public columnList;
  public showSpinner = true;
  public columnId = 0;
  public detailData;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private columnService: ColumnService,
    private common: CommonService,
  ) {
    const id = this.activatedRoute.snapshot.params['id'];
    if (id) {
      this.columnId = parseInt(id, 10);
    }

    // URLが変更されたら再読み込みする
    this.activatedRoute.url.subscribe(params => {
      if (params[1] && params[1].path !== this.columnId.toString()) {
        this.columnId = parseInt(params[1].path, 10);
        this.detailData = null;
        this.ngOnInit();
      }
    });

    // タイトル設定
    this.common.setTitle(Constant.tlColumn);
    this.common.bottomMenu = Constant.tlColumn;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (this.columnId) {
      this.getColumnDetail();
    } else {
      this.getColumnList();
    }
  }

  public ellipsisText(text: string, maxLen: number) {
    text = this.columnService.ellipsisText(text, maxLen);
    return text;
  }

  // 一覧取得
  private getColumnList() {
    this.columnService.getColumnList().then(res => {
      this.columnList = res;
      this.showSpinner = false;
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.showSpinner = false;
    });
  }

  // 詳細取得
  private getColumnDetail() {
    this.columnService.getColumnDetail(this.columnId).then(res => {
      if (!res || res.release_status !== 1) {
        // データが取得できない場合、非公開の場合は一覧に飛ばす
        this.router.navigate([Constant.rpMyColumn]);
        return;
      }
      this.detailData = res;
      this.showSpinner = false;
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.showSpinner = false;
    });
  }
}
