import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';
import { ProfileService } from './../shared/profile.service';
import { Subscription } from 'rxjs';
import * as loadImage from 'blueimp-load-image';
import { FileService } from './../shared/file.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-profile-basic-edit',
  templateUrl: './profile-basic-edit.component.html',
  styleUrls: ['./profile-basic-edit.component.css']
})
export class ProfileBasicEditComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public profile; // プロフィール
  public sexTypeMaster; // 性別マスタ
  public desireJobPlaceType; // 勤務地マスタ
  public form: FormGroup;
  public errorMessageDate: string;
  public errorMessageImage: string;
  private imagePath; // プロフィール画像パス
  public imageUrl; // プロフィール画像URL
  public dataSending = false;
  public lastNameMaxLength = false; // 「姓」の入力制御
  public firstNameMaxLength = false; // 「名」の入力制御
  public lastNameKanaMaxLength = false; // 「姓 かな」の入力制御
  public firstNameKanaMaxLength = false; // 「名 かな」の入力制御
  public address2MaxLength = false; // 「市区町村」の入力制御

  private subscriptionProfile: Subscription;
  private subscriptionMaster: Subscription;
  private subscriptionImage: Subscription;

  constructor(
    private router: Router,
    private common: CommonService,
    private profileService: ProfileService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlProfile);

    // イベント登録
    this.subscriptionProfile = this.profileService.sharedProfile$.subscribe(data => {
      this.profile = data;
      // フォーム作成
      setTimeout(() => {
        this.initForm();
      });
    });
    this.subscriptionMaster = this.profileService.sharedMaster$.subscribe(data => {
      data.forEach(element => {
        if (element.type === Constant.pmSexType) {
          this.sexTypeMaster = element.data;
        } else if (element.type === Constant.pmDesireJobPlaceType) {
          this.desireJobPlaceType = element.data;
        }
      });
    });
    this.subscriptionImage = this.profileService.sharedImage$.subscribe(data => {
      this.imageUrl = data;
    });

    // プロフィール、マスタ取得
    const reset = false;
    this.profileService.getProfile(reset);
    this.profileService.getMaster(reset);
    this.profileService.getImage(reset);
  }

  ngOnDestroy() {
    this.subscriptionProfile.unsubscribe();
    this.subscriptionMaster.unsubscribe();
    this.subscriptionImage.unsubscribe();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  /* テンプレート起動 */
  // キャンセル
  onCancel() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpBasic]);
      })
      .catch(err => { });
  }

  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  onInput(target, maxLenFlg, maxLenCnt) {
    const formName = target.getAttribute('formControlName');
    const beforeText = target.value;
    const changeText = beforeText.replace(/\r?\n/g, '');
    this.form.patchValue({[formName]: beforeText});
    // 改行不可の入力欄に改行入りテキストがペーストされた場合は改行無しに変換したテキストに置き換える
    if (beforeText !== changeText) {
      this.form.patchValue({[formName]: changeText});
    }
    // 入力内容が最大文字数制限を超えた場合は各フラグをtrueにする
    if (target.value.length > maxLenCnt) {
      this[maxLenFlg] = true;
    } else {
      this[maxLenFlg] = false;
    }
  }

  // 文字数カウント
  countInputText(txt) {
    return txt.length;
  }

  // フォーカス移動
  onNextFocus(len, value, nextElm) {
    this.common.nextFocus(len, value, nextElm);
  }

  // 登録
  onRegist() {
    this.errorMessageDate = '';

    this.common
      .preCheck()
      .then(res => {
        this.registExec();
      })
      .catch(err => { });
  }

  // メールアドレス変更
  onChangeMail() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpAccountMail]);
      })
      .catch(err => { });
  }

  // 画像選択
  onChangePhoto(evt) {
    this.errorMessageImage = '';

    this.common
      .preCheck()
      .then(res => {
        const file = evt.target.files[0];
        this.changePhotoExec(file);
      })
      .catch(err => { });
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      last_name: new FormControl(this.profile.last_name, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName)
      ]),
      first_name: new FormControl(this.profile.first_name, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName)
      ]),
      last_name_kana: new FormControl(this.profile.last_name_kana, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName),
        Validators.pattern(/^[\u3040-\u309f|\u002d|\u002f|\u003d|\u30fb|\u30fc|\uff0f|\uff1d|\uff65]+$/)
      ]),
      first_name_kana: new FormControl(this.profile.first_name_kana, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthName),
        Validators.pattern(/^[\u3040-\u309f|\u002d|\u002f|\u003d|\u30fb|\u30fc|\uff0f|\uff1d|\uff65]+$/)
      ]),
      sex_id: new FormControl(this.profile.sex_id, [Validators.required]),
      birthday_year: new FormControl(this.profile.birthday_year, [
        Validators.required,
        Validators.min(Constant.fvMinYear),
        Validators.max(Constant.fvMaxYear)
      ]),
      birthday_month: new FormControl(this.profile.birthday_month, [
        Validators.required,
        Validators.min(Constant.fvMinMonth),
        Validators.max(Constant.fvMaxMonth)
      ]),
      birthday_day: new FormControl(this.profile.birthday_day, [
        Validators.required,
        Validators.min(Constant.fvMinDay),
        Validators.max(Constant.fvMaxDay)
      ]),
      address1: new FormControl(this.profile.address1, [Validators.required, Validators.maxLength(5)]),
      address2: new FormControl(this.profile.address2, [
        Validators.required,
        Validators.maxLength(Constant.fvMaxLengthCityName)
      ]),
      telno: new FormControl(this.profile.telno, [
        Validators.pattern(/^[0-9]*$/),
        Validators.required,
        Validators.minLength(Constant.fvMinLengthTelNo)
      ])
    });
  }

  // BlobをDataURLに変換
  private getDataUrl(blobImage: Blob, options: Object): Promise<any> {
    return new Promise(resolve => {
      loadImage(
        blobImage,
        canvas => {
          resolve(canvas.toDataURL(blobImage.type));
        },
        options
      );
    });
  }

  // 引数のBase64の文字列をBlob形式にする
  private base64ToBlob(base64, filetype) {
    const base64Data = base64.split(',')[1], // Data URLからBase64のデータ部分のみを取得
      data = window.atob(base64Data), // base64形式の文字列をデコード
      buff = new ArrayBuffer(data.length),
      arr = new Uint8Array(buff);
    // blobの生成
    for (let i = 0, dataLen = data.length; i < dataLen; i++) {
      arr[i] = data.charCodeAt(i);
    }
    const blob = new Blob([arr], { type: filetype });
    return blob;
  }

  // 登録実行
  private registExec() {
    // 生年月日チェック
    const birthday = this.common.dateFromYearMonthDay(
      this.form.get(Constant.apBirthdayYear).value,
      this.form.get(Constant.apBirthdayMonth).value,
      this.form.get(Constant.apBirthdayDay).value
    );
    if (!birthday) {
      this.errorMessageDate = Constant.msgErrorInvalidDate;
      return;
    }

    // データ送信中スピナー表示
    this.dataSending = true;

    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUpdateProfileBasic);

    const data = {};
    data[Constant.apBase] = [];
    for (const formName in this.form.value) {
      if (
        formName === Constant.apBirthdayYear ||
        formName === Constant.apBirthdayMonth ||
        formName === Constant.apBirthdayDay ||
        formName === Constant.apMailAddress
      ) {
        continue;
      }
      if (this.form.value.hasOwnProperty(formName)) {
        data[Constant.apBase].push({
          key: formName,
          value: this.common.replaceSpace(this.form.value[formName])
        });
      }
    }

    // 生年月日
    data[Constant.apBase].push({
      key: Constant.apBirthday,
      value: birthday
    });

    // プロフィール画像
    if (this.imagePath) {
      data[Constant.apBase].push({
        key: Constant.apImagePath,
        value: this.imagePath
      });
    }

    const msgTitle = '基本情報';
    this.profileService
      .postProfile(data)
      .then(result => {
        this.common.debug().log(result);
        this.dataSending = false;
        if (result.status === Constant.OK) {
          this.common.showMessage(true, msgTitle);
          this.router.navigate([Constant.rpBasic]);
        } else {
          // APIエラーの場合は「更新に失敗しました」トーストを表示
          this.common.showMessage(false, msgTitle);
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.dataSending = false;
        // オフライン等で結果が取得出来なかった場合は「失敗した可能性があります」トーストを表示
        this.common.showToastMessage(Constant.msgUpdateError);
      });
  }

  // 写真登録実行
  private changePhotoExec(file) {
    // ファイル形式チェック
    if (file.type !== Constant.imageFormatJpg && file.type !== Constant.imageFormatPng) {
      this.errorMessageImage = Constant.msgErrorInvalidFile;
      return;
    }

    loadImage.parseMetaData(file, data => {
      const options = {
        orientation: null,
        maxHeight: 200,
        maxWidth: 200,
        canvas: true
      };
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }
      this.getDataUrl(file, options).then(dataUrl => {
        const uploadBlob = this.base64ToBlob(dataUrl, file.type);

        // S3にアップロード
        const config = environment.amplify.Storage.profilePicture;
        this.fileService.uploadFileToS3(uploadBlob, file.name, file.type, config).then(path => {
          this.imagePath = path;
          this.imageUrl = dataUrl;
        })
        .catch(err => {
          this.common.debug().log(err);
        });
      });
    });
  }

  // form validation
  isSexIdValid() {
    let isInvalid = true;
    const sexId = this.form.get(Constant.apSexId).value;
    if (sexId !== Constant.prfSexNotSelected) {
      isInvalid = false;
    }
    return isInvalid;
  }
}
