<div *ngIf="page === 1" class="content">
  <div class="image-area">
    <img src="assets/walkthrough/walkthrough1.jpg">
  </div>
  <div class="text-area">
    <h4>企業とつながる</h4>
    <p>「候補企業」から、気になる企業とつながりましょう。</p>
    <p>つながりは「解消」することも可能です。つながりを解消すると、企業はあなたのプロフィールを確認できなくなります。</p>
  </div>
  <div class="question-area">
    <div class="dialog">
    <img
      src="assets/common/question.png"
      srcset="assets/common/question@2x.png 1x, assets/common/question@2x.png 2x"
      class="icon"
    />
    <a (click)="onWhatIsTalentPool()" class="color-orange">
      つながるとは
    </a>
    </div>
  </div>
</div>

<div *ngIf="page === 2" class="content">
  <div class="image-area">
    <img src="assets/walkthrough/walkthrough2.jpg">
  </div>
  <div class="text-area">
    <h4>コンテンツや求人情報を見る</h4>
    <p>企業とつながったら「マイページ トップ」から、「コンテンツ」や「求人情報」をチェックしましょう。</p>
    <p>積極的に「いいね」や「気になる」などのアクションをしてみましょう。</p>
  </div>
</div>

<div *ngIf="page === 3" class="content">
  <div class="image-area">
    <img src="assets/walkthrough/walkthrough3.jpg">
  </div>
  <div class="text-area">
    <h4>メッセージを見る/送る</h4>
    <p>「メッセージ」はつながっている企業のみと可能です。</p>
    <p>採用に関する質問などもOKです。</p>
    <p>つながっていない企業からメッセージが届くことはありません。</p>
  </div>
</div>

<div *ngIf="page === 4" class="content">
  <div class="image-area">
    <img src="assets/walkthrough/walkthrough4.jpg">
  </div>
  <div class="text-area">
    <h4>プロフィールを登録/更新する</h4>
    <p>学歴や職歴などを登録して「プロフィール」を充実させましょう。添付ファイルも登録できます。</p>
    <p><span class="color-orange">定期的にプロフィールを更新</span>して採用担当者にアピールしましょう！</p>
  </div>
</div>

<div class="button-area">
    <button *ngIf="page !== 1" mat-raised-button type="button" class="back-btn" (click)="onBack()">戻る</button>
    <button *ngIf="page !== 4" mat-raised-button type="button" class="next-btn" (click)="onNext()">次へ</button>
    <button *ngIf="page === 4" mat-raised-button type="button" class="edit-btn" (click)="onNext()">プロフィールを編集</button>
</div>
