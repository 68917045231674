<ul class="profile-tab">
  <li (click)="onDesire()" class="{{ type == Constant.tlDesire ? 'selected' : '' }}">
    就業/転職<br />希望情報
  </li>
  <li (click)="onEducation()" class="{{ type == Constant.tlEducation ? 'selected' : '' }}">学歴</li>
  <li (click)="onCareer()" class="{{ type == Constant.tlCareer ? 'selected' : '' }}">職歴</li>
  <li (click)="onBasic()" class="{{ type == Constant.tlBasic ? 'selected' : '' }}">基本情報</li>
  <li (click)="onFile()" class="{{ type == Constant.tlFile ? 'selected' : '' }}">ファイル<br>添付</li>
</ul>
<div class="spacer"></div>
