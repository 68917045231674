import type { OverlayRef } from '@angular/cdk/overlay';

export class AppOverlayRef<TComponent = any> {

  component!: TComponent;

  constructor(
    private readonly _overlayRef: OverlayRef,
  ) {}

  close(): void {
    this._overlayRef.dispose();
  }
}
