import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { CommonService } from './../shared/common.service';
import { Constant } from './../constant';

@Component({
  selector: 'app-resend-mailcode',
  templateUrl: './resend-mailcode.component.html',
  styleUrls: ['./resend-mailcode.component.css']
})
export class ResendMailcodeComponent implements OnInit {
  public showSpinner = false;
  public errorMessage: string;

  constructor(private router: Router, private common: CommonService) {}

  ngOnInit() {
    // タイトル設定
    this.common.setTitle(Constant.tlResendMail);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.common.checkRefreshToken();
  }

  // テンプレート起動
  onSend() {
    this.common
      .preCheck()
      .then(res => {
        this.sendExec();
      })
      .catch(err => {});
  }

  /* プライベート */
  private sendExec() {
    this.showSpinner = true;
    Auth.currentUserInfo()
      .then(data => {
        this.common.debug().log(data);
        if (data && data.attributes && data.attributes.email_verified) {
          // メールアドレス認証済
          this.showSpinner = false;
          this.errorMessage = Constant.msgAlreadyVarified;
        } else {
          Auth.verifyCurrentUserAttribute('email')
            .then(result => {
              this.router.navigate([Constant.rpConfirmmail]);
            })
            .catch(error => {
              this.common.debug().log(error);
              this.showSpinner = false;
              if (error.code === Constant.awsErrorLimitExceededException) {
                this.errorMessage = Constant.msgErrorLimitExceeded;
              } else {
                this.common.showToastMessage(Constant.msgErrorNetwork);
              }
            });
        }
      })
      .catch(err => this.common.debug().log(err));
  }
}
