<app-lp-header page="{{ Constant.rpLpPage }}"></app-lp-header>
<div class="wrap" *ngIf="!loading">
  <img src="/assets/lp/txt_search.png" class="title-img">
  <h2>気になる企業を探す</h2>
  <app-lp-search-box (searchCompany)="searchCompany($event)"></app-lp-search-box>

  <!-- ページング -->
  <ng-container *ngTemplateOutlet="paging"></ng-container>
  <ng-template #paging>
    <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="onPagingNum($event)">
      <div class="custom-pagination">
        <div class="pagination-container">
          <ng-container *ngIf="companyInfoList && companyInfoList.length > 0">
            <div *ngIf="p.isFirstPage()" class="paging-link-container paging-disable">
              <span>＜</span>
            </div>
            <div mat-button *ngIf="!p.isFirstPage()" class="paging-link-container" (click)="p.previous()">
              <span class="paging-text">＜</span>
            </div>
            <div *ngFor="let page of p.pages">
              <div mat-button class="paging-link-container" (click)="p.setCurrent(page.value)"
                *ngIf="p.getCurrent() !== page.value">
                <span class="paging-text">{{ page.label }}</span>
              </div>
              <div class="paging-link-container current-page" *ngIf="p.getCurrent() === page.value">
                <span class="paging-text current">{{ page.label }}</span>
              </div>
            </div>
            <div *ngIf="p.isLastPage()" class="paging-link-container paging-disable">
              <span>＞</span>
            </div>
            <div mat-button *ngIf="!p.isLastPage()" class="paging-link-container" (click)="p.next()">
              <span class="paging-text">＞</span>
            </div>
          </ng-container>
        </div>
      </div>
    </pagination-template>
  </ng-template>

  <div *ngIf="showSpinner" class="spinner">
    <mat-spinner diameter="60"></mat-spinner>
  </div>

  <div class="company">
    <div *ngIf="!showSpinner && companyInfoList.length == 0" class="no-data">
      検索条件に該当する企業がありませんでした。
    </div>
    <ng-container *ngFor="let companyInfo of (companyInfoList | paginate : {
                          id: config.id,
                          itemsPerPage: config.itemsPerPage,
                          currentPage: config.currentPage,
                          totalItems: config.totalItems
                          }); index as idx; last as last">
      <div *ngIf="companyInfo.code != null" class="company-contents">
        <div *ngIf="companyInfo.logo_url != Constant.empty" class="name-container">
          <img class="logo" [lazyLoad]="companyInfo.logo_url" />
          <span class="name"> {{ companyInfo.name }} </span>
        </div>
        <div *ngIf="companyInfo.pr_image_url != Constant.empty" class="image-container">
          <img class="image" [lazyLoad]="companyInfo.pr_image_url" />
        </div>
        <div class="detailinfo-area">
          <div *ngIf="companyInfo.employment_area && companyInfo.employment_area != Constant.empty" class="row employment-area">
            <div class="title">勤務地：</div>
            <div class="value">{{ companyInfo.employment_area }}</div>
          </div>
          <div *ngIf="companyInfo.industry && companyInfo.industry != Constant.empty" class="row industry">
            <div class="title">業種：</div>
            <div class="value">{{ companyInfo.industry }}</div>
          </div>
        </div>

        <div class="main-copy-area" *ngIf="companyInfo.summary">
          <div class="text-all" id="{{ Constant.elementIdLpMaincopyAll }}{{idx}}">{{ companyInfo.summary }}</div>
        </div>

        <div class="more-btn-area">
          <a routerLink='/{{ Constant.rpLogin }}/{{companyInfo.code}}' target="_blank" class="more-btn" (click)="onClickCompanyDetail(idx)">
            <div *ngIf="companyInfo.document_path" class="document">スライド資料あり</div>
            <div [class.more-btn-top]="companyInfo.document_path">企業の詳細ページへ</div>
            <img src="assets/lp/arrow-white.svg" />
          </a>
        </div>
        <div class="bottom-separator" *ngIf="!last"></div>
      </div>
    </ng-container>
  </div>

  <!-- ページング -->
  <ng-container *ngTemplateOutlet="paging"></ng-container>

  <mat-divider></mat-divider>

  <div class="calin-desc">
    企業各社とつながるには、キャリン株式会社が運営するタレントプール採用サービス「CaLin」のアカウントを作成する必要があります。CaLinのご利用は完全無料です。
  </div>

  <div class="calin-registration">
    <a mat-button class="registration-btn" routerLink='/{{ Constant.rpSignup }}' (click)="onClickGAEvent(Constant.gaActionPageAccount)">会員登録（無料）</a>
  </div>

</div>
<app-lp-footer></app-lp-footer>
