<mat-dialog-content class="dialog-content">
  <h2 mat-dialog-title [class.title-year]="data.type==Constant.sdTypeYear">{{ data.title }}</h2>
  <form [formGroup]="form">
    <ng-container *ngIf="data.type == Constant.sdTypePlace">
      <div class="item pad"><mat-checkbox class="location" [formControlName]="place_all" (change)="onPlaceAll($event.checked)">全国</mat-checkbox></div>
      <ng-container *ngFor="let item of data.items">
        <div class="item large pad">
          <mat-checkbox class="location" [formControlName]="item[Constant.apLargeId]" (change)="onLargeId($event.checked, item[Constant.apLargeId])">
            {{ item[Constant.apLargeItemValue] }}
          </mat-checkbox>
        </div>
        <div class="item middle half pad" *ngFor="let middle of item.middle">
          <mat-checkbox class="location" [formControlName]="item[Constant.apLargeId]+'-'+ middle.id" (change)="onMiddelId(item[Constant.apLargeId])">{{
            middle[Constant.apItemValue]
          }}</mat-checkbox>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data.type == Constant.sdTypeJob">
      <div *ngFor="let item of data.items">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{ item[Constant.apLargeItemValue] }}
          </mat-expansion-panel-header>
          <div class="item">
            <mat-checkbox
              class="job"
              formControlName="{{ item[Constant.apLargeId] }}"
              (change)="onLargeId($event.checked, item[Constant.apLargeId])"
              >{{ item[Constant.apLargeItemValue] }}全て</mat-checkbox
            >
          </div>
          <div class="item" *ngFor="let middle of item.middle">
            <mat-checkbox class="job" formControlName="{{ item[Constant.apLargeId] }}-{{ middle.id }}">{{
              middle[Constant.apItemValue]
            }}</mat-checkbox>
          </div>
        </mat-expansion-panel>
      </div>
    </ng-container>
    <ng-container *ngIf="data.type == Constant.sdTypeYear">
      <mat-radio-group [formControlName]="graduate">
        <div class="item large half year" *ngFor="let item of data.items">
          <mat-radio-button class="example-radio-button" [value]="item.id" (click)="onRadio(item.id, 'graduate', $event)">
            {{item[Constant.apItemValue]}}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </ng-container>
    <ng-container *ngIf="data.type == Constant.sdTypeSchool">
      <mat-radio-group [formControlName]="school">
        <div class="item large half year" *ngFor="let item of data.items">
          <mat-radio-button class="example-radio-button" [value]="item.id ">
            {{item[Constant.apItemValue]}}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="button-container">
    <button class="half" mat-raised-button mat-dialog-close>キャンセル</button>
    <button class="half" mat-raised-button color="accent" (click)="onSelect()">選択</button>
  </div>
</mat-dialog-actions>
